<template>
  <v-card class="tablesize">
    <block v-if="loading"></block>
    <v-btn @click="close" elevation="0" color="red" class="close-btn"
      ><v-icon>mdi-close</v-icon></v-btn
    >
    <div class="py-10 px-4">
      <v-card-title class="font-weight-bold"><h3>Exportar CSV</h3></v-card-title>
      <v-card-text class="mt-5">
        <!-- month & year -->
        <v-row class="mt-2">
          <v-col cols="12" md="6">
            <v-select
              v-model="search.month"
              :items="monthList"
              label="Mes"
              outlined
              attach
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="search.year"
              :items="yearList"
              label="Año"
              outlined
              attach
              dense
            ></v-select>
          </v-col>
        </v-row>
        <!-- month & year -->
       <!--  <v-btn
          @click.prevent="getSalesTaxpayer"
        >
          test
        </v-btn> -->
        <!-- Select all -->

        <v-row>
            <v-col cols="12">
                <v-radio-group v-model="dataType">
                    <v-radio label="Ver reporte con datos de prueba" value="00"></v-radio>
                    <v-radio label="Ver reporte con datos de producción" value="01"></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>

        <v-radio-group v-model="selectedRadio">
          <v-row class="my-3">
            <v-col cols="12" md="6" sm="6">
              <v-radio
                label="Seleccionar todos"
                value="selectAll"
                @click="handleRadioClick('selectAll')"
              ></v-radio>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-radio
                label="Desmarcar todos"
                value="deselectAll"
                @click="handleRadioClick('deselectAll')"
              ></v-radio>
            </v-col>
          </v-row>
        </v-radio-group>
        <!-- Select all -->

        <!-- inputs -->
        <h3>Opciones</h3>
        <v-row class="mt-3">
          <v-col cols="6" v-for="(option, index) in options" :key="index">
            <v-checkbox
              v-model="selectedOptions"
              :label="option.text"
              :value="option.value"
            ></v-checkbox>
          </v-col>

          <v-col cols="12" class="mt-5 d-flex justify-center">
            <v-btn class="btn-add" @click="showSelections"
              ><v-icon class="mr-3">mdi-download</v-icon> Guardar CSV</v-btn
            >
          </v-col>
        </v-row>
        <!-- inputs -->
      </v-card-text>
    </div>
    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    ></alerts>
  </v-card>
</template>

<script>
import Block from "@/components/Block";
import alerts from "@/components/Alerts.vue";
import { convertToCSV, getCurrentDateTimeString } from "../../../helpers/convertToCSV";
export default {
  name: "AccountingExportCSV",

  props: ["win"],

  components: { Block, alerts },

  data() {
    return {
      dataType: '01',
      panel: 0,
      tab: null,
      key: 0,
      search: {
        month: null,
        year: null,
        dataType: ''
      },
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      selectedOptions: [],
      options: [
        {
          text: "Ventas Contribuyente",
          value: "vc",
          titleHeaders: [
              'FECHA EMISIÓN DEL DOCUMENTO',
              'CLASE DE DOCUMENTO',
              'TIPO DE DOCUMENTO',
              'NÚMERO DE RESOLUCIÓN',
              'NUMERO DE SERIE DE DOCUMENTO',
              'NÚMERO DE DOCUMENTO',
              'NÚMERO DE CONTROL INTERNO',
              'NIT O NRC DEL CLIENTE',
              'NOMBRE, RAZÓN SOCIAL O DENOMINACIÓN',
              'VENTAS EXENTAS',
              'VENTAS NO SUJETAS',
              'VENTAS GRAVADAS LOCALES',
              'DÉBITO FISCAL',
              'VENTAS A CUENTA DE TERCEROS NO DOMICILIADOS',
              'DÉBITO FISCAL POR VENTA A CUENTA DE TERCEROS',
              'TOTAL VENTAS',
              'DUI DEL CLIENTE',
              'NÚMERO DE ANEXO'
          ],
          dataHeaders: [
              'FECHA EMISIÓN DEL DOCUMENTO',
              'CLASE DE DOCUMENTO',
              'TIPO DE DOCUMENTO',
              'NÚMERO DE RESOLUCIÓN',
              'NUMERO DE SERIE DE DOCUMENTO',
              'NÚMERO DE DOCUMENTO',
              'NÚMERO DE CONTROL INTERNO',
              'NIT O NRC DEL CLIENTE',
              'NOMBRE, RAZÓN SOCIAL O DENOMINACIÓN',
              'VENTAS EXENTAS',
              'VENTAS NO SUJETAS',
              'VENTAS GRAVADAS LOCALES',
              'DÉBITO FISCAL',
              'VENTAS A CUENTA DE TERCEROS NO DOMICILIADOS',
              'DÉBITO FISCAL POR VENTA A CUENTA DE TERCEROS',
              'TOTAL VENTAS',
              'DUI DEL CLIENTE',
              'NÚMERO DE ANEXO'
          ],
        },
        {
          text: "Ventas Consumidor final",
          value: "vcf",
          titleHeaders: [
            "InvoiceDate",
            "ClaseDeDocumento",
            "TipoDeDocumento",
            "NumeroDeResolucion",
            "SerieDeDocumento",
            "NumeroDeControlInternoDel",
            "NumeroDeControlInternoDelAl",
            "NumeroDeDocumentoDel",
            "NumeroDeDocumentoAl",
            "NoDeMaquinaRegistradora",
            "VentasExentas",
            "VentasInternasExentasNoSujetasAProporcionalidad",
            "VentasNoSujetas",
            "VentasGravadasLocales",
            "ExportacionesDentroDelAreaCentroamericana",
            "ExportacionesFueraDelAreaCentroamericana",
            "ExportacionesDeServicios",
            "VentasAZonasFrancasYDPATasaCero",
            "VentaACuentaDeTercerosNoDomiciliados",
            "TotalVentas",
            "NumeroDeAnexo"
          ],
          dataHeaders: [
            "InvoiceDate",
            "ClaseDeDocumento",
            "TipoDeDocumento",
            "NumeroDeResolucion",
            "SerieDeDocumento",
            "NumeroDeControlInternoDel",
            "NumeroDeControlInternoDelAl",
            "NumeroDeDocumentoDel",
            "NumeroDeDocumentoAl",
            "NoDeMaquinaRegistradora",
            "VentasExentas",
            "VentasInternasExentasNoSujetasAProporcionalidad",
            "VentasNoSujetas",
            "VentasGravadasLocales",
            "ExportacionesDentroDelAreaCentroamericana",
            "ExportacionesFueraDelAreaCentroamericana",
            "ExportacionesDeServicios",
            "VentasAZonasFrancasYDPATasaCero",
            "VentaACuentaDeTercerosNoDomiciliados",
            "TotalVentas",
            "NumeroDeAnexo"
          ]
        },
        {
          text: "Documentos Anulados",
          value: "da",
          titleHeaders: [
            "NÚMERO DE RESOLUCIÓN",
            "CLASE DE DOCUMENTO",
            "DESDE (PREIMPRESO)",
            "HASTA (PREIMPRESO)",
            "TIPO DE DOCUMENTO",
            "TIPO DE DETALLE",
            "SERIE",
            "DESDE",
            "HASTA",
            "CÓDIGO DE GENERACIÓN"
          ],
          dataHeaders: [
            "NÚMERO DE RESOLUCIÓN",
            "CLASE DE DOCUMENTO",
            "DESDE (PREIMPRESO)",
            "HASTA (PREIMPRESO)",
            "TIPO DE DOCUMENTO",
            "TIPO DE DETALLE",
            "SERIE",
            "DESDE",
            "HASTA",
            "CÓDIGO DE GENERACIÓN"
          ]
        },
        {
          text: "Comprobante Retención Recibos",
          value: "crr",
          titleHeaders: [
              "NIT DEL AGENTE",
              "FECHA DE EMISIÓN",
              "TIPO DE DOCUMENTO",
              "SERIE",
              "NÚMERO DE DOCUMENTO",
              "MONTO SUJETO",
              "MONTO RETENCIÓN 1%",
              "DUI DEL AGENTE",
              "NÚMERO DE ANEXO"
          ],
          dataHeaders: [
              "NIT DEL AGENTE",
              "FECHA DE EMISIÓN",
              "TIPO DE DOCUMENTO",
              "SERIE",
              "NÚMERO DE DOCUMENTO",
              "MONTO SUJETO",
              "MONTO RETENCIÓN 1%",
              "DUI DEL AGENTE",
              "NÚMERO DE ANEXO"
          ],
        },
        {
          text: "Compras",
          value: "c",
          titleHeaders: [
              "FECHA DE EMISIÓN",
              "CLASE DE DOCUMENTO",
              "TIPO DE DOCUMENTO",
              "NÚMERO DE DOCUMENTO",
              "NIT O NRC DEL PROVEEDOR",
              "NOMBRE DEL PROVEEDOR",
              "COMPRAS INTERNAS EXENTAS Y/O NO SUJETAS",
              "INTERNACIONES EXENTAS Y/O NO SUJETAS",
              "IMPORTACIONES EXENTAS Y/O NO SUJETAS",
              "COMPRAS INTERNAS GRAVADAS",
              "INTERNACIONES GRAVADAS DE BIENES",
              "IMPORTACIONES GRAVADAS DE BIENES",
              "IMPORTACIONES GRAVADAS DE SERVICIOS",
              "CRÉDITO FISCAL",
              "TOTAL DE COMPRAS",
              "DUI DEL PROVEEDOR",
              "TIPO DE OPERACIÓN",
              "CLASIFICACIÓN",
              "SECTOR",
              "TIPO DE COSTO / GASTO",
              "NÚMERO DE ANEXO"
          ],
          dataHeaders:[
              "FECHA DE EMISIÓN",
              "CLASE DE DOCUMENTO",
              "TIPO DE DOCUMENTO",
              "NÚMERO DE DOCUMENTO",
              "NIT O NRC DEL PROVEEDOR",
              "NOMBRE DEL PROVEEDOR",
              "COMPRAS INTERNAS EXENTAS Y/O NO SUJETAS",
              "INTERNACIONES EXENTAS Y/O NO SUJETAS",
              "IMPORTACIONES EXENTAS Y/O NO SUJETAS",
              "COMPRAS INTERNAS GRAVADAS",
              "INTERNACIONES GRAVADAS DE BIENES",
              "IMPORTACIONES GRAVADAS DE BIENES",
              "IMPORTACIONES GRAVADAS DE SERVICIOS",
              "CRÉDITO FISCAL",
              "TOTAL DE COMPRAS",
              "DUI DEL PROVEEDOR",
              "TIPO DE OPERACIÓN",
              "CLASIFICACIÓN",
              "SECTOR",
              "TIPO DE COSTO / GASTO",
              "NÚMERO DE ANEXO"
          ],
        },
        {
          text: "Compras Sujetos Excluidos",
          value: "cse",
          titleHeaders: [
            "Tipo Documento",
            "NRC/NIT/DUI",
            "Nombre",
            "Fecha",
            "Número de Serie",
            "Número de Documento",
            "Monto de Operación",
            "Monto de Retención",
            "Número Anexo",
          ],
          dataHeaders: [],
        },
        { text: "Percepciones", value: "p", titleHeaders:  [
              "NIT SUJETO",
              "FECHA DE EMISIÓN DEL DOCUMENTO",
              "TIPO DOCUMENTO",
              "NÚMERO DE RESOLUCIÓN",
              "SERIE DE DOCUMENTO",
              "NÚMERO DE DOCUMENTO",
              "MONTO SUJETO",
              "MONTO DE LA PERCEPCIÓN 1% DE IVA",
              "DUI SUJETO",
              "NÚMERO DE ANEXO"
          ], dataHeaders:  [
                "NIT SUJETO",
                "FECHA DE EMISIÓN DEL DOCUMENTO",
                "TIPO DOCUMENTO",
                "NÚMERO DE RESOLUCIÓN",
                "SERIE DE DOCUMENTO",
                "NÚMERO DE DOCUMENTO",
                "MONTO SUJETO",
                "MONTO DE LA PERCEPCIÓN 1% DE IVA",
                "DUI SUJETO",
                "NÚMERO DE ANEXO"
            ] },
        {
          text: "Anticipo a Cuenta",
          value: "ac",
          titleHeaders: [
              "NIT SUJETO",
              "FECHA DE EMISIÓN DEL DOCUMENTO",
              "NÚMERO DE RESOLUCIÓN",
              "SERIE DE DOCUMENTO",
              "NÚMERO DE DOCUMENTO",
              "MONTO SUJETO",
              "MONTO DEL ANTICIPO A CUENTA 2%",
              "DUI SUJETO",
              "NÚMERO DE ANEXO"
          ],
          dataHeaders: [
              "NIT SUJETO",
              "FECHA DE EMISIÓN DEL DOCUMENTO",
              "NÚMERO DE RESOLUCIÓN",
              "SERIE DE DOCUMENTO",
              "NÚMERO DE DOCUMENTO",
              "MONTO SUJETO",
              "MONTO DEL ANTICIPO A CUENTA 2%",
              "DUI SUJETO",
              "NÚMERO DE ANEXO"
          ],
        },
      ],
      yearList: [],
      monthList: [
        { text: "Enero", value: 1 },
        { text: "Febrero", value: 2 },
        { text: "Marzo", value: 3 },
        { text: "Abril", value: 4 },
        { text: "Mayo", value: 5 },
        { text: "Junio", value: 6 },
        { text: "Julio", value: 7 },
        { text: "Agosto", value: 8 },
        { text: "Septiembre", value: 9 },
        { text: "Octubre", value: 10 },
        { text: "Noviembre", value: 11 },
        { text: "Diciembre", value: 12 },
      ],
      selectAll: false,
      deselectAll: false,
      selectedRadio: null,
      loading: false,
      vc: null,
      vcf: null,
      c: null,
      cse: null,
      da: null,
      ac: null,
      p: null,
      crr: null,

    };
  },
  watch: {
    dataType(newVal) {
      this.search.dataType = newVal;
    },
  },
  mounted() {
    this.search.dataType = this.dataType;
    this.generateYearList();
    this.setCurrentTime();
  },

  methods: {
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    closeAlert() {
      this.alert.show = false;
    },
    showSelections() {
      console.log(this.search);
      this.callSelectedApis();
      this.close();
    },
    close() {
      this.$emit("close-csv");
    },
    setCurrentTime() {
      const currentTime = new Date();
      this.search = {
        year: currentTime.getFullYear(),
        month: currentTime.getMonth() + 1,
        dataType: this.dataType
      };
    },
    // To select all & unselect
    handleRadioClick(selectedValue) {
      if (selectedValue === "selectAll") {
        this.selectAllItems();
      } else if (selectedValue === "deselectAll") {
        this.deselectAllItems();
      }
    },
    selectAllItems() {
      this.selectedOptions = this.options.map((item) => item.value);
      this.deselectAll = false;
    },
    deselectAllItems() {
      this.selectedOptions = [];
      this.selectAll = false;
    },
    generateYearList() {
      const currentYear = new Date().getFullYear();
      const startYear = 2020;

      for (let year = currentYear; year >= startYear; year--) {
        this.yearList.push({ text: year.toString(), value: year });
      }
    },

    // API calling
    callSelectedApis() {
      this.loading = true;
      try {
        if (this.selectedOptions && this.selectedOptions.length > 0) {
          for (const selectedOption of this.selectedOptions) {
            this.callApi(selectedOption);
          }
        } else {
          console.log("No options selected");
        }
      } catch (error) {
        console.error("Error calling APIs:", error);
      } finally {
        this.loading = false;
      }
    },
    callApi(selectedOption) {
      // this.validateDate();
      console.log("corriendo", selectedOption);
      switch (selectedOption) {
        case "vc":
          this.getSalesTaxpayer();
          break;
        case "vcf":
          this.getFinalConsumerSales();
          break;
        case "da":
          this.getCancelDocument();
          break;
        case "crr":
        this.getWithholdingVoucherReceipts();
          break;
        case "c":
          this.getPurchases();
          break;
        case "cse":
          this.getPurcharsesExcludedSubject(this.search);
          break;
        case "p":
          this.getPerception();
          break;
          case "ac":
          this.getAdvanceAccount();         
          break;
        default:
          console.log("seleccione un opcion");
      }
    },
    getAdvanceAccount(){
      this.$API.accountingreports.getAdvanceAccount({ ...this.search }).then((res) => {
        this.ac = res.data.data;
        console.log(this.ac);
        this.downloadCSV("ac", this.ac);
      });
    },
    getPerception() {
      this.$API.accountingreports.getPerception({ ...this.search }).then((res) => {
        this.p = res.data.data;
        console.log(this.p);
        this.downloadCSV("p", this.p);
      });
    },
    getWithholdingVoucherReceipts(){
      this.$API.accountingreports.getWithholdingVoucherReceipts({ ...this.search }).then((res) => {
        this.crr = res.data.data;
       
        this.downloadCSV("ac", this.crr);
      });
    },
    getCancelDocument(){
      this.$API.accountingreports.getCancelDocument({ ...this.search }).then((res) => {
        this.da = res.data.data;
        console.log(this.da);
        this.downloadCSV("da", this.da);
      });
    
    },
    getSalesTaxpayer(){
      this.$API.accountingreports.getSalesTaxpayer({ ...this.search }).then((res) => {
        this.vc = res.data.data;
        console.log(this.vc);
        this.downloadCSV("vc", this.vc);
      });
    },
    getFinalConsumerSales() {
      this.$API.accountingreports
        .getFinalConsumer({ ...this.search })
        .then((res) => {
          console.log( 'final customer response ', res.data.data );
          this.vcf = res.data.data;
         
          this.downloadCSV("vcf", this.vcf);
        });
    }, 
    // API call for Ventas Contribuyente (vc) and Ventas Consumidor final (vcf)
    getReportSales(search, reportType) {
      this.$API.accountingreports
        .getSalesByDate({ ...search, reportType: reportType , dataType: this.dataType })
        .then((res) => {
          if (reportType === "1") {
            this.vc = res.data.data;
            console.log(this.vc, "vc");
            this.downloadCSV("vc", this.vc);
          } else {
            this.vcf = res.data.data;
            console.log(this.vcf, "vcf");
            this.downloadCSV("vcf", this.vcf);
          }
        });
    },
    // API call for Comprars Sujetos Excluidos (cse)
    getPurcharsesExcludedSubject(search) {
      console.log(search);
      this.$API.accountingreports.getPurcharsesExcludedSubject({ ...search }).then((res) => {
        this.cse = res.data.data;
        console.log(this.cse, "cse");
        this.downloadCSV("cse", this.cse);
      });
    },
    // API call for Compras (c)
    getPurchases() {
      this.$API.accountingreports.getPurchases({ ...this.search }).then((res) => {
        this.c = res.data.data;
        console.log(this.c);
        this.downloadCSV("c", this.c);
      });
    },
    validateDate() {
      if (this.search.month == null || this.search.year == null) {
        console.log("no fecha");
        return;
      }
    },
    //Download CSV
    downloadCSV(value, data) {
      const { text, dataHeaders } = this.getOption(value);
      // const csvContent = convertToCSV(data, dataHeaders, titleHeaders);
      const csvContent = convertToCSV(data, dataHeaders);

      // Lógica para descargar el archivo CSV
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${text} de ${this.monthList[this.search.month -1].text}/${
          this.search.year
        } - (${getCurrentDateTimeString()}).csv`
      );
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    },
    getOption(value) {
      return this.options.find((option) => option.value === value);
    },
  },
};
</script>

<style scoped>
.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}

.btn-add {
  max-width: 100% !important;
  width: max-content;
}

@media (min-height: 1460px) {
  .tablesize {
    max-height: 100%;
  }
}

@media (min-height: 1060px) and (max-height: 1459px) {
  .tablesize {
    max-height: 100%;
  }
}
@media (min-height: 930px) and (max-height: 1059px) {
  .tablesize {
    max-height: 100%;
  }
}
@media (min-height: 860px) and (max-height: 929px) {
  .tablesize {
    max-height: 100%;
  }
}
@media (min-height: 660px) and (max-height: 859px){
  .tablesize {
    max-height: 600px;
    overflow-y: scroll;
  }
}
@media (min-height: 460px) and (max-height: 659px) {
  .tablesize {
    max-height: 400px;
    overflow-y: scroll;
  }
}
@media (max-height: 459px) {
  .tablesize {
    max-height: 250px;
    overflow-y: scroll;
  }
}
</style>
