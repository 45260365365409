import { render, staticRenderFns } from "./UploadCustomImage.vue?vue&type=template&id=9b0f3f5a&scoped=true&"
import script from "./UploadCustomImage.vue?vue&type=script&lang=js&"
export * from "./UploadCustomImage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b0f3f5a",
  null
  
)

export default component.exports