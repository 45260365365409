import axios from "./axios";
import {userData} from "./Currentuserdata";

export default {
    /**
     * Get All Patients
     * @api {Get} manageveterinarypatient
     * @param {object} filter
     */
     getPatients(filter) {
        const options = {
            method: 'GET',
            url: 'manageveterinarypatient',
            headers: {'content-type': 'application/json'},
            params: filter
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getOne({id, customer_id}) {
        const options = {
            method: 'GET',
            url: `manageveterinarypatient/${id}`,
            headers: {'content-type': 'application/json'},
            params: {
                customer_id: customer_id
            }
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getPatientsHistory(filter) {
      const options = {
          method: 'GET',
          url: 'manageveterinarypatient/list/history',
          headers: {'content-type': 'application/json'},
          params: filter
      };
      return new Promise((resolve, reject) => {
          axios(options)
              .then(response => {
                  resolve(response.data);
              })
              .catch(error => {
                  reject(error.message)
              })
      })
  },
  getPatientsFiltered(filter) {
    const options = {
        method: 'GET',
        url: 'manageveterinarypatient/list/filtered',
        headers: {'content-type': 'application/json'},
        params: filter
    };
    return new Promise((resolve, reject) => {
        axios(options)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.message)
            })
    })
},
    filterPatients(filter) {
        const options = {
            method: 'GET',
            url: 'manageveterinarypatient/filtered',
            headers: {'content-type': 'application/json'},
            params: filter
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
  createMedicationsTemplate(data) {
    const options = {
        method: 'POST',
        url: 'manageveterinarypatient/defaultTemplate',
        headers: {'content-type': 'application/json'},
        data: data
    };
    return new Promise((resolve, reject) => {
        axios(options)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.message)
            })
    })
},
findmedicationstemplate( data ) {
  const options = {
      method: 'GET',
      url: 'manageveterinarypatient/findmedicationstemplate',
      headers: {'content-type': 'application/json'},
      params: data
  };
  return new Promise((resolve, reject) => {
      axios(options)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.message)
          })
  })
},
    addPatient(data) {
        const options = {
            method: 'POST',
            url: `manageveterinarypatient/addpatient`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    /**
     * Add Patient
     * @api {PUT} managepatient
     */
     updatePatient(id, data) {
        const options = {
            method: 'PUT',
            url: `manageveterinarypatient/${id}`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    /**
     * Delete Patient
     * @api {DELETE} managepatient/:id
     */
     deletePatient(id) {
        const user = userData();
        const options = {
            method: 'DELETE',
            url: `manageveterinarypatient/${id}`,
            headers: {'content-type': 'application/json'},
            data: {
                ID_Branch: user.branch,
                ID_Business: user.businessid        ,
              }
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    logicalDeletePatient(id) {
      const options = {
        method: "PUT",
        url: `manageveterinarypatient/logicaldelete/${id}`,
        headers: { "content-type": "application/json" },
      };
      return new Promise((resolve, reject) => {
        axios(options)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.message)
          });
      });
    },

    findnextApoitments(data) {
        const options = {
          method: 'POST',
          url: `manageveterinarypatient/getnextappointment`,
          headers: { 'content-type': 'application/json' },
          data: data
        };
        return new Promise((resolve, reject) => {
          axios(options)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.message)
            })
        });
      },
      findnextApoitmentsHistory(data) {
        const options = {
          method: 'POST',
          url: `manageveterinarypatient/getnextappointment/list/history`,
          headers: { 'content-type': 'application/json' },
          data: data
        };
        return new Promise((resolve, reject) => {
          axios(options)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.message)
            })
        });
      },
      findnextApoitmentsFiltered(data) {
        const options = {
          method: 'POST',
          url: `manageveterinarypatient/getnextappointment/list/filtered`,
          headers: { 'content-type': 'application/json' },
          data: data
        };
        return new Promise((resolve, reject) => {
          axios(options)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.message)
            })
        });
      },
      getServicesToBeBilled(data) {
        const options = {
          method: 'POST',
          url: `manageveterinarypatient/getServicesToBeBilled`,
          headers: { 'content-type': 'application/json' },
          data: data
        };
        return new Promise((resolve, reject) => {
          axios(options)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.message)
            })
        });
      },
      getProductsToBeBilled(data) {
        const options = {
          method: 'POST',
          url: `manageveterinarypatient/getProductsToBeBilled`,
          headers: { 'content-type': 'application/json' },
          data: data
        };
        return new Promise((resolve, reject) => {
          axios(options)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.message)
            })
        });
      },
      saveServicesToBeBilled(data) {
        const options = {
          method: 'POST',
          url: `manageveterinarypatient/saveServicesToBeBilled`,
          headers: { 'content-type': 'application/json' },
          data: data
        };
        return new Promise((resolve, reject) => {
          axios(options)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.message)
            })
        });
      },
      saveProductsToBeBilled(data) {
        const options = {
          method: 'POST',
          url: `manageveterinarypatient/saveProductsToBeBilled`,
          headers: { 'content-type': 'application/json' },
          data: data
        };
        return new Promise((resolve, reject) => {
          axios(options)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.message)
            })
        });
      },
      billedPatientsOfCustomer(data) {
        const options = {
          method: 'POST',
          url: `manageveterinarypatient/billedPatientsOfCustomer`,
          headers: { 'content-type': 'application/json' },
          data: data
        };
        return new Promise((resolve, reject) => {
          axios(options)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.message)
            })
        });
      }
}
