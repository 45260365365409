<template>
  <div class="pa-1">
    <v-form v-model="valid" class="white pa-4" style="border-radius: 15px">
      <block v-if="loading"></block>

      <v-row class="ml-2 mr-2 mb-3">
        <v-col cols="12" class="tabsNh">
          <v-col cols="12" class="d-flex justify-end" style="min-height: 50px">
            <v-btn v-if="$store.getters['rolesUser/getCustomRole'](43)" small class="btnsave"
              @click.prevent.stop="addProductHandler">
              <v-icon> mdi-content-save </v-icon>
            </v-btn>
          </v-col>
        </v-col>
        <v-col cols="12" md="12" style="display: flex; justify-content: space-between; margin-top: 1rem">
          <h2>Información General</h2>
          <div style="display: none">
            <v-checkbox v-model="form.isglobalbranch" label="Mostrar en todas las sucursales"></v-checkbox>
          </div>
        </v-col>
        <v-col cols="6" md="6"></v-col>

        <!--    <v-col cols="3"></v-col> <v-col cols="3">
                        <v-checkbox v-model="isMediccine" label="Es Medicamento"></v-checkbox>
                      </v-col >-->
        <v-col cols="12" md="12">
          <label style="opacity: 0.8">Nota: los campos con (*) al final son requeridos</label>

          <br />
        </v-col>

        <v-col  cols="12" md="3" sm="8" style="margin-top: 0.8rem;justify-content: center">
          <div style="justify-content: center; display: flex; width: 50%; margin-left: 25%">
            <v-img :src="url" class="preview-avatar" />
          </div>
          <v-file-input style="width: 80%; margin-left: 10%" @change="Preview_image" v-model="image"
            label="Seleccione una imágen" prepend-icon="mdi-camera" placeholder="Seleccione una imágen " :rules="[maxFileSize]">
          </v-file-input>
        </v-col>
        <v-col cols="12" md="3" class="mb-2"  >
          <!--  <v-select outlined dense  :items="types" label="Tipo de Articulo*" v-model="form.itemtype" required attach
                              :rules="[(v) => !!v || 'Seleccione el tipo de articulo']"></v-select> -->

          <v-text-field outlined dense v-model="form.itemname" label="Nombre del Articulo*"
            :rules="[(v) => !!v || 'El nombre del articulo es requerido']" @input="fillItemComercialName"
            required></v-text-field>
          <!-- <v-text-field
            outlined
            dense
            v-model="form.itembarcode"
            label="Código de Barra del Articulo"
            required
          ></v-text-field> -->
          <v-autocomplete outlined dense attach :items="unidades" item-value="Valores" item-text="Valores"
            label="Medida de Unidad" v-model="form.itemmeasureunit"></v-autocomplete>
          <!-- <v-btn class="btnadd" @click="dialogBarCode = true">Gestión de códigos de barra /<br>códigos alternos</v-btn> -->
        </v-col>
        <v-col cols="12" md="3" class="mb-2">
          <!--v-select outlined dense  :items="providers" item-text="ProviderName" item-value="ID_Provider" label="Proveedor*" attach
              v-model="form.idprovider" required
              :rules="[(v) => !!v || 'Seleccione el proveedor del articulo']"></v-select-->
          <v-text-field outlined dense v-model="form.itemprice" label="Precio*" type="number" prefix="$" required
            :rules="[(v) => !!v || 'El precio del articulo es requerido']"></v-text-field>

          <v-text-field outlined dense v-model="form.suggestedPrice" label="Precio sugerido*" type="number" prefix="$"
            required :rules="[(v) => !!v || 'El precio sugerido del articulo es requerido']"></v-text-field>

          <v-btn class="btnadd" @click="dialogProviders = true">Gestión de proveedores</v-btn>
        </v-col>

        <br />
        <!-- <v-col cols="12" md="12">
            <h3>Informacion Adicional</h3>

            <br />
          </v-col> -->
        <v-col cols="12" md="3">
          <v-text-field outlined dense v-model="form.itemcomercialname"
            label="Nombre Comercial del Articulo"></v-text-field>
          <v-text-field outlined dense v-model="form.itemdescription" label="Descripción del Articulo"></v-text-field>
          <v-checkbox v-model="form.isexempt" label="Producto exento"></v-checkbox>
        </v-col>
        <v-col cols="12" md="3"> </v-col>

        <v-col cols="12" md="4"> </v-col>
        <!--  <v-col cols="12" md="4">

            <v-text-field outlined dense v-model="form.idbusiness" label="ID del Negocio" :hint="business" persistent-hint
              disabled></v-text-field>
          </v-col> -->
        <!--v-col cols="12" md="4">
                                      <v-select outlined dense  attach :items="aditional_type" label="Dato Adicional" v-model="form.aditionaltype"></v-select>
                                  </v-col-->

        <v-col cols="12" md="12">
          <br />
          <h3 class="d-none">Información contable</h3>

          <br />
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="3" xl="3">
          <!--   <v-select outlined dense  :items="families" item-text="name" item-value="id" label="Familia" v-model="form.family" attach
                    required></v-select> -->
          <v-autocomplete outlined dense label="Familia" v-model="form.family" :items="families" item-text="name" attach
            item-value="id" @change="getCategoriesByFamily"></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="3" xl="3">
          <!-- <v-select outlined dense  :items="categories" item-text="name" item-value="id" label="Categoria" v-model="form.category" attach
                    required></v-select> -->
          <v-autocomplete outlined dense label="Categoría" v-model="form.category" :items="categories"
            item-text="name" attach item-value="id" :disabled="!form.family" @change="getSubsByCategory"></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="3" xl="3">
          <!--  <v-select outlined dense  :items="subcategories" item-text="name" item-value="id" label="Sub Categoria" v-model="subcategory" attach
                  required></v-select> -->
          <v-autocomplete outlined dense label="Subcategoría" v-model="form.subcategory" :items="subcategories"
            attach item-text="name" item-value="id" :disabled="!form.category"></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="3" xl="3">
          <v-btn class="btnadd" @click.prevent.stop="dialog = true">
            Gestionar segmentación
          </v-btn>
        </v-col>
        <v-col cols="12" md="3">
          <v-checkbox v-model="form.ishidrocarburo" label="Producto es hidrocarburo"></v-checkbox>
        </v-col>

        <!-- <v-col cols="12" md="4">
          <v-autocomplete
            outlined
            dense
            :items="warehouses"
            item-text="Warehouse"
            item-value="ID_Warehouse"
            label="Almacén*"
            attach
            v-model="form.Warehouse"
            :rules="[(v) => !!v || 'Lugar de almacenamiento del artículo es requerido']"
            required
            @change="fillBatchsAndAccountingGroups()"
          >
          </v-autocomplete>
        </v-col> -->
      </v-row>
      <AccountingAssign style="display: none" :form="form" :win="win" />
      <v-row>
        <v-col cols="12" md="4" style="display: none !important">
          <v-text-field outlined dense v-model="form.itemconversionfactor" label="Factor de Conversión"></v-text-field>
        </v-col>
        <v-col cols="12" md="2" style="display: none !important">
          <v-text-field outlined dense v-model="form.itemunitspackaging" type="number"
            label="Unidades Packaging"></v-text-field>
        </v-col>
        <v-col cols="12" md="2" style="display: none !important">
          <v-autocomplete outlined dense :items="unidades" label="Unidad Packaging" item-value="Valores"
            item-text="Valores" attach v-model="typepack"></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <h3 style="text-align: center" :class="showBgcolorNotes" @click.prevent="showNotes = !showNotes">
            Comentarios
          </h3>
          <v-dialog v-model="showNotes" scrollable :width="dialogWidth">
            <v-card class="pa-4">
              <div style="
                  margin-top: 0.3rem;
                  margin-bottom: 0.3rem;
                  display: flex;
                  justify-content: flex-end;
                ">
                <v-btn small class="btndelete" @click.prevent="showNotes = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <quill-editor :content="form.itemnote" @change="onEditorChange($event)" v-show="showNotes"
                :options="quillOptions"></quill-editor>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-form>

    <v-dialog v-model="dialog" scrollable :max-width="dialogWidth" height="100%">
      <ManageCategory :win="win" v-on:reloadCategories="reloadCategories" />
    </v-dialog>

    <v-dialog v-model="dialogProviders" scrollable :max-width="dialogWidth">
      <v-card>
        <v-card-title>
          <h2>Asignación de proveedores</h2>
        </v-card-title>
        <v-card-text>
          <h4>Seleccione los proveedores asociados a este producto.</h4>
          <GeneralFilter :hideLimitLabel="true" :filterEndpoint="filterPromise" :search="{}" @emptyFilter="clearFilter"
            @filtered="filterHandler">
            <DxDataGrid 
            :scrolling="{useNative: true}"
             :show-borders="true"
              :data-source="isFiltered ? providersFiltered : providers">
              <DxPaging :page-size="5" />
              <DxColumn :width="70" cell-template="select-provider" caption=""></DxColumn>
              <DxColumn :width="columnWidth"  data-field="ProviderName" caption="Proveedor"></DxColumn>
              <DxColumn :width="columnWidth"  data-field="ProviderPhone" caption="Télefono"></DxColumn>
              <DxColumn :width="columnWidth"  data-field="ProviderEmail" caption="Correo"></DxColumn>
              <DxColumn :width="columnWidth" data-field="ProviderAddress" caption="Dirección"></DxColumn>

              <template #select-provider="{ data }">
                <div>
                  <v-checkbox v-model="data.data.Checked" />
                </div>
              </template>
            </DxDataGrid>
          </GeneralFilter>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn right class="btncontinue" @click="dialogProviders = false">Continuar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add barcode -->
    <v-dialog v-model="dialogBarCode" scrollable class="barcode-dialog border-boxGlobal" :width="calculateWidth">

      <v-card>
        <block v-if="loadingBarCode"></block>
        <v-card-title>
          <h2 class="mx-3 width-100">Asignación de código de barra / código alterno</h2>
          <span class="mx-3 width-100 note-size"><b>Nota:</b> Los códigos serán creados al guardar el producto</span>
        </v-card-title>
        <v-card-text>
          <!-- <h4>Seleccione los proveedores asociados a este producto.</h4> -->
          <v-row class="mt-3">
            <v-col cols="12" class="d-flex justify-center mx-3">
              <v-text-field outlined dense v-model="barCodeToAdd" label="Código de barra / código alterno *"
                :rules="[(v) => !!v || 'El código de barra / código alterno es requerido']" required
                @keyup.enter="addBarCode"></v-text-field>
              
            </v-col>
            <v-col cols="12" class="d-flex justify-center mx-3">
              <v-btn class="btnadd ml-5 mx-8" @click="addBarCode"><v-icon class="mr-3">mdi-barcode-scan</v-icon>Agregar código</v-btn>
            </v-col>
          </v-row>
          <GeneralFilter :hideLimitLabel="true" :filterEndpoint="filterPromiseBarCodes" :search="search"
            @emptyFilter="clearFilterBarCode" @filtered="filterHandlerBarCodes">
            <DxDataGrid class="isScrolledByGeneralFilter grid-max-width" :show-borders="true"
              :data-source="isFilteredBarCode ? barCodesToSaveFiltered : barCodesToSave">
              <DxPaging :page-size="5" />
              <DxColumn :width="70" cell-template="edit-barcode"></DxColumn>
              <DxColumn data-field="BarCode" caption="Código de barra / Código alterno"></DxColumn>
              <DxColumn cell-template="check-principal" caption="Principal"></DxColumn>
              <template #check-principal="{ data }">
                <div>
                  <center><v-checkbox v-model="data.data.PrincipalBarCode" @change="changePrincipalBarCode(data.data)"></v-checkbox> </center>
                </div>
              </template>
              <DxColumn :width="70" cell-template="delete-barcode"> </DxColumn>
              <template #edit-barcode="{ data }">
                <div>
                  <a class="mx-1" @click="editShowBarCode(data.data)">
                    <v-icon color="primary">mdi-eye</v-icon>
                  </a>
                </div>
              </template>
              <template #delete-barcode="{ data }">
                <div>
                  <a class="mx-1" @click="deleteBarCode(data.data)">
                    <v-icon color="error">mdi-delete</v-icon>
                  </a>
                </div>
              </template>
            </DxDataGrid>
          </GeneralFilter>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn right class="btncontinue" @click="dialogBarCode = false">Continuar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Add barcode -->

    <!-- Edit barcode -->
    <v-dialog v-model="dialogEditBarCode" scrollable class="barcode-edit-dialog .border-boxGlobal" max-width="40%">
      <v-card>
        <v-card-title>
          <h2>Editar de código de barra / código alterno - {{ previousCode }}</h2>
        </v-card-title>
        <v-card-text>
          <!-- <h4>Seleccione los proveedores asociados a este producto.</h4> -->
          <v-row class="mt-3">
            <v-col cols="12" class="d-flex justify-center">
              <v-text-field @keyup.enter="editBarCode" outlined dense v-model="barCodeToEdit"
                label="Código de barra / código alterno*"
                :rules="[(v) => !!v || 'El código de barra / código alterno requerido']" required></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-col cols="12" class="d-flex justify-space-between">
            <v-btn class="btnadd ml-5" @click="editBarCode">Editar</v-btn>
            <v-btn right class="btncontinue" @click="dialogEditBarCode = false">Continuar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Edit barcode -->

    <alerts v-if="alert.show && !dialog" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert"
      class="alert-dialog"></alerts>
  </div>
</template>

<script>
import GeneralFilter from "@/components/GeneralFilter";
import Block from "@/components/Block";
import Alerts from "@/components/Alerts";
import { quillConfig } from "../../../helpers/quillconfig";
import { mapState, mapGetters } from "vuex";
import { DxDataGrid, DxColumn, DxPaging } from "devextreme-vue/data-grid";
import AccountingAssign from "../../acounting/partial/AccountingAssign.vue";
import { multipleCriteriaFilter } from "../../../helpers/object";
import ManageCategory from "@/components/Categories/ManageCategory.vue";
export default {
  name: "AddProduct",
  props: ["win"],
  components: {
    DxDataGrid,
    DxColumn,

    DxPaging,
    Block,
    Alerts,
    GeneralFilter,
    AccountingAssign,
    ManageCategory,
  },
  data() {
    return {
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      windowWidth: window.innerWidth,
      typepack: "",
      valid: false,
      dialog: false,
      url: null,
      showBgcolorNotes: "tabs",
      quillOptions: quillConfig,
      showNotes: false,
      image: null,
      family: null,
      category: null,
      subcategory: null,
      families: [],
      categories: [],
      subcategories: [],
      form: {
        family: null,
        category: null,
        subcategory: null,
        isglobalbranch: true,
      },
      showFamCat: null,
      showCatSub: null,
      units: [
        "Unidades",
        "Libras",
        "Kilos",
        "Galones",
        "Litros",
        "Mililitros",
        "Metros",
        "Centímetros",
      ],
      aditional_type: ["", "Medicamento", "Vacuna"],
      types: [],
      loading: false,
      requiredRule: [(value) => !!value || "Required."],
      /*       form.family: null,
            form.category: null, */
      /*  form.subcategory: null, */
      providers: [],
      business: null,
      isMediccine: false,
      familyName: null,
      //WAREHOUSES
      search: {
        filter: null,
      },
      warehouses: {},
      batchs: {},
      categoryName: null,
      subName: null,
      toDelete: undefined,
      accountinggroups: {},
      selectaccountinggroup: {},
      loadaedfromprovider: false,
      dialogProviders: false,
      dialogBarCode: false,
      dialogEditBarCode: false,
      providersFiltered: [],
      isFiltered: false,
      isFilteredBarCode: false,
      tabsCategories: "families",
      barCodesToSave: [],
      barCodesToSaveFiltered: [],
      barCodeToAdd: null,
      barCodeToEdit: null,
      previousCode: null,
      loadingBarCode: false,
      validImageSize: true,
    };
  },
  created() {
    this.$store.dispatch("getParam", { type: "InventoryItemType" }).then((data) => {
      this.types = data.map((param) => {
        return { text: param.ParameterName, value: param.ParameterName };
      });
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWidth);
  },
  mounted() {
    window.addEventListener('resize', this.updateWidth);

    this.$store.dispatch("rolesUser/getAllRoles");

    this.form.idbusiness = JSON.parse(localStorage.getItem("user")).businessid;
    this.business = JSON.parse(localStorage.getItem("user")).business;
    this.form.idbranch = JSON.parse(localStorage.getItem("user")).branch;
    this.form.iduser = JSON.parse(localStorage.getItem("user")).id;

    this.search.business = JSON.parse(localStorage.getItem("user")).businessid;
    this.search.branch = JSON.parse(localStorage.getItem("user")).branch;
    this.getAllProviders();
    this.getWarehouses();
    this.getFamilies();
    this.getCategories();
    this.getSubs();
    if (this.win.meta.product) {
      this.form.idprovider = this.win.meta.product;
      this.loadaedfromprovider = true;
    }
    if (this.win.meta.warehouse) {
      this.form.Warehouse = this.win.meta.warehouse;
    }
  },
  computed: {
    //comercialName() {
    //  return this.form.itemname;
    //},
    ...mapGetters(["getCatalogMH"]),
    unidades() {
      return this.getCatalogMH("UnidadMedida");
    },
    filteredCategories() {
      console.log("selected fam", this.form.family);
      return this.form.family
        ? this.$API.itemtypes.getCategoriesByFamily( { id_family: this.form.family } )
        : [];
    },
    filteredSubcategories() {
      console.log("selected cat", this.form.category);
      return this.form.category
        ? this.$API.itemtypes.getSubsByCategory( { id_cat: this.form.category } )
        : [];
    },
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
    calculateWidth() {
      return this.windowWidth < 800 ? '85%' : '45%';
    }
    ,
    columnWidth() {
    
    return this.$vuetify.breakpoint.smAndDown ? '80px' : 'auto !important';
  },
  dialogWidth() {
      return this.$vuetify.breakpoint.smAndDown ? "95%" : "60%";
    },
  },
  watch: {},
  methods: {
    /*   filterCategories() {
        // filter categories based on selected form.family
        this.categories = this.form.family ? this.form.family.items_categories : [];
        // resetform.subcategory selection
        this.form.category = null;
        this.subcategory = null;
        // reset subcategories
        this.subcategories = [];
      },
      filterSubcategories() {
        // filter subcategories based on selected form.category
        this.subcategories = this.form.category ? this.form.category.items_subcats : [];
        // resetform.subcategory selection
        this.subcategory = null;
      }, */
    changePrincipalBarCode(data) {
      if(data.PrincipalBarCode){
        this.barCodesToSave.forEach((item, index) => {
          if (item.BarCode != data.BarCode) {
            this.barCodesToSave[index].PrincipalBarCode = false;
          }
        })
      }
    },
    updateWidth() {
      this.windowWidth = window.innerWidth;
    },
    fillItemComercialName() {
      this.form.itemcomercialname = this.form.itemname
    },
    checkDuplicate(NewBarCode) {
      if (this.barCodesToSave.some(({ BarCode }) => NewBarCode === BarCode)) {
        return Promise.resolve({ success: false, message: `El código "${NewBarCode}" acaba de ser agregado, agregue uno distinto.` });
      }

      return new Promise((resolve, reject) => {
        this.$API.inventoryitemsbarcodes
          .checkBarCodeExists({ barcode: NewBarCode })
          .then((response) => {
            console.log(response)
            if (!response.success) {
              resolve({ success: false, message: response.message || `El código "${NewBarCode}" acaba de ser agregado, agregue uno distinto.` });
            } else {
              resolve({ success: true });
            }
          })
          .catch((error) => {
            console.log(error)
            reject(false);
          })
          .finally(() => {
            this.barCodeToAdd = null
          })
      })

    },
    addBarCode() {
      this.loadingBarCode = true;
      console.log("entro")
      this.checkDuplicate(this.barCodeToAdd)
        .then(({ success, message }) => {
          console.log(success, message)
          if (success) {
            const newBarCode = {
              BarCode: this.barCodeToAdd,
              PrincipalBarCode: false
            };
            this.barCodesToSave.push(newBarCode)
          } else {
            this.dialogBarCode = false;
            this.showAlert("warning", "Advertencia", message || 'Ya existe.', 'showBackBarcodes');
          }
        })
        .catch(() => {
        })
        .finally(() => {
          this.loadingBarCode = false;
        })
    },
    editShowBarCode(data) {
      this.dialogEditBarCode = true;
      this.previousCode = data.BarCode;
      this.barCodeToEdit = this.previousCode;
    },
    editBarCode() {
      this.loadingBarCode = true;
      this.dialogEditBarCode = false;
      this.checkDuplicate(this.barCodeToEdit)
        .then(({ success, message }) => {

          if(!success) {
            this.dialogBarCode = false;
            this.showAlert("warning", "Advertencia", message || 'Ya existe.', 'showBackBarcodes');
          }

          if (success) {
            this.dialogBarCode = false;
            this.showAlert("success", "Éxito", 'El código se actualizó exitosamente.', "showBackBarcodes");
            this.barCodesToSave.find((item) => {
              if (item.BarCode === this.previousCode) {
                item.BarCode = this.barCodeToEdit;
              }
            });
          } 
          this.barCodeToAdd = null;
        })
        .catch((error) => {
          console.error("Error checking duplicate:", error);
        })
        .finally(() => {
          this.loadingBarCode = false;
        });
    },
    deleteBarCode(data) {
      const index = this.barCodesToSave.indexOf(data);
      if (index > -1) {
        this.barCodesToSave.splice(index, 1);
      }
    },
    clearFilter() {
      this.isFiltered = false;
    },
    clearFilterBarCode() {
      this.isFilteredBarCode = false;
    },
    filterHandler(response) {
      this.isFiltered = true;
      this.providersFiltered = response;
    },
    filterPromise(filterObj) {
      const filterArray = [
        "ProviderName",
        "ProviderPhone",
        "ProviderEmail",
        "ProviderAddress",
      ];
      return new Promise((resolve, reject) => {
        try {
          resolve(multipleCriteriaFilter(filterArray, filterObj, this.providers));
        } catch (error) {
          reject(error);
        }
      });
    },
    filterPromiseBarCodes(filterObj) {
      const filterArray = ["BarCode"];
      return new Promise((resolve, reject) => {
        try {
          resolve(multipleCriteriaFilter(filterArray, filterObj, this.barCodesToSave));
        } catch (error) {
          reject(error);
        }
      });
    },
    closeAlert() {
      this.alert.show = false;
      /* this.$store.dispatch('addWindow', { 'name': 'ProductsList', component: 'ProductsList', unique: false }); */
      //this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid });
      this.toDelete = undefined;
      if (this.alert.options == 'showBackBarcodes') {
        this.dialogBarCode = true;
      }
    },
    ShowDelete(id, action) {
      this.toDelete = id;
      this.showAlert(
        "question",
        "Eliminar Elemento",
        "¿Está seguro que desea eliminar este Elemento?",
        action
      );
    },

    getFamilies() {
      this.$API.itemtypes.getFamiliesHistory().then((response) => {
        this.families = response;
        console.log("response famili", response);
      });
    },
    getCategories() {
      this.$API.itemtypes.getCategoriesHistory().then((response) => {
        this.categories = response;
      });
    },
    async getCategoriesByFamily() {
      this.categories = await this.$API.itemtypes.getCategoriesByFamily( { id_family: this.form.family } );
    },
    async getSubsByCategory() {
      this.subcategories = await this.$API.itemtypes.getSubsByCategory( { id_cat: this.form.category } );
    },
    getSubs() {
      this.$API.itemtypes.getSubsHistory().then((response) => {
        this.subcategories = response;
      });
    },

    acceptAlert() {
      this.alert.show = false;
      //this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid }); falta el id
      //this.$store.dispatch('addWindow',{'name':'ProductsList',component:'ProductsList',unique:false});

      if (this.alert.options == "destroyFamily") {
        this.destroyFamily(this.toDelete);
      }
      if (this.alert.options == "destroyCat") {
        this.destroyCat(this.toDelete);
      }

      if (this.alert.options == "destroySub") {
        this.destroySub(this.toDelete);
      }
    },
    reloadCategories() {
      console.log("reload categories");
      this.getFamilies();
      this.getCategories();
      this.getSubs();
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    Preview_image() {
      this.url = URL.createObjectURL(this.image);
    },
    getAllProviders() {
      this.$API.providers.getProviders(this.search).then((response) => {
        this.providers = response;
        this.providers.forEach((obj) => {
          obj.Checked = false;
        });
      });
    },
    addProductHandler() {
      if (this.checkImageSize()) return;
      
      if (!this.valid) {
        this.showAlert(
          "warning",
          "Advertencia",
          "Debe llenar todos los campos requeridos"
        );
        return;
      }

      const validProviders = this.providers.some((provider) => provider.Checked);

      if (!validProviders) {
        this.showAlert(
          "warning",
          "Advertencia",
          "El producto debe estar asociado con al menos un proveedor"
        );
        return;
      }

      if (this.barCodesToSave.length > 0) {
        this.form.barCodes = this.barCodesToSave;
      }

      //this.form.itemcomercialname = this.comercialName;
      this.loading = true;
      if (this.form.itemunitspackaging) {
        this.form.itemunitspackaging = this.form.itemunitspackaging + " " + this.typepack;
      }
      const formdata = new FormData();
      if (this.isMediccine) {
        this.form.itemtype = "Medicamentos";
      } else {
        this.form.itemtype = "Otros";
      }
      formdata.append("file", this.image);
      formdata.append("product", JSON.stringify(this.form));

      var providersForProduct = this.providers.filter(
        (item) => item.Checked === true || item.Checked === 1
      );
      formdata.append("providers", JSON.stringify(providersForProduct));

      this.$API.products
        .addProduct(formdata)
        .then(() => {
          this.loading = false;
          //throw new Error(response);
          if (this.loadaedfromprovider) {
            this.$store.commit("vetProviders/setvetProductoprov");
          }
          this.showAlert(
            "success",
            "Exito",
            "El producto se agrego de manera exitosa",
            "ProductsList"
          );
          this.image = null;
          this.url = null;
          let valuesf = {
            family: this.form.family,
            category: this.form.category,
            subcategory: this.form.subcategory,
            Warehouse: this.form.Warehouse,
            idbranch: this.form.idbranch,
            idbusiness: this.form.idbusiness,
            iduser: this.form.iduser,
            idprovider: this.form.idprovider,
          };
          this.form = {
            idbranch: valuesf.idbranch,
            idbusiness: valuesf.idbusiness,
            iduser: valuesf.iduser,
            idprovider: valuesf.idprovider,
            family: valuesf.family,
            category: valuesf.category,
            subcategory: valuesf.subcategory,
            Warehouse: valuesf.Warehouse,
            isglobalbranch: true,
            barCodes: null
          };

          this.barCodesToSave = []

          this.isMediccine = false;
          if (this.isFiltered) {
            this.providersFiltered = this.providersFiltered.map((item) => {
              item.Checked = false;
              return item;
            });
          } else {
            this.providers = this.providers.map((item) => {
              item.Checked = false;
              return item;
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.showAlert("danger", "Error", "Error al agregar el producto");
        });
    },
    onEditorChange(e) {
      this.form.itemnote = e.html;
      if (this.form.itemnote != "") {
        this.showBgcolorNotes = "tabsContent";
      } else if (this.form.itemnote == "" || this.form.itemnote == "<p><br></p>") {
        this.showBgcolorNotes = "tabs";
      } else {
        this.showBgcolorNotes = "tabs";
      }
    },

    getWarehouses() {
      this.$API.warehouse.getWarehouses(this.search).then((response) => {
        this.warehouses = response;
      });
    },

    fillBatchsAndAccountingGroups() {
      let warehouse = this.form.Warehouse;
      this.search.warehouse = warehouse;
      this.$API.warehouse
        .getBatchs(this.search)
        .then((response) => {
          this.batchs = response;
        })
        .catch(() => {
          this.batchs = {};
        });

      this.$API.warehouse
        .getAccountingGroups(this.search)
        .then((response) => {
          this.accountinggroups = response;
        })
        .catch(() => {
          this.accountinggroups = {};
        });
    },

    setValInForm() {
      this.form.idaccountingline = this.selectaccountinggroup.ID_AccountingLine;
      this.form.idaccountingcategory = this.selectaccountinggroup.ID_AccountingCategory;
      this.form.idaccountingsubcategory = this.selectaccountinggroup.ID_AccountingSubCategory;
    },

    filterHandlerFamilies(response) {
      this.families = response;
    },

    filterHandlerCategories(response) {
      this.categories = response;
    },

    filterHandlerSubs(response) {
      this.subcategories = response;
    },
    filterHandlerBarCodes(response) {
      this.isFilteredBarCode = true;
      this.barCodesToSaveFiltered = response;
    },
    maxFileSize(event) {
      const allowedSize = 2 * 1024 * 1024; // 2 MB in bytes
      const fileSize = event.size;

      if (fileSize > allowedSize) {
        this.validImageSize = false;
        return `El tamaño del archivo debe ser menor a 2 Mb`;
      }

      this.validImageSize = true;
      return true;
    },
    checkImageSize() {
      if (this.validImageSize == false) {
        this.showAlert("danger", "Error", "El tamaño de la imagen debe ser menor a 2 Mb");
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btnadd {
  background-color: #3498DB !important;
  color: white;
}

.btncontinue {
  background-color: rgba(80, 80, 80, 1) !important;
  color: white;
}

.p-dropdown-panel {
  width: 50%;
}

.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}

.tabsContent:hover {
  background-color: #ffb266;
  cursor: pointer;
}

.tabs {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.tabsContent {
  width: 100%;
  padding: 10px;
  background-color: #f29d35;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.grid-max-width {
  min-width: 100px !important;
}

.barcode-dialog {
  z-index: 100 !important;
}

.barcode-edit-dialog {
  z-index: 200 !important;
}

.alert-dialog {
  z-index: 300 !important;
}

.note-size{
  font-size: 14px;
}

</style>
