<template>
   <div style="display: none;">

        
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center v-if="mailLabel">
              
            </center>
        </div>

        <div class="book" style="margin: 0; padding: 0; background-color: rgb(105, 105, 105); font-family: arial !important; padding-top: 20px; padding-bottom: 20px;">
                <div style="margin: 0 auto; width: 1000px;display: flex;
                                 justify-content: center; font-size: 10pt;">
                    <div ref="dataprint" style="margin: 0 !important; padding: 0 !important;">
                        <!--div style="position: relative; width: 1000px; min-height: 1294px; background: white;"-->
                      
                            <div style="position: relative; width: 258px;  background: white; ">
                            
                                <div  style="display: flex; justify-content: center; margin-top: 1rem">
                                    
                                    <div> 
                                        {{dataProps.data.user.business}}
                                    </div>
                                </div>
                                <div  style="display: flex; justify-content: center; margin-top: .2rem">
                                
                                    <div>
                                        <b>Correlativo:</b>
                                        {{dataProps.data.header.ID_Invoice}}
                                    </div>
                                </div>
                                <div  style="display: flex; justify-content: center; margin-top: .2rem">
                                    <div>
                                        <div> 
                                            <b>Vendedor </b>
                                            {{dataProps.data.user.business}}
                                        </div>
                                    </div>
                                </div>
                                <div v-for="(item, index) in dataProps.data.body" :key="index" style="margin-top: .2rem">
                                   
                                   <div style="margin-left:.5rem">
                                    {{ item.ID_Item  + ' ' + item.ItemName }}
                                   </div>
                                   <div  style="margin-left:.8rem">
                                    {{ item.ItemQuantity + ' X '  + numberWithCommas(parseFloat(item.ItemUnitPrice).toFixed(2)) + ' = ' +  numberWithCommas(parseFloat(item.ItemGravada).toFixed(2)) }}
                                   </div>
                                   
                                </div>
                                <div style="margin-top:1rem; margin-left:.5rem; display:flex">
                                    Sub total 
                                    <div style="margin-left:7rem">
                                        {{dataProps.data.header.InvoiceCurrency}}
                                        {{numberWithCommas(parseFloat(dataProps.data.header.InvoiceSubTotalVentas).toFixed(2))}}
                                    </div>
                                </div>
                                <!--div style="margin-top:.2rem; margin-left:.5rem; display:flex">
                                    Ventas no sujetas
                                    <div style="margin-left:3.7rem">
                                        {{dataProps.data.header.InvoiceCurrency}}
                                        {{numberWithCommas(parseFloat(dataProps.data.header.InvoiceDescuNoSuj).toFixed(2))}}
                                    </div>
                                </div-->
                                <!--div style="margin-top:.2rem; margin-left:.5rem; display:flex">
                                    Ventas exentas
                                    <div style="margin-left:4.6rem">
                                        {{dataProps.data.header.InvoiceCurrency}}
                                        {{numberWithCommas(parseFloat(dataProps.data.header.InvoiceDescuExenta).toFixed(2))}}
                                    </div>
                                </div-->
                                <!--div style="margin-top:.2rem; margin-left:.5rem; display:flex">
                                    Ventas gravadas
                                    <div style="margin-left:4.1rem">
                                        {{dataProps.data.header.InvoiceCurrency}}
                                        {{numberWithCommas(parseFloat(dataProps.data.header.InvoiceDescuGravada).toFixed(2))}}
                                    </div>
                                </div-->
                                <div style="font-size: 14px;margin-top:.2rem; margin-left:.5rem; display:flex">
                                    <b>Total</b>
                                    <div style="margin-left:8.6rem">
                                        <b>{{dataProps.data.header.InvoiceCurrency}}</b>
                                        <b>{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceTotalPagar).toFixed(2))}}</b>
                                    </div>
                                </div>
                                <div style="min-height:200px">

                                </div>
                            </div>
                            
                    </div>
                <div>
    
    
   
    
</div>



                  
                    </div>
        </div>
   </div>
</template>

<script>

import jsPDF from "jspdf"
import QRCode from 'qrcode';

//import html2canvas from "html2canvas"
//import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue"
import { addFormatToNIT, addFormatNRC } from "../../helpers/misc"
import { numberWithCommas } from "../../helpers/money";
import {numberToText} from '@/helpers/conveterNumberText.js'

export default {
    name: "PrintPosTkNotVisible",
    props: {
        dataProps: {
            type: Object,
        },
        autoPrint: {
            type: Boolean,
            default: false
        },
        id_print: {
            type: Number,
            required: true
        }
    },
   
    data() {
        return {
            header: null,
            footer: null,
            loading: false,
            //seal: "",
            //sealBranch: "",
            numPages: 0,
            //valuesRows: []
            mailLabel: null,
            valuesRows: [],
            urlQr: null,
            typePrint: 0,
            PrintAutoPdf: false,
            PrintAutoJson: false,
        }
    },
    async  mounted() {

        try{
            await this.calculatePagesOfDoc()
            this.setupComponent();
        }
        catch(err){
          //  console.log(err)
            this.$emit('autoPrintError', err)
        }
    },
    computed: {
        /*edad() {
            let edad = this.dataProps.data.profile.Age
            if (edad == null || edad == undefined) {
                return ''
            } else {
                return edad
            }
        },*/
      
        BillingNrc() {
            return addFormatNRC(this.dataProps.data.branch.BusinessBillingNRC)
        },
        BillingNit() {
            //console.log(this.dataProps.data.branch.BusinessBillingNIT);
            return addFormatToNIT(this.dataProps.data.branch.BusinessBillingNIT)
        },
     
    },
    watch: {
       
    },
    methods: {
        isAutoPrintCompleted() {
            if(this.PrintAutoPdf && this.PrintAutoJson) {
                this.$emit('autoPrintCompleted', this.id_print)
            }
        },
        setupComponent() {
            const partes = this.dataProps.data.header.InvoiceDate.split("/");
            const dia = partes[0];
            const mes = partes[1];
            const anio = partes[2];

            // Crear una nueva fecha en formato "yyyy-mm-dd"
            const fechaNueva = `${anio}-${mes}-${dia}`;
            const url = this.dataProps.data.branch.AuthFeMode == 1 ? `https://admin.factura.gob.sv/consultaPublica?ambiente=01&codGen=${this.dataProps.data.header.CodigoGeneracion}&fechaEmi=${fechaNueva}` : `https://admin.factura.gob.sv/consultaPublica?ambiente=00&codGen=${this.dataProps.data.header.CodigoGeneracion}&fechaEmi=${fechaNueva}`;
            
            // Generar el código QR y mostrarlo en el elemento con id "qrcode"

            const opt = {
                errorCorrectionLevel: 'H',
                type: 'image/png',
                quality: 0.3,
                margin: 1,
                color: {
                    dark:"#000000",
                    light:"#ffffff"
                },
                width: 100
            }

            // QRCode.toCanvas(this.$refs.qrcode, url, { width: 100 }, (error) => {
            //     if (error) {
            //         console.log('Error al generar el código QR:', error);
            //     }
            // });
            
            QRCode.toDataURL(url, opt, (error, url) => {
                if (error) {
                    console.log('Error al generar el código QR:', error);
                    throw error;
                }

                //console.log(url);
                this.urlQr = url
                //this.$refs.qrcode.src = url;
            });
            if(this.autoPrint){
            
            setTimeout(() => {
           //     this.print()

                try{
                   
                    this.Download();
                    this.downLoadJSON()
                }
                catch(err){
                    console.log(err)
                    this.$emit('autoPrintError',err)
                }
            
            }, 200);
        }
        },
        GetnumberToText(number) {
            if(number) return numberToText(number)
            else return ''
        },
        numberWithCommas(x) {
            return numberWithCommas(x);
        },
        getHeaderFooter() {
            let search = {};
            search.ID_business = JSON.parse(localStorage.getItem("user")).businessid || ""
            search.ID_branch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.$API.HF.getHF(search).then((data) => {
                let header = [], footer = [];
                header = data.filter((x) => x.State == '1' && x.Type == 'Header');
                this.header = header.length > 0 ? JSON.parse(header[0].Body).html : '';
                this.header = this.header.replace(/(\n)/gm, "");
                footer = data.filter((x) => x.State == '1' && x.Type == 'Footer');
                this.footer = footer.length > 0 ? JSON.parse(footer[0].Body).html : '';
            })
        },

        calculatePagesOfDoc() {
            let pages = 0
            let rows = 0
            this.valuesRows[pages] = []

            try{
                if(this.dataProps.data.branch.BusinessBillingNRC == '3213715' || this.dataProps.data.branch.BusinessBillingNRC == '2201050') {
                    this.typePrint = 1
                    //One page
                    if(this.dataProps.data.body.length <= 24){
                        for(let i = 0; i < this.dataProps.data.body.length; i++){
                            rows++
                            this.valuesRows[pages].push(this.dataProps.data.body[i])
                        }    
                    }
                    else{
                        for(let i = 0; i < this.dataProps.data.body.length; i++){
                            if(rows < 29 && (i !== this.dataProps.data.body.length - 1)){
                                rows++
                                this.valuesRows[pages].push(this.dataProps.data.body[i])
                            }
                            else if(rows <= 24){
                                rows++
                                this.valuesRows[pages].push(this.dataProps.data.body[i])
                            }
                            else{
                                this.valuesRows[pages].push(this.dataProps.data.body[i])
                                pages++
                                rows = 0
                                this.valuesRows[pages] = []
                            }
                        }
                    }
                }
                else{
                    this.typePrint = 0
                    //Cuando es solo una página
                    if(this.dataProps.data.body.length <= 34){
                        for(let i = 0; i < this.dataProps.data.body.length; i++){
                            rows++
                            this.valuesRows[pages].push(this.dataProps.data.body[i])
                        }    
                    }
                    else{
                        for(let i = 0; i < this.dataProps.data.body.length; i++){
                            if(rows < 39 && (i !== this.dataProps.data.body.length - 1)){
                                rows++
                                this.valuesRows[pages].push(this.dataProps.data.body[i])
                            }
                            else if(rows <= 34){
                                rows++
                                this.valuesRows[pages].push(this.dataProps.data.body[i])
                            }
                            else{
                                this.valuesRows[pages].push(this.dataProps.data.body[i])
                                pages++
                                rows = 0
                                this.valuesRows[pages] = []
                            }
                        }
                    }
                }
                
            }
            catch(err) {
                alert(err)
            }
            pages = pages + 1
            this.numPages = pages
        },

        async Print(){
           
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')

            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 1,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            })
            
            
            //doc.deletePage(2)

            doc.autoPrint({variant: 'non-conform'});
            const blob = doc.output("bloburl")
            window.open(blob)

            this.loading = false
        },

        async Download(){

            try{
            this.loading = true
            var doc = new jsPDF('p', 'mm', [270, 841.89]);
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 1,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            });
            
            //doc.deletePage(2)
            
            await  doc.save(this.dataProps.data.header.ID_Invoice+ "_"+this.dataProps.data.header.InvoiceDate.replaceAll("/","-") + "_"+this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".pdf"); //Agregado por Juan 13082023
            if(this.autoPrint){
                this.PrintAutoPdf = true
            this.isAutoPrintCompleted()

            }
            this.loading = false}
            catch(err){
  
                this.$emit('autoPrintError',err)
            }
        },
        async sendMail() {
            try {
                this.mailLabel = null;
                var doc = new jsPDF('p', 'pt', 'letter')
                //console.log(doc);
                //console.log(this.$refs.dataprint);
                await doc.html(this.$refs.dataprint, {
                    callback: function(doc){
                        doc.output()
                    },
                    x: 0,
                    y: 0,
                    margin: [0, 0, 0, 0],
                    html2canvas:{ 
                        scale: 0.612,
                        //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                        useCORS: true,
                        logging: false,
                        allowTaint: true
                    },
                });
                
                this.assignCuerpoDocumento();

                const { data: { header: { jsonMH }, dteJson: { ID_Invoice } } } = this.dataProps;

                const body = {
                    dtefilebuffer: new Buffer.from(doc.output('arraybuffer')),
                    dtejson: { ...jsonMH, ID_Invoice },
                    dtefilebufferfilename: this.dataProps.data.header.ID_Invoice+ "_"+this.dataProps.data.header.InvoiceDate.replaceAll("/","-") + "_"+this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".pdf"
                };

                // console.log(body);
                this.$API.invoices.sendInvoiceMail(body);

                this.mailLabel = 'Se envió el correo correctamente';
                this.$emit('mailSent');
            } catch (error) {
                this.mailLabel = 'Ocurrió un error al enviar el correo';
                console.log(error);
            }
        },
        assignCuerpoDocumento() {
            this.dataProps.data.header.jsonMH.cuerpoDocumento = this.dataProps.data.body.map( ( item, index ) => ( {
                "descripcion": item.ItemDescription,
                "montoDescu": item.ItemDiscount,
                "codigo": item.ID_Item,
                "ventaGravada": item.ItemGravada,
                "ivaItem": item.ItemIva,
                "ventaNoSuj": item.ItemNoSuj,
                "ventaExenta": item.ItemExenta,
                // "tributos": null,
                "numItem": index + 1,
                "noGravado": item.ItemNoGravada,
                "psv": item.ItemPsv,
                // "tipoItem": 3,
                // "codTributo": null,
                "uniMedida": item.ItemMeasureUnit,
                // "numeroDocumento": null,
                "cantidad": item.ItemQuantity,
                "precioUni": item.ItemUnitPrice
            } ) )
            // for( const item in this.dataProps.data.body ) {
            //      [
            //         "descr"
            //     ]
            // }
        },
        async downLoadJSON() {
            try {
                const aElement = document.createElement('a');
                aElement.setAttribute('download', this.dataProps.data.header.ID_Invoice+ "_"+this.dataProps.data.header.InvoiceDate.replaceAll("/","-") + "_"+this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".pdf");
                this.assignCuerpoDocumento();
                const blob = new Blob([JSON.stringify(this.dataProps.data.header.jsonMH)], { type: 'application/json' });
                const href = URL.createObjectURL(blob);
                aElement.href = href;
                aElement.download = this.dataProps.data.header.ID_Invoice+ "_"+this.dataProps.data.header.InvoiceDate.replaceAll("/","-") + "_"+this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".json";
                await aElement.click();
                URL.revokeObjectURL(href);
                if(this.autoPrint){
                    this.PrintAutoJson = true
                    this.isAutoPrintCompleted()

                }
            } catch (error) {
                   
                
                this.$emit('autoPrintError', error);

                console.log(error);
            }
        }
    }
}
</script>

<style scoped>
    .u-row .u-col-100{
        width: 100% !important;
    }
    .btnclean{
        border-radius: 15px;
        border: 1px solid white;
  background-color: #F29D35 !important;
  color: white !important;
}
    .btnclose{
        border-radius: 15px;
        border: 1px solid white;
        background-color: rgb(255, 38, 38) !important;
        color: white !important;
       
    }

    * {
        font-family: Arial, Helvetica, sans-serif !important;
    }

 

</style>
