<template>
  <v-app translate="no">
    <template v-if="token">
      <drawer
      
    :drawer-mini="drawerMini"
    
        :drawerMini="drawerMini"
        :drawer-visibility.sync="drawerVisibility"
        
        @toggleDrawer="drawerMini = true"
        @chevron="changeIsShow"
        @showSidebarOptions="hideButton"
        :style="
          !minimizeSidebar
            ? !drawerMini
              ? 'width: 192px;'
              : 'width:56px;'
            : 'max-width:192px'
        "
        @mouse-over="mouseIn = true"
        @mouse-out="mouseIn = false"
      />

      <v-main
        class="pr-3"
          :style="mainStyle"
      >
        <v-btn
          color="pink"
          fab
          dark
          small
          fixed
          elevation="7"
          :class="{
            'fixed-display-m': drawerMini,
            'fixed-display-l': !drawerMini,
            'fixed-display-mobile': showMobileToggleButton,
           

          }"
          :style="isSidebarOpen ? 'display: none;' : ''"
          @click="changeDrawerView()"
          v-if="isShow && !$vuetify.breakpoint.smAndDown"
        >
          <v-icon class="white--text">{{
            drawerMini ? "mdi-chevron-right" : "mdi-chevron-left"
          }}</v-icon>
        </v-btn>
        <v-app-bar fixed  v-if="isMobile">
 
  <v-app-bar-nav-icon  @click="drawerVisibility = !drawerVisibility" ></v-app-bar-nav-icon>
</v-app-bar>
        <router-view />
      </v-main>
    </template>
    <router-view v-else></router-view>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import Drawer from "./layouts/Drawer";
import axios from "axios";


export default {
  name: "App",
  components: { Drawer },
  computed: {
    ...mapState(["token", "customizeOptions", "drawerMini"]),
    bg: function () {
      return JSON.parse(this.customizeOptions).backgroundImage;
    },
    isMobile() {

      return this.$vuetify.breakpoint.smAndDown;
    },
    minimizeSidebar() {
      return JSON.parse(this.customizeOptions).minimizeSidebar;
    },
    showMobileToggleButton() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    mainStyle() {
    const isMobile = this.$vuetify.breakpoint.smAndDown; // Verdadero si es pantalla pequeña (móvil)
    const paddingBase = this.isDrawerVisible ? '56px' : '0'; // Base padding cuando drawer es visible
    
   
    const padding = isMobile ? '60px 0 0 0 ' : (
      !this.minimizeSidebar && this.drawerMini ? '0 0 0 56px' :
      !this.minimizeSidebar && !this.drawerMini ? '0 0 0 192px' :
      this.mouseIn && this.minimizeSidebar ? '0 0 0 192px' : `0 0 0 ${paddingBase}`
    );
    
    
    const backgroundStyle = this.bg.split('/')[0] == 'https:' ?
      `url(${this.backgroundImage}) center center / cover no-repeat` :
      `url(${this.bg}) center center / cover no-repeat`;
    
    return {
      background: backgroundStyle,
      backgroundAttachment: 'fixed',
      padding,
    };
  },
  },
  data: () => ({
    mouseIn: false,
    isShow: true,
    backgroundImage: null,
   
    isSidebarOpen:false,
    drawerVisibility: true
  }),
  async created() {
    axios.get("https://api.ipify.org?format=json").then(async (response) => {
      localStorage.setItem("clientIP", response.data.ip);
    });
  },
  metaInfo() {
    return {
      title: `innova${process.env.VUE_APP_TITLE} APP`,
      link: [
        {
          rel: "icon",
          type: "image/png",
          href: `${process.env.VUE_APP_ICON_URL}`,
        },
      ],
    };
  },
  async mounted() {
 
      this.adjustDrawerState(this.$vuetify.breakpoint.xs),
      this.handleResize(); // Llama al método al montar el componente
  window.addEventListener('resize', this.handleResize);
      
  },
  beforeDestroy() {
  window.removeEventListener('resize', this.handleResize); // Limpia el listener
},

  watch: {
    drawerVisibility(newVal){
      console.log(newVal)
    }
    ,

    customizeOptions: {
      handler: async function () {
        await this.getBackground();
      },
      immediate: true,
    },
    "$vuetify.breakpoint.smAndDown"(newVal) {
      this.adjustDrawerState(newVal);
    },
   
    isSidebarOpen(){
!this.isSidebarOpen?'display:none':''
    },
  },
  methods: {
    handleMouseOver() {
      console.log("mouse over");
      this.mouseIn = true;
    },
    handleMouseOut() {
      this.mouseIn = false;
    },
    changeIsShow(status) {
      this.isShow = status;
    },
    closeDrawer() {
      this.drawerVisibility = false; 
    },

    changeDrawerView() {
      this.$store.commit("setDrawerMini", !this.drawerMini);
    },
    handleResize() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.drawerVisibility = false;
    } else {
      this.drawerVisibility = true;
    }
  },
    async getBackground() {
      this.backgroundImage = JSON.parse(this.customizeOptions).backgroundImage;
      /*if(this.backgroundImage.split('/')[0] == 'https:') {
                const { imageBase64: logoImageBase64 } = await this.$API.utilities.getImageInBase64({ "file": JSON.parse(this.customizeOptions).backgroundImage })
                this.backgroundImage = `${JSON.parse(this.customizeOptions).backgroundImage}`
            }*/
    },

  
    hideButton(isSidebarOpen){

this.isSidebarOpen=isSidebarOpen
return this.isSidebarOpen
    },
    

  },
};
</script>
<style>

.v-dialog {
  z-index: 1 !important;
}
.v-application .fixed-display-m {
  display: fixed !important;
  z-index: 102 !important;
  left: 36px !important;
}

.fixed-display-l {
  z-index: 102 !important;
  left: 176px !important;
}

.fixed-display-mobile {
  right: 36px !important;
  top: 16px !important;
  z-index: 101 !important;
}

.drawer-mobile {
  z-index: 100 !important;
}

.fixed-display-m {
  display: fixed !important;
  z-index: 102 !important;
}

.fixed-display-m {
  z-index: 102 !important;

  left: 3% !important;
}
.hideButton {
  display: none !important;
}

.hovered {
  background-color: red !important;
}

.noHovered {
  background-color: blue !important;
}


</style>
