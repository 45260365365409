<template>
  <div>
    <v-card flat>
      <v-card-text>
        <user-profile :patientinfo="patientinfo" :data="data" :useData="useData" />
        <v-row style="margin-top: 1rem">
          <v-col cols="12" class="box" style="margin-top: 1rem">
            <v-form v-model="valid" class="position-relative">
              <block v-if="loading"></block>

              <v-row class="ml-2 mr-2" style="margin-top: 0.3rem">
                <v-col cols="12" class="tabsNh">
                  <v-col
                    cols="12"
                    v-if="reproduction.ID_Reproduction"
                    style="display: flex; justify-content: space-between"
                  >
                    <div style="width: 212px;">
                      <v-btn
                        v-if="$store.getters['rolesUser/getCustomRole'](146)"
                        small
                        color="error"
                        @click.prevent="
                          showAlert(
                            'question',
                            'Borrar',
                            'Esta seguro que desea eliminar el elemento',
                            'deleteReproduction'
                          );
                          confirmtoDelete = reproduction;
                        "
                      >
                        <v-icon> mdi-delete </v-icon>
                      </v-btn>
                    </div>
                    <div>
                        <v-btn v-if="$store.getters['rolesUser/getCustomRole'](107)" small class="btn-add mr-1" @click.prevent="getItemsToBeBilled(); showBillPatient = true">
                            <v-icon class="mr-3">mdi-account-details</v-icon> Facturar paciente
                        </v-btn>
                    </div>
                    <div>
                      <v-btn
                        v-if="$store.getters['rolesUser/getCustomRole'](147)"
                        small
                        @click="
                          print = {
                            show: true,
                            data: {
                              date: reproduction.ReproductionDate,
                              reproduction: { ...reproduction, breeds },
                              patientinfo: patientinfo,
                              useData: useData,
                              customer: patientinfo.customer,
                            },
                          }
                        "
                        style="margin-right: 0.3rem"
                      >
                        <v-icon> mdi-printer </v-icon>
                      </v-btn>
                      <v-btn
                        small
                        class="btnblue mr-1"
                        @click="showhistory = !showhistory"
                        v-if="$store.getters['rolesUser/getCustomRole'](144)"
                        :width="80"
                      >
                        <v-icon> mdi-magnify </v-icon>
                      </v-btn>
                      <v-btn small class="btnclean" @click.prevent="resetData">
                        <v-icon> mdi-broom </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="$store.getters['rolesUser/getCustomRole'](145)"
                        small
                        class="btnsave"
                        @click.prevent="updateReproduction"
                      >
                        <v-icon> mdi-content-save </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    v-if="
                      selectedvalues && $store.getters['rolesUser/getCustomRole'](147)
                    "
                    style="display: flex; justify-content: space-between"
                  >

                    <div style="width: 212px;" ></div>
                    <div>
                        <v-btn v-if="$store.getters['rolesUser/getCustomRole'](107)" small class="btn-add mr-1" @click.prevent="getItemsToBeBilled(); showBillPatient = true">
                            <v-icon class="mr-3">mdi-account-details</v-icon> Facturar paciente
                        </v-btn>
                    </div>
                    <div>
                      <v-btn
                        small
                        @click="
                          print = {
                            show: true,
                            data: {
                              date: reproduction.ReproductionDate,
                              reproduction: { ...reproduction, breeds },
                              patientinfo: patientinfo,
                              useData: useData,
                              customer: patientinfo.customer,
                            },
                          }
                        "
                        style="margin-right: 0.3rem"
                      >
                        <v-icon> mdi-printer </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="$store.getters['rolesUser/getCustomRole'](144)"
                        small
                        class="btnblue mr-1"
                        @click="showhistory = !showhistory"
                      >
                        <v-icon> mdi-magnify </v-icon>
                      </v-btn>
                      <v-btn small class="btnclean" @click.prevent="resetData">
                        <v-icon> mdi-broom </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="$store.getters['rolesUser/getCustomRole'](145)"
                        small
                        class="btnsave"
                        @click.prevent.stop="addReproduction"
                      >
                        <v-icon> mdi-content-save </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-col>
                <v-col cols="12" style="margin-top: 1rem"></v-col>

                <v-col cols="12" md="3" sm="12">
                  <date-picker
                    label="Fecha"
                    :value="reproduction.ReproductionDate"
                    @dateSelected="
                      (param) => {
                        let newd = param;
                        if (!selectedvalues) {
                          resetData();
                        }
                        reproduction.ReproductionDate = newd;
                        checkReproductionDate(newd);
                      }
                    "
                  ></date-picker>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    outlined
                    dense
                    v-model="reproduction.Type"
                    :items="Type"
                    attach
                    clearable
                    label="Tipo"
                    :rules="[(v) => (v !== null && v !== '') || 'Campo requerido']"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="5">
                  <!--v-btn @click.prevent="">
                                                  <v-icon>
                                                      mdi-mail
                                                  </v-icon>
                                              </v-btn-->

                  <!--v-btn dark color="green" @click.prevent="">
                                                  <v-icon>
                                                      mdi-whatsapp
                                                  </v-icon>
                                              </v-btn-->

                  <!--v-btn @click.prevent="">
                                                  <v-icon>
                                                      mdi-file-excel
                                                  </v-icon>
                                              </v-btn-->

                  <!--v-btn @click.prevent="">
                                                  <v-icon>
                                                      mdi-xml
                                                  </v-icon>
                                              </v-btn-->

                  <!--v-btn @click.prevent="">
                                                  <v-icon>
                                                      mdi-file-pdf-box
                                                  </v-icon>
                                              </v-btn-->
                </v-col>

                <v-col cols="12" md="1" style="display: flex; justify-content: flex-end">
                  <v-btn
                    @click="showComments = !showComments"
                    :class="reproduction.Notes ? 'btnclean' : 'btnblack'"
                  >
                    Comentarios
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="ml-2 mr-2">
                <v-col cols="12" md="12" sm="12">
                  <h2>Celos</h2>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <div style="margin-top: 0.8rem;">
                    <v-checkbox
                      v-model="reproduction.Jealousy"
                      label="Celos"
                      class="start-align-label"
                    ></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="3" >
                  <div style="width: 200px !important; margin-top: 0.8rem">
                    <date-picker
                      label="Inicio"
                      :value="reproduction.JealousyBeginning"
                      :disbaled="!reproduction.Jealousy ? true : false"
                      @dateSelected="(param) => (reproduction.JealousyBeginning = param)"
                    ></date-picker>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="3" >
                  <div style="width: 200px !important; margin-top: 0.8rem">
                    <date-picker
                      label="Fin"
                      :value="reproduction.JealousyEnd"
                      :disbaled="!reproduction.Jealousy ? true : false"
                      @dateSelected="(param) => (reproduction.JealousyEnd = param)"
                    ></date-picker>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <div style="margin-top: 0.8rem;">
                    <v-checkbox
                      v-model="reproduction.HeatPrevent"
                      label="Prevención anticelo"
                      class="start-align-label"
                    ></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <div style="margin-top: 0.8rem;">
                    <v-checkbox
                      v-model="reproduction.AbortiveVaccine"
                      label="Vacuna abortiva"
                      class="start-align-label"
                    ></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12" md="12">
                  <h2 style="padding: 10px 0;">Cruzas</h2>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    outlined
                    dense
                    label="Cruzado con"
                    v-model="breed.ReproductionWith"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-btn
                    :class="breeds.length > 0 ? 'btnclean' : 'btnblack'"
                    @click="showcruz = !showcruz"
                  >
                    Fechas cruzas
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <h2 style="padding: 10px 0;">Gestación</h2>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                  <date-picker
                    label="Fecha"
                    :value="reproduction.DateGestation"
                    @dateSelected="(param) => (reproduction.DateGestation = param)"
                  ></date-picker>
                </v-col>
                <v-col cols="12" md="2">
                  <!-- <v-text-field outlined dense label="Tipo de Diagnostico" v-model="reproduction.TypeDiagnostic"></v-text-field> -->
                  <v-select
                    outlined
                    dense
                    v-model="reproduction.TypeDiagnostic"
                    :items="diagTypeG"
                    clearable
                    attach
                    label="Tipo de Diagnostico"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    outlined
                    dense
                    label="Perímetro Abdominal (cm)"
                    v-model="reproduction.AbdominalPerimeter"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <!-- <v-text-field outlined dense label="Resultado" v-model="reproduction.Outcome"></v-text-field> -->
                  <v-select
                    outlined
                    dense
                    v-model="reproduction.Outcome"
                    :items="resTypeG"
                    clearable
                    attach
                    label="Resultado"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <h2>Nacimiento</h2>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <div style="margin-top: 0.8rem">
                    <v-checkbox
                      v-model="reproduction.CaesareanSection"
                      label="Cesárea"
                      v-on:change="handleCaesareanSectionChange"
                    ></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <div style="margin-top: 0.8rem">
                    <v-checkbox
                      v-model="reproduction.Natural"
                      label="Natural"
                      v-on:change="handleNaturalChange"
                    ></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    style="margin-top: 0.8rem;"
                    outlined
                    dense
                    v-model="reproduction.Alive"
                    label="Vivos"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    style="margin-top: 0.8rem;"
                    outlined
                    dense
                    v-model="reproduction.Dead"
                    label="Muertos"
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>

              <br />
              <!-- <div >
                <v-tabs  v-model="tab" centered>
               
                  
                  <v-tab href="#tab-4">Nacimiento</v-tab>
                  <v-tab href="#tab-5">Notas</v-tab>
                </v-tabs>
                <v-tabs-items  class="ml-2 mr-2"  v-model="tab">
                  <v-tab-item value="tab-1">
                    <div>
                      <br />
                    
                      
                    </div>
                    <br />



                  </v-tab-item>
                  <v-tab-item value="tab-2">
                    <div>
                      <br />
                      <v-row>

                        
                    </v-row>
                    </div>
                  </v-tab-item>
                  <v-tab-item value="tab-3">
                    <div>
                      <br />

                      <v-row>
                        
                      </v-row>
                    </div>
                  </v-tab-item>
                  <v-tab-item value="tab-4">
                    <div>
                      <br />
                      <p>TIPO DE NACIMIENTO</p>
                      <br />
                      <v-row>

                       
                      </v-row>
                    </div>
                  </v-tab-item>
                  <v-tab-item value="tab-5">
                    <div>
                      <br />

                      <v-row>

                        <v-col cols="12" md="12" style="margin-bottom: 1rem;">
                          <quill-editor v-model="reproduction.Notes" :options="quillOptions"></quill-editor>
                        </v-col>

                      </v-row>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </div> -->

              <v-row style="margin-top: 0.6rem; margin-bottom: 1rem"> </v-row>
            </v-form>
          </v-col>
        </v-row>

        <!--   </v-card-text> -->
        <!--  </v-card> -->
        <v-dialog v-model="showcruz" scrollable :max-width="$vuetify.breakpoint.smAndDown ? '90%':'60%'">
          <v-card class="pa-4" height="100%">
            <v-col
              cols="12"
              md="12"
              style="display: flex; justify-content: space-between"
            >
              <label>Fechas de Cruzas</label>
              <v-btn small class="btndelete" @click.prevent="showcruz = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col
            class="flex-wrap"
              cols="12"
              md="12"
              style="display: flex; justify-content: space-between"
            >
              <div style="width:200px">
                <date-picker
                  label="Fecha"
                  :value="breed.Date"
                  @dateSelected="(param) => (breed.Date = param)"
                ></date-picker>
              </div>

              <v-btn class="btn-add" @click.prevent="transportDataBreed()">
                AGREGAR
              </v-btn>
            </v-col>

            <DxDataGrid
               :scrolling="{ useNative: true }"
              :show-borders="true"
              :data-source="breeds"
              key-expr="id"
              style="margin-bottom: 0.6rem"
            >
              <DxColumn :width="columnWidth" cell-template="show-template" caption=""></DxColumn>
              <DxColumn :width="columnWidth" data-field="ReproductionWith" caption="Cruzado con"></DxColumn>
              <DxColumn :width="columnWidth" data-field="Date" caption="Fecha"></DxColumn>
              <template #show-template="{ data }">
                <div>
                  <a href="#" @click.prevent.stop="deleteBreedSelected(data.data)">
                    <v-icon color="error">mdi-delete</v-icon>
                  </a>
                </div>
              </template>
            </DxDataGrid>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showComments" scrollable :max-width="$vuetify.breakpoint.smAndDown ? '90%':'60%'">
          <v-card class="pa-4">
            <div
              style="
                margin-top: 0.3rem;
                margin-bottom: 0.3rem;
                display: flex;
                justify-content: flex-end;
              "
            >
              <v-btn small class="btndelete" @click.prevent="showComments = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-col cols="12" md="12" style="">
              <quill-editor
                v-model="reproduction.Notes"
                :options="quillOptions"
              ></quill-editor>
            </v-col>
          </v-card>
        </v-dialog>

        <v-dialog v-model="showhistory" scrollable :width="'90%'">
          <v-card class="pa-4">
            <v-card-text>
              <div
                style="
                  margin-top: 0.3rem;
                  margin-bottom: 0.3rem;
                  display: flex;
                  justify-content: flex-end;
                "
              >
                <v-btn small class="btndelete" @click.prevent="showhistory = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>

              <GeneralFilter
                class="background border-box"
                entity="cliente"
                :filterEndpoint="
                  $API.veterinaryReproduction.getfilterVeterinaryReproduction
                "
                :search="search"
                @emptyFilter="getAllReproduction"
                @filtered="filterHandler"
              >
                <DxDataGrid
                  :scrolling="{ useNative: true }"
                  :show-borders="true"
                  :data-source="reproductions"
                  key-expr="ID_Reproduction"
                  @exporting="onExporting"
                  :column-auto-width="true" 
                  style="width: fit-content;"
                >
                  <DxExport :enabled="true" />
                  <DxPaging :page-size="10" />
                  <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                  <DxColumn :min-width="100" data-field="ReproductionDate" caption="Fecha"></DxColumn>
                  <DxColumn :min-width="100" data-field="ID_Reproduction" caption="Id"></DxColumn>
                  <DxColumn :min-width="100" data-field="Type" caption="Tipo"></DxColumn>
                  <DxColumn :min-width="200" data-field="fullName" caption="Atendido Por"></DxColumn>
                  <DxColumn :min-width="200" data-field="DateGestation" caption="Fecha gestación"></DxColumn>
                  <DxColumn :min-width="200" data-field="TypeDiagnostic" caption="Tipo de diagnostico"></DxColumn>
                  <DxColumn :min-width="200" data-field="Outcome" caption="Resultado"></DxColumn>
                  <DxColumn
                    v-if="$store.getters['rolesUser/getCustomRole'](146)"
                    :width="80"
                    cell-template="show-delete"
                    caption=""
                  ></DxColumn>
                  <template #show-delete="{ data }">
                    <div>
                      <a
                        href=""
                        class="mx-2"
                        @click.prevent.stop="
                          showAlert(
                            'question',
                            'Borrar',
                            'Esta seguro que desea eliminar el elemento',
                            'deleteReproduction'
                          );
                          confirmtoDelete = data.data;
                          showhistory = false;
                        "
                      >
                        <v-icon color="error">mdi-delete</v-icon>
                      </a>
                    </div>
                  </template>
                  <template #show-template="{ data }">
                    <div>
                      <a
                        href="#"
                        @click.prevent.stop="
                          selectingReproduction(data.data);
                          selectedvalues = false;
                          showhistory = false;
                        "
                      >
                        <v-icon color="primary">mdi-eye</v-icon>
                      </a>
                    </div>
                  </template>
                </DxDataGrid>
              </GeneralFilter>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- Dialog bill patient -->
        <v-dialog v-model="showBillPatient" width="90%">
          <v-card class="py-3 px-3">
              <v-card-title class="text-h5">
              Facturar paciente 
              </v-card-title>

              <v-card-actions>
              <v-row>
                  <v-col cols="12" class="tabsNh" style="padding: 14px">
                  <v-col style="display: flex; justify-content: space-between; min-height: 50px">
                      <div>
                      <!-- <v-btn v-if="!showServicesToAdd" small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                          <v-icon>mdi-menu-down</v-icon>
                          Ver items
                      </v-btn>
                      <v-btn v-else small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                          <v-icon>mdi-menu-up</v-icon>
                          Ocultar
                      </v-btn> -->
                      </div>
                      <div>
                      <v-btn small class="btn-add" @click.prevent="saveItemsToBeBilled()">
                          <v-icon>mdi-content-save</v-icon>
                          Guardar
                      </v-btn>
                      </div>

                      <div>
                      <v-btn small class="btnsave" @click.prevent="billedPatientsOfCustomer()">
                          <v-icon>mdi-cash-register</v-icon>
                          Facturar cliente
                      </v-btn>
                      </div>
                  </v-col>
                  </v-col>
              </v-row>
              </v-card-actions>
              <div style="height: 500px; overflow-y: auto; overflow-x: hidden; margin-top: 20px; margin-bottom: 20px; padding: 0 20px;">
              <!-- Services default -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver servicios por defecto
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide1 = !togglehide1' v-show='!togglehide1' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide1 = !togglehide1' v-show='togglehide1' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide1">
                  <DxDataGrid class="isScrolledByGeneralFilter dataGridService" :data-source="servicesDefault" 
                  :show-borders="true" :column-auto-width="true" style="width: fit-content;">
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="CodeService" header-cell-template="headerCodeService" />
                      <template #headerCodeService="{ }">
                      <div>Código de<br />servicio</div>
                      </template>
                      <DxColumn data-field="NameService" header-cell-template="headerNameService" />
                      <template #headerNameService="{ }">
                      <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" header-cell-template="headerName" />
                      <template #headerName="{ }">
                      <div>Nombre a<br />facturar</div>
                      </template>
                      <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice" />
                      <template #headerTypeservice="{ }">
                      <div>Tipo de<br />servicio</div>
                      </template-->
                      <DxColumn :width="65" data-field="Price" caption="Precio" />
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""/>
                      <template #show-template="{ data }">
                      <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                          <v-icon>mdi-plus</v-icon>
                      </a>
                      </template>
                      <template #quantity-template="{ data }">
                      <p>{{ servicesToAdd.find(item => item.CodeService == data.data.CodeService).quantity }}</p>
                      </template>
                  </DxDataGrid>
                  </v-col>
              </v-row>

              <!-- Services to select -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver todos los servicios
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide2 = !togglehide2' v-show='!togglehide2' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide2 = !togglehide2' v-show='togglehide2' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide2">
                  <GeneralFilter 
                      :filterEndpoint="$API.services.getAllServicesFiltered" 
                      :search="searchToBill"
                      @emptyFilter="getServices" 
                      @filtered="servicesFilterHandler"
                  >
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="servicesToBill"
                      key-expr="CodeService" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="CodeService" caption="ID"></DxColumn>
                      <DxColumn data-field="NameService" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice">
                      </DxColumn-->
                      <!--template #headerTypeservice="{ }">
                          <div>Tipo de<br />servicio</div>
                      </template-->
                      <DxColumn :width="65" data-field="Price" caption="Precio"></DxColumn>
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                          <v-icon>mdi-plus</v-icon>
                          </a>
                      </template>
                      <template #quantity-template="{ data }">
                          <p style="margin: 0;">{{ servicesToAdd.find(item => item.CodeService == data.data.CodeService)?.quantity || '' }}</p>
                      </template>
                      </DxDataGrid>
                  </GeneralFilter>
                  </v-col>
              </v-row>

              <!-- Products to select -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver todos los productos
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide3 = !togglehide3' v-show='!togglehide3' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide3 = !togglehide3' v-show='togglehide3' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide3">
                  <GeneralFilter 
                      :filterEndpoint="$API.products.getProductsToBillForInvoiceFiltered" 
                      :search="searchToBill"
                      @emptyFilter="getProducts" 
                      @filtered="productsFilterHandler"
                  >
                      <v-row style="padding: 1rem;">
                          <v-col cols="12" md="3" sm="4">
                              <v-autocomplete
                              v-model="selectedWarehouse"
                              item-text="Warehouse"
                              item-value="ID_Warehouse"
                              :items="warehouses"
                              @change="handleSelectWarehouse"
                              label="Almacenes"
                              ref="werehouseRef"
                              dense 
                              outlined 
                              attach
                              >
                              </v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="9" sm="8">
                              <p style="color: #e3191e !important; font-size: 12px; margin: 12px 0" v-if="warehouses.length == 0">No existen almacenes de productos para pacientes.</p>
                          </v-col>
                      </v-row>
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="productsToBill"
                      key-expr="ID_Item" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="ID_Item" caption="ID"></DxColumn>
                      <DxColumn data-field="ItemName" header-cell-template="headerName"></DxColumn>
                      <template #headerName="{ }">
                          <div>Nombre del<br />producto</div>
                      </template>
                      <DxColumn data-field="ItemComercialName" header-cell-template="headerNameComercial"></DxColumn>
                      <template #headerNameComercial="{ }">
                          <div>Nombre<br />comercial</div>
                      </template>
                      <DxColumn :width="65" data-field="ItemPrice" caption="Precio"></DxColumn>
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="addProduct(data.data)">
                          <v-icon>mdi-plus</v-icon>
                          </a>
                      </template>
                      <template #quantity-template="{ data }">
                        <p style="margin: 0;">{{ productsToAdd.find(item => item.ID_Item == data.data.ID_Item)?.quantity || '' }}</p>
                      </template>
                      </DxDataGrid>
                  </GeneralFilter>
                  </v-col>
              </v-row>

              <!-- ServiceToAdd -->
              <v-row style="margin-top: 2rem">
                  <v-col cols="12">
                  <h4 style="padding: 16px;">Servicios seleccionados</h4>
                  <div style="overflow-x: scroll; padding: 0 12px;">
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="servicesToAdd"
                      key-expr="CodeService" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"    
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="5" />
                      <DxColumn data-field="CodeService" :allow-editing="false" caption="ID"></DxColumn>
                      <DxColumn data-field="NameService" :allow-editing="false" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" :allow-editing="false" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <DxColumn data-field="TypeService" :allow-editing="false" header-cell-template="headerTypeservice">
                      </DxColumn>
                      <template #headerTypeservice="{ }">
                          <div>Tipo de<br />servicio</div>
                      </template>
                      <DxColumn data-field="quantity" caption="Cantidad">
                          <DxFormat typed="fixedPoint" :precision="0" />
                          <DxRequiredRule message="La cantidad del servicio a facturar es requerida" />
                          <DxPatternRule :pattern="itemQtyPattern"
                          message="La cantidad del servicio facturado solo puede contener numeros enteros mayores a 0" />
                      </DxColumn>
                      <DxColumn data-field="unitPrice" :allow-editing="false" caption="Precio"></DxColumn>
                      <DxColumn cell-template="total-template" :allow-editing="false" caption="Total"></DxColumn>
                      <DxColumn cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="deleteService(data.data)">
                          <v-icon>mdi-delete</v-icon>
                          </a>
                      </template>
                      <template #total-template="{ data }">
                          <div>
                          {{ totalXQuantity( data.data ) }}
                          </div>
                      </template>
                      </DxDataGrid>
                  </div>
                  </v-col>
              </v-row>

              <!-- Product to add -->
              <v-row style="margin-top: 2rem">
                  <v-col cols="12">
                  <h4 style="padding: 16px;">Productos seleccionados</h4>
                  <div style="overflow-x: scroll; padding: 0 12px;">
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="productsToAdd"
                      key-expr="ID_Item" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"    
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="5" />
                      <DxColumn data-field="ID_Item" :allow-editing="false" caption="ID"></DxColumn>
                      <DxColumn data-field="ItemName" :allow-editing="false" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre del<br />producto</div>
                      </template>
                      <DxColumn data-field="ItemComercialName" :allow-editing="false" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <DxColumn data-field="ItemType" :allow-editing="false" header-cell-template="headerTypeservice">
                      </DxColumn>
                      <template #headerTypeservice="{ }">
                          <div>Tipo de<br />producto</div>
                      </template>
                      <DxColumn data-field="quantity" caption="Cantidad">
                          <DxFormat typed="fixedPoint" :precision="0" />
                          <DxRequiredRule message="La cantidad del producto a facturar es requerida" />
                          <DxPatternRule :pattern="itemQtyPattern"
                          message="La cantidad del producto facturado solo puede contener numeros enteros mayores a 0" />
                      </DxColumn>
                      <DxColumn data-field="unitPrice" :allow-editing="false" caption="Precio"></DxColumn>
                      <DxColumn cell-template="total-template" :allow-editing="false" caption="Total"></DxColumn>
                      <DxColumn cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="deleteProduct(data.data)">
                          <v-icon>mdi-delete</v-icon>
                          </a>
                      </template>
                      <template #total-template="{ data }">
                          <div>
                          {{ totalXQuantity( {...data.data, Price: data.data.ItemPrice} ) }}
                          </div>
                      </template>
                      </DxDataGrid>
                  </div>
                  </v-col>
              </v-row>

              </div>
          </v-card>
        </v-dialog>
        <!-- Dialog bill patient -->

        <alerts
          v-if="alert.show"
          v-on:close_alert="closeAlert"
          v-on:accept_alert="acceptAlert"
          :properties="alert"
        >
        </alerts>
        <reproduction :data="print"></reproduction>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import Block from "@/components/Block";
import { DxDataGrid, DxColumn, DxPaging, DxScrolling, DxEditing, DxFormat, DxPatternRule, DxRequiredRule, DxExport } from "devextreme-vue/data-grid";
import { printToExcel } from "@/helpers/printToexcel";
import DatePicker from "../../../components/DatePicker";
import Alerts from "@/components/Alerts";
import Reproduction from "../../../components/ReportsAndPrintings/Reproduction.vue";
import { mapState } from "vuex";
import GeneralFilter from "@/components/GeneralFilter";
import UserProfile from "@/components/Displays/UserProfileDisplay.vue";
import { numberWithCommas } from "@/helpers/money";

export default {
  name: "VeterinaryReproduction",
  components: {
    DxDataGrid, 
    DxColumn, 
    DxEditing, 
    DxPaging, 
    DxFormat, 
    DxPatternRule, 
    DxScrolling, 
    DxRequiredRule,
    DatePicker,
    Block,
    Alerts,
    Reproduction,
    DxExport,
    GeneralFilter,
    UserProfile,
  },
  props: ["data", "win"],
  data: () => ({
    currency: JSON.parse(localStorage.getItem('branch')).Currency,
    itemQtyPattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/i,
    showBillPatient: false,
    useData: {},
    showServicesToAdd: false,
    showProductsToAdd: false,
    servicesToBill: [],
    productsToBill: [],
    servicesToAdd: [],
    productsToAdd: [],
    filterProducts: {
        filter: null
    },
    searchToBill: {
        filter: null
    },
    togglehide1: true,
    togglehide2: true,
    togglehide3: true,
    warehouses: [],
    selectedWarehouse: null,
    customer: {},
    print: {
      show: false,
      data: [],
    },
    showcruz: false,
    alert: {
      type: "success",
      show: false,
      header: "",
      body: "",
    },
    tab: "tab-1",
    valid: false,
    Type: ["Monta", "Parto", "Celo", "Gestación"],
    showComments: false,
    commentclass: "btnblack",
    search: {},
    url: null,
    patientinfo: [],
    reproductions: {},
    reproduction: {},
    breeds: [],
    breed: {},
    loading: false,
    disabled1: 0,
    disabled2: 0,
    disabled3: 0,
    disabled4: 0,
    disabled5: 0,
    id_user_login: null,
    count: 0,
    id_patient: null,
    confirmtoDelete: null,
    selectedvalues: true,
    showReproductionHistory: false,
    showhistory: false,
    diagTypeG: ["Palpación", "Rayos X", "Ultrasonografía"],
    resTypeG: ["Positivo", "Negativo", "Sospecha"],
    quillOptions: {
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ align: [] }],

            ["clean"],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ script: "sub" }, { script: "super" }],
            [{ formula: "" }],
          ],
          handlers: {},
          useData: null,
        },
      },
      theme: "snow",
    },
  }),
  computed: {
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
  },
  async mounted() {
    this.$store.dispatch("rolesUser/getAllRoles");
    if (this.data) {
      Object.keys(this.data).map((el) => {
        Vue.set(this.patientinfo, el.toLowerCase(), this.data[el]);
      });
      this.search.id = this.patientinfo.id_patient;
      this.url = this.$store.getters.getBackendUrl + "/public" + this.data.ProfilePicPath;
      await this.getAllReproduction();
      this.reproduction.Weight = this.data.Weight;
    }
    this.id_user_login = JSON.parse(localStorage.getItem("user")).id;
    this.useData = JSON.parse(localStorage.getItem("user"));
    Vue.set(this.reproduction, "ID_Patient", this.patientinfo.id_patient);
    await this.getAllReproduction();
    //this.useData = JSON.parse(localStorage.getItem("user"));
    this.customer.ID_Customer = this.data.ID_Customer;
    console.log('customer', this.customer);
    this.warehouses = await this.$API.warehouse.getWarehousesFiltered({ isPatient: true });
    this.filterProducts.business = this.useData.businessid;
    this.filterProducts.branch = this.useData.branch;
    this.selectedWarehouse = this.warehouses.length == 0 ? 0 : this.warehouses[0].ID_Warehouse;
    this.filterProducts.warehouse = this.selectedWarehouse;    
    await this.getServices();
    await this.getProducts();
    await this.getDefaultServices();
},
  watch: {
    servicesToAdd: {
        deep: true,
        immediate: true,
        handler() {
            if(this.servicesToAdd.length !== 0) {
                this.showServicesToAdd = true;
            }
            else {
                this.showServicesToAdd = false;
            }
        }
    },
    productsToAdd: {
        deep: true,
        immediate: true,
        handler() {
            if(this.productsToAdd.length !== 0) {
                this.showProductsToAdd = true;
            }
            else {
                this.showProductsToAdd = false;
            }
        }
    },
  },
  methods: {
    totalXQuantity( { Price, quantity } ) {
        return `${this.currency}${ numberWithCommas( parseFloat( Price * quantity ).toFixed( 2 ) ) }`
    },
    async handleSelectWarehouse() {
        this.loading = true;
        this.filterProducts.warehouse = this.selectedWarehouse;
        await this.getProducts();
        this.$refs.werehouseRef.blur();
        this.loading = false;
    },
    addService(data) {
        const isRepetitive = this.servicesToAdd.find((service) => service.ID_Service === data.ID_Service);

        if (!isRepetitive) {
            this.servicesToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.Price), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.Price).toFixed(4))}` });
        } else {
            const service = this.servicesToAdd.find(item => item.ID_Service === data.ID_Service)
            if(service) {
                service.quantity++;
            }
            console.log("Service already exists in servicesToAdd");
        }
    },
    addProduct(data) {
        const isRepetitive = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
        if (!isRepetitive) {
            this.productsToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.ItemPrice), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.ItemPrice).toFixed(4))}` });
        } else {
            const product = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
            if(product) {
                product.quantity++;
            }
            console.log("Product already exists in productsToAdd.");
        }
    },
    deleteService(data) {
        const serviceIndex = this.servicesToAdd.findIndex((service) => service.CodeService === data.CodeService);

        if (serviceIndex !== -1) {
            this.servicesToAdd.splice(serviceIndex, 1);
        }
    },
    deleteProduct(data) {
        const index = this.productsToAdd.findIndex((item) => item.ID_Item === data.ID_Item);

        if (index !== -1) {
            this.productsToAdd.splice(index, 1);
        }
    },
    getServices() {
        this.$API.services.getAllServices(this.filterServices).then((response) => {
            this.servicesToBill = response;
        });
    },
    getProducts() {
        this.$API.products.getProductsToBillForInvoice(this.filterProducts).then((response) => {
            this.productsToBill = response;
        });
    },
    getDefaultServices() {
        this.$API.services.getDefaultServices().then((response) => {
            this.servicesDefault = response;
        });
    },
    servicesFilterHandler(response) {
        this.servicesToBill = response;
    },
    productsFilterHandler(response) {
        this.productsToBill = response;
    },
    getItemsToBeBilled() {
        this.$API.veterinarypatients.getServicesToBeBilled({ 'customer': this.data.ID_Customer })
        .then((response) => {
            this.servicesToAdd = []
            for(let i = 0; i < response.length; i++){
            this.servicesToAdd.push({ ...response[i], unitPrice: `${this.currency}${numberWithCommas(parseFloat(response[i].Price).toFixed(4))}` });
            }
        });
        this.$API.veterinarypatients.getProductsToBeBilled({ 'customer': this.data.ID_Customer }).then((res) => {
            if(res.success) {
            this.productsToAdd = [];
            for (const item of res.data) {
                this.productsToAdd.push({ ...item, quantity: item.Quantity, unitPrice: `${this.currency}${numberWithCommas(parseFloat(item.ItemPrice).toFixed(4))}`});
            }
            }
        });
    },
    async saveItemsToBeBilled() {
        this.showBillPatient = false;
        this.loading = true;

        try {
            if(this.servicesToAdd.length) {
            await this.$API.veterinarypatients.saveServicesToBeBilled({ 'customer': this.customer.ID_Customer, 'services': this.servicesToAdd })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
                throw new Error('Ocurrio un error al guardar los servicios.');
            });
            }
            if(this.productsToAdd.length) {
            console.log(this.productsToAdd);
            
            await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
                throw new Error('Ocurrio un error al guardar los productos.');
            });
            }
            await this.getItemsToBeBilled();
            this.loading = false;
            this.showAlert("success", "Exito", "Los datos de facturación de este paciente han sido guradados.")
        } catch (error) {
            console.log(error);
            this.loading = false;
            this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
        }

    },
    async billedPatientsOfCustomer() {
        this.showBillPatient = false;
        this.loading = true;

        try{
            await this.$API.veterinarypatients.saveServicesToBeBilled({'customer': this.customer.ID_Customer, 'services': this.servicesToAdd});
            await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd });
            await this.$API.veterinarypatients.billedPatientsOfCustomer({ 'customer': this.customer.ID_Customer});

            this.loading = false;

            this.showAlert("success", "Exito", `Se ha realizado la factura de los ${this.servicesToAdd.length && this.productsToAdd.length ? 'servicios y productos' : this.servicesToAdd.length ? 'servicios' : this.productsToAdd.length ? 'productos' : 'items' } seleccionados, podrá observarla en el módulo de POS en listo para facturar.`);
            await this.getItemsToBeBilled();        
        }
        catch(error) {
            console.log(error)
            this.loading = false
            this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
        }
    },

    onExporting(e) {
      printToExcel(e, "Reproducciones");
    },
    checkReproductionDate(date) {
      /*let datetoday = false;

      this.reproductions.forEach((el) => {
        if (el.ReproductionDate == date) {
          this.selectingReproduction(el);
          datetoday = true;
        }
      });

      if (!datetoday) {
        this.resetData();
        this.reproduction.ReproductionDate = date;
      }*/
      this.reproduction.ReproductionDate = date
    },
    Preview_image() {
      this.url = URL.createObjectURL(this.image);
    },
    handleCaesareanSectionChange: function () {
      if (this.reproduction.CaesareanSection) {
        this.reproduction.Natural = false;
      }
    },
    handleNaturalChange: function () {
      if (this.reproduction.Natural) {
        this.reproduction.CaesareanSection = false;
      }
    },

    disabledOne() {
      this.disabled1 = (this.disabled1 + 1) % 2;
      /*this.disabled2 = 0;
      this.disabled3 = 0;
      this.disabled4 = 0;
      this.disabled5 = 0;*/
    },
    disabledTwo() {
      this.disabled2 = (this.disabled2 + 1) % 2;
      /*this.disabled1 = 0;
      this.disabled3 = 0;
      this.disabled4 = 0;
      this.disabled5 = 0;*/
    },
    disabledThree() {
      this.disabled3 = (this.disabled3 + 1) % 2;
      /*this.disabled2 = 0;
      this.disabled1 = 0;
      this.disabled4 = 0;
      this.disabled5 = 0;*/
    },
    disabledFour() {
      this.disabled4 = (this.disabled4 + 1) % 2;
      /*this.disabled2 = 0;
      this.disabled1 = 0;
      this.disabled3 = 0;
      this.disabled5 = 0;*/
    },
    disabledFive() {
      this.disabled5 = (this.disabled5 + 1) % 2;
      /*this.disabled2 = 0;
      this.disabled1 = 0;
      this.disabled3 = 0;
      this.disabled4 = 0;*/
    },

    addReproduction() {
      this.loading = true;
      let datetoday = false;

      let dateday = this.reproduction.ReproductionDate;

      this.reproductions.forEach((element) => {
        if (element.ReproductionDate == dateday) {
          datetoday = true;
          this.loading = false;
          this.showAlert(
            "danger",
            "error",
            "Ya se ha registrado una reproducción para el paciente en la fecha de hoy"
          );
        }
      });
      if (!datetoday) {
        const formdata = new FormData();
        Vue.set(this.reproduction, "ID_User_Insert", this.id_user_login);

        if (!this.reproduction.ID_Patient) {
          Vue.set(this.reproduction, "ID_Patient", this.patientinfo.id_patient);
        }

        formdata.append("veterinaryreproduction", JSON.stringify(this.reproduction));

        formdata.append("reproductionswith", JSON.stringify(this.breeds));

        this.$API.veterinaryReproduction
          .addVeterinaryReproduction(formdata)
          .then((res) => {
            this.loading = false;
            this.showAlert("success", "Exito", "Reproducción registrada correctamente");
            this.reproduction.ID_Reproduction = res.data.ID_Reproduction;
            this.getAllReproduction();
          })
          .catch(() => {
            this.loading = false;
            this.showAlert(
              "danger",
              "error",
              "Ha ocurrido un error al registrar la reproducción"
            );
          });
      }
    },

    updateReproduction() {
      if (this.reproduction.ID_Reproduction) {
        this.loading = true;
        const formdata = new FormData();
        Vue.set(this.reproduction, "ID_User_Update", this.id_user_login);
        formdata.append("veterinaryreproduction", JSON.stringify(this.reproduction));
        formdata.append("reproductionswith", JSON.stringify(this.breeds));
        this.$API.veterinaryReproduction
          .updateVeterinaryReproduction(formdata)
          .then(() => {
            this.loading = false;
            this.showAlert("success", "Exito", "Reproducción actualizada correctamente");
            this.selectedvalues = false;
            this.getAllReproduction();
          })
          .catch(() => {
            this.loading = false;
            this.showAlert(
              "danger",
              "error",
              "Ha ocurrido un error al actualizar la reproducción"
            );
          });
      } else {
        this.showAlert("danger", "error", "No se ha seleccionado una reproducción");
      }
    },
    filterHandler(data) {
      this.reproductions = data;
    },

    getAllReproduction() {
      this.$API.veterinaryReproduction
        .getVeterinaryReproduction(this.patientinfo.id_patient)
        .then((response) => {
          this.reproductions = response;
          let dateday;
          if (this.$store.state.vetPatientData.datereproduction) {
            dateday = this.$store.state.vetPatientData.datereproduction;
            //console.log(this.$store.state.vetPatientData.datereproduction)
            this.$store.commit("vetPatientData/cleanData");
            //console.log(this.$store.state.vetPatientData.datereproduction);
            // console.log("la fecha de hoy es",dateday);
          } else {
            dateday = this.reproduction.ReproductionDate;
          }
          this.reproductions.forEach((element) => {
            if (element.ReproductionDate == dateday) {
              this.selectingReproduction(element);
            }
          });
        });
    },

    deleteReproduction(data) {
      this.loading = true;
      this.$API.veterinaryReproduction
        .deleteVeterinaryReproduction(data.ID_Reproduction)
        .then(() => {
          this.loading = false;
          this.showAlert("success", "Exito", "Reproducción eliminada correctamente");
          this.getAllReproduction();
          this.resetData();
        })
        .catch(() => {
          this.loading = false;
          this.resetData();
          this.showAlert(
            "danger",
            "error",
            "Ha ocurrido un error al eliminar la reproducción"
          );
        });
    },

    selectingReproduction(data) {
      this.reproduction = data;
      this.disabledOne();
      this.disabledTwo();
      this.disabledThree();
      this.disabledFour();
      this.disabledFive();
      this.selectedvalues = false;
      this.$API.veterinaryReproduction
        .getAllReproductionsWith(this.reproduction.ID_Reproduction)
        .then((response) => {
          this.breeds = response;
          for (let i = 0; i < this.breeds.length; i++) {
            var count = i + 1;
            this.breeds[i].id = count;
          }
        })
        .catch(() => {
          this.loading = false;
          this.showAlert("danger", "error", "Ha ocurrido un error al obtener los datos");
          this.selectedvalues = true;
        });
    },

    transportDataBreed() {
      this.count = this.count + 1;
      let br = {
        id: this.count,
        ReproductionWith: this.breed.ReproductionWith,
        Date: this.breed.Date,
      };
      this.breeds.push(br);
    },

    deleteBreedSelected(data) {
      const index = this.breeds.indexOf(data);
      this.breeds.splice(index, 1);
    },

    resetData() {
      /* if(this.id_patient== null){
          this.reproduction.ID_Patient = this.id_patient
      } */
      this.reproduction = {};
      this.reproduction.Weight = this.data.Weight;
      this.reproduction.ReproductionDate = this.moment().format("DD/MM/YYYY");
      this.reproduction.DateGestation = this.moment().format("DD/MM/YYYY");
      this.reproduction.JealousyBeginning = this.moment().format("DD/MM/YYYY");
      this.reproduction.JealousyEnd = this.moment().format("DD/MM/YYYY");
      this.breeds = [];
      this.breed = {};
      this.breed.Date = this.moment().format("DD/MM/YYYY");
      this.selectedvalues = true;
    },
    closeAlert() {
      this.alert.show = false;

      if (this.alert.options == "deleteReproduction") {
        this.confirmtoDelete = null;
      }
    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "deleteReproduction") {
        this.deleteReproduction(this.confirmtoDelete);
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },

    /*onEditorChange(e) {
        //this.medicalhistory.comment = e.html;
    },*/
  },
};
</script>

<style scoped>
.btndisable {
    background-color: #41464c !important;
    color: white;
}
.btndisable2 {
    background-color: #1976d2 !important;
    color: white;
}

.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;

  border-radius: 15px;
}

.tabsB {
  width: 100%;
  padding: 10px;
  background-color: #1976d2;
  color: white;
  border-radius: 15px;
  border-style: solid;
  border-color: black;
  border-width: 1px;
}

.btnblack:hover {
  background-color: rgba(80, 80, 80, 1) !important;
  color: white !important;
  cursor: pointer;
}

.btnblack {
  background-color: rgba(60, 60, 60, 1) !important;
  color: white !important;
}

.tabsB:hover {
  cursor: pointer;
}

.tabs {
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white;
}

.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.btnblue {
  background-color: #1976d2 !important;
  color: white;
}

.infopatient {
  margin-top: 5%;
}

.btn-add {
  background-color: #3498db !important;
  color: white;
}

.infopatient {
  margin-top: 5%;
}

.btn-add {
  background-color: #3498db !important;
  color: white;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
}

</style>

<style>

.start-align-label .v-input__slot {
  align-items: flex-start !important;
}

</style>
