<template>
    <div class="block-wrapper"  style="position: fixed;"  >
        <v-card v-if="properties.type == 'success'" class="dialog" style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);">
            <v-card-title class="header_alert" >
                <v-icon dark>mdi-alert-circle</v-icon> &nbsp; {{ properties.header }}
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="body_card" style="font-weight: bold; color: whitesmoke;">
                <br/>
                {{ properties.body }}
            </v-card-text>
            <v-card-actions class="body_card" style="align-items: center; justify-content: center;">
                <v-btn class="btn" style="background: #3498DB; font-size: 20px;"   small text @click="closeAlert()">
                    Aceptar
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-if="properties.type == 'successWithCallback'" class="dialog" style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);">
            <v-card-title class="header_alert" >
                <v-icon dark>mdi-alert-circle</v-icon> &nbsp; {{ properties.header }}
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="body_card" style="font-weight: bold; color: whitesmoke;">
                <br/>
                {{ properties.body }}
            </v-card-text>
            <v-card-actions class="body_card" style="align-items: center; justify-content: center;">
                <v-btn class="btn" style="background: #3498DB; font-size: 20px;"   small text @click="acceptAlert()">
                    Aceptar
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-card v-if="properties.type == 'question'" class="dialog" style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);">
            <v-card-title class="header_alert">
                <v-icon>mdi-alert-circle</v-icon> &nbsp; {{ properties.header }}
            </v-card-title>

            <v-card-text class="body_card" style="font-weight: bold; color: whitesmoke;">
                <br/>
                {{ properties.body }}
            </v-card-text>

            <v-card-actions class="body_card" >
                <v-btn class="btn " style="background: #3498DB ;font-size: 20px;" small text @click="closeAlert()"
                v-on:keydown.enter="closeAlert()"
                >
                    Cancelar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn class="btn" style="background: #E3191E;font-size: 20px;" dark small text @click="acceptAlert()">
                    Eliminar
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-if="properties.type == 'questionWithInnerHTML'" class="dialog" style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);">
            <v-card-title class="header_alert">
                <v-icon>mdi-alert-circle</v-icon> &nbsp; {{ properties.header }}
            </v-card-title>

            <v-card-text class="body_card" style="font-weight: bold; color: whitesmoke;">
                <br/>
                <div v-html="properties.body">
                </div>
            </v-card-text>

            <v-card-actions class="body_card" >
                <v-btn class="btn " style="background: #3498DB ;font-size: 20px;" small text @click="closeAlert()"
                v-on:keydown.enter="closeAlert()"
                >
                    Cancelar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn class="btn" style="background: #00A178;font-size: 20px;" dark small text @click="acceptAlert()">
                    Continuar
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-if="properties.type == 'closeeSesion'" class="dialog" style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);">
            <v-card-title class="header_alert">
                <v-icon>mdi-alert-circle</v-icon> &nbsp; {{ properties.header }}
            </v-card-title>

            <v-card-text class="body_card" style="font-weight: bold; color: whitesmoke;">
                <br/>
                {{ properties.body }}
            </v-card-text>

            <v-card-actions class="body_card" >
               
                <v-btn class="btn" style="background: #E3191E;font-size: 16px;" dark  text @click="acceptAlert()">
                    Cerrar 
                    <br/>
                    sesion 
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn class="btn " style="background: #3498DB ;font-size: 16px;"  text @click="closeAlert()"
                v-on:keydown.enter="closeAlert()"
                >
                    Mantener 
                    <br/>
                    sesion
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-card v-if="properties.type == 'question2'" class="dialog" style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);">
            <v-card-title class="header_alert">
                <v-icon>mdi-alert-circle</v-icon> &nbsp; {{ properties.header }}
            </v-card-title>

            <v-card-text class="body_card" style="font-weight: bold; color: whitesmoke;">
                <br/>
                {{ properties.body }}
            </v-card-text>

            <v-card-actions class="body_card">
                <v-btn class="btn " style="background: #3498DB ;font-size: 20px;" small text @click="closeAlert()"
                v-on:keydown.enter="closeAlert()"
                >
                    Cancelar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn class="btn" style="background: #00A178;font-size: 20px;" dark small text @click="acceptAlert()">
                    Aceptar
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-card v-if="properties.type == 'danger'" class="dialog" style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);">
            <v-card-title class="header_alert" style="color: white;">
                <v-icon dark>mdi-alert-circle</v-icon> &nbsp; {{ properties.header }}
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="body_card" style="font-weight: bold; color: whitesmoke;font-size: 20px;">
                <br/>
                {{ properties.body }}
            </v-card-text>
            <v-card-actions class="body_card" style="align-items: center; justify-content: center;">
                <v-btn class="btn" style="background: #E3191E ; font-size: 20px;" small text @click="closeAlert()"
              
                >
                    Aceptar
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-card v-if="properties.type == 'dangerHtml'" class="dialog" style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);">
            <v-card-title class="header_alert" style="color: white;">
                <v-icon dark>mdi-alert-circle</v-icon> &nbsp; {{ properties.header }}
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="body_card" style="font-weight: bold; color: whitesmoke;font-size: 20px;">
                <br/>
                <p style="color: white !important; font-size: 16px;" v-html="properties.body"></p> 
            </v-card-text>
            <v-card-actions class="body_card" style="align-items: center; justify-content: center;">
                <v-btn class="btn" style="background: #E3191E ; font-size: 20px;" small text @click="closeAlert()"
              
                >
                    Aceptar
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-if="properties.type == 'warning'" class="dialog" style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);"
            @keyup.enter="funct()"
        >
            <v-card-title class="header_alert" style="color: white;">
                <v-icon dark>mdi-alert-circle</v-icon> &nbsp; {{ properties.header }}
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="body_card" style="font-weight: bold; color: whitesmoke;font-size: 20px;"   @keyup.enter="funct()"
            >
                <br/>
                {{ properties.body }}
            </v-card-text>
            <v-card-actions class="body_card" style="align-items: center; justify-content: center;">
                <v-btn class="btn" style="background: #F29D35  ; font-size: 20px;" small text  @keyup.enter="closeAlert()" @click="closeAlert()"
               
                >
                    Aceptar
                </v-btn>
            </v-card-actions>
        </v-card>


        <v-card v-if="properties.type == 'successHtml'" class="dialog" style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);">
            <v-card-title class="header_alert" >
                <v-icon dark>mdi-alert-circle</v-icon> &nbsp; {{ properties.header }}
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="body_card" style="font-weight: bold; color: whitesmoke;">
                <br/>
               
                <p style="color: white !important; font-size: 16px;" v-html="properties.body"></p> 
            </v-card-text>
            <v-card-actions class="body_card" style="align-items: center; justify-content: center;">
               
                <v-btn class="btn" style="background: #3498DB; font-size: 20px;"   small text @click="closeAlert()">
                    Aceptar
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-card v-if="properties.type == 'warningHtml'" class="dialog" style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);"
            @keyup.enter="funct()"
        >
            <v-card-title class="header_alert" style="color: white;">
                <v-icon dark>mdi-alert-circle</v-icon> &nbsp; {{ properties.header }}
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="body_card" style="font-weight: bold; color: whitesmoke;font-size: 20px;"   @keyup.enter="funct()"
            >
                <br/>
                {{ properties.body }}
              <!--   <p style="color: white !important; font-size: 16px;" v-html="properties.body"></p> -->
            </v-card-text>
            <v-card-actions class="body_card" style="align-items: center; justify-content: center;">
                <v-btn class="btn " style="background: #3498DB ;font-size: 20px;" small text @click="acceptAlert()"
               
                >
                    Ver detalles
                </v-btn>
                <v-btn class="btn" style="background: #F29D35  ; font-size: 20px;" small text  @keyup.enter="closeAlert()" @click="closeAlert()"
               
                >
                    Aceptar
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-card v-if="properties.type == 'progress'" class="dialog" style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);">
            <v-card-title class="header_alert" >
                <v-icon dark>mdi-alert-circle</v-icon> &nbsp; {{ properties.header }}
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="body_card" style="font-weight: bold; color: whitesmoke;">
                <br/>
                {{ properties.body }}
            </v-card-text>
            <v-card-actions class="body_card">
                <v-btn class="btn " style="background: #3498DB ;font-size: 20px;" small text @click="closeAlert()"
                v-on:keydown.enter="closeAlert()"
                >
                    Cancelar
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    export default {
        name: "Alerts",
        props: ['properties'],
        mounted() {
            setTimeout( () => {
                window.addEventListener('keyup', this.handleKeyUp);
            }, 200 );
           
        },
        beforeDestroy() {
            window.removeEventListener('keyup', this.handleKeyUp);
        },
        methods: {
            async closeAlert(){
                this.$emit('close_alert', false);
            },
            async acceptAlert(){
                this.$emit('accept_alert', false);
            },
            funct(){
                console.log("hola");
            },
            handleKeyUp(e) {
                const { type } = this.properties;
                if (e.key === 'Enter') {
                    type === 'successWithCallback' ? this.acceptAlert() : this.closeAlert();
                }
            },
        }
    };
</script>

<style scoped>
.block-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffffa6;
        z-index:  20000000;
    }

.dialog{
    width: 600px;
    border-radius: 15px;
    z-index:  20000 !important;

}

.header{
    border-bottom: solid green;
    background: white;
    color: black;
    font-size: 20px;
    padding: 10px;
}

.body_card{
    background: #41464C;
    color: whitesmoke;

}

.footer{
    background: green;
    color: black;
    font-size: 16px;
    padding: 10px;
}

.button{
    background: white;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
}
.header_alert{
    background:#41464C;
    color: whitesmoke;
}
.btn{
    color: whitesmoke;
    width: 140px;
    height: 40px !important;
    margin: 20px;
}
/*
colores
#00A178 Verde
#F29D35 Naranja
#E3191E rojo
#3498DB Celeste
#41464C gris
*/
</style>
