<template>
  <div>
    <v-card flat>
      <v-card-text>
        <!-- <v-row class="box" style="background-color: white;">
      <v-col cols="12" sm="1" class="d-flex justify-center">
        <img
          :src="data.ProfilePicPath ? data.ProfilePicPath : 'https://drive.google.com/uc?export=view&id=1c8jTvxIT33xIptNpcVgm6KoKnVHvGq-Y'"
          width="50px"
          height="50px"
          alt="Profile Picture"
        />
      </v-col>

      <v-col cols="12" sm="11">
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <p><b>ID de Paciente:</b> {{ patientinfo.id_patient }}</p>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <p><b>Especie:</b> {{ patientinfo.species }}</p>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <p><b>Raza:</b> {{ patientinfo.breed }}</p>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <p><b>Nombre del Paciente:</b> {{ patientinfo.namepatient }}</p>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <p>
              <b>{{ patientinfo.customer.Is_a_Business ? "Empresa:" : "Dueño:" }}</b>
              {{ patientinfo.customer.Is_a_Business ? patientinfo.customer.BusinessName : patientinfo.customer.FirstName + " " + patientinfo.customer.LastName }}
            </p>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <p>
              <b>Médico actual: </b> {{ useData.firstName + " " + useData.lastName }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row> -->
        <template v-if="type=== 'medicalclinic'">
          <user-profile-lab :patient="data" :patientinfo="data" :useData="useData"></user-profile-lab>
        </template>
        <template v-else>
          <user-profile :patientinfo="patientinfo" :data="data" :useData="useData" />
        </template>
        <v-row style="margin-top: 1rem">
          <!--v-col class="tabs"   :class="showReports ? 'box':'box'" :style="showReports ? 'background-color: white':''" >



<div  style="margin-top: .3rem;" >
  <div class="tabs"  @click="showReports = !showReports" :class="showReports ? ' ':''" style="border:none" >
      <v-icon v-if="showReports" color="white" left>
        mdi-minus
      </v-icon>
      <v-icon v-else color="white" left> mdi-plus </v-icon>
      Historial de Reportes Médicos
  </div>

      <div  v-show="showReports">
        <DxDataGrid class="isScrolledByGeneralFilter" :show-borders="true" :data-source="Reports" key-expr="ID_VPG">
          <DxColumn :width="70" cell-template="show-template" caption=""></DxColumn>
          <DxPaging :page-size="10" />
          
          <DxColumn data-field="Date" caption="Fecha"></DxColumn>
          <DxColumn data-field="NextAppointment" caption="Proxima cita"></DxColumn>
          <DxColumn data-field="Groomer" caption="Peluquero"></DxColumn>
          <DxColumn :width="70" cell-template="show-delete" caption=""></DxColumn>
          <template #show-delete="{ data }">
            <div>

              <a href="" class="mx-2" @click.prevent.stop="confirmDelete(data.data);">
                <v-icon color="error">mdi-delete</v-icon>
              </a>
            </div>
          </template>
          <template #show-template="{ data }">
            <div>
              <a href="#" @click.prevent.stop="selectedGrooming(data.data); selectedvalues = false">
                <v-icon color="primary">mdi-eye</v-icon>
              </a>
             
            </div>
          </template>
        </DxDataGrid>
      </div>
    </div>
  </v-col-->
          <v-col cols="12" class="box" style="margin-top: 1rem">
            <block v-if="loading"></block>

            <v-row class="ml-2 mr-2" style="margin-top: 0.3rem; margin-bottom: 0.3rem">
              <v-col cols="12" class="tabsNh">
                <!-- TAB 1 -->
                <v-col
                  style="display: flex; justify-content: space-between"
                  cols="12"
                  v-if="tab == 'tab-1'"
                >
                  <div style="width: 158px;" ></div>
                  <div>
                      <v-btn v-if="$store.getters['rolesUser/getCustomRole'](107)" small class="btn-add mr-1" @click.prevent="getItemsToBeBilled(); showBillPatient = true">
                          <v-icon class="mr-3">mdi-account-details</v-icon> Facturar paciente
                      </v-btn>
                  </div>
                  <div>
                    <v-btn 
                      v-if="$store.getters['rolesUser/getCustomRole'](158)"
                      small class="btnblue mr-1" @click="showhistory = !showhistory">
                      <v-icon> mdi-magnify </v-icon>
                    </v-btn>
                    <v-btn 
                      v-if="$store.getters['rolesUser/getCustomRole'](169)"
                      small class="mr-1" @click="handlePrint(true)">
                      <v-icon color="black"> mdi-printer </v-icon>
                    </v-btn>
                    <v-btn 
                      v-if="$store.getters['rolesUser/getCustomRole'](170)"
                      class="btnsave" small @click="handleSave(true)">
                      <v-icon> mdi-content-save </v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <!-- TAB 1 -->

                <!-- TAB 2 -->
                <v-col
                  style="display: flex; justify-content: space-between"
                  v-if="tab == 'tab-2'"
                >
                  <div style="width: 158px;" ></div>
                  <div>
                      <v-btn v-if="$store.getters['rolesUser/getCustomRole'](107)" small class="btn-add mr-1" @click.prevent="getItemsToBeBilled(); showBillPatient = true">
                          <v-icon class="mr-3">mdi-account-details</v-icon> Facturar paciente
                      </v-btn>
                  </div>
                  <div>
                    <v-btn 
                      v-if="$store.getters['rolesUser/getCustomRole'](158)"
                      small class="btnblue mr-1" @click="showhistory = !showhistory">
                      <v-icon> mdi-magnify </v-icon>
                    </v-btn>
                    <v-btn 
                      v-if="$store.getters['rolesUser/getCustomRole'](168)"
                      small class="mr-1" @click="handlePrint()">
                      <v-icon color="black"> mdi-printer </v-icon>
                    </v-btn>
                    <v-btn 
                      v-if="$store.getters['rolesUser/getCustomRole'](159)"
                      class="btnsave" small @click="handleSave()">
                      <v-icon> mdi-content-save </v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <!-- TAB 2 -->
              </v-col>
              <v-col cols="12">
                <v-tabs v-model="tab" centered>
                  <v-tab href="#tab-1">Constancia Médica</v-tab>
                  <v-tab href="#tab-2">Certificado de Salud</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab" touchless>
                  <v-tab-item value="tab-1">
                    <v-row class="pt-8 pb-8">
                      <v-col cols="12">
                        <p>
                          EL (LA) INFRASCRITO(A) MEDICO(A) VETERINARIO(A) HACE CONSTAR QUE
                          HA REALIZADO EL EXAMEN CLINICO AL EJEMPLAR:
                        </p>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          outlined
                          dense
                          label="NOMBRE:"
                          v-model="patientinfo.namepatient"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          outlined
                          dense
                          label="FICHA PACIENTE:"
                          v-model="patientinfo.id_patient"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          outlined
                          dense
                          label="ESPECIE:"
                          v-model="patientinfo.species"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          outlined
                          dense
                          label="RAZA:"
                          v-model="patientinfo.breed"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          outlined
                          dense
                          label="SEXO:"
                          v-model="patientinfo.gender"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          outlined
                          dense
                          label="FECHA DE NACIMIENTO:"
                          v-model="patientinfo.dateofbirth"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          outlined
                          dense
                          label="COLOR:"
                          v-model="patientinfo.color"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          outlined
                          dense
                          label="REGISTRO:"
                          v-model="patientinfo.registrynumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          outlined
                          dense
                          label="TATUAJE:"
                          v-model="patientinfo.tattoo"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          outlined
                          dense
                          label="MICROCHIP:"
                          v-model="patientinfo.microchip"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          outlined
                          dense
                          label="PROPIETARIO:"
                          v-model="patientinfo.namepatient"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <label>ENCONTRANDOLE: </label>
                        <quill-editor
                          v-model="healthCertificate.findingHim"
                          :options="quillOptions"
                        ></quill-editor>
                      </v-col>

                      <v-col cols="12">
                        <p>
                          POR TODO ELLO Y PARA LOS USOS QUE AL INTERESADO CONVENGAN
                          EXTIENDO LA PRESENTE CERTIFICACIÓN
                        </p>
                      </v-col>
                      <v-col cols="5">
                        <v-text-field
                          outlined
                          dense
                          v-model="branchinfo.BranchAddress"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="2">
                        <date-picker
                          @dateSelected="
                            (param) => {
                              date = param;
                            }
                          "
                          :value="date"
                        ></date-picker>
                      </v-col>
                      <v-col cols="" md="5">
                        <v-text-field
                          outlined
                          dense
                          label="F:"
                          v-model="healthCertificate.sign"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-tab-item>

                  <v-tab-item value="tab-2">
                    <v-row class="pt-8 pb-8">
                      <v-col cols="12">
                        <p>
                          EL (LA) INFRASCRITO(A) MEDICO(A) VETERINARIO(A) HACE CONSTAR QUE
                          HA REALIZADO EL EXAMEN CLINICO Y PARASITOLÓGICO AL EJEMPLAR:
                        </p>
                      </v-col>
                      <v-col  cols="12" md="3">
                        <v-text-field
                          outlined
                          dense
                          label="NOMBRE:"
                          v-model="patientinfo.namepatient"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          outlined
                          dense
                          label="ESPECIE:"
                          v-model="patientinfo.species"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          outlined
                          dense
                          label="RAZA:"
                          v-model="patientinfo.breed"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          outlined
                          dense
                          label="SEXO:"
                          v-model="patientinfo.gender"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          outlined
                          dense
                          label="COLOR:"
                          v-model="patientinfo.color"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          outlined
                          dense
                          label="FECHA DE NACIMIENTO:"
                          v-model="patientinfo.dateofbirth"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          outlined
                          dense
                          label="MICROCHIP:"
                          v-model="patientinfo.microchip"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <p>
                          PROPIETARIO SR./(A)
                          {{
                            patientinfo.customer.Is_a_Business
                              ? patientinfo.customer.BusinessName
                              : patientinfo.customer.FirstName +
                                " " +
                                patientinfo.customer.LastName
                          }}
                        </p>
                      </v-col>

                      <!-- <v-col cols="12">
                      <P>HABIENDOLE ENCONTRADO LIBRE DE PARASITOS EXTERNOS, ENFERMEDADES INFECCIOSAS Y NO INFECCIOSAS Y 
                      EN BUEN ESTADO DE SALUD Y APTO PARA REALIZAR EL VIAJE HACIA SU PAIS DE DESTINO</P>
                    </v-col> -->
                      <v-col cols="12" md="12">
                        <P
                          >ADEMAS HACER CONSTAR HABERLE PUESTO Y QUE ESTAN VIGENTES, LAS
                          DOSIS PROPIEAS DE SU EDAD, DE LAS SIGUENTES VACUNAS</P
                        >
                      </v-col>
                      <v-col cols="12" md="2">
                        <date-picker
                          @dateSelected="
                            (param) => {
                              dateReport = param;
                              getDateMedicals(param);
                            }
                          "
                          :value="dateReport"
                        ></date-picker>
                      </v-col>
                      <v-col cols="8">
                        <DxDataGrid
                         :scrolling="{useNative:true}"
                          :show-borders="true"
                          :dataSource="vaccines"
                          width="100%"
                        >
                          <DxColumn width="100%"  dataField="Medicine" caption="VACUNA" />
                        </DxDataGrid>
                      </v-col>
                      <v-col cols="12">
                        <p>Notas Adicionales:</p>
                        <quill-editor
                          v-model="healthCertificate.othervacc"
                          :options="quillOptions"
                        ></quill-editor>
                      </v-col>

                      <v-col cols="12">
                        <p>
                          POR TODO ELLO Y PARA LOS USOS QUE AL INTERESADO CONVENGAN
                          EXTIENDO LA PRESENTE CERTIFICACIÓN
                        </p>
                      </v-col>
                      <v-col cols="12"  md="5">
                        <v-text-field
                          outlined
                          dense
                          v-model="branchinfo.BranchAddress"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2"> </v-col>

                      <v-col cols="12" md="5">
                        <v-text-field
                          outlined
                          dense
                          label="F:"
                          v-model="healthCertificate.sign"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <br />
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
              <!--v-col cols="12" md="3" sm="12">
              <div style="width: 285px !important; display: flex;">
            <date-picker
              label="Fecha"
              @dateSelected="
                (param) =>{

                  date = param;
                }
              "
              :value="date"
            ></date-picker>
            <v-btn
                class="btnclean"
              
                
              >
                Limpiar datos
              </v-btn>

            </div>
          </v-col-->
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <health-certificate :data="print"></health-certificate>
    <health-clausule :data="print2"></health-clausule>

    <v-dialog v-model="showhistory" :width="'90%'">
      <v-card>
        <v-card-title>
          <span class="headline">Historial de reportes</span>
        </v-card-title>
        <v-card-text>
          <GeneralFilter
            entity="medic_reports_history"
            :filterEndpoint="$API.medicalreports.getFilterReport"
            :search="search"
            @emptyFilter="getAllReports"
            @filtered="filterHandler"
          >
            <DxDataGrid :scrolling="{useNative:true}" :show-borders="true" :data-source="history" key-expr="id" :column-auto-width="true" style="width: fit-content;">
              <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>

              <DxColumn :min-width="100" data-field="Report_Date" caption="Fecha"></DxColumn>
              <DxColumn :min-width="200" data-field="FullName" caption="Hecho por"></DxColumn>
              <DxColumn :min-width="200" data-field="Type_C" caption="Tipo"></DxColumn>
              <DxColumn :width="80" caption=""></DxColumn>

              <template #show-template="{ data }">
                <div v-if="(data.data.Type_C == 'Constancia Médica' && $store.getters['rolesUser/getCustomRole'](169)) || (data.data.Type_C == 'Certificado de Salud' && $store.getters['rolesUser/getCustomRole'](168))">
                  <a href="#" @click.prevent.stop="selectedPrint(data.data)">
                    <v-icon color="primary">mdi-eye</v-icon>
                  </a>
                </div>
              </template>
            </DxDataGrid>
          </GeneralFilter>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog bill patient -->
    <v-dialog v-model="showBillPatient" width="90%">
          <v-card class="py-3 px-3">
              <v-card-title class="text-h5">
              Facturar paciente 
              </v-card-title>

              <v-card-actions>
              <v-row>
                  <v-col cols="12" class="tabsNh" style="padding: 14px">
                  <v-col style="display: flex; justify-content: space-between; min-height: 50px">
                      <div>
                      <!-- <v-btn v-if="!showServicesToAdd" small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                          <v-icon>mdi-menu-down</v-icon>
                          Ver items
                      </v-btn>
                      <v-btn v-else small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                          <v-icon>mdi-menu-up</v-icon>
                          Ocultar
                      </v-btn> -->
                      </div>
                      <div>
                      <v-btn small class="btn-add" @click.prevent="saveItemsToBeBilled()">
                          <v-icon>mdi-content-save</v-icon>
                          Guardar
                      </v-btn>
                      </div>

                      <div>
                      <v-btn small class="btnsave" @click.prevent="billedPatientsOfCustomer()">
                          <v-icon>mdi-cash-register</v-icon>
                          Facturar cliente
                      </v-btn>
                      </div>
                  </v-col>
                  </v-col>
              </v-row>
              </v-card-actions>
              <div style="height: 500px; overflow-y: auto; overflow-x: hidden; margin-top: 20px; margin-bottom: 20px; padding: 0 20px;">
              <!-- Services default -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver servicios por defecto
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide1 = !togglehide1' v-show='!togglehide1' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide1 = !togglehide1' v-show='togglehide1' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide1">
                  <DxDataGrid class="isScrolledByGeneralFilter dataGridService" :data-source="servicesDefault" 
                  :show-borders="true" :column-auto-width="true" style="width: fit-content;">
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="CodeService" header-cell-template="headerCodeService" />
                      <template #headerCodeService="{ }">
                      <div>Código de<br />servicio</div>
                      </template>
                      <DxColumn data-field="NameService" header-cell-template="headerNameService" />
                      <template #headerNameService="{ }">
                      <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" header-cell-template="headerName" />
                      <template #headerName="{ }">
                      <div>Nombre a<br />facturar</div>
                      </template>
                      <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice" />
                      <template #headerTypeservice="{ }">
                      <div>Tipo de<br />servicio</div>
                      </template-->
                      <DxColumn :width="65" data-field="Price" caption="Precio" />
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""/>
                      <template #show-template="{ data }">
                      <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                          <v-icon>mdi-plus</v-icon>
                      </a>
                      </template>
                      <template #quantity-template="{ data }">
                      <p>{{ servicesToAdd.find(item => item.CodeService == data.data.CodeService).quantity }}</p>
                      </template>
                  </DxDataGrid>
                  </v-col>
              </v-row>

              <!-- Services to select -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver todos los servicios
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide2 = !togglehide2' v-show='!togglehide2' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide2 = !togglehide2' v-show='togglehide2' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide2">
                  <GeneralFilter 
                      :filterEndpoint="$API.services.getAllServicesFiltered" 
                      :search="searchToBill"
                      @emptyFilter="getServices" 
                      @filtered="servicesFilterHandler"
                  >
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="servicesToBill"
                      key-expr="CodeService" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="CodeService" caption="ID"></DxColumn>
                      <DxColumn data-field="NameService" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice">
                      </DxColumn-->
                      <!--template #headerTypeservice="{ }">
                          <div>Tipo de<br />servicio</div>
                      </template-->
                      <DxColumn :width="65" data-field="Price" caption="Precio"></DxColumn>
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                          <v-icon>mdi-plus</v-icon>
                          </a>
                      </template>
                      <template #quantity-template="{ data }">
                          <p style="margin: 0;">{{ servicesToAdd.find(item => item.CodeService == data.data.CodeService)?.quantity || '' }}</p>
                      </template>
                      </DxDataGrid>
                  </GeneralFilter>
                  </v-col>
              </v-row>

              <!-- Products to select -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver todos los productos
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide3 = !togglehide3' v-show='!togglehide3' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide3 = !togglehide3' v-show='togglehide3' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide3">
                  <GeneralFilter 
                      :filterEndpoint="$API.products.getProductsToBillForInvoiceFiltered" 
                      :search="searchToBill"
                      @emptyFilter="getProducts" 
                      @filtered="productsFilterHandler"
                  >
                      <v-row style="padding: 1rem;">
                          <v-col cols="12" md="3" sm="4">
                              <v-autocomplete
                              v-model="selectedWarehouse"
                              item-text="Warehouse"
                              item-value="ID_Warehouse"
                              :items="warehouses"
                              @change="handleSelectWarehouse"
                              label="Almacenes"
                              ref="werehouseRef"
                              dense 
                              outlined 
                              attach
                              >
                              </v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="9" sm="8">
                              <p style="color: #e3191e !important; font-size: 12px; margin: 12px 0" v-if="warehouses.length == 0">No existen almacenes de productos para pacientes.</p>
                          </v-col>
                      </v-row>
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="productsToBill"
                      key-expr="ID_Item" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="ID_Item" caption="ID"></DxColumn>
                      <DxColumn data-field="ItemName" header-cell-template="headerName"></DxColumn>
                      <template #headerName="{ }">
                          <div>Nombre del<br />producto</div>
                      </template>
                      <DxColumn data-field="ItemComercialName" header-cell-template="headerNameComercial"></DxColumn>
                      <template #headerNameComercial="{ }">
                          <div>Nombre<br />comercial</div>
                      </template>
                      <DxColumn :width="65" data-field="ItemPrice" caption="Precio"></DxColumn>
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="addProduct(data.data)">
                          <v-icon>mdi-plus</v-icon>
                          </a>
                      </template>
                      <template #quantity-template="{ data }">
                        <p style="margin: 0;">{{ productsToAdd.find(item => item.ID_Item == data.data.ID_Item)?.quantity || '' }}</p>
                    </template>
                      </DxDataGrid>
                  </GeneralFilter>
                  </v-col>
              </v-row>

              <!-- ServiceToAdd -->
              <v-row style="margin-top: 2rem">
                  <v-col cols="12">
                  <h4 style="padding: 16px;">Servicios seleccionados</h4>
                  <div style="overflow-x: scroll; padding: 0 12px;">
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="servicesToAdd"
                      key-expr="CodeService" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"    
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="5" />
                      <DxColumn data-field="CodeService" :allow-editing="false" caption="ID"></DxColumn>
                      <DxColumn data-field="NameService" :allow-editing="false" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" :allow-editing="false" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <DxColumn data-field="TypeService" :allow-editing="false" header-cell-template="headerTypeservice">
                      </DxColumn>
                      <template #headerTypeservice="{ }">
                          <div>Tipo de<br />servicio</div>
                      </template>
                      <DxColumn data-field="quantity" caption="Cantidad">
                          <DxFormat typed="fixedPoint" :precision="0" />
                          <DxRequiredRule message="La cantidad del servicio a facturar es requerida" />
                          <DxPatternRule :pattern="itemQtyPattern"
                          message="La cantidad del servicio facturado solo puede contener numeros enteros mayores a 0" />
                      </DxColumn>
                      <DxColumn data-field="unitPrice" :allow-editing="false" caption="Precio"></DxColumn>
                      <DxColumn cell-template="total-template" :allow-editing="false" caption="Total"></DxColumn>
                      <DxColumn cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="deleteService(data.data)">
                          <v-icon>mdi-delete</v-icon>
                          </a>
                      </template>
                      <template #total-template="{ data }">
                          <div>
                          {{ totalXQuantity( data.data ) }}
                          </div>
                      </template>
                      </DxDataGrid>
                  </div>
                  </v-col>
              </v-row>

              <!-- Product to add -->
              <v-row style="margin-top: 2rem">
                  <v-col cols="12">
                  <h4 style="padding: 16px;">Productos seleccionados</h4>
                  <div style="overflow-x: scroll; padding: 0 12px;">
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="productsToAdd"
                      key-expr="ID_Item" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"    
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="5" />
                      <DxColumn data-field="ID_Item" :allow-editing="false" caption="ID"></DxColumn>
                      <DxColumn data-field="ItemName" :allow-editing="false" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre del<br />producto</div>
                      </template>
                      <DxColumn data-field="ItemComercialName" :allow-editing="false" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <DxColumn data-field="ItemType" :allow-editing="false" header-cell-template="headerTypeservice">
                      </DxColumn>
                      <template #headerTypeservice="{ }">
                          <div>Tipo de<br />producto</div>
                      </template>
                      <DxColumn data-field="quantity" caption="Cantidad">
                          <DxFormat typed="fixedPoint" :precision="0" />
                          <DxRequiredRule message="La cantidad del producto a facturar es requerida" />
                          <DxPatternRule :pattern="itemQtyPattern"
                          message="La cantidad del producto facturado solo puede contener numeros enteros mayores a 0" />
                      </DxColumn>
                      <DxColumn data-field="unitPrice" :allow-editing="false" caption="Precio"></DxColumn>
                      <DxColumn cell-template="total-template" :allow-editing="false" caption="Total"></DxColumn>
                      <DxColumn cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="deleteProduct(data.data)">
                          <v-icon>mdi-delete</v-icon>
                          </a>
                      </template>
                      <template #total-template="{ data }">
                          <div>
                          {{ totalXQuantity( {...data.data, Price: data.data.ItemPrice} ) }}
                          </div>
                      </template>
                      </DxDataGrid>
                  </div>
                  </v-col>
              </v-row>

              </div>
          </v-card>
    </v-dialog>
    <!-- Dialog bill patient -->

    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    ></alerts>
  </div>
</template>

<script>
import Vue from "vue";
import GeneralFilter from "@/components/GeneralFilter";
import { DxDataGrid, DxColumn, DxPaging, DxScrolling, DxEditing, DxFormat, DxPatternRule, DxRequiredRule } from "devextreme-vue/data-grid";
import HealthCertificate from "../../../components/ReportsAndPrintings/HealthCertificate";
import HealthClausule from "../../../components/ReportsAndPrintings/HealthClausule";
import UserProfile from "@/components/Displays/UserProfileDisplay.vue";
import UserProfileLab from "@/components/Displays/UserProfileDisplayLab.vue";
import DatePicker from "@/components/DatePicker";
import Block from "@/components/Block";
import Alerts from "@/components/Alerts";
import { numberWithCommas } from "@/helpers/money";

export default {
  props: ["data", "win"],
  components: {
    DxDataGrid, DxColumn, DxEditing, DxPaging, DxFormat, DxPatternRule, DxScrolling, DxRequiredRule,
    HealthClausule,
    DatePicker,
    Block,
    HealthCertificate,
    GeneralFilter,
    UserProfile,
    UserProfileLab,
    Alerts,
  },
  data() {
    return {
      currency: JSON.parse(localStorage.getItem('branch')).Currency,
      itemQtyPattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/i,
      showBillPatient: false,
      showServicesToAdd: false,
      showProductsToAdd: false,
      servicesToBill: [],
      productsToBill: [],
      servicesToAdd: [],
      productsToAdd: [],
      filterProducts: {
          filter: null
      },
      searchToBill: {
          filter: null
      },
      togglehide1: true,
      togglehide2: true,
      togglehide3: true,
      warehouses: [],
      selectedWarehouse: null,
      customer: {},
      print: {
        show: false,
        data: [],
      },
      print2: {
        show: false,
        data: [],
      },
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      dialog: false,
      search: {
        patient: null,
      },
      history: [],
      showhistory: false,
      dateReport: null,
      loading: false,
      vaccines: null,
      patientinfo: {},
      useData: JSON.parse(localStorage.getItem("user")),
      healthCertificate: {},
      Reports: [],
      showReports: false,
      date: new Date(),
      Block: false,
      tab: "tab-1",
      branchinfo: {},
      userinfo: {},
      quillOptions: {
        modules: {
          toolbar: {
            container: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ align: [] }],

              ["clean"],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ script: "sub" }, { script: "super" }],
              [{ formula: "" }],
            ],
            handlers: {},
            useData: null,
          },
        },
        theme: "snow",
      },
    };
  },
  watch: {
    date: {
      handler: function () {
        this.healthCertificate.date = this.date;
      },
      immediate: true,
    },
    servicesToAdd: {
        deep: true,
        immediate: true,
        handler() {
            if(this.servicesToAdd.length !== 0) {
                this.showServicesToAdd = true;
            }
            else {
                this.showServicesToAdd = false;
            }
        }
    },
    productsToAdd: {
        deep: true,
        immediate: true,
        handler() {
            if(this.productsToAdd.length !== 0) {
                this.showProductsToAdd = true;
            }
            else {
                this.showProductsToAdd = false;
            }
        }
    },
  },
  async mounted() {
    this.search.business = JSON.parse(localStorage.getItem("user")).businessid || "";
    (this.search.branch = JSON.parse(localStorage.getItem("user")).branch || ""),
      (this.dateReport = this.moment().format("YYYY-MM-DD"));
    await this.getInfoBranch();
    if (this.data) {
      Object.keys(this.data).map((el) => {
        Vue.set(this.patientinfo, el.toLowerCase(), this.data[el]);
      });
      this.search.patient = this.patientinfo.id_patient;
      await this.getAllReports();
    }
    this.customer.ID_Customer = this.data.ID_Customer;
    console.log('customer', this.customer);
    this.useData = JSON.parse(localStorage.getItem("user"));
    this.warehouses = await this.$API.warehouse.getWarehousesFiltered({ isPatient: true });
    this.filterProducts.business = this.useData.businessid;
    this.filterProducts.branch = this.useData.branch;
    this.selectedWarehouse = this.warehouses.length == 0 ? 0 : this.warehouses[0].ID_Warehouse;
    this.filterProducts.warehouse = this.selectedWarehouse;    
    await this.getServices();
    await this.getProducts();
    await this.getDefaultServices();
  },
  computed:{
    columnWidth() {
      return this.$vuetify.breakpoint.smAndDown ? '150px' : 'auto !important'
    },
  },
  methods: {
    totalXQuantity( { Price, quantity } ) {
        return `${this.currency}${ numberWithCommas( parseFloat( Price * quantity ).toFixed( 2 ) ) }`
    },
    async handleSelectWarehouse() {
        this.loading = true;
        this.filterProducts.warehouse = this.selectedWarehouse;
        await this.getProducts();
        this.$refs.werehouseRef.blur();
        this.loading = false;
    },
    addService(data) {
        const isRepetitive = this.servicesToAdd.find((service) => service.ID_Service === data.ID_Service);

        if (!isRepetitive) {
            this.servicesToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.Price), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.Price).toFixed(4))}` });
        } else {
            const service = this.servicesToAdd.find(item => item.ID_Service === data.ID_Service)
            if(service) {
                service.quantity++;
            }
            console.log("Service already exists in servicesToAdd");
        }
    },
    addProduct(data) {
        const isRepetitive = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
        if (!isRepetitive) {
            this.productsToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.ItemPrice), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.ItemPrice).toFixed(4))}` });
        } else {
            const product = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
            if(product) {
                product.quantity++;
            }
            console.log("Product already exists in productsToAdd.");
        }
    },
    deleteService(data) {
        const serviceIndex = this.servicesToAdd.findIndex((service) => service.CodeService === data.CodeService);

        if (serviceIndex !== -1) {
            this.servicesToAdd.splice(serviceIndex, 1);
        }
    },
    deleteProduct(data) {
        const index = this.productsToAdd.findIndex((item) => item.ID_Item === data.ID_Item);

        if (index !== -1) {
            this.productsToAdd.splice(index, 1);
        }
    },
    getServices() {
        this.$API.services.getAllServices(this.filterServices).then((response) => {
            this.servicesToBill = response;
        });
    },
    getProducts() {
        this.$API.products.getProductsToBillForInvoice(this.filterProducts).then((response) => {
            this.productsToBill = response;
        });
    },
    getDefaultServices() {
        this.$API.services.getDefaultServices().then((response) => {
            this.servicesDefault = response;
        });
    },
    servicesFilterHandler(response) {
        this.servicesToBill = response;
    },
    productsFilterHandler(response) {
        this.productsToBill = response;
    },
    getItemsToBeBilled() {
        this.$API.veterinarypatients.getServicesToBeBilled({ 'customer': this.data.ID_Customer })
        .then((response) => {
            this.servicesToAdd = []
            for(let i = 0; i < response.length; i++){
            this.servicesToAdd.push({ ...response[i], unitPrice: `${this.currency}${numberWithCommas(parseFloat(response[i].Price).toFixed(4))}` });
            }
        });
        this.$API.veterinarypatients.getProductsToBeBilled({ 'customer': this.data.ID_Customer }).then((res) => {
            if(res.success) {
            this.productsToAdd = [];
            for (const item of res.data) {
                this.productsToAdd.push({ ...item, quantity: item.Quantity, unitPrice: `${this.currency}${numberWithCommas(parseFloat(item.ItemPrice).toFixed(4))}`});
            }
            }
        });
    },
    async saveItemsToBeBilled() {
        this.showBillPatient = false;
        this.loading = true;

        try {
            if(this.servicesToAdd.length) {
            await this.$API.veterinarypatients.saveServicesToBeBilled({ 'customer': this.customer.ID_Customer, 'services': this.servicesToAdd })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
                throw new Error('Ocurrio un error al guardar los servicios.');
            });
            }
            if(this.productsToAdd.length) {
            console.log(this.productsToAdd);
            
            await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
                throw new Error('Ocurrio un error al guardar los productos.');
            });
            }
            await this.getItemsToBeBilled();
            this.loading = false;
            this.showAlert("success", "Exito", "Los datos de facturación de este paciente han sido guradados.")
        } catch (error) {
            console.log(error);
            this.loading = false;
            this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
        }

    },
    async billedPatientsOfCustomer() {
        this.showBillPatient = false;
        this.loading = true;

        try{
            await this.$API.veterinarypatients.saveServicesToBeBilled({'customer': this.customer.ID_Customer, 'services': this.servicesToAdd});
            await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd });
            await this.$API.veterinarypatients.billedPatientsOfCustomer({ 'customer': this.customer.ID_Customer});

            this.loading = false;

            this.showAlert("success", "Exito", `Se ha realizado la factura de los ${this.servicesToAdd.length && this.productsToAdd.length ? 'servicios y productos' : this.servicesToAdd.length ? 'servicios' : this.productsToAdd.length ? 'productos' : 'items' } seleccionados, podrá observarla en el módulo de POS en listo para facturar.`);
            await this.getItemsToBeBilled();        
        }
        catch(error) {
            console.log(error)
            this.loading = false
            this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
        }
    },


    closeAlert() {
      this.alert.show = false;
    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "deletecustomer") {
        this.deleteCustomer();
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    filterHandler(data) {
      this.history = data;
    },
    getAllReports() {
      this.$API.medicalreports.getAllreport(this.patientinfo.id_patient).then((res) => {
        this.history = res;
      });
    },
    handlePrint(type = false) {
      if (type) {
        this.print = {
          show: true,
          data: {
            healthCertificate: this.healthCertificate,
            patientinfo: this.patientinfo,
            useData: this.useData,
            branchinfo: this.branchinfo,
            dateReport: this.dateReport,
            vac: this.vaccines,
          },
        };
      } else {
        this.print2 = {
          show: true,
          data: {
            healthCertificate: this.healthCertificate,
            patientinfo: this.patientinfo,
            useData: this.useData,
            branchinfo: this.branchinfo,
            dateReport: this.dateReport,
            vac: this.vaccines,
          },
        };
      }
    },
    selectedPrint(data) {
      const value = JSON.parse(data.Content);
      console.log(data.Type_C);

      data.Type_C == "Constancia Médica"
        ? (this.print = { show: true, data: value })
        : (this.print2 = { show: true, data: value });
    },

    handleSave(type = false) {
      let form = {};
      let typeN = type ? "Constancia Médica" : "Certificado de Salud";
      const userdata = {
        id: this.useData.id,
        username: this.useData.username,
        seal: this.useData.seal,
      };
      const brantosave = { BranchAddress: this.branchinfo.BranchAddress };
      form = JSON.stringify({
        healthCertificate: this.healthCertificate,
        patientinfo: this.patientinfo,
        useData: userdata,
        branchinfo: brantosave,
        dateReport: this.dateReport,
        vac: this.vaccines,
      });
      if (type) {
        // this.print = { show: true, data: { healthCertificate: this.healthCertificate , patientinfo: this.patientinfo, useData: this.useData, branchinfo: this.branchinfo, dateReport: this.dateReport, vac: this.vaccines} }
      } else {
        //this.print2 = { show: true, data: { healthCertificate: this.healthCertificate , patientinfo: this.patientinfo, useData: this.useData, branchinfo: this.branchinfo, dateReport: this.dateReport, vac: this.vaccines} }
      }
      this.$API.medicalreports
        .createreport({
          date: this.healthCertificate.date,
          user: this.useData.id,
          patient: this.patientinfo.id_patient,
          Type_C: typeN,
          content: form,
        })
        .then((res) => {
          console.log(res);
          this.getAllReports();
          this.showAlert(
            "success",
            "Reporte creado",
            "El reporte se ha creado correctamente"
          );
        })
        .catch((err) => {
          console.log(err);
          this.showAlert("danger", "Error", "Ha ocurrido un error al crear el reporte");
        });
    },
    getInfoBranch() {
      this.$API.branches.findSpecificBranch(this.search).then((res) => {
        this.branchinfo = res[0];
      });
    },
    getDateMedicals(date) {
      this.loading = true;
      this.$API.medication
        .getMedicalsByDate({ date: date, patientid: this.patientinfo.id_patient })
        .then((res) => {
          this.vaccines = res;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.btndisable {
    background-color: #41464c !important;
    color: white;
}
.btndisable2 {
    background-color: #1976d2 !important;
    color: white;
}

.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;

  border-radius: 15px;
}

.infopatient {
  margin-top: 5%;
}
.tabs {
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white;
}
.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}
.btnblue {
  background-color: #1976d2 !important;
  color: white;
}
</style>
