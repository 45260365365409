<template>
  <div class="pa-1">
    <v-form v-model="valid" class="white pa-4" style="border-radius: 15px">
      <block v-if="loading"></block>

      <v-row class="ml-2 mr-2">
        <v-col cols="12" md="12" class="tabsNh">
          <v-col cols="12" class="d-flex justify-space-between" style="min-height: 50px">
            <div>
              <v-btn small color="error" @click.prevent.stop="confirmdeleteProduct()">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>

            <div>
              <v-btn
                 small class="btnsave" @click.prevent.stop="updateProducts">
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-col>

        <v-col cols="12" md="12" style="display: flex; justify-content: space-between; margin-top: 1rem">
          <h2>Información General</h2>

          <div style="display: none">
            <v-checkbox v-model="form.isglobalbranch" label="Mostrar en todas las sucursales"></v-checkbox>
          </div>
        </v-col>

        <v-col cols="12" md="12" style="display: flex; justify-content: space-between; margin: 1rem 0">
          <h4>ID del Producto: {{ form.iditem }}</h4>
        </v-col>

        <v-col cols="12" md="3" sm="8" style="margin-top: 0.8rem">
          <div style="justify-content: center; display: flex; width: 50%; margin-left: 25%">
            <v-img :src="url" class="preview-avatar" @click="handleClickImage"/>
          </div>
          <v-file-input style="width: 80%; margin-left: 10%" @change="Preview_image" v-model="image"
            label="Seleccione imágen del articulo" prepend-icon="mdi-camera" placeholder="Seleccione imágen del articulo" :rules="[maxFileSize]">
          </v-file-input>
        </v-col>

        <v-col cols="12" md="3">
          <!--     <v-select outlined dense  :items="types" label="Tipo de Articulo*" v-model="form.itemtype" required
              :rules="requiredRule"></v-select> -->
          <v-text-field outlined dense v-model="form.itemname" label="Nombre del Articulo*" required
            :rules="[(v) => !!v || 'El nombre del articulo es requerido']" @input="fillItemComercialName"></v-text-field>
          <v-autocomplete outlined dense :items="unidades" item-value="Valores" item-text="Valores" attach label="Medida de Unidad"
            v-model="form.itemmeasureunit"></v-autocomplete>
          <v-btn class="btnadd mb-5" @click="dialogBarCode = true">Gestión de códigos de barra /<br>códigos alternos</v-btn>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field outlined dense v-model="form.itemprice" label="Precio" type="number" prefix="$" required
            :rules="[(v) => !!v || 'El precio del articulo es requerido']"></v-text-field>
          <!--v-select outlined dense  :items="providers" item-text="ProviderName" item-value="ID_Provider" label="Provedor*" attach
              v-model="form.idprovider" required :rules="requiredRule"></v-select-->
          <!-- <v-text-field outlined dense v-model="form.itembarcode" label="Código de Barra del Articulo" required
              maxlength="100"></v-text-field> -->
          <v-text-field outlined dense v-model="form.suggestedprice" label="Precio sugerido*" type="number" prefix="$"
            required :rules="[(v) => !!v || 'El precio sugerido del articulo es requerido']"></v-text-field>

          <v-btn class="btnadd mb-5" @click="dialogProviders = true">Gestión de proveedores</v-btn>
        </v-col>

          <v-col cols="12" md="3">
            <v-text-field outlined dense v-model="form.itemcomercialname" label="Nombre Comercial del Articulo"
              maxlength="20"></v-text-field>
              <v-text-field outlined dense v-model="form.itemdescription" label="Descripción del Articulo" maxlength="20"></v-text-field>

            <v-checkbox v-model="form.isexempt" label="Producto exento"></v-checkbox>
          </v-col>

          <v-col cols="12" md="6" lg="4">
          </v-col>
          <v-col cols="12" md="4">
           
          </v-col>

        <v-col cols="12" md="12">
          <h3 class="d-none">Información contable</h3>

          <br />
        </v-col>
        <v-col cols="12" md="3">
          <!--   <v-select outlined dense  :items="families" item-text="name" item-value="id" label="Familia" v-model="form.family" attach
                      required></v-select> -->
          <v-autocomplete outlined dense label="Familia " v-model="form.family" 
          @change="() => {
            removeSon();
            getCategoriesByFamily();
          }"
          :items="families" item-text="name" attach
            item-value="id"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <!-- <v-select outlined dense  :items="categories" item-text="name" item-value="id" label="Categoria" v-model="form.category" attach
                      required></v-select> -->
          <v-autocomplete outlined dense label="Categoría" 
          @change="() => {
            form.subcategory = null;
            getSubsByCategory();
          }"
          v-model="form.category" :items="filteredCategories" 
            item-text="name" attach item-value="id" :disabled="!form.family"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <!--  <v-select outlined dense  :items="subcategories" item-text="name" item-value="id" label="Sub Categoria" v-model="subcategory" attach
                    required></v-select> -->
          <v-autocomplete outlined dense label="Subcategoría" v-model="form.subcategory" :items="filteredSubcategories"
            attach item-text="name" item-value="id" :disabled="!form.category"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn class="btnadd  mb-5" @click.prevent.stop="dialog = true">
            Gestionar segmentación
          </v-btn>
        </v-col>
        <v-col cols="12" md="3">
          <v-checkbox v-model="form.ishidrocarburo" label="Producto es hidrocarburo"></v-checkbox>
        </v-col>
        <!-- <v-col cols="12" md="4" lg="4" class="mt-7">
          <v-autocomplete outlined dense :items="warehouses" item-text="Warehouse" item-value="ID_Warehouse"
            label="Almacén" attach v-model="form.warehouse" @change="fillBatchsAndAccountingGroups()">
          </v-autocomplete>
        </v-col> -->
        <!-- <v-col cols="12" md="12">
          <h3>Cuenta Contable</h3>
          <br />
        </v-col>
        <v-col cols="12" md="2" class="mb-7">
          <v-btn class="btnsave" @click.prevent.stop="">
          <v-btn class="btnsave" @click.prevent.stop="showAccountsModal = true" >Buscar Cuenta
            
          </v-btn>
        </v-col>
        <v-col cols="12" md="2">
          <v-autocomplete outlined dense  label="Código de Cuenta Contable" v-model="form.id_account" :items="accountList" attach
            item-text="account_code" item-value="id_account"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="8">
          <v-autocomplete outlined dense  label="Nombre de Cuenta Contable" v-model="form.id_account" :items="accountList" attach
            item-text="account_description" item-value="id_account" :disabled="!form.category"></v-autocomplete>
        </v-col>

        <v-dialog v-model="showAccountsModal" width="60%">
          <v-card>1
            <AccountingItemForm :win="win"
              v-on:sendSelection="accountSelectionHandler"
            />
          </v-card>
        </v-dialog> -->
      </v-row>

      <AccountingAssign :form="form" :win="win" />
      
      <v-row class="mx-2">
        <!--v-col cols="12" md="6" lg="4">
                              <v-select outlined dense  :disabled="!batchs.length" :items="batchs" item-text="ID_ItemBatch" attach
                                  item-value="ID_ItemBatch" v-model="form.itemlote" label="Lote"></v-select>
                          </v-col-->

        <!--v-col cols="12" md="6" lg="4">
                              <v-select outlined dense  :disabled="!accountinggroups.length" :items="accountinggroups"
                                  label="Grupo de Productos" hint="Familía de Productos / Categoría / Subcategoría"
                                  persistent-hint return-object @change="setValInForm()" v-model="selectaccountinggroup">
                                  <template slot="item" slot-scope="data">
                                      {{ data.item.AccountingLineName }} / {{ data.item.AccountingCategoryName }} / {{
                                              data.item.AccountingSubCategoryName
                                      }}
                                  </template>
                                  <template slot="selection" slot-scope="data">
                                      {{ data.item.AccountingLineName }} / {{ data.item.AccountingCategoryName }} / {{
                                              data.item.AccountingSubCategoryName
                                      }}
                                  </template>

                              </v-select>
                          </v-col-->

        <v-col cols="12" md="4" style="display: none !important">
          <v-text-field outlined dense v-model="form.itemconversionfactor" label="Factor de Conversión"
            maxlength="20"></v-text-field>
        </v-col>
        <v-col cols="12" md="2" style="display: none !important">
          <v-text-field outlined dense v-model="form.unitspackaging" type="number" label="Unidades Packaging"
            maxlength="20"></v-text-field>
        </v-col>
        <v-col cols="12" md="2" style="display: none !important">
          <v-autocomplete outlined dense v-model="typepack" label="Unidad" attach :items="unidades" item-value="Valores" item-text="Valores"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <h3 style="text-align: center" class="tabs" @click.prevent.stop="showItemStructure = !showItemStructure">
            Gestión de estructuración de producto
          </h3>
          <v-dialog v-model="showItemStructure" max-width="60%" scrollable>
            <v-card>
              <v-card-title>
                <h3>Gestión de estructuración del producto</h3>
              </v-card-title>
              <v-card-text>
                <GeneralFilter v-model="filterProducts" :filterEndpoint="$API.products.newGetProducts" 
                  :search="{filter: filterProducts, provider: filterProvider || null, warehouse: filterWarehouse || null, family: filterFamily || null, category: filterCategory || null, subcategory: filterSubcategory || null}"
                  @emptyFilter="getProducts" @filtered="filterHandlerProducts">
                  <DxDataGrid :data-source="dataProducts" :scrolling="{ useNative: true }">
                    <DxPaging :page-size="5" />
                    <DxColumn :width="50" cell-template="add-product" caption=""></DxColumn>
                    <template #add-product="{ data }">
                      <a href="#" @click="addProductToStructure(data.data)">
                        <v-icon>mdi-plus</v-icon>
                      </a>
                    </template>
                    <DxColumn data-field="ID_Item" caption="ID de Producto"></DxColumn>
                    <DxColumn data-field="ItemName" caption="Nombre de Producto"></DxColumn>
                    <DxColumn data-field="ItemComercialName" caption="Nombre Comercial"></DxColumn>
                  </DxDataGrid>
                </GeneralFilter>

                <h4>Estructuración actual del producto:</h4>
                <DxDataGrid :data-source="dataProductsStructure" :scrolling="{ useNative: true }">
                  <DxPaging :page-size="5" />
                  <DxEditing
                    :allow-updating="true"
                    :select-text-on-edit-start="true"
                    start-edit-action="click"
                    mode="cell"
                  />
                  <DxColumn caption="ID de Producto" data-field="ID_Item_Base" :allow-editing="false"></DxColumn>
                  <DxColumn caption="Nombre de Producto" data-field="ItemName" :allow-editing="false"></DxColumn>
                  <DxColumn caption="Nombre Comercial" data-field="ItemComercialName" :allow-editing="false"></DxColumn>
                  <DxColumn caption="Cantidad base" data-field="QuantityBase" data-type="number" :allow-editing="true"></DxColumn>
                  <DxColumn :width="145" caption="Unidad" cell-template="select-unit" data-field="UnitBase" :allow-editing="false"></DxColumn>
                  <template #select-unit="{ data }">
                    <span>
                    <select
                      class="form-control custom-select"
                      v-model="data.data.UnitBase"
                    >
                      <option
                        v-for="unidad in unidades"
                        :value="unidad.Valores"
                        :key="unidad.Valores"
                      >
                        {{ unidad.Valores }}
                      </option>
                    </select>
                  </span>
                  </template>
                  <DxColumn :width="50" cell-template="del-product" caption=""></DxColumn>
                  <template #del-product="{ data }">
                    <a href="#" @click="delProductOfStructure(data.data)">
                      <v-icon>mdi-delete</v-icon>
                    </a>
                  </template>
                </DxDataGrid>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="saveStructureOfProduct()" class="btncontinue">
                  Cerrar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="saveStructureOfProduct()" class="btnsave" >
                  <v-icon>mdi-content-save</v-icon>
                   Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="6">
          <h3 style="text-align: center" :class="showBgcolorNotes" @click.prevent="showNotes = !showNotes">
            Comentarios
          </h3>
          <v-dialog v-model="showNotes" width="60%">
            <v-card class="pa-4">
              <div style="
                  margin-top: 0.3rem;
                  margin-bottom: 0.3rem;
                  display: flex;
                  justify-content: flex-end;
                ">
                <v-btn small class="btndelete" @click.prevent="showNotes = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <quill-editor v-show="showNotes" :options="quillOptions" :content="form.itemnote"
                @change="onEditorChange($event)"></quill-editor>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-form>

    <v-dialog v-model="dialog" max-width="60%" height="100%">
      <ManageCategory :win="win" v-on:reloadCategories="reloadCategories" />
    </v-dialog>
    <v-dialog v-model="dialogProviders" max-width="60%">
      <v-card>
        <v-card-title>
          <h2>Asignación de proveedores</h2>
        </v-card-title>
        <v-card-text>
          <h4>Seleccione los proveedores asociados a este producto.</h4>
          <GeneralFilter :hideLimitLabel="true" :filterEndpoint="filterPromise" :search="{}" @emptyFilter="clearFilter"
            @filtered="filterHandler">
            <DxDataGrid class="isScrolledByGeneralFilter" :show-borders="true"
              :data-source="isFiltered ? providersFiltered : providers">
              <DxPaging :page-size="5" />
              <DxColumn :width="70" cell-template="select-provider" caption=""></DxColumn>
              <DxColumn data-field="ProviderName" caption="Proveedor"></DxColumn>
              <DxColumn data-field="ProviderPhone" caption="Télefono"></DxColumn>
              <DxColumn data-field="ProviderEmail" caption="Correo"></DxColumn>
              <DxColumn data-field="ProviderAddress" caption="Dirección"></DxColumn>

              <template #select-provider="{ data }">
                <div>
                  <v-checkbox v-model="data.data.Checked" @click="testFunction" />
                  <!-- <v-checkbox v-model="data.data.Checked" /> -->
                </div>
              </template>
            </DxDataGrid>
          </GeneralFilter>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn right class="btncontinue" @click="dialogProviders = false">Continuar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add barcode -->
    <v-dialog v-model="dialogBarCode" class="barcode-dialog .border-boxGlobal" max-width="40%">
      <v-card>
      <block v-if="loadingBarCode"/>
        <v-card-title>
          <h2 class="mx-3">Asignación de código de barra / código alterno</h2>
          <span class="mx-3 width-100 note-size"><b>Nota:</b> Los códigos de barras se actualizan, crean y eliminan al realizar la acción</span>
        </v-card-title>
        <v-card-text>
          <!-- <h4>Seleccione los proveedores asociados a este producto.</h4> -->
          <v-row class="mt-3">
            <v-col cols="12" class="d-flex justify-center mx-3">
              <v-text-field outlined dense v-model="barCodeToAdd" @keyup.enter="addBarCode" label="Código de barra / código alterno*"
                :rules="[(v) => !!v || 'El código de barra / código alterno requerido'] " required></v-text-field>
              <v-btn class="btnsave ml-5 mx-8" @click="addBarCode"><v-icon class="mr-3">mdi-barcode-scan</v-icon> Agregar código</v-btn>
            </v-col>
          </v-row>
          <GeneralFilter :hideLimitLabel="true" :filterEndpoint="$API.inventoryitemsbarcodes.getAllBarCodesFiltered"
            :search="search" @emptyFilter="getAllBarCodesHistory" @filtered="filterHandlerBarCodes">
            <DxDataGrid class="isScrolledByGeneralFilter grid-max-width" :show-borders="true" :data-source="barCodes">
              <DxPaging :page-size="5" />
              <DxColumn :width="70" cell-template="edit-barcode"></DxColumn>
              <DxColumn data-field="BarCode" caption="Código de barra / código alterno"></DxColumn>
              <DxColumn :width="70" cell-template="check-principal" caption="Principal"></DxColumn>
              <template #check-principal="{ data } ">
                <div>
                  <center><v-checkbox v-model="data.data.PrincipalBarCode" @change="checkPrincipalBarCode(data.data)"></v-checkbox></center>
                </div>
              </template> 
              <DxColumn :width="70" cell-template="delete-barcode"> </DxColumn>
              <template #edit-barcode="{ data }">
                <div>
                  <a class="mx-1" @click="editShowBarCode(data.data)">
                    <v-icon color="primary">mdi-eye</v-icon>
                  </a>
                </div>
              </template>
              <template #delete-barcode="{ data }">
                <div>
                  <a class="mx-1" @click="deleteBarCode(data.data)">
                    <v-icon color="error">mdi-delete</v-icon>
                  </a>
                </div>
              </template>
            </DxDataGrid>
          </GeneralFilter>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn right class="btncontinue" @click="dialogBarCode = false">Continuar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Add barcode -->

    <!-- Edit barcode -->
    <v-dialog v-model="dialogEditBarCode" class="barcode-edit-dialog .border-boxGlobal" max-width="40%">
      <v-card>
        <v-card-title>
          <h2>Editar de código de barra - {{ previousCode }}</h2>
        </v-card-title>
        <v-card-text>
          <!-- <h4>Seleccione los proveedores asociados a este producto.</h4> -->
          <v-row class="mt-3">
            <v-col cols="12" class="d-flex justify-center">
              <v-text-field @keyup.enter="editBarCode" outlined dense v-model="barCodeToEdit" label="Código de barra / código alterno*"
                :rules="[(v) => !!v || 'El código de barra / código alterno es requerido']" required></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-col cols="12" class="d-flex justify-space-between">
            <v-btn class="btnsave ml-5" @click="editBarCode">Editar</v-btn>
            <v-btn right class="btncontinue" @click="dialogEditBarCode = false">Continuar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Edit barcode -->

    <v-dialog v-model="showImageDialog" v-if="url" scrollable max-width="60%">
      <v-card style="border-radius: 20px;">
        <div style="width: auto; height: 80vh; border-radius: 20px;">
          <img :src="url" alt="product_image" style="width: auto; height: 80vh; border-radius: 20px;"/>
        </div>
      </v-card>
    </v-dialog>

    <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
    </alerts>
  </div>
</template>

<script>
import ManageCategory from "@/components/Categories/ManageCategory.vue";
import GeneralFilter from "@/components/GeneralFilter";
import Block from "@/components/Block";
import Vue from "vue";
import Alerts from "@/components/Alerts";
import { quillConfig } from "../../../helpers/quillconfig";
import { mapState, mapGetters } from "vuex";
import { DxDataGrid, DxColumn, DxPaging, DxEditing } from "devextreme-vue/data-grid";
import AccountingAssign from "../../acounting/partial/AccountingAssign.vue";
import { multipleCriteriaFilter } from "../../../helpers/object";
export default {
  name: "EditProduct",
  components: {
    Block,
    Alerts,
    ManageCategory,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    GeneralFilter,
    AccountingAssign,
  },
  props: ["data", "win"],
  data() {
    return {
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      valid: false,
      families: [],
      categories: [],
      subcategories: [],
      showBgcolorNotes: "tabs",
      quillOptions: quillConfig,
      showNotes: false,
      url: null,
      image: null,
      aditional_type: ["", "Medicamento", "Vacuna"],
      types: [],
      loading: false,
      loadingBarCode: false,
      categoryName: null,
      subName: null,
      toDelete: undefined,
      showFamCat: null,
      showCatSub: null,
      form: {},
      requiredRule: [(value) => !!value || "Required."],
      providers: [],
      dialog: false,
      showImageDialog: false,
      //WAREHOUSES
      search: {
        filter: null,
      },
      warehouses: {},
      batchs: {},
      accountinggroups: {},
      selectaccountinggroup: {},
      familyName: null,
      typepack: "",
      units: [
        "Unidades",
        "Libras",
        "Kilos",
        "Galones",
        "Litros",
        "Mililitros",
        "Metros",
        "Centímetros",
      ],
      dialogProviders: false,
      providersFiltered: [],
      isFiltered: false,
      dialogBarCode: false,
      dialogEditBarCode: false,
      barCodes: [],
      barCodesToSave: [],
      barCodeToAdd: null,
      barCodeToEdit: null,
      previousCode: null,
      validImageSize: true,
      showItemStructure: false,
      dataProducts: [],
      filterProducts: '',
      dataProductsStructure: [],
    };
  },
  computed: {
    //comercialName() {
    //  return this.form.itemname;
    //},
    ...mapGetters(["getCatalogMH"]),
    unidades(){
      return this.getCatalogMH("UnidadMedida");
    },
    filteredCategories() {
      return this.form.family
        ? this.categories.filter((category) => category.id_family == this.form.family)
        : [];
    },
    filteredSubcategories() {
      const cat = this.filteredCategories.find(
        (category) => category.id == this.form.category
      );
      if(!cat){
        return [];
      }
      return this.form.category
        ? this.subcategories.filter(
          (subcategory) => subcategory.id_cat == this.form.category
        )
        : [];
    },
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
  },
  async mounted() {
    this.$store.dispatch("rolesUser/getAllRoles");
    this.search.business = JSON.parse(localStorage.getItem("user")).businessid;
    this.search.branch = JSON.parse(localStorage.getItem("user")).branch;
    this.getAllProviders();
    this.form.iduser = JSON.parse(localStorage.getItem("user")).id;
    this.getFamilies();
    this.getCategories();
    this.getSubs();
    if (this.data) {
      Object.keys(this.data).map((el) => {
        Vue.set(
          this.form,
          el != "id_account" ? el.replace("_", "").toLowerCase() : el,

          this.data[el]
        );
      });
      this.form.family = this.data.family || null;
      if( this.form.family ) {
        await this.getCategoriesByFamily();
      }
      
      this.form.category = this.data.category || null
      if( this.form.category ) {
        await this.getSubsByCategory();
      }
      this.form.subcategory = this.data.subcategory || null
      this.form.isglobalbranch = true;
      if (
        this.form.unitspackaging != null ||
        this.form.itemunitspackaging != undefined ||
        this.form.itemunitspackaging != ""
      ) {
        const [y, z] = this.form.unitspackaging.split(" ");
        this.typepack = z;
        this.form.unitspackaging = y;
      }
    }
    this.loadImage();

    this.getWarehouses();
    this.firstFillBarchsAndAccountingGroups();

    this.search.ID_Item = this.form.iditem;
    this.getAllBarCodesHistory();
    
    // To open barcode from Product list for Aniimate
    console.log(this.win.meta)
    if(this.win.meta.openDialogBarCode){
      this.dialogBarCode = true;
    }

    this.getProducts()
    this.getItemsStructureByProduct()
  },
  created() {
    this.$store.dispatch("getParam", { type: "InventoryItemType" }).then((data) => {
      this.types = data.map((param) => {
        return { text: param.ParameterName, value: param.ParameterName };
      });
    });
  },

  methods: {
    handleClickImage() {
      if(this.url && this.url.length) {
        this.showImageDialog = true;
      }
      else {
        this.showAlert("warning", "Advertencia", "El producto no cuenta con imagen disponible.");
      }
    },
    checkPrincipalBarCode(data) {
      this.dialogBarCode = false
      if(data.PrincipalBarCode) {
        this.$API.inventoryitemsbarcodes.updatePrincipalBarCode({
          status: data.PrincipalBarCode,
          BarCode: data.BarCode,
          ID_Item: this.data.ID_Item
        }).then(response => {
          this.barCodes.forEach((item, index) => {
            if (item.BarCode != data.BarCode) {
              this.barCodes[index].PrincipalBarCode = false;
            }
          })
          this.showAlert("success", "Éxito", response.message);
        })
        .catch(() => {
          this.showAlert("warning", "Advertencia", "Ocurrio un problema al intentar actualizar el código de barra / código alterno.");
        })
      }
      else {
        this.$API.inventoryitemsbarcodes.updatePrincipalBarCode({
          status: data.PrincipalBarCode,
          BarCode: data.BarCode,
          ID_Item: this.data.ID_Item
        }).then(response => {
          this.showAlert("success", "Éxito", response.message);
        })
        .catch(() => {
          this.showAlert("warning", "Advertencia", "Ocurrio un problema al intentar actualizar el código de barra / código alterno.");
        })
      }
    },
    async loadImage() {
      if (this.data.ItemImagePath){
      //const { imageBase64: profileimagetoassign } = await this.$API.utilities.getImageInBase64({ "file": this.data.ItemImagePath }); 
      this.url = this.data.ItemImagePath;
      }
    },
    fillItemComercialName() {
      this.form.itemcomercialname = this.form.itemname
    },
    getAllBarCodesHistory() {
      this.$API.inventoryitemsbarcodes
        .getAllBarCodesHistory(this.form.iditem)
        .then((data) => {
          this.barCodes = data;
        })
        .catch((error) => console.log(error));
    },
    reloadCategories() {
      this.getFamilies();
      this.getCategories();
      this.getSubs();
    },
    checkDuplicate(NewBarCode) {
      return !this.barCodes.find(({ BarCode }) => NewBarCode === BarCode);
    },
    addBarCode() {
      this.loadingBarCode = true;
      if (this.checkDuplicate(this.barCodeToAdd)) {
        const newBarCode = {
          BarCode: this.barCodeToAdd,
          ID_Branch: this.form.idbranch,
          ID_Business: this.form.idbusiness,
          ID_Item: this.form.iditem,
        };

        this.$API.inventoryitemsbarcodes
          .saveBarCode(newBarCode)
          .then((response) => {
            if (!response.success) {
              this.dialogBarCode = false;
              this.showAlert("warning", "Advertencia", response.message, "showBackBarcodes");
              this.dialogBarCode = false;
            }
            if(response.success){
              this.dialogBarCode = false;
              this.showAlert("success", "Éxito", response.message, "showBackBarcodes");
            }
            this.getAllBarCodesHistory();
          })
          .catch((error) => console.log(error))
          .finally(() =>  {
            this.loadingBarCode = false
            this.barCodeToAdd = null
          })
      } else {
        this.dialogBarCode = false;
        this.loadingBarCode = false
        this.barCodeToAdd = null
        this.showAlert("warning", "Advertencia", "No debe repetir códigos de barra / código alterno", "showBackBarcodes");
      }
    },
    editShowBarCode(data) {
      this.dialogEditBarCode = true;
      this.previousCode = data.BarCode;
      this.barCodeToEdit = this.previousCode;
    },
    editBarCode() {
      this.loadingBarCode = true;
      this.dialogEditBarCode = false;
      if (this.checkDuplicate(this.barCodeToEdit)) {
        const editBarCode = this.barCodes.find(
          (item) => item.BarCode === this.previousCode
        );
        editBarCode.BarCode = this.barCodeToEdit;

        this.$API.inventoryitemsbarcodes
          .updateBarCode(editBarCode)
          .then((response) => {
            if (!response.success) {
              this.dialogBarCode = false;
              this.showAlert("warning", "Advertencia", response.message, "showBackBarcodes");
            }
            
            if(response.success){
              this.dialogBarCode = false;
              this.showAlert("success", "Éxito", response.message, "showBackBarcodes");
            }
            this.getAllBarCodesHistory()
          })
          .catch((error) => console.log(error))
          .finally(() => {
            this.loadingBarCode = false;
            this.dialogEditBarCode = false;
          });
      } else {
        this.dialogBarCode = false;
        this.loadingBarCode = false
        this.showAlert("warning", "Advertencia", "No debe repetir códigos de barra / código alterno", "showBackBarcodes");
      }
    },
    deleteBarCode(data) {
      this.loadingBarCode = true;
      this.$API.inventoryitemsbarcodes
        .deleteBarCode(data)
        .then((response) => {
          if(!response.success){
              this.dialogBarCode = false;
              this.showAlert("warning", "Advertencia", response.message, "showBackBarcodes");
          }

          if(response.success){
              const index = this.barCodes.indexOf(data);
              if (index > -1) {
                this.barCodes.splice(index, 1);
              }
              this.dialogBarCode = false;
              this.showAlert("success", "Éxito", response.message, "showBackBarcodes");
          } 
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loadingBarCode = false;
        })
    },
    clearFilter() {
      this.isFiltered = false;
    },
    filterHandler(response) {
      console.log("filter handler ", response);
      this.isFiltered = true;
      this.providersFiltered = response;
    },
    filterPromise(filterObj) {
      const filterArray = [
        "ProviderName",
        "ProviderPhone",
        "ProviderEmail",
        "ProviderAddress",
      ];
      return new Promise((resolve, reject) => {
        try {
          resolve(multipleCriteriaFilter(filterArray, filterObj, this.providers));
        } catch (error) {
          reject(error);
        }
      });
    },

    getFamilies() {
      this.$API.itemtypes.getFamiliesHistory().then((response) => {
        this.families = response;
      });
    },
    getCategories() {
      this.$API.itemtypes.getCategoriesHistory().then((response) => {
        this.categories = response;
      });
    },
    async getCategoriesByFamily() {
      this.categories = await this.$API.itemtypes.getCategoriesByFamily( { id_family: this.form.family } );
    },
    getSubs() {
      this.$API.itemtypes.getSubsHistory().then((response) => {
        this.subcategories = response;
      });
    },
    async getSubsByCategory() {
      this.subcategories = await this.$API.itemtypes.getSubsByCategory( { id_cat: this.form.category } );
    },
    closeAlert() {
      this.alert.show = false;

      if (this.alert.options == "deleteproduct") {
        this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid });
        this.$store.dispatch("reloadData", "products");
      }

      if (this.alert.options == 'showBackBarcodes') {
        this.dialogBarCode = true;
      }
    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "deleteproduct") {
        this.deleteProducts();
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },

    confirmdeleteProduct() {
      this.showAlert(
        "question",
        "Eliminar",
        "¿Seguro que desea eliminar el producto seleccionado?",
        "deleteproduct"
      );
    },
    Preview_image() {
      this.url = URL.createObjectURL(this.image);
    },
    getAllProviders() {
      this.$API.providers.getProviders(this.search).then((response) => {
        this.providers = response;
        this.$API.providers
          .getCheckedProvidersOfProduct({ iditem: this.form.iditem })
          .then((response2) => {
            response2.forEach((item2) => {
              const index = response.findIndex(
                (item1) => item1.ID_Provider === item2.ID_Provider
              );
              if (index !== -1) {
                this.providers[index] = item2;
              }
            });

            this.providers = this.providers.sort((a, b) => {
              if(a.Checked && !b.Checked){
                return -1;
              }else if( !a.Checked && b.Checked ){
                return 1;
              }else {
                return 0;
              }
            })
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    updateProducts() {
      if (this.checkImageSize()) return;

      if (!this.valid) {
        this.showAlert("warning", "Advertencia", "Faltan campos por llenar");
        return;
      }

      const validProviders = this.providers.some((provider) => provider.Checked);

      if (!validProviders) {
        this.showAlert(
          "warning",
          "Advertencia",
          "El producto debe estar asociado con al menos un proveedor"
        );
        return;
      }

      if (this.form.unitspackaging) {
        this.form.unitspackaging = this.form.unitspackaging + " " + this.typepack;
      }
      //this.form.itemcomercialname = this.comercialName;
      this.loading = true;
      const formdata = new FormData();
      formdata.append("file", this.image);
      formdata.append("product", JSON.stringify(this.form));

      var providersForProduct = this.providers.filter(
        (item) => item.Checked === true || item.Checked === 1
      );
      formdata.append("providers", JSON.stringify(providersForProduct));

      this.$API.products
        .updateProduct(this.form.iditem, formdata)
        .then(() => {
          this.loading = false;
          this.showAlert("success", "Exito", "Producto actualizado");
          this.$store.dispatch("reloadData", "products");
        })
        .catch(() => {
          this.loading = false;
        });
    },
    deleteProducts() {
      this.loading = true;
      this.$API.products
        .logicalDeleteProduct(this.form.iditem)
        .then((res) => {
          console.log(res);
          this.loading = false;
          this.showAlert("success", "Exito", "Producto eliminado", "deleteproduct");
          //this.$emit('reloadData', 'delete');
        })
        .catch(() => {
          this.loading = false;
          this.showAlert("danger", "Error", "No se pudo eliminar el producto");
        });
    },
    onEditorChange(e) {
      this.form.itemnote = e.html;
      if (this.form.itemnote != "") {
        this.showBgcolorNotes = "tabsContent";
      } else if (this.form.itemnote == "" || this.form.itemnote == "<p><br></p>") {
        this.showBgcolorNotes = "tabs";
      } else {
        this.showBgcolorNotes = "tabs";
      }
    },

    getWarehouses() {
      this.$API.warehouse.getWarehouses(this.search).then((response) => {
        this.warehouses = response;
      });
    },

    firstFillBarchsAndAccountingGroups() {
      let warehouse = this.form.warehouse;
      this.search.warehouse = warehouse;
      this.$API.warehouse
        .getBatchs(this.search)
        .then((response) => {
          this.batchs = response;
        })
        .catch(() => {
          this.batchs = {};
        });

      this.$API.warehouse
        .getAccountingGroups(this.search)
        .then((response) => {
          this.accountinggroups = response;
          this.selectaccountinggroup = this.accountinggroups.find(
            (i) => i.ID_AccountingLine == this.form.idaccountingline
          );
        })
        .catch(() => {
          this.accountinggroups = {};
        });
    },

    fillBatchsAndAccountingGroups() {
      let warehouse = this.form.warehouse;
      this.search.warehouse = warehouse;
      this.$API.warehouse
        .getBatchs(this.search)
        .then((response) => {
          this.batchs = response;
        })
        .catch(() => {
          this.batchs = {};
        });

      this.$API.warehouse
        .getAccountingGroups(this.search)
        .then((response) => {
          this.accountinggroups = response;
        })
        .catch(() => {
          this.accountinggroups = {};
        });
    },

    setValInForm() {
      this.form.idaccountingline = this.selectaccountinggroup.ID_AccountingLine;
      this.form.idaccountingcategory = this.selectaccountinggroup.ID_AccountingCategory;
      this.form.idaccountingsubcategory = this.selectaccountinggroup.ID_AccountingSubCategory;
    },
    removeSon(){
      console.log("remove son")
      this.form.category = null;
      this.form.subcategory = null;
    },

    filterHandlerFamilies(response) {
      this.families = response;
    },

    filterHandlerCategories(response) {
      this.categories = response;
    },

    filterHandlerSubs(response) {
      this.subcategories = response;
    },

    filterHandlerBarCodes(response) {
      console.log(response);
      this.barCodes = response;
    },
    maxFileSize(event) {
      const allowedSize = 2 * 1024 * 1024; // 2 MB in bytes
      const fileSize = event.size;

      if (fileSize > allowedSize) {
        this.validImageSize = false;
        return `El tamaño del archivo debe ser menor a 2 Mb`;
      }

      this.validImageSize = true;
      return true;
    },
    checkImageSize() {
      if (this.validImageSize == false) {
        this.showAlert("danger", "Error", "El tamaño de la imagen debe ser menor a 2 Mb");
        return true;
      }
      return false;
    },
    async getProducts() {
      await this.$API.products.newGetProducts({ filter: this.filterProducts, provider: this.filterProvider || null, warehouse: this.filterWarehouse || null, family: this.filterFamily || null, category: this.filterCategory || null, subcategory: this.filterSubcategory || null})
      .then(response => {
        this.dataProducts = response
      })
    },
    filterHandlerProducts(response) {
      this.dataProducts = response
    },
    addProductToStructure(data) {
      if (!this.dataProductsStructure.find(item => item.ID_Item_Base == data.ID_Item)) {
        let item = {
          ID_Item: this.form.iditem,
          ID_Item_Base: data.ID_Item,
          QuantityBase: 0,
          UnitBase: data.ItemMeasureUnit,
          ID_Business: this.form.idbusiness,
          ID_Branch: this.form.idbranch,
          ItemName: data.ItemName,
          ItemComercialName: data.ItemComercialName
        }

        this.dataProductsStructure.push(item)
      }
    },
    getItemsStructureByProduct() {
      this.$API.products.getItemsStructureByProduct({ ID_Item: this.form.iditem })
        .then(response => {
          this.dataProductsStructure = response
        })
    },
    saveStructureOfProduct() {
      if(this.dataProductsStructure.length == 0) {
        this.$API.products.saveStructureOfProduct({ "deleteAll": 1, "ID_Business": this.form.idbusiness, "ID_Branch": this.form.idbranch, "ID_Item": this.form.iditem})
        .then(response => {
          this.showItemStructure = false
          if (response?.status == "OK") {
            this.showAlert("success", "Éxito", "La información del producto se ha guardado satisfactoriamente.");
          }
          else if (response?.status == "ERROR") {
            this.showAlert("danger", "Error", "Hubo un problema en guardar la información, por favor intentelo nuevamente.");
          }
          else {
            this.showAlert("danger", "Error", "Hubo un problema en guardar la información, por favor intentelo nuevamente o contáctese con el administrador del sistema.");
          }
        }) .catch(() => {
          this.showItemStructure = false
          this.showAlert("danger", "Error", "Hubo un problema en guardar la información, por favor intentelo nuevamente o contáctese con el administrador del sistema.");
        })
      }
      else {
        this.$API.products.saveStructureOfProduct(this.dataProductsStructure)
          .then(response => {
            this.showItemStructure = false
            if (response?.status == "OK") {
              this.showAlert("success", "Éxito", "La información del producto se ha guardado satisfactoriamente.");
            }
            else if (response?.status == "ERROR") {
              this.showAlert("danger", "Error", "Hubo un problema en guardar la información, por favor intentelo nuevamente.");
            }
            else {
              this.showAlert("danger", "Error", "Hubo un problema en guardar la información, por favor intentelo nuevamente o contáctese con el administrador del sistema.");
            }
          }) .catch(() => {
            this.showItemStructure = false
            this.showAlert("danger", "Error", "Hubo un problema en guardar la información, por favor intentelo nuevamente o contáctese con el administrador del sistema.");
          })
      }
    },
    delProductOfStructure(data) {
      this.dataProductsStructure = this.dataProductsStructure.filter(item => item.ID_Item_Base !== data.ID_Item_Base)
    }
  },
};
</script>

<style scoped>
::v-deep .ndfHFb-c4YZDc-Wrql6b {
  display: none;
}

.btnsave {
  background-color: #00A178 !important;
  color: white;
}

.btnadd {
  background-color: #3498DB !important;
  color: white;
}

.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}

.tabsContent:hover {
  background-color: #ffb266;
  cursor: pointer;
}

.tabs {
  width: 100%;
  padding: 10px !important;
  background-color: rgba(60, 60, 60, 1) !important;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px !important;
  border-radius: 10px !important;
}

.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.tabsContent {
  width: 100%;
  padding: 10px;
  background-color: #f29d35;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.btncontinue {
  background-color: rgba(80, 80, 80, 1) !important;
  color: white;
}

.grid-max-width {
  min-width: 100px !important;
}

.barcode-dialog {
  z-index: 100 !important;
}

.barcode-edit-dialog {
  z-index: 200 !important;
}

.alert-dialog {
  z-index: 300 !important;
}

.note-size{
  font-size: 14px;
}

</style>
