import { render, staticRenderFns } from "./MedicalImages.vue?vue&type=template&id=c8bd1488&scoped=true&%23all=true&"
import script from "./MedicalImages.vue?vue&type=script&lang=js&"
export * from "./MedicalImages.vue?vue&type=script&lang=js&"
import style0 from "./MedicalImages.vue?vue&type=style&index=0&id=c8bd1488&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8bd1488",
  null
  
)

export default component.exports