<template>
  <v-card flat>
    <v-card-text class="pa-3 py-0">
      <v-row>
        <div style="margin-top: 0.3rem">
          <v-dialog v-model="showSuc" scrollable :width="'90%'">
            <v-card
              ><div
                style="
                  margin-top: 0.3rem;
                  margin-bottom: 0.3rem;
                  margin-right: 0.3rem;
                  display: flex;
                  justify-content: flex-end;
                "
              >
                <v-btn small class="btndelete" @click.prevent="showSuc = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <div>
                <GeneralFilter
                  entity="lista"
                  :filterEndpoint="$API.branches.findBranchesFiltered"
                  :search="search"
                  @emptyFilter="getBranches"
                  @filtered="filterHandler"
                >
                  <DxDataGrid
                    class="isScrolledByGeneralFilter"
                    :show-borders="true"
                    :data-source="branches"
                    key-expr="ID_Branch"
                  >
                    <DxColumn
                      :width="80"
                      cell-template="show-template"
                      caption=""
                    ></DxColumn>
                    <DxColumn data-field="BranchName" caption="Sucursal"></DxColumn>
                    <DxColumn
                      data-field="BranchLegalName"
                      caption="Nombre legal de la sucursal"
                    ></DxColumn>
                    <!--     <DxColumn cell-template="manage-correlatives-invoices" caption="Facturación"></DxColumn> -->
                    <DxColumn
                      :width="80"
                      cell-template="delete-template"
                      caption=""
                    ></DxColumn>
                    <template #show-template="{ data }">
                      <div>
                        <a
                          href="#"
                          @click.prevent.stop="
                            selectedBranch(data.data);
                            showSuc = false;
                          "
                        >
                          <v-icon color="primary">mdi-eye</v-icon>
                        </a>
                      </div>
                    </template>
                    <!--  <template #manage-correlatives-invoices="{ data }">
                    <div>
                      <a href="" class="mx-2" @click.prevent.stop="showModalInfoInvoices(data.data);showSuc = false">
                        <v-icon color="primary">mdi-eye</v-icon>
                      </a>
                    </div>
                  </template> -->
                    <template #delete-template="{ data }">
                      <div>
                        <a
                          href=""
                          class="mx-2"
                          @click.prevent.stop="
                            confirmDeleteBranch();
                            showSuc = false;
                            Todelete = data.data;
                          "
                        >
                          <v-icon color="error">mdi-delete</v-icon>
                        </a>
                      </div>
                    </template>
                  </DxDataGrid>
                </GeneralFilter>
              </div>
            </v-card>
          </v-dialog>
        </div>

        <v-col cols="12" class="box my-4">
          <v-form v-model="valid">
            <v-row class="my-4 ml-2 mr-2">
              <v-col cols="12" class="tabsNh">
                <v-col
                  cols="12"
                  v-if="branch.ID_Branch == null"
                  md="12"
                  class="d-flex justify-end"
                >
                  <v-btn small class="btnblue mr-1" @click="showSuc = !showSuc">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    v-if="branch.ID_Branch == null && business.NoMultipleBranch == 0"
                    class="btnsave"
                    @click="addBranch()"
                  >
                    <v-icon> mdi-content-save</v-icon>
                  </v-btn>
                </v-col>
                <v-col v-else class="d-flex justify-space-between">
                  <v-btn
                    small
                    color="error"
                    style="margin-right: 0.3rem"
                    @click="confirmDeleteBranch()"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <div>
                    <!-- <v-btn small class="mr-1 btn-add" style="margin-right: .3rem;" @click="showModalInfoInvoices(edit);">
                        <v-icon >mdi-store-edit</v-icon>
                      </v-btn> -->
                    <v-btn small class="btnblue mr-1" @click="showSuc = !showSuc">
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                    <v-btn
                      small
                      class="btnclean"
                      style="margin-right: 0.3rem"
                      @click="cleanBranch()"
                    >
                      <v-icon>mdi-broom</v-icon>
                    </v-btn>
                    <v-btn
                      small
                      v-if="branch.ID_Branch != null"
                      class="btnsave"
                      @click="updateBranch()"
                    >
                      <v-icon> mdi-content-save</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-col>
            </v-row>
            <v-row class="ml-2 mr-2" style="margin-top: 2rem; margin-bottom: 1rem">
              <v-col cols="12" md="2">
                <div>
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      width: 80%;
                      margin-left: 10%;
                    "
                  >
                    <v-img :src="url" class="preview-avatar"></v-img>
                  </div>
                  <v-file-input
                    @change="Preview_image"
                    v-model="image"
                    class="hide-details"
                    label="Imagen factura"
                    prepend-icon="mdi-camera"
                    flat
                    solo
                    placeholder="Buscar"
                    :rules="[maxFileSize]"
                  >
                  </v-file-input>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    width: 80%;
                    margin-left: 10%;
                  "
                >
                  <v-img :src="sellourl" class="preview-sello"></v-img>
                </div>
                      <v-file-input @change="Preview_sello" v-model="sello" label="Sello" prepend-icon="mdi-camera"
                      flat solo placeholder="Buscar" :rules="[maxFileSize]">
                      </v-file-input>
                <div v-if="TipoPlan != 'Emprendedor' && TipoPlan != 'Basic'">
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      width: 80%;
                      margin-left: 10%;
                    "
                  >
                    <v-img :src="urlBanner" class="preview-avatar"></v-img>
                  </div>
                  <v-file-input
                    @change="Preview_urlBanner"
                    v-model="banner"
                    class="hide-details"
                    label="Banner para facturas"
                    prepend-icon="mdi-camera"
                    flat
                    solo
                    placeholder="Buscar"
                    :rules="[maxFileSize]"
                  >
                  </v-file-input>
                  <v-btn @click="clearFile" class="btnsave" style="width: 100%; margin-top: -30px !important; margin-bottom: 30px;">Eliminar Imagen Seleccionada</v-btn>
                </div>
                      <div class="d-flex" style="justify-content: center;">
                        <v-checkbox v-if="edit" label="Activar modo contingencia" v-model="branch.ActiveContingency" @click="activeContingency()"></v-checkbox>
                      </div>
                      <!--div class="d-flex" style="justify-content: center;  ">
                        <v-btn class="btn-add" v-if="edit" @click="showModalInfoInvoices(edit);">
                          Gestionar correlativos 
                        </v-btn>
                      </div-->
                      <br/>
                      <div class="d-flex" style="justify-content: center; ">
                        <v-btn class="btn-add" style="padding: 20px;" v-if="edit" @click="showInfoFe = true;">
                          Auth. Facturación <br/> Electrónica 
                        </v-btn>
                      </div>
                      <br/>
                      <div class="d-flex" style="justify-content: center; ">
                        <v-btn class="btn-add" style="padding: 20px;" v-if="edit" @click="fixControlNumber()">
                          Corregir número <br/> de control 
                        </v-btn>
                      </div>
                  
                </v-col>
               
        
                <v-col cols="12" md="10">
                  <v-row>
                    <v-col cols="12" sm="12" md="12" style="margin-top: 1rem;">
                <h3>Datos Principales</h3>
                <label>Nota: si el pais seleccionado no es el salvador unicamente nombre de la sucursal,  el nombre legal de la sucursal y la dirección legal de la sucursal son necesarios</label>

              </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field outlined dense v-model="branch.BranchName" label="Nombre sucursal*" :rules="requiredRule"
                        placeholder="Digite el nombre de la sucursal" required></v-text-field>
                    </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field
                      outlined
                      dense
                      v-model="branch.BranchPhone"
                      label="Télefono sucursal*"
                      @input="maskNum"
                      :rules="checkcountry ? requiredRule : noRules"
                      required
                      placeholder="Digite el télefono de la sucursal"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field
                      outlined
                      dense
                      v-model="branch.BranchEmail"
                      label="Correo electrónico sucursal*"
                      :rules="checkcountry ? requiredRule : noRules"
                      required
                      placeholder="Digite el correo de la sucursal"
                    ></v-text-field>
                  </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field outlined dense  v-model="branch.BranchMobile" label="Celular sucursal" @input="maskNum"
                        placeholder="Digite el télefono celular de la sucursal"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field outlined dense  v-model="branch.BranchAddress" label="Dirección sucursal*"
      :rules="checkcountry ? requiredRule : noRules" required
                        placeholder="Digite la dirección de la sucursal"></v-text-field>
                    </v-col>
                    <v-col cols="6" md="2">
                      <v-select outlined dense  :items="getcurrency" label="Moneda*" attach
                        :rules="checkcountry ? requiredRule : noRules" required

                        v-model="branch.currency" item-text="name" item-value="value"></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="pb-3"  style="margin-top: 1rem;">
                <h3>Gerente sucursal</h3>
              </v-col>
              <v-col cols="12" md="3">
                      <v-text-field outlined dense v-model="branch.BranchManagerName" label="Nombre gerente*"
      :rules="checkcountry ? requiredRule : noRules" required

                        placeholder="Digite el nombre de gerente de la sucursal"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field outlined dense v-model="branch.BranchManagerPhone" label="Télefono gerente*" @input="maskNum"
      :rules="checkcountry ? requiredRule : noRules" required
                        placeholder="Digite el télefono del gerente de la sucursal"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field outlined dense v-model="branch.BranchManagerMobile" label="Celular gerente" @input="maskNum"
                        placeholder="Digite el télefono celular del gerente">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field outlined dense v-model="branch.BranchManagerEmail" label="Correo eléctronico gerente*"
      :rules="checkcountry ? requiredRule : noRules" required

                        placeholder="Digite el correo eléctronico del gerente">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="pb-3" style="margin-top: 1rem;">
                <h3>Contacto directo sucursal</h3>
              </v-col>
              <v-col cols="12" md="3">
                      <v-text-field outlined dense  v-model="branch.BranchContactName" label="Nombre contacto*"
                      
                        placeholder="Digite un nombre de contacto para la sucursal"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field outlined dense  v-model="branch.BranchContactPhone" label="Télefono contacto*" @input="maskNum"
      :rules="checkcountry ? requiredRule : noRules" required

                        placeholder="Digite un télefono de contacto para la sucursal"></v-text-field>
                    </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field
                      outlined
                      dense
                      v-model="branch.BranchContactMobile"
                      label="Celular contacto"
                      @input="maskNum"
                      placeholder="Digite un télefono celular de contacto para la sucursal"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      outlined
                      dense
                      v-model="branch.BranchContactEmail"
                      label="Correo eléctronico contacto*"
                      :rules="checkcountry ? requiredRule : noRules"
                      required
                      placeholder="Digite un correo eléctronico de contacto para la sucursal"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="pb-3" style="margin-top: 0.6rem">
                    <h3 >Información de facturación de sucursal</h3>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      outlined
                      dense
                      v-model="branch.BranchLegalName"
                      label="Nombre legal de la sucursal*"
                      required
                      :rules="requiredRule"
                      placeholder="Digite el nombre legal de la sucursal"
                    ></v-text-field>
                  </v-col>
                  <!--   <v-col cols="12" md="2">
                      <v-text-field outlined dense  v-model="branch.BranchLegalNRC" label="NRC Legal de la sucursal*"
                      required
                      v-if="false"
                       :rules="checkcountry ? requiredRule : noRules"
                        placeholder="Digite el NRC legal de la sucursal"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field outlined dense  v-model="branch.BranchLegalNIT" label="NIT legal de la sucursal*"
                      v-if="false"
                        placeholder="Digite el NIT legal de la sucursal">
                      </v-text-field>
                    </v-col> -->
                  <v-col cols="12" md="5">
                    <v-text-field
                      outlined
                      dense
                      v-model="branch.BranchLegalAddress"
                      label="Dirección legal de la sucursal*"
                      required
                      :rules="requiredRule"
                      placeholder="Digite la dirección legal de la sucursal"
                    >
                    </v-text-field>
                  </v-col>
                  <!--  <v-col cols="12" md="12" style="margin-top: .6rem;">
                    <h3>Información de facturación Electronica</h3>
                  
                  </v-col> -->
                  <!--    <v-col cols="12" md="6">
                    <v-autocomplete outlined dense  :items="activities"  item-text="Valores"
                      v-if="false" attach
      :rules="checkcountry ? requiredRule : noRules" required 
      item-value="Codigo" v-model="branch.codactividad" label="Descripción de la actividad económica*"
                     ></v-autocomplete>
                  </v-col> -->
                  <v-col cols="12" md="4">
                    <v-autocomplete
                      outlined
                      dense
                      :items="countryList"
                      item-text="name"
                      :rules="checkcountry ? requiredRule : noRules"
                      required
                      attach
                      @change="getStates"
                      item-value="name"
                      v-model="branch.country"
                      label="País*"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-autocomplete
                      outlined
                      dense
                      v-if="checkcountry"
                      :items="departments"
                      attach
                      item-text="Valores"
                      item-value="Codigo"
                      v-model="branch.branchdepartment"
                      label="Estado/Departamento"
                    ></v-autocomplete>

                    <v-autocomplete
                      outlined
                      dense
                      v-else
                      :items="stateList"
                      attach
                      item-text="name"
                      v-model="branch.branchdepartment"
                      label="Estado/Departamento"
                      @change="getCities"
                    >
                      <template v-slot:no-data>
                        <div class="px-4">{{ noDataLabelStates }}</div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-autocomplete
                      outlined
                      dense
                      :items="municipalities"
                      item-text="Valores"
                      item-value="Codigo"
                      v-if="checkcountry"
                      v-model="branch.branchmunicipality"
                      label="Ciudad"
                      attach
                    ></v-autocomplete>
                    <v-autocomplete
                      outlined
                      dense
                      :items="cityList"
                      v-else
                      :readonly="!branch.branchdepartment"
                      item-text="name"
                      attach
                      v-model="branch.branchmunicipality"
                      label="Ciudad"
                    >
                      <template v-slot:no-data>
                        <div class="px-4">{{ noDataLabelCities }}</div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-autocomplete
                      outlined
                      dense
                      item-text="Valores"
                      item-value="Codigo"
                      :items="establisment"
                      v-model="branch.establishmenttype"
                      label="Tipo establecimiento*"
                      attach
                      :rules="checkcountry ? requiredRule : noRules"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3" style="display: none;">
                    <v-text-field outlined dense v-model="branch.codestable" :maxlength="4" label="Codigo establecimiento* " attach
      :rules="checkcountry ? requiredRule : noRules" required
                    
                    readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      outlined
                      dense
                      :rules="checkcountry ? requiredRule : noRules"
                      required
                      v-model="branch.codestablemh"
                      :maxlength="4"
                      label="Codigo establecimiento MH*"
                    ></v-text-field>
                  </v-col>
                  <!--v-col cols="12" md="3">
                    <v-text-field
      :rules="checkcountry ? requiredRule : noRules" required
                    v-model="branch.codpuntoventamh" :maxlength="4" label="Codigo punto venta MH*"></v-text-field>
                  </v-col> -->
                  <!--v-col cols="12" md="3">
                    <v-text-field outlined dense v-model="branch.codpuntoventa" :maxlength="15" label="Codigo punto venta*"
      :rules="checkcountry ? requiredRule : noRules" required
                    
                    ></v-text-field>
                  </v-col-->
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-combobox
                      v-model="BccEmails"
                      :items="BccEmails"
                      multiple
                      chips
                      outlined
                      attach
                      label="Enviar facturas a (como copias ocultas)"
                      clearable
                      @blur="validateEmails"
                      :rules="[emailRule]"
                    ></v-combobox>
                  </v-col>
                </v-row>

                <v-row v-if="TipoPlan != 'Emprendedor'">
                  <div
                  :class="`${showBgcolorNotes} mt-3 pa-2`"
                  @click="showComment = !showComment"
                  >
                    <center>Información personalizada en impresión de factura</center>
                  </div>
                  <v-dialog v-model="showComment" max-width="400">
                    <v-card>
                      <v-card-title class="text-h5"
                        >Información personalizada en impresión de factura</v-card-title
                      >
                      <v-card-text>
                        <quill-editor
                          :options="quillOptions"
                          class="mt-5"
                          @change="onEditorChange($event)"
                          v-model="branch.BranchBilledInfo"
                        ></quill-editor>
                      </v-card-text>
                      <v-card-actions>
                        <v-col class="text-right">
                          <v-btn
                            @click="showComment = !showComment"
                            class="btndisable"
                          >
                            Continuar
                          </v-btn>
                        </v-col>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog
      v-model="showInfoInvoices"
      persistent
      scrollable
      max-width="600px"
      style="z-index: 100"
    >
      <v-card height="inherit" style="overflow-y: auto;">
        <v-card-title class="text-h5">
          Información de correlativos de facturación
        </v-card-title>
        <v-card-text>
          <p>
            Esta información es indispensable para proceder a realizar facturas en el POS.
          </p>
          <v-tabs v-model="tab">
            <v-tab v-for="item in items" :key="item" @click="loadInfoInvoices(item)">
              {{ item }}
            </v-tab>

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in items" :key="item">
                <v-row>
                  <v-col cols="12">
                    <br />
                    <br />
                    <v-checkbox
                      dense
                      label="¿Correlativo activo?"
                      @change="
                        invoice.CurrentCorrelativeActive = !invoice.CurrentCorrelativeActive
                      "
                      :value="invoice.CurrentCorrelativeActive == 1 ? 1 : 0"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12">
                    <v-checkbox
                      dense
                      @change="invoice.IsGlobalBranch = !invoice.IsGlobalBranch"
                      label="¿Es una sucursal global?"
                      :value="invoice.IsGlobalBranch == 1 ? 1 : 0"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      outlined
                      dense
                      label="Prefijo de correlativos de facturas"
                      v-model="invoice.InvoiceCorrelativesPrefix"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      outlined
                      dense
                      label="Comienzo de rango de correlativos"
                      v-model="invoice.InvoiceCorrelativesRange_Start"
                      type="number"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      outlined
                      dense
                      label="Fin de rango de correlativos"
                      v-model="invoice.InvoiceCorrelativesRange_Finish"
                      type="number"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      outlined
                      dense
                      label="Correlativo actual del rango"
                      v-model="invoice.InvoiceCorrelativesRange_Actual"
                      type="number"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      outlined
                      dense
                      label="Autorización legal"
                      v-model="invoice.InvoiceCorrelativesLegalAuthorization"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <date-picker
                      label="Fecha de autorización legal"
                      @dateSelected="
                        (param) =>
                          (invoice.InvoiceCorrelativesLegalAuthorizationDate = param)
                      "
                      dense
                    >
                    </date-picker>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      outlined
                      dense
                      label="Autorización legal de imprenta"
                      v-model="invoice.InvoiceCorrelativesLegalAuthorizationPrintery"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <date-picker
                      label="Fecha de autorización legal de imprenta"
                      @dateSelected="
                        (param) => (invoice.InvoiceCorrelativesLegalDateOfPrint = param)
                      "
                      dense
                    >
                    </date-picker>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-card-text>

        <v-card-actions>
          <v-btn class="btnsave" @click="saveOrUpdateInfoInvoices(tab)">
            Actualizar
          </v-btn>
          <v-btn class="btn-add" dark @click="closeModalInfoInvoices()"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
      <alerts
        v-if="alert.show && !showInfoFe"
        v-on:close_alert="closeAlert"
        v-on:accept_alert="acceptAlert"
        :properties="alert"
      >
      </alerts>
    </v-dialog>

    <v-dialog v-model="showInfoFe" scrollable max-width="600px" style="z-index: 100">
      <v-card height="inherit">
        <v-card-title> Autenticación de Facturación Electrónica </v-card-title>
        <v-card-text>
          <label
            v-if="authfedata.status != '' && authfedata.status != null"
            class="label success"
            >Ya se ha guardado información de facturación electrónica previamente.</label
          >
          <label v-else class="label danger"
            >No se ha guardado información de facturación electrónica previamente</label
          >
          <v-col>
            <v-text-field
              outlined
              dense
              v-model="authfedata.AuthFeUser"
              type="text"
              label="NIT"
            >
            </v-text-field>
          </v-col>

          <v-col>
            <v-text-field
              outlined
              dense
              v-model="authfedata.AuthFePassword"
              type="password"
              label="Contraseña de autenticación"
            >
            </v-text-field>
          </v-col>

          <v-col>
            <v-text-field ref="urlfirm" outlined dense v-model="authfedata.UrlFirmador" type="text" label="URL Firmador DTE">
            </v-text-field>
          </v-col>

          <v-col>
            <v-text-field
              outlined
              dense
              v-model="authfedata.AuthFePasswordPri"
              type="password"
              label="Contraseña privada para firmador"
            >
            </v-text-field>
          </v-col>

          <v-col>
            <v-radio-group v-model="authfedata.AuthFeMode" @change="changeFirmadorUrl">
              <v-radio label="Modo de prueba" value="0"></v-radio>
              <v-radio label="Modo de producción" value="1"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col>
            <v-checkbox
              v-model="authfedata.AuthFeActive"
              :true-value="1"
              :false-value="0"
              label="Activar Factura Electrónica en POS"
            />
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn class="btnsave" @click="saveInfoFe()"> Guardar </v-btn>
          <v-btn class="btn-add" dark @click="showInfoFe = false"> Cancelar </v-btn>
        </v-card-actions>
      </v-card>
      <alerts
        v-if="alert.show && !showInfoInvoices"
        v-on:close_alert="closeAlert"
        v-on:accept_alert="acceptAlert"
        :properties="alert"
      >
      </alerts>
    </v-dialog>

    <alerts
      v-if="alert.show && !showInfoInvoices && !showInfoFe"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    >
    </alerts>
  </v-card>
</template>

<script>
import GeneralFilter from "@/components/GeneralFilter";
import Vue from "vue";
import { DxDataGrid, DxColumn } from "devextreme-vue/data-grid";
import DatePicker from "../../../../components/DatePicker";
import Alerts from "@/components/Alerts";
import { mapGetters } from "vuex";
import Vuex from "../../../../store";
import { numberSV } from "@/helpers/masks";

export default {
  name: "ManageBranches",
  components: { DxDataGrid, DxColumn, DatePicker, Alerts, GeneralFilter },
  props: ["business", "win"],
  data: () => ({
    currencies: [],
    showSuc: false,
    alert: {
      type: "success",
      show: false,
      header: "",
      body: "",
    },
    /*    departments: ['San salvador','Santa Ana', 'Soyapango'],
    municipalities: ['San salvador','Santa Ana', 'Soyapango','San Miguel'],
    activities: [
      {
        Codigo: '01131',
        Valores: 'AGSP-PAPCASC : Cultivo de raíces y tubérculos',
      },
      {
        Codigo: '01191',
        Valores: 'AGSP-PAPCASC : Cultivo de plantas no perennes para la producción de semillas y flores',
      },
      {
        Codigo: '01619',
        Valores: 'AGSP-PAPCASC : Servicios agrícolas ncp'
      },
      {
        Codigo: '62020',
        Valores: 'IC-PICIAC : Consultorías y gestión de servicios informáticos'
      }
    ],
    establisment: [
      {
        Codigo: '01',
        Valores: 'Sucursal / Agencia',
      },
      {
        Codigo: '02',
        Valores: 'Casa matriz',
      }
    ], */
    Todelete: {},
    valid: false,
    id_user_login: null,
    branch: {},
    branches: [],
    invoice: {},
    image: null,
    sello: null,
    url: null,
    sellourl: null,
    showInfoInvoices: false,
    showInfoFe: false,
    tab: null,
    authfedata: {},
    items: ["Crédito Fiscal", "Consumidor final", "Nota de crédito", "Nota de débito"],
    itemSelected: null,
    edit: null,
    tmpid_branch: null,
    requiredRule: [(value) => !!value || "El dato es requerido."],
    noRules: [
      // eslint-disable-next-line no-unused-vars
      (value) => true,
    ],
    stateList: [],
    cityList: [],
    noDataLabelStates: "Seleccione un país",
    noDataLabelCities: "No hay ciudades disponibles",
    search: {
      filter: null,
    },
    validImageSize: true,
    banner: null,
    urlBanner: null,
    showBgcolorNotes: "tabs",
    showComment: false,
    BccEmails: [],
    emailRule: value => this.isValidEmails(value) || 'Por favor ingrese solo direcciones de correo validas.',
  }),
  mounted() {
    this.TipoPlan = JSON.parse(localStorage.getItem("user")).TipoPlan;
    this.id_user_login = JSON.parse(localStorage.getItem("user")).id;
    this.invoice.ID_User_Insert = this.id_user_login;
    this.invoice.ID_User_Update = this.id_user_login;
    this.invoice.ID_Business = this.business.ID_Business;
    this.branch.codestable = JSON.parse(localStorage.getItem("user")).branch;
    this.getBranches();
  },
  computed: {
    ...mapGetters(["getCatalogMH"]),
    departments() {
      return this.getCatalogMH("Departamento");
    },
    municipalities() {
      if (this.branch.branchdepartment == null) return [];
      const municipalities = this.getCatalogMH("Municipio");

      if (this.branch.branchdepartment) {
        const department = municipalities.filter(
          (mun) => mun.Departamento == this.branch.branchdepartment
        );

        return department;
      }
      return [];
    },
    establisment() {
      return this.getCatalogMH("TipoEstablecimiento");
    },
    activities() {
      return this.getCatalogMH("ActividadEconomica");
    },
    countryList() {
      return this.$store.getters.getCountries;
    },
    checkcountry() {
      if (this.branch.country == "El Salvador") return true;
      else return false;
    },
    getcurrency() {
      const countries = this.$store.getters.getCountries;
      const currencies = countries.map((country) => {
        return {
          name: country.currency_name,
          value: country.currency_symbol,
          currentcountry: country.name,
        };
      });

      return currencies;
    },
  },
  methods: {
    onEditorChange(e) {
      this.branch.BranchBilledInfo = e.html;
      if (this.branch.BranchBilledInfo != "") {
        this.showBgcolorNotes = "tabsContent";
      } else if (
        this.branch.BranchBilledInfo == "" ||
        this.branch.BranchBilledInfo == "<p><br></p>"
      ) {
        this.showBgcolorNotes = "tabs";
      } else {
        this.showBgcolorNotes = "tabs";
      }
    },
    clearFile() {
      this.banner = null
      this.urlBanner = null
    },
    changeFirmadorUrl(e) {
      try {
        if( e === '0' ) {
          this.authfedata.UrlFirmador = 'https://firmadorprueba.innovatebusiness.net/firma/firmardocumento/'
          this.$refs.urlfirm.value = 'https://firmadorprueba.innovatebusiness.net/firma/firmardocumento/';
        } else if( e === '1' ) {
          this.authfedata.UrlFirmador = 'https://firmadorprod.innovatebusiness.net/'
          this.$refs.urlfirm.value = 'https://firmadorprod.innovatebusiness.net/';
        }
        // console.log(this.authfedata.UrlFirmador);
        
      } catch (error) {
        console.log(error);
      }
    },
    async getStates(){
      if(this.branch.country == 'El Salvador') return [];
      if(this.branch.country){
        this.noDataLabelStates = 'Cargando...'
        this.stateList = []
        
        this.stateList = await this.$API.general.getStates(this.branch.country)

        this.noDataLabelStates = "No hay Estados disponibles";
      }
    },
    async getCities() {
      if (this.branch.country == "El Salvador") return [];
      console.log(this.branch.branchdepartment);
      if (this.branch.branchdepartment) {
        this.noDataLabelCities = "Cargando...";
        this.cityList = [];

        this.cityList = await this.$API.general.getCities(this.branch.branchdepartment);
        this.noDataLabelCities = "No hay Ciudades disponibles";
      }
    },
    async maskNum() {
      if (this.branch.BranchContactMobile && this.branch.country == "El Salvador") {
        this.branch.BranchContactMobile = await numberSV(
          this,
          this.branch.BranchContactMobile
        );
      }
      if (this.branch.BranchMobile && this.branch.country == "El Salvador") {
        this.branch.BranchMobile = await numberSV(this, this.branch.BranchMobile);
      }
      if (this.branch.BranchManagerMobile && this.branch.country == "El Salvador") {
        this.branch.BranchManagerMobile = await numberSV(
          this,
          this.branch.BranchManagerMobile
        );
      }
      if (this.branch.BranchManagerPhone && this.branch.country == "El Salvador") {
        this.branch.BranchManagerPhone = await numberSV(
          this,
          this.branch.BranchManagerPhone
        );
      }
      if (this.branch.BranchPhone && this.branch.country == "El Salvador") {
        this.branch.BranchPhone = await numberSV(this, this.branch.BranchPhone);
      }
      if (this.branch.BranchContactPhone && this.branch.country == "El Salvador") {
        this.branch.BranchContactPhone = await numberSV(
          this,
          this.branch.BranchContactPhone
        );
      }
    },
    getcurrencybyCountry() {
      this.countryList.forEach((country) => {
        if (country.name == this.branch.country) {
          this.branch.currency = country.currency_symbol;
        }
      });
    },
    asignDescription() {
      this.activities.forEach((element) => {
        if (element.Codigo == this.branch.codactividad) {
          this.branch.descactividad = element.Valores;
        }
      });
    },
    closeAlert() {
      this.alert.show = false;
    },
    acceptAlert() {
      this.alert.show = false;
      if (this.alert.options == "closeSesion") {
        this.logout();
      }

      if (this.alert.options == "deletecustomer") {
        this.deleteCustomer();
      }
      if (this.alert.options == "deletebranch") {
        this.deleteBranch(this.Todelete);
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    Preview_image() {
      console.log(URL.createObjectURL(this.image));
      this.url = URL.createObjectURL(this.image)
    },
    Preview_sello() {
      console.log(URL.createObjectURL(this.sello));
      this.sellourl = URL.createObjectURL(this.sello)
    },
    Preview_urlBanner() {
      console.log(URL.createObjectURL(this.banner));
      this.urlBanner = URL.createObjectURL(this.banner)
    },
    getBranches() {
      this.search.ID_Business = this.business.ID_Business;
      this.$API.branches
        .findBranchesHistory({ ID_Business: this.business.ID_Business })
        .then((data) => {
          this.branches = data;
        })
        .catch(() => {
          this.showAlert(
            "danger",
            "Error",
            "Ha ocurrido un error en obtener las sucursales del negocio."
          );
        });
    },
    async selectedBranch(data) {
      const newData = Object.assign({}, data); // Crea una copia superficial del objeto data

      // dont forget to pass values

      newData.codactividad = newData.CodActividad;
      newData.branchdepartment = newData.BranchDepartment;
      newData.branchmunicipality = newData.BranchMunicipality;
      newData.establishmenttype = newData.EstablishmentType;
      newData.codestable = newData.CodEstable;
      newData.codestablemh = newData.CodEstableMH;
      newData.codpuntoventa = newData.CodPuntoVenta;
      newData.codpuntoventamh = newData.CodPuntoVentaMH;
      newData.currency = newData.Currency;
      newData.country = newData.Country;
      newData.accountingModule = newData.AccountingModule;

      this.Todelete = newData;
      this.edit = newData;
      this.branch = newData;
      this.image = newData.BranchLogoPath;
      //const { imageBase64: imagenFactura } = await this.$API.utilities.getImageInBase64({ "file": this.image });
      this.url = this.image;
      if( newData.sello ) {
        //const { imageBase64: imagenSello } = await this.$API.utilities.getImageInBase64({ "file": newData.sello });
        this.sello = newData.sello;
      }
      this.sellourl = this.sello;

      this.banner = newData.BranchBannerPath
      this.urlBanner = this.banner

      this.branch.codestable = newData.ID_Branch;

      if(this.branch.BranchBilledInfo != null && this.branch.BranchBilledInfo != "") {
        this.showBgcolorNotes = "tabsContent"
      }

      if(this.branch.BccEmails != null && this.branch.BccEmails != "") {
        this.BccEmails = this.branch.BccEmails.split(',').map(email => email.trim());
      }

      await this.getInfoFe();
    },
    cleanBranch() {
      this.branch = {};
      this.branch.codestable = JSON.parse(localStorage.getItem("user")).branch;
      this.url = null
      this.sellourl = null
      this.urlBanner = null
      this.BccEmails = []
      this.edit = null
      this.Todelete = {};
      this.edit = null;
    },
    logout() {
      // this.delete_cookie('details')
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("customizeOptions");
      localStorage.removeItem("branch");

      Vuex.commit("setToken", null);
      Vuex.commit("setUser", null);
      Vuex.commit("setCustomizeOptions", null);
      Vuex.commit("RESTART_STATE", null);
      localStorage.clear();
      this.$router.push("/login");
    },
    addBranch() {
      if (this.checkImageSize()) return;

      if (!this.valid) {
        this.showAlert("warning", "Error", "Debe completar los campos obligatorios.");
        return;
      }
      this.branch.codpuntoventamh = this.branch.codpuntoventa;
      //this.branch.codestablemh = this.branch.codestable
      this.asignDescription();
      this.branch.currency = this.branch.currency
        ? this.branch.currency
        : this.business.Currency;
      const formdata = new FormData();
      Vue.set(this.branch, "ID_User_Insert", this.id_user_login);
      Vue.set(this.branch, "ID_Business", this.business.ID_Business);
      this.branch.BccEmails = this.BccEmails.join(', ')
      formdata.append("branch", JSON.stringify(this.branch));
      formdata.append("file", this.image);
      formdata.append("sello", this.sello);
      formdata.append("banner", this.banner);
      this.$API.branches
        .addBranch(formdata)
        .then(() => {
          this.showAlert(
            "closeeSesion",
            "exito ",
            "Se ha agregado la nueva sucursal al negocio.Para que los cambios surtan efecto debe cerrar sesion",
            "closeSesion"
          );
          this.branch = {};
          this.image = null;
          this.url = null;
          this.sello = null;
          this.sellourl = null;

          this.getBranches();
        })
        .catch(() => {
          this.showAlert(
            "danger",
            "Error",
            "Ha ocurrido un error en agregar la sucursal al negocio."
          );
        });
    },
    updateBranch() {
      if (this.checkImageSize()) return;

      if (!this.valid) {
        this.showAlert("warning", "Error", "Debe completar los campos obligatorios.");
        return;
      }
      this.branch.codpuntoventamh = this.branch.codpuntoventa;
      //this.branch.codestablemh = this.branch.codestable
      this.asignDescription();
      const formdata = new FormData();
      Vue.set(this.branch, "ID_User_Update", this.id_user_login);
      this.branch.BccEmails = this.BccEmails.join(', ')
      /*const obj = {
        branch: JSON.stringify(this.branch),
        file: this.image,
        sello: this.sello,
        banner: this.banner
      }*/
      formdata.append("branch", JSON.stringify(this.branch));
      formdata.append("file", this.image);
      formdata.append("sello", this.sello);
      formdata.append("banner", this.banner);
      this.$API.branches
        .updateBranch(formdata)
        .then((data) => {
          if (data.operation == true) {
            this.showAlert(
              "closeeSesion",
              "exito ",
              "Se ha actualizado la sucursal al negocio.Para que los cambios surtan efecto debe cerrar sesion",
              "closeSesion"
            );

            this.getBranches();
            this.branch = {};
            this.url = null
            this.sellourl = null
            this.urlBanner = null
            this.BccEmails = []
            this.edit = null
          } else {
            this.showAlert(
              "danger",
              "Error",
              "Ha ocurrido un error en actualizar la sucursal."
            );
          }
        })
        .catch(() => {
          this.showAlert(
            "danger",
            "Error",
            "Ha ocurrido un error en actualizar la sucursal."
          );
        });
    },
    deleteBranch(data) {
      this.$API.branches.logicalDeleteBranch(data.ID_Branch)
        .then((res) => {
          console.log(res);
          this.showAlert("success", "Exito", "Se ha eliminado la sucursal seleccionada." );
          this.getBranches();
          this.branch = {};
          this.Todelete = {};
        })
        .catch((err) => {
          console.log(err);
          this.showAlert("danger", "Error", "Ha ocurrido un error en eliminar la sucursal seleccionada.");
        });
    },
    confirmDeleteBranch() {
      this.showAlert("question", "Advertencia", "¿Está seguro de eliminar la sucursal seleccionada?", "deletebranch");
    },

    showModalInfoInvoices(data) {
      this.showInfoInvoices = true;
      this.tmpid_branch = data.ID_Branch;
      this.loadInfoInvoices(this.itemSelected);
    },

    setInvoiceType() {
      if (this.itemSelected == "Crédito Fiscal" || this.itemSelected == null) {
        this.invoice.InvoiceType = "CCF";
      }

      if (this.itemSelected == "Consumidor final") {
        this.invoice.InvoiceType = "FCF";
      }

      if (this.itemSelected == "Nota de crédito") {
        this.invoice.InvoiceType = "NC";
      }

      if (this.itemSelected == "Nota de débito") {
        this.invoice.InvoiceType = "ND";
      }
    },

    loadInfoInvoices(invoicetype) {
      this.cleanDataInvoice();
      this.invoice.ID_Branch = this.tmpid_branch;
      this.itemSelected = invoicetype;
      this.setInvoiceType();
      const formdata = new FormData();
      formdata.append("invoice", JSON.stringify(this.invoice));
      this.$API.invoices.loadInfoInvoice(formdata).then((response) => {
        if (response) {
          this.invoice = response;
        }
      });
    },

    saveOrUpdateInfoInvoices() {
      if (this.invoice.CurrentCorrelativeActive == undefined) {
        this.invoice.CurrentCorrelativeActive = 0;
      }

      if (this.invoice.IsGlobalBranch == undefined) {
        this.invoice.IsGlobalBranch = 0;
      }

      this.setInvoiceType();

      const formdata = new FormData();

      formdata.append("invoice", JSON.stringify(this.invoice));

      this.$API.invoices
        .saveOrUpdateInfoInvoices(formdata)
        .then((response) => {
          if (response.operation == "insert") {
            this.showAlert(
              "success",
              "Exito",
              "Información de correlativos de facturación agregada"
            );
          }

          if (response.operation == "update") {
            this.showAlert(
              "success",
              "Exito",
              "Información de correlativos de facturación actualizada"
            );
          }

          this.invoice = response.data;
        })
        .catch((response) => {
          console.log(response);
          this.showAlert(
            "danger",
            "Error",
            "Ha ocurrido un error en guardar la información de correlativos de facturación"
          );
        });
    },

    cleanDataInvoice() {
      this.invoice = {};
      this.invoice.ID_User_Insert = this.id_user_login;
      this.invoice.ID_User_Update = this.id_user_login;
      this.invoice.ID_Business = this.business.ID_Business;
    },

    closeModalInfoInvoices() {
      this.cleanDataInvoice();
      this.tmpid_branch = null;
      this.showInfoInvoices = false;
    },

    saveInfoFe() {
      this.authfedata.ID_Business = this.branch.ID_Business;
      this.authfedata.ID_Branch = this.branch.ID_Branch;

      this.$API.branches.updateInfoMh(this.authfedata).then((response) => {
        if(response.token != '' && response.token != null){
          this.showAlert("success", "Exito", response.message);
          localStorage.setItem("FaMh", JSON.stringify({ AuthFeActive: 1}))
          //this.$store.commit("setFaMh", response)
        }
        else{
          this.showAlert("success", "Exito (error autenticación)", response.message);
          localStorage.setItem("FaMh", JSON.stringify({ AuthFeActive: 0}))
          //this.$store.commit("setFaMh", response)
        }

      })
      .catch(() => {
        this.showAlert("danger", "Error", "No se pudo establecer comunicación con el ministerio de hacienda, se activará modo contingencia.");
      })
    },

    getInfoFe() {
      this.authfedata = {};
      const search = {
        ID_Business: this.branch.ID_Business,
        ID_Branch: this.branch.ID_Branch,
      };

      this.$API.branches.getInfoMh(search).then((response) => {
        this.authfedata.status = response.select.AuthFeActive ? "OK" : null;
        this.authfedata.AuthFeUser = response.select.AuthFeUser;
        this.authfedata.UrlFirmador = response.select.UrlFirmador;
        this.authfedata.AuthFeActive = response.select.AuthFeActive;
        this.authfedata.AuthFeMode = response.select.AuthFeMode.toString();
      });
    },

    filterHandler(response){
      this.branches = response
    },

    fixControlNumber() {
      this.$API.branches.fixControlNumber().then((response) => {
        if(response.status == "OK") {
          this.showAlert("success", "Exito", "Se ha corregido el número de control exitosamente.");
        }
        else {
          this.showAlert("danger", "Error", "Hubo un problema corrigiendo el número de control, por favor íntentelo nuevamente.")
        }
      })
    },

    activeContingency() {
      this.$API.branches.activeContingency({ branch: this.branch }).then((response) => {
        if(response.status == "OK") {
          this.branch.ActiveContingency = response.mode
          if(response.mode) {
            this.showAlert("success", "Exito", "Se activo el modo contingencia para la sucursal seleccionada.");
          } else {
            this.showAlert("success", "Exito", "Se desactivo el modo contingencia para la sucursal seleccionada.")
          }

          this.getBranches()
        }
        else {
          this.showAlert("danger", "Error", "Hubo un problema al activar o desactivar el modo de contingencia, por favor intentelo nuevamente.")
        }
      })
    },
    maxFileSize(event) {
      const allowedSize = 2 * 1024 * 1024; // 2 MB in bytes
      const fileSize = event.size;

      if (fileSize > allowedSize) {
        this.validImageSize = false;
        return `El tamaño del archivo debe ser menor a 2 Mb`;
      }

      this.validImageSize = true;
      return true;
    },
    checkImageSize() {
      if (this.validImageSize == false) {
        this.showAlert("danger", "Error", "El tamaño de la imagen debe ser menor a 2 Mb");
        return true;
      }
      return false;
    },
    isValidEmails(value) {
      if (!value) return true;
      const emails = value.split(';');
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emails.every(email => emailPattern.test(email.trim()));
    },
    validateEmails() {
      this.BccEmails = this.BccEmails.filter(email => this.isValidEmails(email));
    }
  }
}
</script>

<style scoped>
.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
}

.btn-add {
  background-color: #3498db !important;
  color: white;
}
.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.btnblue {
  background-color: #1976d2 !important;
  color: white;
}

.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;

  border-radius: 15px;
}
.tabsB {
  width: 100%;
  padding: 10px;
  background-color: #1976d2;
  color: white;
  border-radius: 15px;
  border-style: solid;
  border-color: black;
  border-width: 1px;
}
.tabsB:hover {
  background-color: #0d47a1;
  cursor: pointer;
}
.tabs {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}
.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}

.tabsContent:hover {
  background-color: #ffb266;
  cursor: pointer;
}

.tabsContent {
  width: 100%;
  padding: 10px;
  background-color: #f29d35;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}
</style>
