import axios from "./axios";

export default {
    getGeneralLedgerByDate(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getledger',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        console.log(options)
        return axios(options);
    },
    getDiaryByDate(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getdiary',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        console.log(options)
        return axios(options);
    },
    getGeneralBalance(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getgeneralbalance',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        return new Promise( ( resolve, reject ) => {
            axios(options)
                .then( response => {
                    const { data: responseResult } = response;

                    const { success } = responseResult;
                    
                    if( success ) {
                        const { data } = responseResult;
                        resolve( data );
                    } else {
                        const { message, status } = responseResult;

                        reject( { status, message } );
                    }
                } )
                .catch( error => {
                    reject(error )
                } );
        } ) ;
    },
    getVerificationBalance(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getverificationbalance',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        return new Promise( ( resolve, reject ) => {
            axios(options)
                .then( response => {
                    const { data: responseResult } = response;

                    const { success } = responseResult;
                    
                    if( success ) {
                        const { data } = responseResult;
                        resolve( data );
                    } else {
                        const { message, status } = responseResult;

                        reject( { status, message } );
                    }
                } )
                .catch( error => {
                    reject(error )
                } );
        } ) ;
    },
    getSalesByDate(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getsales',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        console.log(options)
        return axios(options);
    },
    getSalesTaxpayer(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getcsvsalestaxpayer',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        console.log(options)
        return axios(options);
    },
    getFinalConsumer(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getcsvfinalconsumer',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        console.log(options)
        return axios(options);
    },
    getCancelDocument(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getcsvcanceldocument',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        console.log(options)
        return axios(options);
    },
    getWithholdingVoucherReceipts(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getcsvwithholdingreceipts',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        console.log(options)
        return axios(options);
    },

    getPerception(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getcsvperception',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        console.log(options)
        return axios(options);
    },
    getAdvanceAccount(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getcsvadvancedAccount',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        console.log(options)
        return axios(options);
    },
    getPurchases(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getcsvpurchases',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        console.log(options)
        return axios(options);
    },
    
    getPurcharsesExcludedSubject(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getpurcharsesxcludedsubject',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        console.log(options)
        return axios(options);
    },
    getPurchasesByDate(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getpurchases',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        console.log(options)
        return axios(options);
    },
    getFilterSalesByDate(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getFiltersales',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        console.log(options)
        return axios(options);
    },
    getFilterPurchasesByDate(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getFilterpurchases',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        console.log(options)
        return axios(options);
    },
    getPurchaseOrderDateYears() {
        const options = {
            method: 'GET',
            url: '/accountingreports/getpurchaseorderdateyears',
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    if(response === undefined){
                        reject('No se obtuvo respuesta del servidor');
                    }
                    else if(response.data.success){
                        resolve(response.data);
                    }else{
                        reject(response.data);
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getPurchaseOrderDateMonths(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getpurchaseorderdatemonths',
            headers: {'content-type': 'application/json'},
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    if(response === undefined){
                        reject('No se obtuvo respuesta del servidor');
                    }
                    else if(response.data.success){
                        resolve(response.data);
                    }else{
                        reject(response.data);
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getSalesDateYears() {
        const options = {
            method: 'GET',
            url: '/accountingreports/getsalesdateyears',
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    if(response === undefined){
                        reject('No se obtuvo respuesta del servidor');
                    }
                    else if(response.data.success){
                        resolve(response.data);
                    }else{
                        reject(response.data);
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getSalesDateMonths(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getsalesdatemonths',
            headers: {'content-type': 'application/json'},
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    if(response === undefined){
                        reject('No se obtuvo respuesta del servidor');
                    }
                    else if(response.data.success){
                        resolve(response.data);
                    }else{
                        reject(response.data);
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getJournalData(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getJournalData',
            headers: {'content-type': 'application/json'},
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getGeneralJournalData(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getGeneralJournalData',
            headers: {'content-type': 'application/json'},
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getGeneralDetailJournalData(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getGeneralDetailJournalData',
            headers: {'content-type': 'application/json'},
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getGeneralLedgerData(data) {
        const options = {
            method: 'GET',
            url: '/accountingreports/getGeneralLedgerData',
            headers: {'content-type': 'application/json'},
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
   
};

