<template>
    <div style="z-index: 200; padding: 14px; min-height: 500px;">
        <block v-if="loading"></block>
        <v-stepper v-model="step" class="white pa-4">
            <v-stepper-header style="flex-wrap: nowrap;">
                <v-stepper-step :complete="step > 1" step="1">
                    Carga de datos
                </v-stepper-step>
                <v-stepper-step :complete="step > 2" step="2">
                    Datos de los proveedores
                </v-stepper-step>
                <v-stepper-step :complete="step > 3" step="3">
                    Datos de la segmentación
                </v-stepper-step>
                <v-stepper-step :complete="step > 4" step="4">
                    Previsualización de los datos
                </v-stepper-step>
                <v-stepper-step step="5">
                    Procesamiento de los datos
                </v-stepper-step>
            </v-stepper-header>
            
            <!-- <v-row>
                <v-col>
                    <v-btn class="btnclean" @click="resetAll" :disabled="loading">
                        <v-icon class="mr-2">mdi-broom</v-icon>Reiniciar
                    </v-btn>
                </v-col>
            </v-row> -->
            <div style="margin: 1rem 0; display: flex; flex-direction: row; justify-content: space-between;">
                <v-btn class="btnsave" @click="downloadTemplate" :disabled="loading">
                    <v-icon class="mr-2">mdi-download</v-icon>Descargar plantilla para importar
                </v-btn>
                <v-btn class="btnclean" @click="resetAll" :disabled="loading">
                    <v-icon class="mr-2">mdi-broom</v-icon>Reiniciar
                </v-btn>
            </div>
            
            <v-stepper-items>
                <!-- Step 1: Upload Excel -->
                <v-stepper-content step="1">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-step">
                                <v-card-title class="font-weight-bold text-nowrap">Seleccionar almacén para los productos</v-card-title>
                                <v-card-actions>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-autocomplete 
                                            v-model="selectedWarehouse"
                                            :items="warehouses"
                                            item-text="Warehouse"
                                            item-value="ID_Warehouse"
                                            label="Seleccione almacén"
                                            append-outer
                                            outlined 
                                            dense
                                            attach
                                            ></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                    </v-card-actions>
                                    <v-card-title class="font-weight-bold text-nowrap">Importar datos desde archivo</v-card-title>
                                    <v-card-actions>
                                        <v-row>
                                            <v-col cols="12" class="col-input-file">
                                                <v-file-input
                                                style="min-width: 300px; width: 50%; display: flex; gap: 10px;"
                                                outlined
                                                dense
                                                prepend-icon="mdi-file-excel"
                                                v-model="selectedFile"
                                                accept=".xlsx, .xls, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                label="Seleccione hoja de calculo"
                                                :disabled="loading"
                                                >
                                            </v-file-input>
                                            <v-btn class="btn-import mb-3" @click="handleFileUpload" :disabled="loading || (selectedFile && uploadedFile)">
                                                <v-icon class="mr-2">mdi-import</v-icon>Cargar archivo
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                                <v-card-actions style="padding: 1rem; justify-content: flex-end;">
                                    <v-btn class="btncontinue" @click="nextStep" :disabled="loading">Continuar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-stepper-content>
                
                <!-- Step 2: Preparing Data Providers -->
                <v-stepper-content step="2">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-step">
                                <v-card-title class="font-weight-bold text-nowrap">Datos de los proveedores importados</v-card-title>
                                <v-card-text v-if="providersErrors" style="display: flex; flex-direction: row;">
                                    <v-icon color="red darken-4" style="margin: 10px; font-size: 20px;" >mdi-alert-circle</v-icon>
                                    <p style="margin-top: 20px; margin-bottom: 20px; font-size: 16px; color: #e3191e !important;">
                                        Algunos proveedores no pudieron ser insertados automaticamente, sus productos no serán procesados.
                                    </p>
                                </v-card-text>
                                <v-data-table 
                                    :headers="tableProvidersHeaders" 
                                    :items="tableUniqueProviders"
                                    :items-per-page="50"
                                    :footer-props="{
                                        showFirstLastPage: true,
                                        firstIcon: 'mdi-page-first',
                                        lastIcon: 'mdi-page-last',                                            
                                        'items-per-page-options': [50, 100, 250],
                                        'items-per-page-text':'Proveedores por pagina:',
                                    }"
                                    class="data-table"
                                >
                                    <template v-slot:item.status="{ item }">
                                        <v-icon v-if="item.error?.provider" color="red darken-4" style="margin: 10px; font-size: 20px;" @click.prevent="showError(item.error.message)">mdi-alert-circle</v-icon>
                                        <v-icon v-else style="margin: 10px; font-size: 20px;">mdi-check-circle</v-icon>
                                    </template>
                                </v-data-table>
                                <v-card-actions style="padding: 1rem; justify-content: space-between;">
                                    <v-btn class="btncontinue" @click="prevStep" :disabled="loading">Anterior</v-btn>
                                    <v-btn class="btncontinue" @click="nextStep" :disabled="loading">Continuar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <!-- Step 3: Preparing Data Products Grouping-->
                <v-stepper-content step="3">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-step">
                                <v-card-title class="font-weight-bold text-nowrap">Datos de segmentación de los productos</v-card-title>
                                <v-card-text style="display: flex; flex-direction: row;">
                                    <p style="margin-top: 20px; margin-bottom: 20px; font-size: 16px;">
                                        En la siguiente tabla se muestran las diferentes segmentaciones (familia, categoría y subcategoría) según los datos importados, compruebe si la información de cada pestaña es correcta.
                                    </p>
                                </v-card-text>

                                <v-tabs v-model="segActiveTab" style="padding: 16px;" class="tabs-seg">
                                    <v-tab>Familias</v-tab>
                                    <v-tab>Categorías</v-tab>
                                    <v-tab>Subcategorías</v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="segActiveTab" style="padding: 16px; min-height: 200px;">
                                    <v-tab-item>
                                        <!-- <v-row v-for="(item, index) in dataFamilies" :key="index">
                                            <v-col cols="3" style="margin: 20px 0;">
                                                <p style="margin: 0;"><strong>Familia: </strong>{{ item['Familia'] }}</p>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="!dataFamilies.length">
                                            <v-col cols="12" style="margin: 20px 0;">
                                                <p style="margin: 0;">No se han importado familias</p>
                                            </v-col>
                                        </v-row> -->
                                        <v-data-table 
                                            :headers="segmentationHeaders.family"
                                            :items="dataFamilies"
                                            :items-per-page="10"
                                            hide-default-header
                                            :footer-props="{
                                                showFirstLastPage: true,
                                                firstIcon: 'mdi-page-first',
                                                lastIcon: 'mdi-page-last',                                            
                                                'items-per-page-options': [10, 50, 100],
                                                'items-per-page-text':'Familias por pagina:',
                                            }"
                                            class="data-table"
                                        >
                                        </v-data-table>
                                    </v-tab-item>

                                    <v-tab-item>
                                        <!-- <v-row v-for="(item, index) in dataCategories" :key="index">
                                            <v-col cols="4" style="margin: 20px 0;">
                                                <p style="margin: 0;"><strong>Categoría: </strong>{{ item['Categoría'] }}</p>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="!dataCategories.length">
                                            <v-col cols="12" style="margin: 20px 0;">
                                                <p style="margin: 0;">No se han importado categorías</p>
                                            </v-col>
                                        </v-row> -->
                                        <v-data-table 
                                            :headers="segmentationHeaders.category"
                                            :items="dataCategories"
                                            :items-per-page="10"
                                            hide-default-header
                                            :footer-props="{
                                                showFirstLastPage: true,
                                                firstIcon: 'mdi-page-first',
                                                lastIcon: 'mdi-page-last',                                            
                                                'items-per-page-options': [10, 50, 100],
                                                'items-per-page-text':'Categorías por pagina:',
                                            }"
                                            class="data-table"
                                        >
                                        </v-data-table>
                                    </v-tab-item>

                                    <v-tab-item>
                                        <!-- <v-row v-for="(item, index) in dataSubcategories" :key="index">
                                            <v-col cols="4" style="margin: 20px 0; padding: 16px;">
                                                <p style="margin: 0;"><strong>Subcategoría: </strong>{{ item['SubCategoría'] }}</p>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="!dataSubcategories.length">
                                            <v-col cols="12" style="margin: 20px 0;">
                                                <p style="margin: 0;">No se han importado subcategorías</p>
                                            </v-col>
                                        </v-row> -->
                                        <v-data-table 
                                            :headers="segmentationHeaders.subcategory"
                                            :items="dataSubcategories"
                                            :items-per-page="10"
                                            hide-default-header
                                            :footer-props="{
                                                showFirstLastPage: true,
                                                firstIcon: 'mdi-page-first',
                                                lastIcon: 'mdi-page-last',                                            
                                                'items-per-page-options': [10, 50, 100],
                                                'items-per-page-text':'Subcategorías por pagina:',
                                            }"
                                            class="data-table"
                                        >
                                        </v-data-table>
                                    </v-tab-item>
                                </v-tabs-items>

                                <v-card-actions style="padding: 1rem; justify-content: space-between;">
                                    <v-btn class="btncontinue" @click="prevStep">Anterior</v-btn>
                                    <v-btn class="btncontinue" @click="nextStep">Continuar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-stepper-content>
    
                <!-- Step 4: Preview Data -->
                <v-stepper-content step="4">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-step">
                                <v-card-title class="font-weight-bold text-nowrap">Productos importados desde el archivo seleccionado</v-card-title>
                                <v-card-text v-if="productsErrors" style="display: flex; flex-direction: row;">
                                    <v-icon color="red darken-4" style="margin: 10px; font-size: 20px;" >mdi-alert-circle</v-icon>
                                    <p style="margin-top: 20px; margin-bottom: 20px; font-size: 16px; color: #e3191e !important;">
                                        Algunos productos importados presentan errores en la información, los mismos no serán procesados hasta que se solucionen los conflictos.
                                    </p>
                                </v-card-text>
                                <v-data-table 
                                    :headers="tableHeaders" 
                                    :items="tableData"
                                    :items-per-page="50"
                                    :footer-props="{
                                        showFirstLastPage: true,
                                        firstIcon: 'mdi-page-first',
                                        lastIcon: 'mdi-page-last',                                            
                                        'items-per-page-options': [50, 100, 250],
                                        'items-per-page-text':'Productos por pagina:',
                                    }"
                                    class="data-table"
                                >
                                    <template v-slot:item.status="{ item }">
                                        <v-icon v-if="item.error?.product" color="red darken-4" style="margin: 10px; font-size: 20px;" @click.prevent="showError(item.error.message)">mdi-alert-circle</v-icon>
                                        <v-icon v-else style="margin: 10px; font-size: 20px;">mdi-check-circle</v-icon>
                                    </template>
                                </v-data-table>
                                <v-card-actions style="padding: 1rem; justify-content: space-between;">
                                    <v-btn class="btncontinue" @click="prevStep">Anterior</v-btn>
                                    <v-btn class="btnoption" @click="onClickProcess">Procesar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-stepper-content>
        
                    <!-- Step 5: Process Data -->
                <v-stepper-content step="5">
                    <v-row>
                        <v-col cols="12">
                            <v-card v-if="loading" class="card-step" style="min-height: 400px;">
                                <v-card-title class="font-weight-bold text-nowrap">Procesando la información importada</v-card-title>
                            </v-card>
                            <v-card v-else class="card-step" style="min-height: 400px;">
                                <v-card-title class="font-weight-bold text-nowrap">Estado del procesamiento de datos</v-card-title>

                                <v-card-text v-if="!processError && productsAllowed.length">
                                    <h3 style="margin-top: 20px; margin-bottom: 20px; font-size: 16px;">
                                        Se han procesado un total de {{ productsAllowed.length }} productos que fueron importados desde el archivo seleccionado.
                                    </h3>
                                    <v-card-actions style="padding: 0px; gap: 50px;">
                                        <v-btn class="btnoption" @click="showProcessed=!showProcessed" style="min-width: 100px;">
                                            {{!showProcessed ? 'Ver' : 'Ocultar'}}
                                            <v-icon v-if="!showProcessed" style="margin-left: 5px;">mdi-menu-down</v-icon>
                                            <v-icon v-else style="margin-left: 5px;">mdi-menu-up</v-icon>
                                        </v-btn>
                                        <v-btn class="btnoption" @click="exportToExcel('SUCCESS')" style="background-color: #00a178 !important;">
                                            <v-icon>mdi-file-excel</v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card-text>
                                <v-data-table 
                                    v-if="showProcessed"
                                    :headers="tableHeaders" 
                                    :items="productsAllowed"
                                    :items-per-page="50"
                                    :footer-props="{
                                        showFirstLastPage: true,
                                        firstIcon: 'mdi-page-first',
                                        lastIcon: 'mdi-page-last',                                            
                                        'items-per-page-options': [50, 100, 250],
                                        'items-per-page-text':'Productos por pagina:',
                                    }"
                                    class="data-table"
                                >
                                </v-data-table>

                                <v-card-text v-if="!processError && productsNotAllowed.length">
                                    <h3 style="margin-top: 20px; margin-bottom: 20px; font-size: 16px; color: #e3191e !important;">
                                        No se logro procesar un total de {{ productsNotAllowed.length }} productos cargados, debido a la existencia de errores en la información.
                                    </h3>
                                    <v-card-actions style="padding: 0px; gap: 50px;">
                                        <v-btn class="btnoption" @click="showNotProcessed=!showNotProcessed" style="min-width: 100px;">
                                            {{!showNotProcessed ? 'Ver' : 'Ocultar'}}
                                            <v-icon v-if="!showNotProcessed" style="margin-left: 5px;">mdi-menu-down</v-icon>
                                            <v-icon v-else style="margin-left: 5px;">mdi-menu-up</v-icon>
                                        </v-btn>
                                        <v-btn class="btnoption" @click="exportToExcel('ERROR')" style="background-color: #00a178 !important;">
                                            <v-icon>mdi-file-excel</v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card-text>
                                <v-data-table 
                                    v-if="showNotProcessed"
                                    :headers="tableHeaders" 
                                    :items="productsNotAllowed"
                                    :items-per-page="50"
                                    :footer-props="{
                                        showFirstLastPage: true,
                                        firstIcon: 'mdi-page-first',
                                        lastIcon: 'mdi-page-last',                                            
                                        'items-per-page-options': [50, 100, 250],
                                        'items-per-page-text':'Productos por pagina:',
                                    }"
                                    class="data-table"
                                >
                                </v-data-table>

                                <v-card-text v-if="processError" style="display: flex; flex-direction: row;">
                                    <h3 style="margin-top: 20px; margin-bottom: 20px; font-size: 16px;">
                                        Ha ocurrido un error en el procesamiento, vuelva a intentarlo más tarde. 
                                    </h3> 
                                </v-card-text>

                                <v-card-text v-if="!productsAllowed.length && !productsNotAllowed.length" style="display: flex; flex-direction: row;">
                                    <h3 style="margin-top: 20px; margin-bottom: 20px; font-size: 16px;">
                                        No existen productos pendiente de procesamiento. 
                                    </h3> 
                                </v-card-text>
                                
                                <v-card-actions style="padding: 1rem; justify-content: space-between;">
                                    <v-btn class="btncontinue" @click="prevStep" :disabled="productsAllowed.length > 0 || productsNotAllowed.length > 0">Anterior</v-btn>
                                    <v-btn class="btncontinue" @click="resetAll">Volver al inicio</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-stepper-content>
                    
            </v-stepper-items>
        </v-stepper>
        <alerts
            v-if="alert.show"
            v-on:close_alert="closeAlert"
            v-on:accept_alert="acceptAlert"
            :properties="alert"
        >
        </alerts>
    </div>
</template>
  
<script>
import Alerts from "@/components/Alerts";
import Block from "@/components/Block";
import * as XLSX from "xlsx";


export default {
    name: "ManageProductImport",
    props: [],
    components: {
        Block,
        Alerts,
    },
    data() {
        return {
            step: 1,
            loading: false,
            uploadedFile: false,
            id_bussiness: null,
            bussiness: null,
            id_branch: null,
            id_user: null,
            warehouses: [],
            providers: [],
            families: [],
            categories: [],
            subcategories: [],

            selectedWarehouse: null,
            selectedFile: null,

            tableHeaders: [],
            tableData: [],
            tableProvidersHeaders: [],
            tableUniqueProviders: [],
            segmentationHeaders: [],
            tableUniqueGrouping:[],
            segActiveTab: 0,
            dataFamilies: [],
            dataCategories: [],
            dataSubcategories: [],
            barcodes: [],

            productsAllowed: [],
            productsNotAllowed: [],

            showProcessed: false,
            showNotProcessed: false,

            providersErrors: null,
            productsErrors: null,
            processError: null,
            alert: {
                type: "success",
                show: false,
                header: "",
                body: "",
            },
        };
    },
    async mounted() {
        this.id_bussiness = JSON.parse(localStorage.getItem("user")).businessid;
        this.business = JSON.parse(localStorage.getItem("user")).business;
        this.id_branch = JSON.parse(localStorage.getItem("user")).branch;
        this.id_user = JSON.parse(localStorage.getItem("user")).id;
        this.warehouses = await this.$API.warehouse.getWarehouses().then(response => response);
        this.providers = await this.$API.providers.getProviders().then(response => response);
        this.families = await this.$API.itemtypes.getFamiliesHistory().then(response => response);
        // Dentro de cada item en families me vienen las cats y subcats de cada uno
        this.categories = await this.$API.itemtypes.getCategoriesHistory().then(response => response);
        this.subcategories = await this.$API.itemtypes.getSubsHistory().then(response => response);
        this.segmentationHeaders = {
                family: [
                    {
                        text:'Familia',
                        value: 'Familia'
                    }
                ],
                category: [
                    {
                        text:'Categoría',
                        value: 'Categoría'
                    }
                ],
                subcategory: [
                    {
                        text:'SubCategoría',
                        value: 'SubCategoría'
                    }
                ]
            };
    },
    watch: {
        tableData: {
            deep: true,
            inmediate: true,
            handler(newValue) {
                this.providersErrors = newValue.some((item) => item.error?.provider);
                this.productsErrors = newValue.some((item) => item.error?.product);
            }
        }
    },
    methods: {
        nextStep() {
            if(this.step === 1) {
                if(!this.selectedWarehouse) {
                    this.showAlert("warning", "Advertencia", "Antes de continuar debe seleccionar un almacén.");
                    return;
                }
                this.handleDataLoad();
                this.step++;
            }
            else {
                this.step++;
            }
        },
        prevStep() {
            this.step--;
        },
        async handleFileUpload() {
            if (!this.selectedFile) {
                this.showAlert("warning", "Advertencia", "Por favor seleccionar un archivo.");
                return;
            }
            try {
                this.loading = true;
                this.resetData();
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: "array" });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    //const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                    this.tableHeaders = jsonData[0].map((header) => ({ text: header, value: header }));
                    this.tableData = jsonData.slice(1).map((row) => {
                        const rowData = {};
                        this.tableHeaders.forEach((header, index) => {
                            rowData[header.value] = row[index];
                        });
                        return rowData;
                    });
                    
                    this.tableHeaders.unshift({text: 'Estado', value: 'status'});

                    this.barcodes = this.tableData.filter(item => item.error ? false : true).map((item) => {
                        return { 
                            code: item['Código Alterno o de Barra'],
                            id: item['ID Producto'],
                        };
                    });
                };
                
                reader.readAsArrayBuffer(this.selectedFile);
                reader.onloadend = () =>  {
                    if(this.tableHeaders.length && this.tableData.length) {
                        this.loading = false;
                        this.uploadedFile = true;
                        this.showAlert("success", "Exito", "La información del archivo seleccionado fue cargado correctamente.");
                    }
                    else {
                        throw new Error();
                    }
                };
            } catch (error) {
                console.log(error);
                this.loading = false;
                this.uploadedFile = false;
                this.showAlert("warning", "Advertencia", "Ha ocurrido un error al cargar el archivo, vuelva a intentarlo más tarde.");
            }
        },
        async handleDataLoad() {            
            this.loading = true;
            try {
                for (const item of this.barcodes) {
                    item.validate = await this.checkDuplicateBarcode(item.code);
                }
    
                for (const item of this.tableData) {
                    const provider = this.checkProvider(item);
                    if(!provider){
                        const newProvider = await this.addProvider(item);
                        
                        if(newProvider && newProvider.ID_Provider) {
                            item['ID Proveedor'] = newProvider.ID_Provider;
                            this.providers = await this.$API.providers.getProviders().then(response => response);
                        }
                        else {
                            item.error = {
                                provider: 'Proveedor no registrado',
                                message: 'Ocurrio un error al insertar el proveedor no existente en la base de datos, debera ingresarlo manualmente.',
                            };
                            item['ID Proveedor'] = item.error.provider;
                        }
                    }
                    else {
                        item['ID Proveedor'] = provider.ID_Provider;
                    }

                    this.barcodes.forEach(el => {
                        if(item['Código Alterno o de Barra'] == el.code) {
                            if(!el.validate) {
                                item.error = {
                                    product: 'Código de alterno o de barras duplicado',
                                    message: 'No se puede procesar el producto debido a que en la base de datos ya existe un registro con el mismo código alterno o de barras.',
                                };
                                //item.status = 'Código de alterno o de barra duplicado';
                            }
                        }
                    })
                }
                
                await this.generateIDs([...this.tableData].filter(item => item.error || item.status ? false : true));

                await this.getUniqueTableData();

                this.tableData.sort((a, b) => {
                    if (a.error && !b.error) {
                        return -1;
                    }
                    if (!a.error && b.error) {
                        return 1;
                    }
                    return 0;
                });

                this.loading = false;
            } 
            catch (error) {
                console.log(error);
                this.loading = false;
                this.showAlert("warning", "Advertencia", "Ha ocurrido un error al procesar el archivo, vuelva a intentarlo más tarde o contacte al soporte.");
            }
        },
        async generateIDs(data) {
            const initialID = await this.$API.products.getInitialID('PRO');
            let index = 0;
            for (const item of data) {
                // Encuentra la última parte numérica de la cadena
                const match = initialID.match(/(\d+)$/);
                // Separa la parte de la cadena antes del número y el número en sí
                const string = initialID.slice(0, -match[0].length);
                const number = parseInt(match[0], 10) + index;
                // Genera un nuevo id con el número incrementado
                const ID = `${string}${number}`;
                item['ID Producto'] = ID;
                index++;
            }
        },
        checkProvider(item) {
            return this.providers.find(el => el.ProviderName == item['Nombre Proveedor'] || el.ProviderLegalName == item['Nombre legal proveedor (Razón Social)'] || el.ProviderName == item['Nombre legal proveedor (Razón Social)'] || el.ProviderLegalName == item['Nombre Proveedor']);
        },
        async addProvider(data) {
            const provider = {
                providername: data['Nombre Proveedor'],
                providerphone: data['Teléfono Proveedor']?.replaceAll('-', ''),
                providermobilenumber: data['Celular Proveedor']?.replaceAll('-', ''),
                provideremail: data['Email Proveedor'],
                providercontactname: data['Nombre Contacto Proveedor'],
                providercontactphone: data['Teléfono Contacto Proveedor']?.replaceAll('-', ''),
                providercontactmobile: data['Celular Contacto Proveedor']?.replaceAll('-', ''),
                providercontactemail: data['Email Contacto Proveedor'],
                providerwebsite: data['Sitio Web Proveedor'],
                provideraddress: data['Dirección Proveedor'],
                providercity: data['Ciudad Proveedor'],
                providerstate: data['Estado o Departamento Proveedor'],
                providercountry: data['País Proveedor'],
                providerlegalname: data['Nombre Legal Proveedor (Razón Social)'],
                providerlegaladdress: data['Dirección Legal Proveedor'] || data['Dirección Proveedor'],
                providerlegalnrc: data['NRC Proveedor'],
                providerlegalnit: data['NIT Proveedor'],
                providerlegalsector: data['Giro del proveedor'],
                providerlegalentity: data['Persona Natural o Jurídica'],
                providerlegaltaxpayersize: data['Categoría contribuyente']?.toUpperCase(),
                notes: 'Insertado autamaticamente por importación de productos',
                idbusiness: this.id_bussiness,
                idbranch: this.id_branch,
                iduserinsert: this.id_user,
            };
            
            return await this.$API.providers.addProvider(provider)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error)
                    return null;
                });
        },
        async checkDuplicateBarcode(value) {
            return await this.$API.inventoryitemsbarcodes.checkBarCodeExists({ barcode: value })
                .then((response) => {
                    return response.success;
                })
                .catch((error) => {
                    console.log(error)
                });
        },
        getUniqueTableData() {
            this.tableProvidersHeaders = [...this.tableHeaders].slice(0, 23).filter(item => item.value !== 'ID Producto');
            const providerSet = new Set();
            this.tableUniqueProviders = [...this.tableData].filter(item => {
                const key = item['Nombre Proveedor'];
                return providerSet.has(key) ? false : providerSet.add(key);
            });

            const familySet = new Set();
            const categorySet = new Set();
            const subcategorySet = new Set();

            this.dataFamilies = [...this.tableData].filter(item => {
                const familyKey = item['Familia'];
                if(!item['Familia']) return false;
                return familySet.has(familyKey) ? false : familySet.add(familyKey);
            }).sort((a, b) => {
                if(a['Familia'] < b['Familia']) return -1; 
                if(a['Familia'] > b['Familia']) return 1; 
                return 0;
            });
            this.dataCategories = [...this.tableData].filter(item => {
                const categoryKey = item['Categoría'];
                if(!item['Categoría']) return false;
                return categorySet.has(categoryKey) ? false : categorySet.add(categoryKey);
            }).sort((a, b) => {
                if(a['Categoría'] < b['Categoría']) return -1; 
                if(a['Categoría'] > b['Categoría']) return 1; 
                return 0;
            });
            this.dataSubcategories = [...this.tableData].filter(item => {
                const subcategoryKey = item['SubCategoría'];
                if(!item['SubCategoría']) return false;
                return subcategorySet.has(subcategoryKey) ? false : subcategorySet.add(subcategoryKey);
            }).sort((a, b) => {
                if(a['SubCategoría'] < b['SubCategoría']) return -1; 
                if(a['SubCategoría'] > b['SubCategoría']) return 1; 
                return 0;
            });
        },
        async processData() {
            this.loading = true;
            this.nextStep();
            try {
                if(!this.tableData.length) throw new Error('No ha seleccionado archivo con la información.');

                const validBarcodes = this.barcodes.filter(item => item.validate ? true : false);
                for (const item of this.tableData) {
                    this.barcodes.forEach(el => {
                        if(item['Código Alterno o de Barra'] == el.code) {
                            if(!el.validate) {
                                item.status = 'Código de alterno o de barra duplicado';
                            }
                        }
                    })
                }
                //throw new Error('Los códigos cargados ya encuentran asociados a un producto, debera agragar uno distinto o eliminarlos.');

                this.productsAllowed = [...this.tableData].filter(item => item.error || item.status ? false : true);
                this.productsNotAllowed = [...this.tableData].filter(item => item.error || item.status ? true : false);
                this.productsNotAllowed.forEach(item => {
                    if(item.error.product && item.error.provider) {
                        item.status = `${item.error.product} / ${item.error.provider}`;
                    }
                    else if(item.error.product && !item.error.provider) {
                        item.status = item.error.product;
                    }
                    else if(!item.error.product && item.error.provider) {
                        item.status = item.error.provider;
                    }
                    item['ID Producto'] = '';
                });

                if(!this.productsAllowed.length) throw new Error('No hay productos validos para procesar, revise la información ingresada.');

                //Revalidación de la correlatividad de los item_id luego de separados los productos con errores
                //await this.generateIDs(this.productsAllowed);

                const products = [];
                                 
                for (const item of this.productsAllowed) {
                    try {
                        for (const family of this.families) {
                            if(item['Familia'] == family.name) {
                                item.family_id = family.id;
                                if(item['Categoría']) {
                                    const cat = family?.items_categories.find(el => item['Categoría'] == el.name);
                                    if(cat) item.category_id = cat.id;
                                    else item.category_id = await this.$API.itemtypes.createCat({name: item['Categoría'] , id_family: item.family_id}).then(response => response.id);
                                    if(item['SubCategoría']) {
                                        const subcat = cat?.items_subcats.find(el => item['SubCategoría'] == el.name)
                                        if(subcat) item.subcategory_id = subcat.id;
                                        else item.subcategory_id = await this.$API.itemtypes.createSub({name: item['SubCategoría'] , id_cat: item.category_id}).then(response => response.id);
                                    }
                                }
                            }  
                        }
                        if(item['Familia'] && !item.family_id) {
                            item.family_id = await this.$API.itemtypes.createFam(item['Familia']).then(response => response.id);
                            if(item['Categoría']) {
                                item.category_id = await this.$API.itemtypes.createCat({name: item['Categoría'] , id_family: item.family_id}).then(response => response.id);
                                if(item['SubCategoría']) {
                                    item.subcategory_id = await this.$API.itemtypes.createSub({name: item['SubCategoría'] , id_cat: item.category_id}).then(response => response.id);
                                }
                            }
                        }
                    } 
                    catch (error) {
                        console.log(error);
                        this.processError = true;
                        throw new Error('Ha ocurrido un error en el procesamiento, vuelva a intentarlo más tarde. Si el error persiste, contactarse con soporte.');
                    }
                    
                    const product = {
                        id: item['ID Producto'],
                        //type: item[''],
                        name: item['Nombre de producto en sistema'],
                        comercialName: item['Nombre comercial producto'],
                        description: item['ItemDescription'],
                        //imagePath: item[''],
                        measureUnit: item['Unidades'],
                        idProvider: item['ID Proveedor'],
                        //itemNote: item[''],
                        //isGlobalBranch: item[''],    ---------------------------> Ver si tiene que ser true
                        price: item['Costo'],
                        code: item['Código Alterno o de Barra'],
                        //lote: item[''],
                        //conversionFactor: item[''],
                        //unitsPackaging: item[''],
                        idUser: this.id_user,
                        aditionalType: item['AditionalType'],
                        warehouse: this.selectedWarehouse,
                        family: item['family_id'],
                        category: item['category_id'],
                        subcategory: item['subcategory_id'],
                        suggestedPrice: item['Precio de venta'],
                        salesUnits: item['Existencias'],  // ------------------> Ver si esta bien aca existencias
                        //id_account: item[''],
                        //isexempt: item[''],
                    };
                    products.push(product);
                }
                //console.log(products);

                await this.$API.products.importProducts({ products, barcodes: validBarcodes })
                    .then(res => {
                        if(res.success) {
                            //console.log(res);
                            this.loading = false;
                            if(!this.productsNotAllowed.length) {
                                this.showAlert("success", "Exito", "Todos los productos fueron creados y añadidos al inventario." );
                            } else {
                                this.showAlert("success", "Advertencia", `Los productos validados (${this.productsAllowed.length}) fueron creados y añadidos al inventario, pero existe un total de ${this.productsNotAllowed.length} productos que no pudieron ser procesados.` );
                            }
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        this.processError = true;
                        throw new Error('Ha ocurrido un error en el procesamiento, vuelva a intentarlo más tarde. Si el error persiste, contactarse con soporte.');
                    });
                
            } catch (error) {
                this.loading = false;
                this.showAlert("warning", "Advertencia", error.message ? error.message : "Ha ocurrido un error al procesar el archivo, vuelva a intentarlo más tarde.");
            }
        },
        exportToExcel(value) {
            if(value === 'ERROR') {
                const headers = this.tableHeaders.map(header => header.text);
                const data = this.productsNotAllowed.map(item => this.tableHeaders.map(header => item[header.value]));
    
                const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Plantilla inventario');
    
                XLSX.writeFile(workbook, 'plantilla_inventario_errores.xlsx');

            } else if(value === 'SUCCESS') {
                const headers = this.tableHeaders.map(header => header.text);
                const data = this.productsAllowed.map(item => this.tableHeaders.map(header => item[header.value]));
    
                const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Plantilla inventario');
    
                XLSX.writeFile(workbook, 'plantilla_inventario_procesados.xlsx');

            }
        },
        onClickProcess() {
            this.showAlert("question2", "Advertencia", "¿Esta seguro que desea continuar?", "processData");
        },
        showAlert(type, header, body, options = null) {
            type = type == null ? "danger" : type;
            this.alert.type = type;
            this.alert.header = header;
            this.alert.body = body;
            this.alert.show = true;
            this.alert.options = options != null ? options : null;
        },
        closeAlert() {
            this.alert.show = false;
        },
        acceptAlert() {
            this.alert.show = false;
            if (this.alert.options == 'processData') {
                this.processData();
            }
        },
        showError(message) {
            this.showAlert("warning", "Advertencia", message);
        },
        resetData() {
            this.tableHeaders = [];
            this.tableData = [];
            this.tableProvidersHeaders = [];
            this.tableUniqueProviders = [];
            this.tableUniqueGrouping = [];
            this.dataFamilies = [];
            this.dataCategories = [];
            this.dataSubcategories = [];
            this.barcodes = [];
            this.providersErrors = null;
            this.productsErrors = null;
            this.processError = null;
            this.uploadedFile = false;
        },
        resetAll() {
            this.step = 1;
            this.loading = false;
            this.uploadedFile = false;
            this.selectedWarehouse = null;
            this.selectedFile = null;
            this.tableHeaders = [];
            this.tableData = [];
            this.tableProvidersHeaders = [];
            this.tableUniqueProviders = [];
            this.tableUniqueGrouping = [];
            this.segActiveTab = 0;
            this.dataFamilies = [];
            this.dataCategories = [];
            this.dataSubcategories = [];
            this.barcodes = [];
            this.productsAllowed = [];
            this.productsNotAllowed = [];
            this.showProcessed = false;
            this.showNotProcessed = false;
            this.providersErrors = null;
            this.productsErrors = null;
            this.processError = null;
        },
        downloadTemplate() {
            const filePath = '/Plantilla_Inventario.xlsx';
            const link = document.createElement('a');
            link.href = filePath;
            link.download = 'Plantilla_Inventario.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};
</script>

<style scoped>

.card-step {
    min-height: 400px; 
    width: 100%; 
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.col-input-file {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 1rem;
}

.btn-import {
  background-color: #3498db !important;
  color: white;
  min-width: 250px !important;
  height: 40px !important;
}

.btnoption {
  background-color: #3498DB !important;
  color: white;
}

.btncontinue {
  background-color: rgba(80, 80, 80, 1) !important;
  color: white;
}

.btndisable {
    background-color: #41464c !important;
    color: white;
}


</style>

<style>

.mdi-file-excel::before {
    font-size: 32px !important;
}

.data-table .v-data-table__wrapper thead th {
    height: 35px !important;
    text-wrap: nowrap;
    min-width: 100px;

}
.data-table .v-data-table__wrapper tbody tr {
    height: 35px !important;
    text-wrap: nowrap;
    min-width: 100px;
}
.data-table .v-data-table__wrapper tbody td {
    height: 35px !important;
    text-wrap: nowrap;
    min-width: 100px;
}

.tabs-seg .v-slide-group__content {
    justify-content: space-between;
}

</style>