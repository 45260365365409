<template>
    <v-card>
        <block v-if="loading"></block>
        <v-card-title class="text-h5">
            Buscar plantillas
        </v-card-title>

        <v-card-text>
          
             
                    <GeneralFilter :search="search" :filterEndpoint="$API.laboratory.manageTemplateFiltered" @filtered="filterHandler" @emptyFilter="getLaboratory">
                        <DxDataGrid :data-source="templates" :showBorders="true"
                        :scrolling="{ useNative: true }"  
                        :paging="{ enabled: false }"  
                        :column-auto-width="true"
                         width="100%" height="200px"
                         key-expr="ID_LaboratoryTest">
                            <!--DxPaging :page-size="4" /-->
                            <DxColumn :width="columnWidth"  cell-template="show"></DxColumn>
                            <DxColumn :width="columnWidth"  cell-template="selectTemplate"></DxColumn>
                            <DxColumn :width="columnWidth" data-field="ID_LaboratoryTest" caption="ID de test de laboratorio"></DxColumn>
                            <DxColumn :width="columnWidth" data-field="LaboratoryName" caption="Nombre de laboratorio"></DxColumn>
                            <DxColumn :width="columnWidth" data-field="LaboratoryNameBilling" caption="Nombre a facturar"></DxColumn>
    
                            <template #selectTemplate="{data}">
                                <a href=""  @click.prevent.stop="showLabDetails(data.data.ID_LaboratoryTest, true, data.data.LaboratoryName)">
                                    <v-icon>mdi-check-bold</v-icon>
                                </a>
                            </template>
                            <template #show="{data}">
                                <a href="#" @click.prevent.stop="showLabDetails(data.data.ID_LaboratoryTest)">
                                    <v-icon color="primary">mdi-eye</v-icon>
                                </a>
                            </template>
                        </DxDataGrid>
                    </GeneralFilter>

               
                    <DxDataGrid :data-source="details" :showBorders="true" key-expr="ID_TestParam" width="100%">
                        <DxColumn  :width="columnWidth" data-field="ParameterOrder" caption="Orden"></DxColumn>
                        <DxColumn :width="columnWidth" data-field="ParameterName" caption="Nombre del parametro"></DxColumn>
                        <DxColumn :width="columnWidth" data-field="ParameterValue" caption="Valor del parametro"></DxColumn>
                        <DxColumn :width="columnWidth" data-field="ParameterUnit" caption="Unidad del parametro"></DxColumn>
                        <DxColumn :width="columnWidth" data-field="ParameterExpected" caption="Expectativas del parametro"></DxColumn>
                        <DxColumn :width="columnWidth" data-field="ParameterType" caption="Tipo de parametro"></DxColumn>
                    </DxDataGrid>

                
           
        </v-card-text>
    </v-card>
</template>

<script>
    import {DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
    import GeneralFilter from '../../../components/GeneralFilter.vue';
    import Block from "@/components/Block";

    export default {
        name: "ManageTemplate",
        components: {DxColumn, DxDataGrid, GeneralFilter, Block },

        data() {
            return {
                templates: [],
                details: [],
                templatesFiltered: [],
                isFiltered: false,
                search: {
                    filter: null
                },
                loading: false,
            }
        },
        mounted() {
            this.search.business = JSON.parse(localStorage.getItem("user")).businessid || ""
            this.search.branch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.getLaboratory()
        },
        computed: {
            columnWidth() {
                return this.$vuetify.breakpoint.smAndDown ? '150px' : 'auto !important';
            }
        },
        methods: {
            filterHandler(response) {
                this.templates = response;
            },
            clearFilter() {
                this.isFiltered = false;
            },
            getLaboratory(){
                this.$API.laboratory.manageTemplateHistory(this.search)
                .then(response => {
                    this.templates = response
                })
            },
            async showLabDetails(id, backAndClose = false, laboratoryName = null) {
                this.loading = true
                if(backAndClose == false){
                    this.idSelected = id
                    this.$API.laboratory.showLabDetails(id)
                        .then(response => {
                            this.loading = false
                            
                            this.details = response
                        })
                        .catch(error => {
                            this.loading = false
                            console.log(error)
                            this.details = []
                        })
                        
                         
                }
                else{
                    await this.$API.laboratory.showLabDetails(id)
                        .then(response => {
                            this.loading = false
                            this.details = response
                            
                           
                            this.$emit('templateSelected', this.details, laboratoryName, id)
                        })
                        .catch(error => {
                            this.loading = false
                            console.log(error)
                            this.$emit('templateSelected', [], laboratoryName, id)
                        })
                }
            },
            

        }
    }
</script>

<style scoped>

</style>