<template>
  <v-overlay color="white">
    <v-img src="/images/Iphone-spinner-2.gif" alt="Loading"></v-img>
  </v-overlay>
</template>

<script>
export default {
  name: "Block",
};
</script>
