<template>
    <div>
        <block v-if="loading"></block>
        <v-card flat>
            <v-card-text style="z-index: 1">
                <template v-if="type === 'medicalclinic'">
                    <user-profile-lab :patient="data" :patientinfo="data" :useData="useData"></user-profile-lab>
                </template>
                <template v-else>
                    <user-profile :patientinfo="patientinfo" :data="data" :useData="useData" />
                </template>

                <v-row style="margin-top: 1rem">
                    <v-col cols="12" class="box" style="margin-top: 1rem">
                        <v-form class="position-relative">
                            <div>
                                <v-col cols="12" class="tabsNh">
                                    <v-col cols="12" md="12" style="display: flex; justify-content: space-between">
                                        <div style="width: 50px;">
                                            <v-btn small class="mr-1" color="error" v-if="selectedForm && $store.getters['rolesUser/getCustomRole'](165)" @click.prevent="confirmDelete(selectedForm.id)"
                                            >
                                                <v-icon> mdi-delete </v-icon>
                                            </v-btn>
                                        </div>
                                        <div>
                                            <v-btn small class="btnsave mr-1" @click.prevent="billItemsToCustomer()">
                                                <v-icon>mdi-cash-register</v-icon>
                                                Facturar
                                            </v-btn>
                                        </div>
                                        <div>
                                            <v-btn small class="btnblue mr-1" @click="showFormsDialog = !showFormsDialog" v-if="$store.getters['rolesUser/getCustomRole'](138)">
                                                <v-icon> mdi-magnify </v-icon>
                                            </v-btn>
                                            <v-btn small class="btnclean mr-1" @click.prevent.stop="resetForm()">
                                                <v-icon> mdi-broom </v-icon>
                                            </v-btn>
                                            <v-btn small v-if="selectedForm && $store.getters['rolesUser/getCustomRole'](172)
                                                " class="btnsave mr-1" @click.prevent.stop="updateForm()">
                                                <v-icon> mdi-content-save </v-icon>
                                            </v-btn>
                                            <v-btn small v-if="!selectedForm &&
                                                $store.getters['rolesUser/getCustomRole'](172)
                                                " class="btnsave mr-1" @click.prevent.stop="saveForm()">
                                                <v-icon> mdi-content-save </v-icon>
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-col>

                                <v-row style="padding-bottom: 12px;; margin: 1rem 0;">
                                    <v-col cols="6">
                                        <h2 style="margin: 1rem 0;">Formulario de internación de pacientes</h2>
                                    </v-col>
                                </v-row>

                                <v-row style="padding: 0 12px 12px 12px;">
                                    <v-col cols="5" md="3" sm="5">
                                        <date-picker 
                                            label="Fecha de ingreso" 
                                            @dateSelected="(param) => handleHistoryDate(param)"
                                            v-model="form.admissionDate"
                                            :disbaled="selectedForm != null"    
                                        >
                                        </date-picker>
                                    </v-col>
                                    <v-col cols="2" md="2" sm="2">
                                        <v-checkbox
                                            v-model="form.egress"
                                            label="Alta"
                                        >
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="5" md="3" sm="5" v-if="form.egress">
                                        <date-picker 
                                            label="Fecha de egreso" 
                                            @dateSelected="(param) => form.egressDate = param" 
                                            v-model="form.egressDate">
                                        </date-picker>
                                    </v-col>
                                </v-row>

                                <v-row style="padding: 0 12px 12px 12px;">
                                    <v-col cols="12" md="4" sm="4">
                                        <v-text-field label="Paciente" dense outlined disabled v-model="form.patient"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" sm="4">
                                        <v-text-field label="Cliente" dense outlined disabled v-model="form.client"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" sm="4">
                                        <v-text-field label="Telefono" dense outlined disabled v-model="form.phone"></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row style="padding: 0 12px 12px 12px;">
                                    <v-col cols="12" md="4" sm="4">
                                        <v-text-field label="Médico responsable" dense outlined disabled v-model="form.doctor"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" sm="4">
                                        <v-text-field label="Paciente Referido" dense outlined v-model="form.refPatient"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" sm="4">
                                        <v-text-field label="Déposito" dense outlined v-model="form.deposit" type="number"></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row style="padding: 0 12px 12px 12px;">
                                    <v-col cols="6" md="3" sm="6">
                                        <date-picker 
                                            label="Fecha de registro" 
                                            @dateSelected="(param) => date = param" 
                                            v-model="date">
                                        </date-picker>
                                    </v-col>
                                </v-row>

                                <v-row style="padding-bottom: 12px; margin: 1rem 0;">
                                    <v-col cols="12">
                                        <h2 style="margin: 1rem 0;">1. Hospitalización u hospedaje</h2>
                                    </v-col>
                                </v-row>

                                
                                <v-row style="padding: 0 12px 12px 12px;">
                                    <v-col cols="12" md="3" sm="3">
                                        <v-checkbox
                                            v-model="form.hospitalized.status"
                                            @change="handleCheckbox('HOSP')"
                                            label="Hospitalizado" 
                                        >
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="3">
                                        <v-autocomplete
                                            v-model="selectedService"
                                            item-text="NameService"
                                            item-value="CodeService"
                                            :items="hospitalServices"
                                            :disabled="!form.hospitalized.status"
                                            @change="handleSelect('HOSP')"
                                            label="Servicios"
                                            ref="hospRef"
                                            dense 
                                            outlined 
                                            attach
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="3">
                                        <v-text-field label="Tarifa diaria" dense outlined disabled v-model="form.hospitalized.price" type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="3">
                                        <v-text-field ref="hospDaysRef" label="Días" dense outlined v-model="form.hospitalized.days" type="number"></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row style="padding: 0 12px 12px 12px;">
                                    <v-col cols="12" md="3" sm="3">
                                        <v-checkbox
                                            v-model="form.hosted.status"
                                            @change="handleCheckbox('HOST')"
                                            label="Hospedado"
                                        >
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="3">
                                        <v-autocomplete
                                            v-model="selectedService"
                                            item-text="NameService"
                                            item-value="CodeService"
                                            :items="hostServices"
                                            :disabled="!form.hosted.status"
                                            @change="handleSelect('HOST')"
                                            label="Servicios"
                                            ref="hostRef"
                                            dense 
                                            outlined 
                                            attach
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="3">
                                        <v-text-field label="Tarifa diaria" dense outlined disabled v-model="form.hosted.price" type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="3">
                                        <v-text-field ref="hostDaysRef" label="Días" dense outlined v-model="form.hosted.days" type="number"></v-text-field>
                                    </v-col>
                                </v-row>

                                <div style="padding: 0 12px; height: 2rem;">
                                    <h3 v-if="form.hospitalized.status" style="text-align: right;">Subtotal: $ {{ form.hospitalizedSubTotal }}</h3>
                                    <h3 v-if="form.hosted.status" style="text-align: right;">Subtotal: $ {{ form.hostedSubTotal }}</h3>
                                </div>

                                <v-row style="padding: 12px;">
                                    <v-col cols="6">
                                        <h2 style="margin: 1rem 0;">2. A{{ ')' }} Medicamentos y otros aplicados</h2>
                                    </v-col>
                                    <v-col cols="6" style="display: flex; align-items: center; justify-content: end;">
                                        <v-btn class="btnblue mr-1" @click.prevent="addNewRow">
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                        <v-btn class="btnblue mr-1" @click.prevent="showMedications = true; medList = 1;">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row style="padding: 12px;">
                                    <!-- <v-col cols="12" md="4" sm="4">
                                        <v-checkbox
                                            v-model="form.notDetails1"
                                            label="Ocultar detalles en la factura"
                                        >
                                        </v-checkbox>
                                    </v-col> -->
                                    <v-col cols="12" md="6" sm="6">
                                        <h4 style="margin: 0.5rem 0;">Seleccione servicio a mostrar en el detalle de la factura:</h4>
                                        <v-autocomplete
                                            v-if="form.notDetails1"
                                            v-model="form.serviceToInvoice1"
                                            item-text="NameService"
                                            item-value="CodeService"
                                            :items="services"
                                            label="Servicio a facturar"
                                            ref="servRef1"
                                            dense 
                                            outlined 
                                            attach
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>

                                <v-col cols="12">
                                    <div style="width: auto; overflow-x: auto;">
                                        <DxDataGrid  
                                            :show-borders="true" 
                                            :data-source="form.medications1"
                                            :column-auto-width="true"
                                            key-expr="" 
                                            class="items-grid"
                                            style="width: fit-content; overflow-x: auto;"
                                        >
                                            <DxPaging :page-size="5" />
                                            <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                                            <DxColumn :width="80" caption=""></DxColumn>
                                            <DxColumn data-field="date" caption="Fecha" :allow-editing="false" :min-width="100"></DxColumn>
                                            <DxColumn data-field="detail" caption="Detalle" :min-width="200"></DxColumn>
                                            <DxColumn data-field="doctor" caption="Médico" :allow-editing="false" :min-width="200"></DxColumn>
                                            <DxColumn data-field="quantity" caption="Cantidad" :min-width="100"></DxColumn>
                                            <DxColumn data-field="price" caption="Precio" :min-width="100"></DxColumn>
                                            <DxColumn cell-template="total-template" caption="Total" :min-width="100"></DxColumn>
                                            <DxColumn :width="80" cell-template="delete-template" caption=""></DxColumn>
                                            <template #delete-template="{ data }">
                                                <a href="#" class="mx-1" @click.prevent.stop="deleteItem(data.data, 1, 1)">
                                                    <v-icon color="error">mdi-delete</v-icon>
                                                </a>
                                            </template>
                                            <template #total-template="{ data }">
                                                <div>
                                                    $ {{ getItemTotal(data.data.price, data.data.quantity, 1) }}
                                                </div>
                                            </template>
                                        
                                        </DxDataGrid>
                                    </div>
                                    <h3 v-if="form.medications1.length" style="margin: 12px 0; text-align: right;">Subtotal: $ {{ form.medicationsSubTotal1 }}</h3>
                                </v-col>

                                <v-row style="padding: 12px;">
                                    <v-col cols="6">
                                        <h2 style="margin: 1rem 0;">2. B{{ ')' }} Medicamentos de farmacia y otros accesorios</h2>
                                    </v-col>
                                    <v-col cols="6" style="display: flex; align-items: center; justify-content: end;">
                                        <v-btn class="btnblue mr-1" @click.prevent="showMedications = true; medList = 2;">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row style="padding: 12px;">
                                    <v-col cols="12" md="4" sm="4">
                                        <v-checkbox
                                            v-model="form.notDetails2"
                                            label="Ocultar detalles en la factura"
                                        >
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="6" sm="6">
                                        <v-autocomplete
                                            v-if="form.notDetails2"
                                            v-model="form.serviceToInvoice2"
                                            item-text="NameService"
                                            item-value="CodeService"
                                            :items="services"
                                            label="Servicio a facturar"
                                            ref="servRef2"
                                            dense 
                                            outlined 
                                            attach
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>

                                <v-col cols="12">
                                    <div style="width: auto; overflow-x: auto;">
                                        <DxDataGrid  
                                            :show-borders="true" 
                                            :data-source="form.medications2"
                                            :column-auto-width="true"
                                            key-expr="" 
                                            class="items-grid"
                                            style="width: fit-content; overflow-x: auto;"
                                        >
                                            <DxPaging :page-size="5" />
                                            <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                                            <DxColumn :width="80" caption=""></DxColumn>
                                            <DxColumn data-field="date" caption="Fecha" :allow-editing="false" :min-width="100"></DxColumn>
                                            <DxColumn data-field="detail" caption="Detalle" :min-width="200"></DxColumn>
                                            <DxColumn data-field="doctor" caption="Médico" :allow-editing="false" :min-width="200"></DxColumn>
                                            <DxColumn data-field="quantity" caption="Cantidad" :min-width="100"></DxColumn>
                                            <DxColumn data-field="price" caption="Precio" :min-width="100"></DxColumn>
                                            <DxColumn cell-template="total-template" caption="Total" :min-width="100"></DxColumn>
                                            <DxColumn :width="80" cell-template="delete-template" caption=""></DxColumn>
                                            <template #delete-template="{ data }">
                                                <a href="#" class="mx-1" @click.prevent.stop="deleteItem(data.data, 1, 2)">
                                                    <v-icon color="error">mdi-delete</v-icon>
                                                </a>
                                            </template>
                                            <template #total-template="{ data }">
                                                <div>
                                                    $ {{ getItemTotal(data.data.price, data.data.quantity, 1) }}
                                                </div>
                                            </template>
                                        
                                        </DxDataGrid>
                                    </div>
                                    <h3 v-if="form.medications2.length" style="margin: 12px 0; text-align: right;">Subtotal: $ {{ form.medicationsSubTotal2 }}</h3>
                                </v-col>

                                <v-row style="padding: 12px;">
                                    <v-col cols="6">
                                        <h2 style="margin: 1rem 0;">3. Otros servicios</h2>
                                    </v-col>
                                    <v-col cols="6" style="display: flex; align-items: center; justify-content: end;">
                                        <v-btn class="btnblue mr-1" @click.prevent="showServices = true;">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <v-col cols="12">
                                    <div style="width: auto; overflow-x: auto;">
                                        <DxDataGrid  
                                            :show-borders="true" 
                                            :data-source="form.services"
                                            :column-auto-width="true"
                                            key-expr="" 
                                            class="items-grid"
                                            style="width: fit-content; overflow-x: auto;"
                                        >
                                            <DxPaging :page-size="5" />
                                            <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                                            <DxColumn :width="80" caption=""></DxColumn>
                                            <DxColumn data-field="date" caption="Fecha" :min-width="100"></DxColumn>
                                            <DxColumn data-field="detail" caption="Detalle" :allow-editing="true" :min-width="200"></DxColumn>
                                            <DxColumn data-field="doctor" caption="Médico" :min-width="200"></DxColumn>
                                            <DxColumn data-field="quantity" caption="Cantidad" :allow-editing="true" :min-width="100"></DxColumn>
                                            <DxColumn data-field="price" caption="Precio" :min-width="100"></DxColumn>
                                            <DxColumn cell-template="total-template" caption="Total" :min-width="100"></DxColumn>
                                            <DxColumn :width="80" cell-template="delete-template" caption=""></DxColumn>
                                            <template #delete-template="{ data }">
                                                <a href="#" class="mx-1" @click.prevent.stop="deleteItem(data.data, 2, null)">
                                                    <v-icon color="error">mdi-delete</v-icon>
                                                </a>
                                            </template>
                                            <template #total-template="{ data }">
                                                <div>
                                                    $ {{ getItemTotal(data.data.price, data.data.quantity, 2) }}
                                                </div>
                                            </template>
                                        </DxDataGrid>
                                    </div>
                                    <h3 v-if="form.services.length" style="margin: 12px 0; align-self: flex-end; text-align: right;">Subtotal: $ {{ form.servicesSubTotal }}</h3>
                                </v-col>

                                <v-row style="padding: 12px 24px;">
                                    <v-col cols="12" class="total-col">
                                        <h2 style="text-align: center; margin: 1rem;">
                                            Total: $ {{ form.total }}
                                        </h2>
                                    </v-col>
                                </v-row>
                                
                                <v-dialog v-model="showMedications" :width="'90%'">
                                    <v-card flat class="pa-4">
                                        <div style="padding: 0 1rem; display: flex; justify-content: flex-end;">
                                            <v-btn small class="btndelete" @click.prevent="resetDialog">
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </div>
                                        <GeneralFilter 
                                            class="background border-box" 
                                            entity="medications"
                                            :filterEndpoint="$API.products.getProductsToBillForInvoiceFiltered" 
                                            :search="filter"
                                            @emptyFilter="getProductsOnFilter" 
                                            @filtered="filterHandler"
                                        >
                                            <v-row style="padding: 1rem;">
                                                <v-col cols="12" md="3" sm="4">
                                                    <v-autocomplete
                                                        v-model="selectedWarehouse"
                                                        item-text="Warehouse"
                                                        item-value="ID_Warehouse"
                                                        :items="hospitalWarehouses"
                                                        @change="handleSelectWarehouse"
                                                        label="Almacenes de hospital"
                                                        ref="werehouseRef"
                                                        dense 
                                                        outlined 
                                                        attach
                                                    >
                                                    </v-autocomplete>
                                                </v-col>
                                            </v-row>
                                            <DxDataGrid  
                                                :show-borders="true" 
                                                :data-source="medications"
                                                :column-auto-width="true"
                                                key-expr="ID_Item" 
                                                style="width: fit-content; overflow-x: auto;"
                                            >
                                                <DxPaging :page-size="5" />
                                                <DxColumn :width="80" cell-template="add-template" caption=""></DxColumn>
                                                <DxColumn data-field="ID_Item" caption="ID" :min-width="100"></DxColumn>
                                                <DxColumn data-field="ItemName" caption="Nombre" :min-width="200"></DxColumn>
                                                <DxColumn data-field="ItemComercialName" caption="Nombre Comercial" :min-width="200"></DxColumn>
                                                <DxColumn data-field="ItemPrice" caption="Precio" :min-width="100"></DxColumn>
                                                <DxColumn :width="80" caption=""></DxColumn>
                                                <template #add-template="{ data }">
                                                    <div>
                                                        <a href="#" @click.prevent.stop="addMedication(data.data, medList)">
                                                            <v-icon>mdi-plus</v-icon>
                                                        </a>
                                                    </div>
                                                </template>
                                            </DxDataGrid>
                                        </GeneralFilter>
                                    </v-card>
                                </v-dialog>

                                <v-dialog v-model="showServices" :width="'90%'">
                                    <v-card flat class="pa-4">
                                        <div style="padding: 0 1rem; display: flex; justify-content: flex-end;">
                                            <v-btn small class="btndelete" @click.prevent="resetDialog">
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </div>
                                        <GeneralFilter 
                                            class="background border-box" 
                                            entity="services"
                                            :filterEndpoint="$API.services.getAllServicesWithPriceListFiltered" 
                                            :search="filter"
                                            @emptyFilter="getServicesOnFilter" 
                                            @filtered="filterHandler"
                                        >
                                            <DxDataGrid  
                                                :show-borders="true" 
                                                :data-source="services"
                                                :column-auto-width="true"
                                                key-expr="ID_Service" 
                                                style="width: fit-content; overflow-x: auto;"
                                            >
                                                <DxPaging :page-size="5" />
                                                <DxColumn :width="80" cell-template="add-template" caption=""></DxColumn>
                                                <DxColumn data-field="CodeService" caption="Codigo" :min-width="100"></DxColumn>
                                                <DxColumn data-field="NameService" caption="Nombre" :min-width="200"></DxColumn>
                                                <DxColumn data-field="Name" caption="Nombre Comercial" :min-width="200"></DxColumn>
                                                <DxColumn data-field="Price" caption="Precio" :min-width="100"></DxColumn>
                                                <DxColumn :width="80" caption=""></DxColumn>
                                                <template #add-template="{ data }">
                                                    <div>
                                                        <a href="#" @click.prevent.stop="addService(data.data, 1)">
                                                            <v-icon>mdi-plus</v-icon>
                                                        </a>
                                                    </div>
                                                </template>
                                            </DxDataGrid>
                                        </GeneralFilter>
                                    </v-card>
                                </v-dialog>

                                <v-dialog v-model="showFormsDialog" :width="'90%'" scrollable>
                                    <v-card flat class="pa-4">
                                        <v-card-text>
                                            <div style="padding: 0 1rem; display: flex; justify-content: flex-end;">
                                                <v-btn small class="btndelete" @click.prevent="showFormsDialog = false">
                                                    <v-icon>mdi-close</v-icon>
                                                </v-btn>
                                            </div>
                                            <GeneralFilter 
                                                class="background border-box" 
                                                entity="forms"
                                                :filterEndpoint="$API.veterinarypatientshospitalization.getFilteredForms" 
                                                :search="filter"
                                                @emptyFilter="getFormsOnFilter" 
                                                @filtered="handleFilterForms"
                                            >
                                                <p style="font-size: 12px; text-align: right; padding: 0.5rem 1rem;">Ingresar fecha de internación en la busqueda</p>
                                                <DxDataGrid 
                                                    class="isScrolledByGeneralFilter" 
                                                    :show-borders="true" 
                                                    :data-source="formsData"
                                                    :column-auto-width="true"
                                                    key-expr=""
                                                    style="width: fit-content;"
                                                >
                                                    <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                                                    <!-- <DxColumn data-field="id" caption="ID" :min-width="100"></DxColumn> -->
                                                    <DxColumn data-field="admissionDate" caption="Fecha de ingreso" :min-width="100"></DxColumn>
                                                    <DxColumn data-field="doctor" caption="Medico responsable" :min-width="200"></DxColumn>
                                                    <DxColumn data-field="total" caption="Total facturado" :min-width="100"></DxColumn>
                                                    <DxColumn :width="80" cell-template="delete-template" caption="" v-if="$store.getters['rolesUser/getCustomRole'](139)">
                                                    </DxColumn>
                                                    <template #show-template="{ data }">
                                                        <div>
                                                            <a href="#" @click.prevent.stop="selectForm(data.data)">
                                                                <v-icon color="primary">mdi-eye</v-icon>
                                                            </a>
                                                        </div>
                                                    </template>
                                                    <template #delete-template="{ data }">
                                                        <div>
                                                            <a href="" class="mx-2" @click.prevent.stop="confirmDelete(data.data.id)">
                                                                <v-icon color="error">mdi-delete</v-icon>
                                                            </a>
                                                        </div>
                                                    </template>
                                                </DxDataGrid>
                                            </GeneralFilter>
                                        </v-card-text>
                                    </v-card>
                                </v-dialog>
                            </div>
                        </v-form>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <alert
            v-model="alert.show"
            @action="alert.action"
            :type="alert.type"
            :title="alert.title"
        >
            {{ alert.message }}
        </alert>
    </div>
</template>

<script>
    import Vue from "vue";
    import Block from "@/components/Block";
    import { DxDataGrid, DxColumn, DxPaging, DxEditing } from "devextreme-vue/data-grid";
    import GeneralFilter from "@/components/GeneralFilter";
    import DatePicker from "../../../components/DatePicker";
    import UserProfile from "@/components/Displays/UserProfileDisplay.vue";
    import UserProfileLab from "@/components/Displays/UserProfileDisplayLab.vue";

    export default {
        name: 'ManageVeterinaryHospitalization',
        components: {
            DxDataGrid,
            DxColumn,
            DxPaging,
            DxEditing,
            DatePicker,
            Block,
            GeneralFilter,
            UserProfile,
            UserProfileLab,
        },
        props: ["data", "win", "type"],
        data: () => ({
            loading: false,
            patientinfo: [],
            useData: false,
            date: null,
            showFormsDialog: false,
            showMedications: false,
            showServices: false,
            selectedService: null,
            hospitalServices: null,
            selectedWarehouse: null,
            hospitalWarehouses: null,
            hostServices: null,
            medicationsDefault: [],
            medications: [],
            servicesDefault: [],
            services: [],
            filter: {},
            isFilteredForms: false,
            selectedForm: null,
            formsData: [],
            toDeleted: null,
            medList: null,
            form: {
                patient: null,
                client: null,
                phone: null,
                admissionDate: null,
                egress: false,
                egressDate: null,
                doctor: null,
                deposit: null,
                refPatient: null,
                customer: {},
                hosted: {
                    status: false,
                    days: null,
                    price: null,
                },
                hospitalized: {
                    status: false,
                    days: null,
                    price: null,
                },
                medications: [],
                medications1: [],
                medications2: [],
                //notDetails1: false,
                notDetails1: true,
                notDetails2: false,
                serviceToInvoice1: null,
                serviceToInvoice2: null,
                services: [],
                hideDetailsMedicationService: [],
                hostedSubTotal: 0,
                hospitalizedSubTotal: 0,
                medicationsSubTotal1: 0,
                medicationsSubTotal2: 0,
                servicesSubTotal: 0,
                total: 0,
            },
            alert: {
                show: false,
                type: "info",
                title: "",
                message: "",
                action: (() => null),
            },        
        }),
        async mounted () {
            this.loading = true;

            if (this.data) {
                Object.keys(this.data).map((el) => {
                    Vue.set(this.patientinfo, el.toLowerCase(), this.data[el]);
                });
                this.useData = JSON.parse(localStorage.getItem("user"));
                console.log(this.useData);
            }
            this.$store.dispatch("rolesUser/getAllRoles");

            this.filter.business = this.useData.businessid;
            this.filter.branch = this.useData.branch;
            this.filter.customer = this.data.ID_Customer;
            this.filter.patient = this.data.ID_Patient;
            
            //this.medications = await this.$API.products.getProducts({business: this.useData.businessid , branch: this.useData.branch, filter: true});
            //this.services = await this.$API.services.getAllServices({withInnerjoins: true});

            this.hospitalWarehouses = await this.$API.warehouse.getWarehousesFiltered({ isHospital: true });

            if (this.hospitalWarehouses.length == 0) {
                this.showAlert("warning", "Advertencia", "No se ha encontrado ningún almacen de tipo Hospital.");
            }

            this.selectedWarehouse = this.hospitalWarehouses.length == 0 ? null : this.hospitalWarehouses[0].ID_Warehouse;
            this.filter.warehouse = this.selectedWarehouse;
            
            await this.getProductsOnFilter();
            await this.getServicesOnFilter();
            await this.getFormsOnFilter();

            this.form.patient = this.data.NamePatient;
            this.form.client = this.data.customer.FirstName.concat(' ', this.data.customer.LastName);
            this.form.phone = this.data.customer.PhoneNumber;
            this.form.customer = this.data.customer;
            this.form.doctor = this.useData.firstName.concat(' ', this.useData.lastName);
            this.loading = false;
        },
        watch: {
            'form.hospitalized': {
                deep: true,
                immediate: true,
                async handler(val) {
                    if(val.status) {
                        this.form.hosted.status = false;
                    }
                    if(val.days && val.price) {
                        this.form.hospitalizedSubTotal = (Number.parseFloat(val.price.replace(',','.')) * Number.parseInt(val.days)).toFixed(2);
                    }
                    if(val.data) {
                        await this.$API.services.getAllHospServices().then(res => this.hospitalServices = res.data);
                        this.selectedService = val.data.CodeService;
                    }
                    this.form.total = this.getTotal();
                }
            },
            'form.hosted': {
                deep: true,
                immediate: true,
                async handler(val) {
                    if(val.status) {
                        this.form.hospitalized.status = false;
                    }
                    if(val.days && val.price) {
                        this.form.hostedSubTotal = (Number.parseFloat(val.price.replace(',','.')) * Number.parseInt(val.days)).toFixed(2);
                    }
                    if(val.data) {
                        await this.$API.services.getAllHostServices().then(res => this.hospitalServices = res.data);
                        this.selectedService = val.data.CodeService;
                    }
                    this.form.total = this.getTotal();
                }
            },
            'form.medications1': {
                deep: true,
                immediate: true,
                handler(val) {
                    let num = 0;
                    val.forEach(item => {
                        const subtotal = Number.parseFloat(item.price * item.quantity);
                        num += subtotal;
                    });
                    this.form.medicationsSubTotal1 = num.toFixed(2);
                    this.form.total = this.getTotal();
                }
            },
            'form.medications2': {
                deep: true,
                immediate: true,
                handler(val) {
                    let num = 0;
                    val.forEach(item => {
                        const subtotal = Number.parseFloat(item.price * item.quantity);
                        num += subtotal;
                    });
                    this.form.medicationsSubTotal2 = num.toFixed(2);
                    this.form.total = this.getTotal();
                }
            },
            'form.services': {
                deep: true,
                immediate: true,
                handler(val) {
                    let num = 0;
                    val.forEach(item => {
                        const subtotal = Number.parseFloat(item.price * item.quantity);
                        num += subtotal;
                    });
                    this.form.servicesSubTotal = num.toFixed(2);
                    this.form.total = this.getTotal();
                }
            },
            formsData: {
                deep: true,
                immediate: true,
                handler() {
                    this.handleHistoryDate(this.date);
                }
            },               
        },
        methods: {
            showAlert(type, title, message, action) {
                type = type == null ? "info" : type;
                this.alert.type = type;
                this.alert.title = title;
                this.alert.message = message;
                this.alert.action = action ? action : (() => null);
                this.alert.show = true;
            },
            async resetDialog() {
                this.showMedications = false;
                this.showServices = false;
                this.medList = null;
                await this.getProductsOnFilter();
                await this.getServicesOnFilter();
            },
            resetForm() {
                this.loading = true;
                this.selectedForm = null;
                this.selectedService = null;
                this.date = null;
                this.isFilteredForms = false;
                this.form = {
                    patient: null,
                    client: null,
                    phone: null,
                    admissionDate: null,
                    egress: false,
                    egressDate: null,
                    doctor: null,
                    deposit: null,
                    refPatient: null,
                    customer: {},
                    hosted: {
                        status: false,
                        days: null,
                        price: null,
                    },
                    hospitalized: {
                        status: false,
                        days: null,
                        price: null,
                    },
                    medications: [],
                    medications1: [],
                    medications2: [],
                    //notDetails1: false,
                    notDetails1: true,
                    notDetails2: false,
                    serviceToInvoice1: null,
                    serviceToInvoice2: null,
                    services: [],
                    hideDetailsMedicationService: [],
                    hostedSubTotal: 0,
                    hospitalizedSubTotal: 0,
                    medicationsSubTotal1: 0,
                    medicationsSubTotal2: 0,
                    servicesSubTotal: 0,
                    total: 0,
                };
                this.form.patient = this.data.NamePatient;
                this.form.client = this.data.customer.FirstName?.concat(' ', this.data.customer.LastName);
                this.form.phone = this.data.customer.PhoneNumber;
                this.form.customer = this.data.customer;
                this.form.doctor = this.useData.firstName?.concat(' ', this.useData.lastName);
                this.loading = false;
            },
            selectForm(data) {
                if(this.isFilteredForms) return;
                this.loading = true;
                this.showFormsDialog = false;
                this.selectedForm = data;
                this.form = {
                    patient: data.patient,
                    client: data.client,
                    phone: data.phone,
                    admissionDate: data.admissionDate,
                    egress: data.egress,
                    egressDate: data.egressDate,
                    doctor: data.doctor,
                    deposit: data.deposit,
                    refPatient: data.refPatient,
                    customer: data.customer,
                    hosted: data.hosted,
                    hospitalized: data.hospitalized,
                    medications1: data.medications1,
                    medications2: data.medications2,
                    //notDetails1: data.notDetails1,
                    notDetails1: data.notDetails1 || true,
                    notDetails2: data.notDetails2,
                    serviceToInvoice1: data.serviceToInvoice1,
                    serviceToInvoice2: data.serviceToInvoice2,
                    services: data.services,
                    hostedSubTotal: data.hostedSubTotal,
                    hospitalizedSubTotal: data.hospitalizedSubTotal,
                    medicationsSubTotal1: data.medicationsSubTotal1,
                    medicationsSubTotal2: data.medicationsSubTotal2,
                    servicesSubTotal: data.servicesSubTotal,
                    total: data.total,
                };
                this.date = data.admissionDate;
                this.form.patient = this.data.NamePatient;
                this.form.client = this.data.customer.FirstName?.concat(' ', this.data.customer.LastName);
                this.form.phone = this.data.customer.PhoneNumber;
                this.form.customer = this.data.customer;
                this.form.doctor = this.useData.firstName?.concat(' ', this.useData.lastName);
                this.loading = false;
            },
            handleHistoryDate(date) {
                this.loading = true;
                let isToday = false;
                this.formsData.forEach((el) => {
                    if (el.admissionDate == date && this.selectedForm == null) {
                        this.selectForm(el);
                        isToday = true;
                    }
                });
                if (!isToday && this.selectedForm == null) {
                    this.resetForm();
                    this.form.admissionDate = date;
                    this.date = date;
                }
                if (!isToday && this.selectedForm != null) {
                    this.date = date;
                }
                this.loading = false;
            },
            saveForm() {
                if(!this.form.admissionDate) {
                    this.showAlert("warning", "Advertencia", "Debe seleccionar una fecha de ingreso.");
                    return;
                }
                if(!this.form.medications1.length && !this.form.medications2.length && !this.form.services.length && !this.form.hospitalized.status && !this.form.hosted.status) {
                    this.showAlert("warning", "Advertencia", "Debe registrar alguna información antes de guardar.");
                    return;
                }
                if(this.form.medications1.length && !this.form.serviceToInvoice1) {
                    this.showAlert("warning", "Advertencia", "Debe seleccionar un servicio a mostrar en el detalle de la factura.");
                    return;
                }
                if(this.form.notDetails2 && this.form.medications2.length && !this.form.serviceToInvoice2) {
                    this.showAlert("warning", "Advertencia", "Debe seleccionar un servicio a mostrar en el detalle de la factura.");
                    return;
                }
                this.loading = true;
                this.$API.veterinarypatientshospitalization.saveForm({customer: this.data.ID_Customer, patient: this.data.ID_Patient, form: this.form , date: this.date})
                    .then((res) => {
                        console.log(res);
                        if(res.success) {
                            this.loading = false;
                            this.getFormsOnFilter();
                            this.showAlert("success", "Exito", "Se han guardado los datos ingresados al formulario.");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.loading = false
                        this.showAlert("error", "Error", "Ha ocurrido un error, por favor intentarlo nuevamente.");
                    })
            },
            updateForm() {
                if(this.form.medications1.length && !this.form.serviceToInvoice1) {
                    this.showAlert("warning", "Advertencia", "Debe seleccionar un servicio a mostrar en el detalle de la factura.");
                    return;
                }
                if(this.form.notDetails2 && this.form.medications2.length && !this.form.serviceToInvoice2) {
                    this.showAlert("warning", "Advertencia", "Debe seleccionar un servicio a mostrar en el detalle de la factura.");
                    return;
                }
                this.loading = true;
                this.$API.veterinarypatientshospitalization.updateForm({customer: this.data.ID_Customer, patient: this.data.ID_Patient, form: this.form , id: this.selectedForm.id})
                    .then((res) => {
                        console.log(res);
                        if(res.success) {
                            this.loading = false;
                            this.getFormsOnFilter();
                            this.showAlert("success", "Exito", "Se han guardado los datos ingresados al formulario.");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.loading = false
                        this.showAlert("error", "Error", "Ha ocurrido un error, por favor intentarlo nuevamente.");
                    })
            },
            confirmDelete(data) {
                this.toDeleted = data;
                this.showFormsDialog = false;
                this.showAlert("confirm-danger", "Eliminar registro", "¿Está seguro que desea eliminar el formulario del paciente?", this.deleteForm);
            },
            deleteForm() {
                this.loading = true;
                this.$API.veterinarypatientshospitalization.deleteForm({customer: this.data.ID_Customer, patient: this.data.ID_Patient, id: this.toDeleted})
                    .then((res) => {
                        console.log(res);
                        if(res.success) {
                            this.loading = false;
                            this.getFormsOnFilter();
                            this.showAlert("success", "Exito", "Se han eliminado los datos del formulario.");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.loading = false
                        this.showAlert("error", "Error", "Ha ocurrido un error, por favor intentarlo nuevamente.");
                    })
            },
            billItemsToCustomer() {
                if(this.getTotal() == 0) {
                    this.showAlert("warning", "Advertencia", "Debe seleccionar productos o servicios para facturar.");
                    return;
                }
                this.loading = true;
                
                if(this.form.notDetails1 && this.form.notDetails2) {
                    this.form.medications = [];
                    const serviceToShow1 = this.servicesDefault.find(item => item.CodeService === this.form.serviceToInvoice1);
                    const serviceToShow2 = this.servicesDefault.find(item => item.CodeService === this.form.serviceToInvoice2);
                    serviceToShow1.price = this.form.medicationsSubTotal1;
                    serviceToShow1.quantity = 1;
                    serviceToShow2.price = this.form.medicationsSubTotal2;
                    serviceToShow2.quantity = 1;
                    this.form.hideDetailsMedicationService = [serviceToShow1, serviceToShow2];
                }
                else if(this.form.notDetails1 && !this.form.notDetails2) {
                    this.form.medications = this.form.medications2;
                    const serviceToShow1 = this.servicesDefault.find(item => item.CodeService === this.form.serviceToInvoice1);
                    serviceToShow1.price = this.form.medicationsSubTotal1;
                    serviceToShow1.quantity = 1;
                    this.form.hideDetailsMedicationService = [serviceToShow1];
                }
                else if(!this.form.notDetails1 && this.form.notDetails2) {
                    this.form.medications = this.form.medications1;
                    const serviceToShow2 = this.servicesDefault.find(item => item.CodeService === this.form.serviceToInvoice2);
                    serviceToShow2.price = this.form.medicationsSubTotal2;
                    serviceToShow2.quantity = 1;
                    this.form.hideDetailsMedicationService = [serviceToShow2];
                }

                if (!this.form.notDetails1 && !this.form.notDetails2) this.form.medications = this.form.medications1.concat(this.form.medications2);
                
                this.$API.veterinarypatientshospitalization.billItems({customer: this.data.ID_Customer, patient: this.data.ID_Patient, form: this.form})
                    .then((res) => {
                        console.log(res);
                        if(res.success) {
                            this.loading = false;
                            this.showAlert("success", "Exito", "Se ha realizado la factura de la ficha de internación, podrá observarla en el módulo de POS en listo para facturar.");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.loading = false;
                        this.showAlert("error", "Error", "Ha ocurrido un error, por favor intentarlo nuevamente.");
                    })
            },
            getItemTotal(price, quantity) {
                const num = Number.parseFloat(price * quantity).toFixed(2);
                return num;
            },
            getTotal() {
                let total = 0;

                if(parseFloat(this.form.medicationsSubTotal1) > 0) {
                    total += parseFloat(this.form.medicationsSubTotal1);
                }
                if(parseFloat(this.form.medicationsSubTotal2) > 0) {
                    total += parseFloat(this.form.medicationsSubTotal2);
                }
                if(parseFloat(this.form.servicesSubTotal) > 0) {
                    total += parseFloat(this.form.servicesSubTotal);
                }
                if(this.form.hosted.status && parseFloat(this.form.hostedSubTotal) > 0) {
                    total += parseFloat(this.form.hostedSubTotal);
                }
                if(this.form.hospitalized.status && parseFloat(this.form.hospitalizedSubTotal) > 0) {
                    total += parseFloat(this.form.hospitalizedSubTotal);
                }
                
                return total.toFixed(2);
            },
            getProductsOnFilter() {
                this.$API.products.getProductsToBillForInvoice(this.filter)
                    .then((res) => {
                        this.medicationsDefault = res;
                        this.medications = res;
                    });
            },
            getServicesOnFilter() {
                this.$API.services.getAllServicesWithPriceList(this.filter)
                    .then((res) => {
                        this.servicesDefault = res;
                        this.services = res;
                    });
            },
            getFormsOnFilter() {
                this.$API.veterinarypatientshospitalization.getAllForms({customer: this.data.ID_Customer, patient: this.data.ID_Patient})
                    .then((res) => {
                        this.formsData = res;
                    });
            },
            filterHandler(response) {
                this.medications = response;
                this.services = response;
            },
            handleFilterForms(response) {
                this.isFilteredForms = true;
                this.formsData = response;
            },
            async handleCheckbox(type) {
                this.loading = true;
                if(type == 'HOSP') {
                    if(!this.form.admissionDate) {
                        this.showAlert("info", "Advertencia", "Antes de agregar datos debe seleccionar la fecha de ingreso.");
                        this.form.hospitalized.status = false;
                        this.loading = false;
                        console.log(this.form.hospitalized.status);
                        return;
                    }
                    if(this.hospitalServices) {
                        this.form.hospitalized.status = false;
                        this.form.hospitalized.price = null;
                        this.form.hospitalized.data = null;
                        this.hospitalServices = null;
                        this.selectedService = null;
                        this.loading = false;
                        return;
                    }
                    this.form.hosted.status = false;
                    this.hostServices = null;
                    await this.$API.services.getAllHospServices()
                        .then(res => {
                            if(res.success) {
                                if(!res.data.length) {
                                    this.form.hospitalized.status = false;
                                    this.loading = false;
                                    this.showAlert("warning", "Advertencia", "No se encuentran servicios de hospitalización, debera agregarlos en la pestaña de servicios e intentarlo nuevamente.");
                                    return;
                                }
                                this.form.hospitalized.status = true;
                                //this.form.hospitalized.price = res.data.Price || 0;
                                //this.form.hospitalized.data = res.data;
                                this.hospitalServices = res.data;
                                this.loading = false;
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            this.form.hospitalized.status = false;
                            this.loading = false;
                            this.showAlert("error", "Error", "Ha ocurrido un error, por favor intentarlo nuevamente.");
                        })
                }
                
                if(type == 'HOST') {
                    if(!this.form.admissionDate) {
                        this.showAlert("info", "Advertencia", "Antes de agregar datos debe seleccionar la fecha de ingreso.");
                        this.form.hosted.status = false;
                        this.loading = false;
                        console.log(this.form.hosted.status);
                        return;
                    }
                    if(this.hostServices) {
                        this.form.hosted.status = false;
                        this.form.hosted.price = null;
                        this.form.hosted.data = null;
                        this.hostServices = null;
                        this.selectedService = null;
                        this.loading = false;
                        return;
                    }
                    this.form.hospitalized.status = false;
                    this.hospitalServices = null;
                    await this.$API.services.getAllHostServices()
                        .then(res => {
                            if(res.success) {
                                if(!res.data.length) {
                                    this.form.hosted.status = false;
                                    this.loading = false;
                                    this.showAlert("warning", "Advertencia", "No se encuentran servicios de hospedaje, debera agregarlos en la pestaña de servicios e intentarlo nuevamente.");
                                    return;
                                }
                                this.form.hosted.status = true;
                                //this.form.hosted.price = res.data.Price || 0;
                                //this.form.hosted.data = res.data;
                                this.hostServices = res.data;
                                this.loading = false;
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            this.form.hosted.status = false;
                            this.loading = false;
                            this.showAlert("error", "Error", "Ha ocurrido un error, por favor intentarlo nuevamente.");
                        })
                }
            },
            async handleSelect(type) {
                console.log(this.selectedService);
                this.loading = true;
                if(type == 'HOSP') {
                    this.$refs.hospRef.blur();
                    await this.$API.services.getHospService(this.selectedService)
                        .then(res => {
                            if(res.success) {
                                this.form.hospitalized.price = res.data.Price;
                                this.form.hospitalized.data = res.data;
                                this.$refs.hospDaysRef.focus();
                                this.loading = false;
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            this.selectedService = null;
                            this.form.hospitalized.price = null;
                            this.form.hospitalized.data = null;
                            this.loading = false;
                            this.showAlert("error", "Error", "Ocurrio un error al seleccionar el servicio, intentelo nuevamente.");
                        })
                }
                
                if(type == 'HOST') {
                    this.$refs.hostRef.blur();
                    await this.$API.services.getHostService(this.selectedService)
                        .then(res => {
                            if(res.success) {
                                this.form.hosted.price = res.data.Price;
                                this.form.hosted.data = res.data;
                                this.$refs.hostDaysRef.focus();
                                this.loading = false;
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            this.selectedService = null;
                            this.form.hosted.price = null;
                            this.form.hosted.data = null;
                            this.loading = false;
                            this.showAlert("error", "Error", "Ocurrio un error al seleccionar el servicio, intentelo nuevamente.");
                        })
                }
            },
            async handleSelectWarehouse() {
                this.loading = true;
                this.filter.warehouse = this.selectedWarehouse;
                await this.getProductsOnFilter();
                this.$refs.werehouseRef.blur();
                this.loading = false;
            },
            addMedication(data, type) {
                if(!this.form.admissionDate) {
                    this.showMedications = false;
                    this.showAlert("info", "Advertencia", "Antes de agregar datos debe seleccionar la fecha de ingreso.");
                    return;
                }
                const newItem = {
                    date: this.date,
                    detail: data.ItemName,
                    doctor: this.form.doctor,
                    quantity: 1,
                    price: Number.parseFloat(data.ItemPrice).toFixed(2),
                    ...data
                };
                if(type === 1) {
                    if(!this.form.medications1.length) {
                        return this.form.medications1.push(newItem);
                    }
    
                    const isAdded = this.form.medications1.find((item) => {
                        if(item.ID_Item == newItem.ID_Item && item.date == newItem.date && item.doctor == newItem.doctor) return item;
                    });
    
                    if(!isAdded) {
                        return this.form.medications1.push(newItem);
                    }
                    else {
                        isAdded.quantity++;
                    }
                }
                if(type === 2) {
                    if(!this.form.medications2.length) {
                        return this.form.medications2.push(newItem);
                    }
    
                    const isAdded = this.form.medications2.find((item) => {
                        if(item.ID_Item == newItem.ID_Item && item.date == newItem.date && item.doctor == newItem.doctor) return item;
                    });
    
                    if(!isAdded) {
                        return this.form.medications2.push(newItem);
                    }
                    else {
                        isAdded.quantity++;
                    }
                }
            },
            addService(data, quantity) {
                if(!this.form.admissionDate) {
                    this.showServices = false;
                    this.showAlert("info", "Advertencia", "Antes de agregar datos debe seleccionar la fecha de ingreso.");
                    return;
                }
                const newItem = {
                    date: this.date,
                    detail: data.NameService,
                    doctor: this.form.doctor,
                    quantity: quantity,
                    price: Number.parseFloat(data.Price).toFixed(2),
                    ...data,
                };

                if(!this.form.services.length) {
                    return this.form.services.push(newItem);
                }

                const isAdded = this.form.services.find((item) => {
                    if(item.ID_Service == newItem.ID_Service && item.date == newItem.date && item.doctor == newItem.doctor) return item;
                });

                if(!isAdded) {
                    return this.form.services.push(newItem);
                }
                else {
                    isAdded.quantity++;
                }
            },
            deleteItem(data, type, medicationList) {
                if(type === 1) {
                    if(medicationList === 1) {
                        const itemIndex = this.form.medications1.findIndex((item) => item.ID_Item === data.ID_Item && item.date === data.date && item.doctor === data.doctor);
        
                        if (itemIndex !== -1) {
                            return this.form.medications1.splice(itemIndex, 1);
                        }
                    }
                    if(medicationList === 2) {
                        const itemIndex = this.form.medications2.findIndex((item) => item.ID_Item === data.ID_Item && item.date === data.date && item.doctor === data.doctor);
        
                        if (itemIndex !== -1) {
                            return this.form.medications2.splice(itemIndex, 1);
                        }
                    }
                }
                if(type === 2) {
                    const itemIndex = this.form.services.findIndex((item) => item.ID_Service === data.ID_Service && item.date === data.date && item.doctor === data.doctor);

                    if (itemIndex !== -1) {
                        this.form.services.splice(itemIndex, 1);
                    }
                }
            },
            addNewRow() {
                if(!this.form.admissionDate) {
                    this.showAlert("info", "Advertencia", "Antes de agregar datos debe seleccionar la fecha de ingreso.");
                    return;
                }
                this.form.medications1.push({
                    date: this.date,
                    detail: '',
                    doctor: this.form.doctor,
                    quantity: 1,
                    price: 0,
                    ID_Item: `${this.date}_${this.form.medications1.length+1}`
                });
            },
        }

    }

</script>

<style scoped>
    .tabsB {
        width: 100%;
        padding: 10px;
        background-color: #1976d2;
        color: white;
        border-radius: 15px;
        border-style: solid;
        border-color: black;
        border-width: 1px;
    }

    .tabsB:hover {
        cursor: pointer;
    }

    .tabs:hover {
        background-color: rgba(80, 80, 80, 1);
        cursor: pointer;
    }

    .btnblue {
        background-color: #1976d2 !important;
        color: white;
    }

    .btnclean {
        background-color: #f29d35 !important;
        color: white;
    }

    .btnsave {
        background-color: #00a178 !important;
        color: white;
    }

    .tabs {
        width: 100%;
        padding: 10px;
        background-color: rgba(60, 60, 60, 1);
        color: white;
    }

    .text-white {
        color: white !important;
    }

    .box {
        border-style: solid;
        border-color: black;
        border-width: 1px;
        background-color: white;

        border-radius: 15px;
    }

    .label-forms {
        border-radius: 15px;
    }

    .tabs {
        padding: 10px;
        background-color: rgba(60, 60, 60, 1);
        color: white;
    }

    .btnblack {
        background-color: rgba(60, 60, 60, 1) !important;
        color: white !important;
    }

    .tabsNh {
        width: 100%;
        padding: 10px;
        background-color: rgba(60, 60, 60, 1);
        color: white !important;
        border-style: solid;
        border-color: black;
        border-width: 1px;
        border-radius: 10px;
    }

    .total-col {
        width: 100%;
        margin: 1rem 0;
        background-color: rgb(115, 115, 115);
        border-style: solid;
        border-color: black;
        border-width: 1px;
        border-radius: 10px;
    }

    .infopatient {
        margin-top: 5%;
    }
</style>

<style>
    .items-grid .dx-datagrid {
        padding: 0;
    }
</style>
