<template>
    <div>
        <block v-if="loading"></block>
        <v-card flat>
            <v-card-text style="z-index: 1">
                <template v-if="type === 'medicalclinic'">
                    <user-profile-lab :patient="data" :patientinfo="data" :useData="useData"></user-profile-lab>
                </template>
                <template v-else>
                    <user-profile :patientinfo="patientinfo" :data="data" :useData="useData" />
                </template>

                <v-row style="margin-top: 1rem">
                    <v-col cols="12" class="box" style="margin-top: 1rem">
                        <div>
                            <v-row class="ml-1 mr-1" style="margin-top: 0.3rem">
                                <v-row style="margin: 0.2rem">
                                    <v-col cols="12" class="tabsNh">
                                        <v-col cols="12" md="12" style="display: flex; justify-content: space-between">
                                            <div>
                                                <v-btn
                                                    v-if="$store.getters['rolesUser/getCustomRole'](142) && selectedValues"
                                                    small class="btndelete" @click.prevent="confirmDeleteAll">
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </div>
                                            <div>
                                                <v-btn v-if="$store.getters['rolesUser/getCustomRole'](107)" small
                                                    class="btn-add mr-1"
                                                    @click.prevent="getItemsToBeBilled(); showBillPatient = true">
                                                    <v-icon class="mr-3">mdi-account-details</v-icon> Facturar paciente
                                                </v-btn>
                                            </div>

                                            <div>
                                                <v-btn small class="btn mr-1" @click="assigning( prescription, patientinfo, useData, usenextappointment )">
                                                    <v-icon> mdi-printer </v-icon>
                                                    <h4 style="margin: 0 5px;">Imprimir prescripción</h4>
                                                </v-btn>
                                                <!--v-btn small class="btn mr-1" 
                                                    @click="
                                                        printReproduction = {
                                                            show: true,
                                                            data: {
                                                            date: reproduction.ReproductionDate,
                                                            reproduction: { ...reproduction, breeds },
                                                            patientinfo: patientinfo,
                                                            useData: useData,
                                                            customer: patientinfo.customer,
                                                            },
                                                        }
                                                    "
                                                    >
                                                    <v-icon> mdi-printer </v-icon> Imprimir reproducción
                                                </v-btn-->
                                                <v-btn small class="btnclean mr-1" @click.prevent.stop="resetData()">
                                                    <v-icon> mdi-broom </v-icon>
                                                </v-btn>
                                                <v-btn small v-if="!selectedValues && $store.getters['rolesUser/getCustomRole'](172)
                                                " class="btnsave" @click.prevent.stop="saveAll()">
                                                    <v-icon> mdi-content-save </v-icon>
                                                </v-btn>
                                                <v-btn small v-if="selectedValues &&
                                                    $store.getters['rolesUser/getCustomRole'](172)
                                                " class="btnsave" @click.prevent.stop="updateAll()">
                                                    <v-icon> mdi-content-save </v-icon>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-col>
                                </v-row>
                            </v-row>

                            <v-row style="margin: 0.5rem; margin-top: 2rem;">
                                <v-col cols="12" md="2" sm="12">
                                    <date-picker label="Fecha" @dateSelected="(param) => date = param" v-model="date">
                                    </date-picker>
                                </v-col>
                                <v-col cols="6" md="2">
                                    <v-text-field label="Peso" dense outlined v-model="weight"
                                        @change="changeMedicalHistoryWeight">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6" md="2">
                                    <v-select outlined dense attach @change="switchWeightUnit" v-model="weightunit"
                                        :items="['Kg', 'Lb']" label="Unidad de peso">
                                    </v-select>
                                </v-col>
                            </v-row>

                            <v-row style="margin: 0.5rem;">
                                <v-col cols="12">
                                    <v-tabs v-model="tab" centered style="margin-bottom: 0.5rem;">
                                        <v-tab href="#tab-1">Historial Médico</v-tab>
                                        <v-tab href="#tab-2">Prescripción</v-tab>
                                        <v-tab href="#tab-3">Reproducción</v-tab>
                                    </v-tabs>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <v-tabs-items v-model="tab" touchless>
                                        <v-tab-item value="tab-1">
                                            <!-- Medical History Form -->
                                            <v-row style="margin: 1rem 0">
                                                <v-col cols="12">
                                                    <v-form v-model="valid1" class="position-relative">
                                                        <v-row style="padding: 0 1rem">
                                                            <v-col cols="6" md="2">
                                                                <v-text-field label="Talla" dense outlined
                                                                    v-model="medicalhistory.height"></v-text-field>
                                                            </v-col>
                                                            <v-col cols="6" md="2">
                                                                <v-text-field label="Pulso" dense outlined
                                                                    v-model="medicalhistory.pulse"></v-text-field>
                                                            </v-col>
                                                            <v-col cols="6" md="2">
                                                                <v-text-field label="Temperatura" dense outlined
                                                                    v-model="medicalhistory.temp"></v-text-field>
                                                            </v-col>
                                                            <v-col cols="6" md="2">
                                                                <v-text-field label="Frecuencia cardiaca" dense outlined
                                                                    v-model="medicalhistory.heartRate"></v-text-field>
                                                            </v-col>
                                                            <v-col cols="6" md="2">
                                                                <v-text-field label="Frecuencia respiratoria" dense
                                                                    outlined
                                                                    v-model="medicalhistory.breathRate"></v-text-field>
                                                            </v-col>

                                                            <v-col cols="6" md="2">
                                                                <v-checkbox label="Veterinario externo" dense outlined
                                                                    v-model="medicalhistory.vetExt"
                                                                    @change="handlerVetExt"></v-checkbox>
                                                            </v-col>
                                                            <v-col cols="6" md="2">
                                                                <v-text-field v-if="vetExt"
                                                                    label="Nombre del veterinario" dense outlined
                                                                    v-model="medicalhistory.nameExt"></v-text-field>
                                                            </v-col>
                                                        </v-row>

                                                        <v-row justify="end">
                                                            <v-col cols="6" md="3">
                                                                <v-btn class="btnblack" @click="printMedicalHistory()">
                                                                    <v-icon> mdi-printer </v-icon>
                                                                    <h5 style="margin: 0 5px; color: white !important">Imprimir historial médico</h5> 
                                                                </v-btn>
                                                            </v-col>

                                                            <v-col cols="6" md="2">
                                                                <v-btn @click="showCommentsMH = !showCommentsMH"
                                                                    :class="medicalhistory.Comment ? 'btnclean' : 'btnblack'">
                                                                    Comentarios
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>

                                                        <br />
                                                        <v-row class="ml-2 mr-2" style="margin-top: 0.3rem">
                                                            <v-col cols="12" md="6">
                                                                <label class="mb-3 d-inline-block label-forms">Resumen
                                                                    de historia y
                                                                    examen físico</label>
                                                                <span class="ql-editor150">
                                                                    <quill-editor
                                                                        v-model="medicalhistory.PhysicalHistorySummary"
                                                                        :options="quillOptions"
                                                                        @change="onEditorChange($event)"></quill-editor>
                                                                </span>
                                                                <br />
                                                            </v-col>
                                                            <v-col cols="12" md="6">
                                                                <label
                                                                    class="mb-3 d-inline-block label-forms">Diagnóstico
                                                                    Presuntivo
                                                                </label>
                                                                <span class="ql-editor150">
                                                                    <quill-editor
                                                                        v-model="medicalhistory.PresumptiveDiagnosis"
                                                                        :options="quillOptions"
                                                                        @change="onEditorChange($event)"></quill-editor>
                                                                </span>
                                                                <br />
                                                            </v-col>

                                                            <v-col cols="12" md="6">
                                                                <label
                                                                    class="mb-3 d-inline-block label-forms">Diagnóstico
                                                                    Definitivo
                                                                </label>
                                                                <span class="ql-editor150">
                                                                    <quill-editor
                                                                        v-model="medicalhistory.FinalDiagnosis"
                                                                        :options="quillOptions"
                                                                        @change="onEditorChange($event)"></quill-editor>
                                                                </span>
                                                                <br />
                                                            </v-col>
                                                            <v-col cols="12" md="6">
                                                                <label @click="showTreat = !showTreat"
                                                                    class="mb-3 d-inline-block label-forms">Tratamiento
                                                                </label>
                                                                <span class="ql-editor150">
                                                                    <quill-editor v-model="medicalhistory.Treatment"
                                                                        :options="quillOptions"
                                                                        @change="onEditorChange($event)"></quill-editor>
                                                                </span>
                                                                <br />
                                                            </v-col>
                                                        </v-row>
                                                    </v-form>
                                                </v-col>
                                            </v-row>
                                        </v-tab-item>

                                        <v-tab-item value="tab-2">
                                            <v-row style="margin: 1rem 0">
                                                <v-col cols="12">
                                                    <v-form v-model="valid2" class="position-relative">
                                                        <v-row style="padding: 0 1rem">
                                                            <v-col cols="6" md="2" style="visibility: hidden;">
                                                                <date-picker label="Fecha"
                                                                    @dateSelected="handlerDatePicker"
                                                                    :value="prescription.prescriptiondate">
                                                                </date-picker>
                                                            </v-col>
                                                            <v-col cols="6" md="2" style="visibility: hidden;">
                                                                <v-text-field label="Peso" dense outlined
                                                                    v-model="weight"
                                                                    @change="changePrescriptionWeight"></v-text-field>
                                                            </v-col>
                                                            <v-col cols="6" md="2" style="visibility: hidden;">
                                                                <v-select outlined dense attach
                                                                    @change="switchWeightUnit" v-model="weightunit"
                                                                    :items="['Kg', 'Lb']"
                                                                    label="Unidad de peso"></v-select>
                                                            </v-col>
                                                            <v-col cols="12" md="2">
                                                                <div style="display: flex;justify-content: flex-end;">
                                                                    <v-checkbox v-model="usenextappointment"
                                                                        label="Próxima cita"
                                                                        @change="NextAppointmentFunction($event)"
                                                                        value="Appointment"></v-checkbox>
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="12" md="2">
                                                                <div style="display: flex;justify-content: flex-end;">
                                                                    <date-picker @dateSelected="(param) =>
                                                                        (prescription.nextappointment = param)
                                                                        " :value="prescription.nextappointment"
                                                                        :disbaled="usenextappointment.length < 1">
                                                                    </date-picker>
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="12" md="2"
                                                                style="display: flex;justify-content: flex-end;">
                                                                <v-btn @click="showCommentsP = !showCommentsP"
                                                                    this.prescription.ID_PVP=data.ID_PVP;
                                                                    :class="prescription.comment ? 'btnclean' : 'btnblack'">
                                                                    Comentarios
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>

                                                        <!-- Quill Editor -->
                                                        <v-row class="mb-5 px-3">
                                                            <v-col cols="12">
                                                                <span class="ql-editor300">
                                                                    <quill-editor v-model="prescription.prescription"
                                                                        :options="quillOptions"
                                                                        @change="onPrescriptionEditorChange($event)"></quill-editor>
                                                                </span>
                                                            </v-col>
                                                        </v-row>
                                                    </v-form>
                                                </v-col>
                                            </v-row>
                                        </v-tab-item>

                                        <v-tab-item value="tab-3">
                                            <!-- Reproduction Form -->
                                            <v-row style="margin: 1rem 0">
                                                <!-- <block v-if="loading"></block> -->
                                                <v-col cols="12">
                                                    <v-form v-model="valid3" class="position-relative">
                                                        <v-row style="padding: 0 1rem">
                                                            <v-col cols="12" md="3" sm="12" style="visibility: hidden;">
                                                                <date-picker label="Fecha"
                                                                    @dateSelected="handlerDatePicker"
                                                                    :value="reproduction.ReproductionDate">
                                                                </date-picker>
                                                            </v-col>
                                                            <v-col cols="12" md="3">
                                                                <v-select outlined dense v-model="reproduction.Type"
                                                                    :items="Type" attach clearable label="Tipo"
                                                                    :rules="[(v) => (v !== null && v !== '') || 'Campo requerido']"></v-select>
                                                            </v-col>
                                                            <v-col cols="12" md="5"></v-col>
                                                            <v-col cols="12" md="1"
                                                                style="display: flex; justify-content: flex-end">
                                                                <v-btn @click="showCommentsR = !showCommentsR"
                                                                    :class="reproduction.Notes ? 'btnclean' : 'btnblack'">
                                                                    Comentarios
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>

                                                        <v-row class="ml-2 mr-2">
                                                            <v-col cols="12" md="12" sm="12">
                                                                <h2>Celos</h2>
                                                            </v-col>
                                                            <v-col cols="12" sm="2" md="2">
                                                                <div style="margin-top: 0.8rem;">
                                                                    <v-checkbox v-model="reproduction.Jealousy"
                                                                        label="Celos"
                                                                        class="start-align-label"></v-checkbox>
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="12" sm="6" md="3">
                                                                <div
                                                                    style="width: 200px !important; margin-top: 0.8rem">
                                                                    <date-picker label="Inicio"
                                                                        :value="reproduction.JealousyBeginning"
                                                                        :disbaled="!reproduction.Jealousy ? true : false"
                                                                        @dateSelected="(param) => (reproduction.JealousyBeginning = param)">
                                                                    </date-picker>
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="12" sm="6" md="3">
                                                                <div
                                                                    style="width: 200px !important; margin-top: 0.8rem">
                                                                    <date-picker label="Fin"
                                                                        :value="reproduction.JealousyEnd"
                                                                        :disbaled="!reproduction.Jealousy ? true : false"
                                                                        @dateSelected="(param) => (reproduction.JealousyEnd = param)">
                                                                    </date-picker>
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="12" sm="6" md="2">
                                                                <div style="margin-top: 0.8rem;">
                                                                    <v-checkbox v-model="reproduction.HeatPrevent"
                                                                        label="Prevención anticelo"
                                                                        class="start-align-label"></v-checkbox>
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="12" sm="6" md="2">
                                                                <div style="margin-top: 0.8rem;">
                                                                    <v-checkbox v-model="reproduction.AbortiveVaccine"
                                                                        label="Vacuna abortiva"
                                                                        class="start-align-label"></v-checkbox>
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="12" md="12">
                                                                <h2 style="padding: 10px 0;">Cruzas</h2>
                                                            </v-col>
                                                            <v-col cols="12" md="3">
                                                                <v-text-field outlined dense label="Cruzado con"
                                                                    v-model="breed.ReproductionWith"></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" md="3">
                                                                <v-btn
                                                                    :class="breeds.length > 0 ? 'btnclean' : 'btnblack'"
                                                                    @click="showcruz = !showcruz">
                                                                    Fechas cruzas
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <h2 style="padding: 10px 0;">Gestación</h2>
                                                            </v-col>
                                                            <v-col cols="12" md="2" sm="12">
                                                                <date-picker label="Fecha"
                                                                    :value="reproduction.DateGestation"
                                                                    @dateSelected="(param) => (reproduction.DateGestation = param)">
                                                                </date-picker>
                                                            </v-col>
                                                            <v-col cols="12" md="2">
                                                                <!-- <v-text-field outlined dense label="Tipo de Diagnostico" v-model="reproduction.TypeDiagnostic"></v-text-field> -->
                                                                <v-select outlined dense
                                                                    v-model="reproduction.TypeDiagnostic"
                                                                    :items="diagTypeG" clearable attach
                                                                    label="Tipo de Diagnostico"></v-select>
                                                            </v-col>
                                                            <v-col cols="12" md="2">
                                                                <v-text-field outlined dense
                                                                    label="Perímetro Abdominal (cm)"
                                                                    v-model="reproduction.AbdominalPerimeter"></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" md="2">
                                                                <!-- <v-text-field outlined dense label="Resultado" v-model="reproduction.Outcome"></v-text-field> -->
                                                                <v-select outlined dense v-model="reproduction.Outcome"
                                                                    :items="resTypeG" clearable attach
                                                                    label="Resultado"></v-select>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <h2>Nacimiento</h2>
                                                            </v-col>
                                                            <v-col cols="12" sm="6" md="2">
                                                                <div style="margin-top: 0.8rem">
                                                                    <v-checkbox v-model="reproduction.CaesareanSection"
                                                                        label="Cesárea"
                                                                        v-on:change="handleCaesareanSectionChange"></v-checkbox>
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="12" sm="6" md="2">
                                                                <div style="margin-top: 0.8rem">
                                                                    <v-checkbox v-model="reproduction.Natural"
                                                                        label="Natural"
                                                                        v-on:change="handleNaturalChange"></v-checkbox>
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="12" md="2">
                                                                <v-text-field style="margin-top: 0.8rem;" outlined dense
                                                                    v-model="reproduction.Alive" label="Vivos"
                                                                    type="number"></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" md="2">
                                                                <v-text-field style="margin-top: 0.8rem;" outlined dense
                                                                    v-model="reproduction.Dead" label="Muertos"
                                                                    type="number"></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                        <br />
                                                    </v-form>
                                                </v-col>
                                            </v-row>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-col>
                            </v-row>

                            <v-row style="width: 100%;">
                                <v-card flat>
                                    <v-card-text style="padding: 0 1rem;">
                                        <GeneralFilter entity="cliente"
                                            :filterEndpoint="$API.veterinarymedicalhistory.filterloadMedicalHistory"
                                            :search="searchHistory" @emptyFilter="loadHistoryMedical"
                                            @filtered="filterMedicalHistoryHandler" :isAutoScrolled="true">
                                            <DxDataGrid :show-borders="true" :data-source="medicalhistorys" key-expr=""
                                                @exporting="onExportingMedicalHistory" :column-auto-width="true"
                                                :scrolling="{ useNative: true }" style="max-height: 400px;"
                                                :paging="{ enabled: false }">
                                                <DxExport :enabled="true" />
                                                <DxColumn :width="80" cell-template="show-template" caption="">
                                                </DxColumn>
                                                <!--  <DxColumn data-field="ID_MH" caption="Id"></DxColumn> -->
                                                <DxColumn data-field="MedicalHistoryDate" caption="Fecha"
                                                    :min-width="100">
                                                </DxColumn>
                                                <DxColumn data-field="Weight" caption="Peso" :min-width="100">
                                                </DxColumn>
                                                <DxColumn data-field="FullName" caption="Atendido por" :min-width="200">
                                                </DxColumn>
                                                <DxColumn data-field="PhysicalHistorySummaryTable"
                                                    caption="Resumen de historia y examen físico" :min-width="300"
                                                    cssClass="left-align"></DxColumn>
                                                <DxColumn data-field="PresumptiveDiagnosisTable"
                                                    caption="Diagnóstico Presuntivo" :min-width="300"
                                                    cssClass="left-align"></DxColumn>
                                                <DxColumn data-field="FinalDiagnosisTable"
                                                    caption="Diagnóstico Definitivo" :min-width="300"
                                                    cssClass="left-align"></DxColumn>
                                                <DxColumn data-field="TreatmentTable" caption="Tratamiento"
                                                    :min-width="300" cssClass="left-align"></DxColumn>
                                                <DxColumn :width="80" cell-template="delete-template" caption=""
                                                    v-if="$store.getters['rolesUser/getCustomRole'](139)">
                                                </DxColumn>
                                                <template #show-template="{ data }">
                                                    <div>
                                                        <a href="#" @click.prevent.stop="showMedicalHistory(data.data)">
                                                            <v-icon>mdi-eye</v-icon>
                                                        </a>
                                                    </div>
                                                </template>
                                                <template #delete-template="{ data }">
                                                    <div>
                                                        <a href="" class="mx-2"
                                                            @click.prevent.stop="confirmDeleteAllList(data.data)">
                                                            <v-icon>mdi-delete</v-icon>
                                                        </a>
                                                    </div>
                                                </template>
                                            </DxDataGrid>
                                        </GeneralFilter>
                                    </v-card-text>
                                </v-card>
                            </v-row>

                            <v-row style="margin-bottom: 1.2rem; margin-top: 0.6rem"> </v-row>
                        </div>
                    </v-col>
                </v-row>
                <br />
                <hr />
                <br />

                <!-- Medical History V-Dialogs -->
                <v-dialog v-model="dialog" max-width="350">
                    <v-card>
                        <v-card-title class="text-h6"> ¿Desea eliminar el registro? </v-card-title>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="red darken-1" text @click="dialog = false"> No </v-btn>
                            <v-btn color="green darken-1" text @click="
                                deleteMedicalHistory(temp);
                            dialog = false;
                            temp = null;
                            ">
                                Si
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="showCommentsMH" max-width="60%">
                    <v-card class="pa-4">
                        <div style="
                            margin-top: 0.3rem;
                            margin-bottom: 0.3rem;
                            display: flex;
                            justify-content: flex-end;
                        ">
                            <v-btn small class="btndelete" @click.prevent="showCommentsMH = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </div>
                        <v-col cols="12" md="12" style="">
                            <quill-editor v-model="medicalhistory.Comment" :options="quillOptions"></quill-editor>
                        </v-col>
                    </v-card>
                </v-dialog>
                <!-- End -->

                <!-- Prescription V-Dialogs -->
                <!-- Prescription Comments Modal -->
                <v-dialog v-model="showCommentsP" max-width="60%">
                    <v-card class="pa-4">
                        <div style="margin-top: .3rem;margin-bottom: .3rem; display: flex;justify-content: flex-end;">
                            <v-btn small class="btndelete" @click.prevent="showCommentsP = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </div>
                        <v-col cols="12" md="12" style="">
                            <quill-editor v-model="prescription.comment" :options="quillOptions"></quill-editor>
                        </v-col>
                    </v-card>
                </v-dialog>
                <!-- End -->

                <!-- Reproductions V-Dialogs -->
                <v-dialog v-model="showcruz" scrollable :max-width="$vuetify.breakpoint.smAndDown ? '90%' : '60%'">
                    <v-card class="pa-4" height="100%">
                        <v-col cols="12" md="12" style="display: flex; justify-content: space-between">
                            <label>Fechas de Cruzas</label>
                            <v-btn small class="btndelete" @click.prevent="showcruz = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col class="flex-wrap" cols="12" md="12"
                            style="display: flex; justify-content: space-between">
                            <div style="width:200px">
                                <date-picker label="Fecha" :value="breed.Date"
                                    @dateSelected="(param) => (breed.Date = param)">
                                </date-picker>
                            </div>

                            <v-btn class="btn-add" @click.prevent="transportDataBreed()">
                                AGREGAR
                            </v-btn>
                        </v-col>

                        <DxDataGrid :scrolling="{ useNative: true }" :show-borders="true" :data-source="breeds"
                            key-expr="id" style="margin-bottom: 0.6rem">
                            <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                            <DxColumn :width="200" data-field="ReproductionWith" caption="Cruzado con"></DxColumn>
                            <DxColumn :width="100" data-field="Date" caption="Fecha"></DxColumn>
                            <template #show-template="{ data }">
                                <div>
                                    <a href="#" @click.prevent.stop="deleteBreedSelected(data.data)">
                                        <v-icon>mdi-delete</v-icon>
                                    </a>
                                </div>
                            </template>
                        </DxDataGrid>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="showCommentsR" scrollable :max-width="$vuetify.breakpoint.smAndDown ? '90%' : '60%'">
                    <v-card class="pa-4">
                        <div style="
                                    margin-top: 0.3rem;
                                    margin-bottom: 0.3rem;
                                    display: flex;
                                    justify-content: flex-end;
                                ">
                            <v-btn small class="btndelete" @click.prevent="showCommentsR = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </div>
                        <v-col cols="12" md="12" style="">
                            <quill-editor v-model="reproduction.Notes" :options="quillOptions"></quill-editor>
                        </v-col>
                    </v-card>
                </v-dialog>
                <!-- End -->
                <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert"
                    :properties="alert">
                </alerts>
            </v-card-text>

        </v-card>

        <!-- Dialog bill patient -->
        <v-dialog v-model="showBillPatient" width="90%">
            <v-card class="py-3 px-3">
                <v-card-title class="text-h5">
                    Facturar paciente
                </v-card-title>

                <v-card-actions>
                    <v-row>
                        <v-col cols="12" class="tabsNh" style="padding: 14px">
                            <v-col style="display: flex; justify-content: space-between; min-height: 50px">
                                <div>
                                    <!-- <v-btn v-if="!showServicesToAdd" small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                            <v-icon>mdi-menu-down</v-icon>
                            Ver items
                        </v-btn>
                        <v-btn v-else small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                            <v-icon>mdi-menu-up</v-icon>
                            Ocultar
                        </v-btn> -->
                                </div>
                                <div>
                                    <v-btn small class="btn-add" @click.prevent="saveItemsToBeBilled()">
                                        <v-icon>mdi-content-save</v-icon>
                                        Guardar
                                    </v-btn>
                                </div>

                                <div>
                                    <v-btn small class="btnsave" @click.prevent="billedPatientsOfCustomer()">
                                        <v-icon>mdi-cash-register</v-icon>
                                        Facturar cliente
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-col>
                    </v-row>
                </v-card-actions>
                <div
                    style="height: 500px; overflow-y: auto; overflow-x: hidden; margin-top: 20px; margin-bottom: 20px; padding: 0 20px;">
                    <!-- Services default -->
                    <v-row style="margin-top: 2rem">
                        <v-row class="d-flex justify-center mb-2">
                            <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                                <v-alert class="btndisable"
                                    style="padding: 4px !important; margin-bottom: 0px !important">
                                    Ver servicios por defecto
                                </v-alert>
                            </v-col>
                            <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                                <v-alert class="btndisable2"
                                    style="padding: 4px !important; margin-bottom: 0px !important">
                                    <button @click='togglehide1 = !togglehide1' v-show='!togglehide1'
                                        style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                                    <button @click='togglehide1 = !togglehide1' v-show='togglehide1'
                                        style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                                </v-alert>
                            </v-col>
                        </v-row>
                        <v-col cols="12" v-if="!togglehide1">
                            <DxDataGrid class="isScrolledByGeneralFilter dataGridService" :data-source="servicesDefault"
                                :show-borders="true" :column-auto-width="true" style="width: fit-content;">
                                <DxScrolling row-rendering-mode="virtual" />
                                <DxPaging :page-size="20" />
                                <DxColumn :width="150" data-field="CodeService"
                                    header-cell-template="headerCodeService" />
                                <template #headerCodeService="{}">
                                    <div>Código de<br />servicio</div>
                                </template>
                                <DxColumn data-field="NameService" header-cell-template="headerNameService" />
                                <template #headerNameService="{}">
                                    <div>Nombre de<br />servicio</div>
                                </template>
                                <DxColumn data-field="Name" header-cell-template="headerName" />
                                <template #headerName="{}">
                                    <div>Nombre a<br />facturar</div>
                                </template>
                                <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice" />
                        <template #headerTypeservice="{ }">
                        <div>Tipo de<br />servicio</div>
                        </template-->
                                <DxColumn :width="65" data-field="Price" caption="Precio" />
                                <DxColumn :width="45" cell-template="quantity-template" caption="" />
                                <DxColumn :width="45" cell-template="show-template" caption="" />
                                <template #show-template="{ data }">
                                    <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                                        <v-icon>mdi-plus</v-icon>
                                    </a>
                                </template>
                                <template #quantity-template="{ data }">
                                    <p>{{ servicesToAdd.find(item => item.CodeService == data.data.CodeService).quantity
                                        }}</p>
                                </template>
                            </DxDataGrid>
                        </v-col>
                    </v-row>

                    <!-- Services to select -->
                    <v-row style="margin-top: 2rem">
                        <v-row class="d-flex justify-center mb-2">
                            <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                                <v-alert class="btndisable"
                                    style="padding: 4px !important; margin-bottom: 0px !important">
                                    Ver todos los servicios
                                </v-alert>
                            </v-col>
                            <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                                <v-alert class="btndisable2"
                                    style="padding: 4px !important; margin-bottom: 0px !important">
                                    <button @click='togglehide2 = !togglehide2' v-show='!togglehide2'
                                        style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                                    <button @click='togglehide2 = !togglehide2' v-show='togglehide2'
                                        style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                                </v-alert>
                            </v-col>
                        </v-row>
                        <v-col cols="12" v-if="!togglehide2">
                            <GeneralFilter :filterEndpoint="$API.services.getAllServicesFiltered" :search="search"
                                @emptyFilter="getServices" @filtered="servicesFilterHandler">
                                <DxDataGrid class="isScrolledByGeneralFilter dataGridService"
                                    :data-source="servicesToBill" key-expr="CodeService" :allow-column-reordering="true"
                                    :row-alternation-enabled="true" :show-borders="true" :column-auto-width="true"
                                    style="width: fit-content;">
                                    <DxScrolling row-rendering-mode="virtual" />
                                    <DxPaging :page-size="20" />
                                    <DxColumn :width="150" data-field="CodeService" caption="ID"></DxColumn>
                                    <DxColumn data-field="NameService" header-cell-template="headerNameService">
                                    </DxColumn>
                                    <template #headerNameService="{}">
                                        <div>Nombre de<br />servicio</div>
                                    </template>
                                    <DxColumn data-field="Name" header-cell-template="headerNameLab"></DxColumn>
                                    <template #headerNameLab="{}">
                                        <div>Nombre a<br />facturar</div>
                                    </template>
                                    <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice">
                        </DxColumn-->
                                    <!--template #headerTypeservice="{ }">
                            <div>Tipo de<br />servicio</div>
                        </template-->
                                    <DxColumn :width="65" data-field="Price" caption="Precio"></DxColumn>
                                    <DxColumn :width="45" cell-template="quantity-template" caption="" />
                                    <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                                    <template #show-template="{ data }">
                                        <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                                            <v-icon>mdi-plus</v-icon>
                                        </a>
                                    </template>
                                    <template #quantity-template="{ data }">
                                        <p style="margin: 0;">{{ servicesToAdd.find(item => item.CodeService ==
                                            data.data.CodeService)?.quantity || '' }}</p>
                                    </template>
                                </DxDataGrid>
                            </GeneralFilter>
                        </v-col>
                    </v-row>

                    <!-- Products to select -->
                    <v-row style="margin-top: 2rem" v-if="false">
                        <v-row class="d-flex justify-center mb-2">
                            <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                                <v-alert class="btndisable"
                                    style="padding: 4px !important; margin-bottom: 0px !important">
                                    Ver todos los productos
                                </v-alert>
                            </v-col>
                            <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                                <v-alert class="btndisable2"
                                    style="padding: 4px !important; margin-bottom: 0px !important">
                                    <button @click='togglehide3 = !togglehide3' v-show='!togglehide3'
                                        style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                                    <button @click='togglehide3 = !togglehide3' v-show='togglehide3'
                                        style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                                </v-alert>
                            </v-col>
                        </v-row>
                        <v-col cols="12" v-if="!togglehide3">
                            <GeneralFilter :filterEndpoint="$API.products.getProductsToBillForInvoiceFiltered"
                                :search="search" @emptyFilter="getProducts" @filtered="productsFilterHandler">
                                <v-row style="padding: 1rem;">
                                    <v-col cols="12" md="3" sm="4">
                                        <v-autocomplete v-model="selectedWarehouse" item-text="Warehouse"
                                            item-value="ID_Warehouse" :items="warehouses"
                                            @change="handleSelectWarehouse" label="Almacenes" ref="werehouseRef" dense
                                            outlined attach>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="9" sm="8">
                                        <p style="color: #e3191e !important; font-size: 12px; margin: 12px 0"
                                            v-if="warehouses.length == 0">No existen almacenes de productos para
                                            pacientes.</p>
                                    </v-col>
                                </v-row>
                                <DxDataGrid class="isScrolledByGeneralFilter dataGridService"
                                    :data-source="productsToBill" key-expr="ID_Item" :allow-column-reordering="true"
                                    :row-alternation-enabled="true" :show-borders="true" :column-auto-width="true"
                                    style="width: fit-content;">
                                    <DxScrolling row-rendering-mode="virtual" />
                                    <DxPaging :page-size="20" />
                                    <DxColumn :width="150" data-field="ID_Item" caption="ID"></DxColumn>
                                    <DxColumn data-field="ItemName" header-cell-template="headerName"></DxColumn>
                                    <template #headerName="{}">
                                        <div>Nombre del<br />producto</div>
                                    </template>
                                    <DxColumn data-field="ItemComercialName" header-cell-template="headerNameComercial">
                                    </DxColumn>
                                    <template #headerNameComercial="{}">
                                        <div>Nombre<br />comercial</div>
                                    </template>
                                    <DxColumn :width="65" data-field="ItemPrice" caption="Precio"></DxColumn>
                                    <DxColumn :width="45" cell-template="quantity-template" caption="" />
                                    <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                                    <template #show-template="{ data }">
                                        <a href="#" class="mx-1" @click.prevent.stop="addProduct(data.data)">
                                            <v-icon>mdi-plus</v-icon>
                                        </a>
                                    </template>
                                    <template #quantity-template="{ data }">
                                        <p style="margin: 0;">{{ productsToAdd.find(item => item.ID_Item ==
                                            data.data.ID_Item)?.quantity || '' }}</p>
                                    </template>
                                </DxDataGrid>
                            </GeneralFilter>
                        </v-col>
                    </v-row>

                    <!-- ServiceToAdd -->
                    <v-row style="margin-top: 2rem">
                        <v-col cols="12">
                            <h4 style="padding: 16px;">Servicios seleccionados</h4>
                            <div style="overflow-x: scroll; padding: 0 12px;">
                                <DxDataGrid class="isScrolledByGeneralFilter dataGridService"
                                    :data-source="servicesToAdd" key-expr="CodeService" :allow-column-reordering="true"
                                    :row-alternation-enabled="true" :show-borders="true" :column-auto-width="true"
                                    style="width: fit-content;">
                                    <DxEditing :allow-updating="true" :select-text-on-edit-start="true"
                                        start-edit-action="click" mode="cell" />
                                    <DxScrolling row-rendering-mode="virtual" />
                                    <DxPaging :page-size="5" />
                                    <DxColumn data-field="CodeService" :allow-editing="false" caption="ID"></DxColumn>
                                    <DxColumn data-field="NameService" :allow-editing="false"
                                        header-cell-template="headerNameService"></DxColumn>
                                    <template #headerNameService="{}">
                                        <div>Nombre de<br />servicio</div>
                                    </template>
                                    <DxColumn data-field="Name" :allow-editing="false"
                                        header-cell-template="headerNameLab">
                                    </DxColumn>
                                    <template #headerNameLab="{}">
                                        <div>Nombre a<br />facturar</div>
                                    </template>
                                    <DxColumn data-field="TypeService" :allow-editing="false"
                                        header-cell-template="headerTypeservice">
                                    </DxColumn>
                                    <template #headerTypeservice="{}">
                                        <div>Tipo de<br />servicio</div>
                                    </template>
                                    <DxColumn data-field="quantity" caption="Cantidad">
                                        <DxFormat typed="fixedPoint" :precision="0" />
                                        <DxRequiredRule message="La cantidad del servicio a facturar es requerida" />
                                        <DxPatternRule :pattern="itemQtyPattern"
                                            message="La cantidad del servicio facturado solo puede contener numeros enteros mayores a 0" />
                                    </DxColumn>
                                    <DxColumn data-field="unitPrice" :allow-editing="false" caption="Precio"></DxColumn>
                                    <DxColumn cell-template="total-template" :allow-editing="false" caption="Total">
                                    </DxColumn>
                                    <DxColumn cell-template="show-template" caption=""></DxColumn>
                                    <template #show-template="{ data }">
                                        <a href="#" class="mx-1" @click.prevent.stop="deleteService(data.data)">
                                            <v-icon>mdi-delete</v-icon>
                                        </a>
                                    </template>
                                    <template #total-template="{ data }">
                                        <div>
                                            {{ totalXQuantity(data.data) }}
                                        </div>
                                    </template>
                                </DxDataGrid>
                            </div>
                        </v-col>
                    </v-row>

                    <!-- Product to add -->
                    <v-row style="margin-top: 2rem">
                        <v-col cols="12">
                            <h4 style="padding: 16px;">Productos seleccionados</h4>
                            <div style="overflow-x: scroll; padding: 0 12px;">
                                <DxDataGrid class="isScrolledByGeneralFilter dataGridService"
                                    :data-source="productsToAdd" key-expr="ID_Item" :allow-column-reordering="true"
                                    :row-alternation-enabled="true" :show-borders="true" :column-auto-width="true"
                                    style="width: fit-content;">
                                    <DxEditing :allow-updating="true" :select-text-on-edit-start="true"
                                        start-edit-action="click" mode="cell" />
                                    <DxScrolling row-rendering-mode="virtual" />
                                    <DxPaging :page-size="5" />
                                    <DxColumn data-field="ID_Item" :allow-editing="false" caption="ID"></DxColumn>
                                    <DxColumn data-field="ItemName" :allow-editing="false"
                                        header-cell-template="headerNameService"></DxColumn>
                                    <template #headerNameService="{}">
                                        <div>Nombre del<br />producto</div>
                                    </template>
                                    <DxColumn data-field="ItemComercialName" :allow-editing="false"
                                        header-cell-template="headerNameLab"></DxColumn>
                                    <template #headerNameLab="{}">
                                        <div>Nombre a<br />facturar</div>
                                    </template>
                                    <DxColumn data-field="ItemType" :allow-editing="false"
                                        header-cell-template="headerTypeservice">
                                    </DxColumn>
                                    <template #headerTypeservice="{}">
                                        <div>Tipo de<br />producto</div>
                                    </template>
                                    <DxColumn data-field="quantity" caption="Cantidad">
                                        <DxFormat typed="fixedPoint" :precision="0" />
                                        <DxRequiredRule message="La cantidad del producto a facturar es requerida" />
                                        <DxPatternRule :pattern="itemQtyPattern"
                                            message="La cantidad del producto facturado solo puede contener numeros enteros mayores a 0" />
                                    </DxColumn>
                                    <DxColumn data-field="unitPrice" :allow-editing="false" caption="Precio"></DxColumn>
                                    <DxColumn cell-template="total-template" :allow-editing="false" caption="Total">
                                    </DxColumn>
                                    <DxColumn cell-template="show-template" caption=""></DxColumn>
                                    <template #show-template="{ data }">
                                        <a href="#" class="mx-1" @click.prevent.stop="deleteProduct(data.data)">
                                            <v-icon>mdi-delete</v-icon>
                                        </a>
                                    </template>
                                    <template #total-template="{ data }">
                                        <div>
                                            {{ totalXQuantity({ ...data.data, Price: data.data.ItemPrice }) }}
                                        </div>
                                    </template>
                                </DxDataGrid>
                            </div>
                        </v-col>
                    </v-row>

                </div>
            </v-card>
        </v-dialog>
        <!-- Dialog bill patient -->

        <PrintMedicalHistory :dataProps="printMHistory"></PrintMedicalHistory>
        <PrescriptionShort :data="printPrescription" />
        <Reproduction :data="printReproduction"></Reproduction>
    </div>
</template>

<script>
import Vue from "vue";
import Block from "@/components/Block";
import { DxDataGrid, DxColumn, DxScrolling, DxEditing, DxFormat, DxPatternRule, DxRequiredRule, DxExport } from "devextreme-vue/data-grid";
import DatePicker from "../../../components/DatePicker";
import Alerts from "@/components/Alerts";
import { mapState } from "vuex";
import { printToExcel } from "@/helpers/printToexcel";
import GeneralFilter from "@/components/GeneralFilter";
import UserProfile from "@/components/Displays/UserProfileDisplay.vue";
import UserProfileLab from "@/components/Displays/UserProfileDisplayLab.vue";
import { numberWithCommas } from "@/helpers/money";
import PrintMedicalHistory from "../../../components/ReportsAndPrintings/PrintMedicalHistory.vue";
import Json from "../../../assets/Json/printTemplate.json";
import PrescriptionShort from "../../../components/ReportsAndPrintings/PrescriptionShort.vue";
import Reproduction from "../../../components/ReportsAndPrintings/Reproduction.vue";

export default {
    name: "VeterinaryMedicalHConsolidatedView",
    components: {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxFormat,
        DxPatternRule,
        DxScrolling,
        DxRequiredRule,
        DatePicker,
        Block,
        Alerts,
        DxExport,
        GeneralFilter,
        UserProfile,
        UserProfileLab,
        PrintMedicalHistory,
        PrescriptionShort,
        Reproduction
    },
    props: ["data", "win", "type"],
    data: () => ({
        alert: {
            type: "success",
            show: false,
            header: "",
            body: "",
        },
        tab: "tab-1",
        customer: {},
        currency: JSON.parse(localStorage.getItem('branch')).Currency,
        itemQtyPattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/i,
        showBillPatient: false,
        useData: {},
        showServicesToAdd: false,
        showProductsToAdd: false,
        servicesToBill: [],
        productsToBill: [],
        servicesToAdd: [],
        productsToAdd: [],
        filterProducts: {
            filter: null
        },
        search: {
            filter: null
        },
        togglehide1: true,
        togglehide2: true,
        togglehide3: true,
        warehouses: [],
        selectedWarehouse: null,

        showcruz: false,
        datatodelete: null,
        confirmtoDelete: null,
        searchPrescription: {},
        dialog: false,
        valid1: false,
        valid2: false,
        valid3: false,
        url: null,
        showCommentsMH: false,
        showCommentsP: false,
        showCommentsR: false,
        date: null,
        patientinfo: [],
        weightunit: '',
        weight: '',
        medicalhistorys: {},
        medicalhistory: {},
        medicalhistoryDataLoaded: false,
        prescriptions: {},
        prescription: {},
        prescriptionDataLoaded: false,
        loading: false,
        id_user_login: null,
        usenextappointment: [],
        dialogprint: false,
        searchbusiness: {},
        infobusiness: {},
        na: null,
        header: {},
        footer: {},
        template: {},
        prescriptionJSON: {},
        selectedValues: false,
        showPrescriptionHistory: false,
        disabled1: 0,
        disabled2: 1,
        disabled3: 1,
        showFisicH: false,
        showDiagP: false,
        showDiagD: false,
        showTreat: false,
        showMedH: false,
        showRep: false,
        showSurg: false,
        showImg: false,
        vetExt: false,
        searchHistory: {},
        medicalaids: [],
        reproductions: [],
        surgerys: [],
        images: [],
        showMedicalData: false,
        Type: ["Monta", "Parto", "Celo", "Gestación"],
        commentclass: "btnblack",
        searchReproduction: {},
        reproduction: {},
        reproductionDataLoaded: false,
        breeds: [],
        breed: {},
        disabledR1: 0,
        disabledR2: 0,
        disabledR3: 0,
        disabledR4: 0,
        disabledR5: 0,
        count: 0,
        id_patient: null,
        confirmtoDeleteReproduction: null,
        showReproductionHistory: false,
        showhistory: false,
        diagTypeG: ["Palpación", "Rayos X", "Ultrasonografía"],
        resTypeG: ["Positivo", "Negativo", "Sospecha"],
        //useData: false,
        quillOptions: {
            modules: {
                toolbar: {
                    container: [
                        ['bold', 'underline', 'strike'],
                        ['blockquote'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'align': [] }],
                        [{ 'color': [] }, { 'background': [] }],
                    ],
                    handlers: {},
                },
            },
            theme: "snow",
        },
        printPrescription: {
            show: false,
            data: [],
        },
        printMHistory: {
            show: false,
            data: [],
        },
        printReproduction: {
            show: false,
            data: [],
        },
        policlinicaclientdata: JSON.parse(localStorage.getItem("user")).policlinicaclientdata
    }),

    computed: {
        ...mapState("rolesUser", {
            rolesStatus: (state) => state.roles,
        }),
    },
    async mounted() {
        if (this.data) {
            Object.keys(this.data).map((el) => {
                Vue.set(this.patientinfo, el.toLowerCase(), this.data[el]);
            });

            this.medicalhistory.vetExt = false;
            this.medicalhistory.nameExt = '';
            this.medicalhistory.Weight = this.data.Weight;
            this.prescription.Weight = this.data.Weight;
            this.reproduction.Weight = this.data.Weight;

            this.url = this.$store.getters.getBackendUrl + "/public" + this.data.ProfilePicPath;
            this.id_user_login = JSON.parse(localStorage.getItem("user")).id;
            this.useData = JSON.parse(localStorage.getItem("user"));
            this.branchConfig = JSON.parse(localStorage.getItem("branch"));
            if (this.branchConfig?.weightunit) this.weightunit = this.branchConfig.weightunit;
            if (this.data?.weightunit) this.weightunit = this.data.weightunit;

            if (this.data.VeterinarioExterno == 1 || this.data.VeterinarioExterno == true) {
                this.medicalhistory.vetExt = true;
                this.medicalhistory.nameExt = this.data.NombreVeterinarioExterno;
            }
            else {
                this.medicalhistory.vetExt = false;
                this.medicalhistory.nameExt = '';
            }
            this.vetExt = this.medicalhistory.vetExt;

            this.customer.ID_Customer = this.data.ID_Customer;
            console.log('customer', this.customer);
        }

        this.warehouses = await this.$API.warehouse.getWarehousesFiltered({ isPatient: true });
        this.filterProducts.business = this.useData.businessid;
        this.filterProducts.branch = this.useData.branch;
        this.selectedWarehouse = this.warehouses.length == 0 ? 0 : this.warehouses[0].ID_Warehouse;
        this.filterProducts.warehouse = this.selectedWarehouse;
        await this.getServices();
        await this.getProducts();
        await this.getDefaultServices();

        this.$store.dispatch('rolesUser/getAllRoles');
        this.prescriptionJSON = Json[0]['Prescription'];

        this.getHeaderFooter();
        this.getInfoBranch();

        this.searchbusiness.branch = JSON.parse(localStorage.getItem("user")).branch || "";
        this.searchbusiness.business = JSON.parse(localStorage.getItem("user")).businessid || "";

        const localparams = JSON.parse(localStorage.getItem("localparams")) || null;
        if (localparams && localparams.weightunit) this.weightunit = localparams.weightunit;

        if (this.prescription && this.prescription.Weight.includes('Kg')) this.weightunit = 'Kg';
        if (this.prescription && this.prescription.Weight.includes('Lb')) this.weightunit = 'Lb';

        if (this.prescription.Weight && this.prescription.Weight.includes(this.weightunit)) {
            this.weight = this.prescription.Weight.slice(0, -2).replace(' ', '');
        } else {
            this.weight = this.prescription.Weight.replace(' ', '');
        }

        if (this.medicalhistory && this.medicalhistory.Weight.includes('Kg')) this.weightunit = 'Kg';
        if (this.medicalhistory && this.medicalhistory.Weight.includes('Lb')) this.weightunit = 'Lb';

        if (this.medicalhistory.Weight && this.medicalhistory.Weight.includes(this.weightunit)) {
            this.weight = this.medicalhistory.Weight.slice(0, -2).replace(' ', '');
        } else {
            this.weight = this.medicalhistory.Weight.replace(' ', '');
        }

        this.medicalhistory.ID_Patient = this.patientinfo.id_patient;
        this.searchHistory.patientId = this.patientinfo.id_patient;

        Vue.set(this.prescription, "id_patient", this.patientinfo.id_patient)
        this.searchPrescription.id_patient = this.patientinfo.id_patient

        Vue.set(this.reproduction, "ID_Patient", this.patientinfo.id_patient);
        this.searchReproduction.id_patient = this.patientinfo.id_patient;

        await this.loadAllData();
    },
    watch: {
        date: {
            handler: function () {
                if(this.medicalhistorys.length) {
                    for (const el of this.medicalhistorys) {
                        if (el.MedicalHistoryDate == this.date) {
                            this.showMedicalHistory(el);
                        }
                    }
                }
                else {
                    this.resetData();
                }
                this.searchHistory.date = this.date;
                this.searchPrescription.date = this.date;
                this.searchReproduction.date = this.date;
                this.medicalhistory.MedicalHistoryDate = this.date;
                this.prescription.prescriptiondate = this.date;
                this.reproduction.ReproductionDate = this.date;
                this.medicalaids = [];
                this.handlerDatePicker(null);
            },
            immediate: true,
        },
        servicesToAdd: {
            deep: true,
            immediate: true,
            handler() {
                if (this.servicesToAdd.length !== 0) {
                    this.showServicesToAdd = true;
                }
                else {
                    this.showServicesToAdd = false;
                }
            }
        },
        productsToAdd: {
            deep: true,
            immediate: true,
            handler() {
                if (this.productsToAdd.length !== 0) {
                    this.showProductsToAdd = true;
                }
                else {
                    this.showProductsToAdd = false;
                }
            }
        },
    },
    methods: {
        printMedicalHistory() {
            this.printMHistory = {
                show: true,
                data: {
                    medicalhistory: this.medicalhistory,
                    patientinfo: this.data,
                }
            }
        },
        totalXQuantity({ Price, quantity }) {
            return `${this.currency}${numberWithCommas(parseFloat(Price * quantity).toFixed(2))}`
        },
        async handleSelectWarehouse() {
            this.loading = true;
            this.filterProducts.warehouse = this.selectedWarehouse;
            await this.getProducts();
            this.$refs.werehouseRef.blur();
            this.loading = false;
        },
        addService(data) {
            const isRepetitive = this.servicesToAdd.find((service) => service.ID_Service === data.ID_Service);

            if (!isRepetitive) {
                this.servicesToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.Price), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.Price).toFixed(4))}` });
            } else {
                const service = this.servicesToAdd.find(item => item.ID_Service === data.ID_Service)
                if (service) {
                    service.quantity++;
                }
                console.log("Service already exists in servicesToAdd");
            }
        },
        addProduct(data) {
            const isRepetitive = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
            if (!isRepetitive) {
                this.productsToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.ItemPrice), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.ItemPrice).toFixed(4))}` });
            } else {
                const product = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
                if (product) {
                    product.quantity++;
                }
                console.log("Product already exists in productsToAdd.");
            }
        },
        deleteService(data) {
            const serviceIndex = this.servicesToAdd.findIndex((service) => service.ID_Item === data.ID_Item);

            if (serviceIndex !== -1) {
                this.servicesToAdd.splice(serviceIndex, 1);
            }
        },
        deleteProduct(data) {
            const index = this.productsToAdd.findIndex((item) => item.ID_Item === data.ID_Item);

            if (index !== -1) {
                this.productsToAdd.splice(index, 1);
            }
        },
        getServices() {
            this.$API.services.getAllServices(this.filterServices).then((response) => {
                this.servicesToBill = response;
            });
        },
        getProducts() {
            this.$API.products.getProductsToBillForInvoice(this.filterProducts).then((response) => {
                this.productsToBill = response;
            });
        },
        getDefaultServices() {
            this.$API.services.getDefaultServices().then((response) => {
                this.servicesDefault = response;
            });
        },
        servicesFilterHandler(response) {
            this.servicesToBill = response;
        },
        productsFilterHandler(response) {
            this.productsToBill = response;
        },
        getItemsToBeBilled() {
            this.$API.veterinarypatients.getServicesToBeBilled({ 'customer': this.data.ID_Customer })
                .then((response) => {
                    this.servicesToAdd = []
                    for (let i = 0; i < response.length; i++) {
                        this.servicesToAdd.push({ ...response[i], unitPrice: `${this.currency}${numberWithCommas(parseFloat(response[i].Price).toFixed(4))}` });
                    }
                });
            this.$API.veterinarypatients.getProductsToBeBilled({ 'customer': this.data.ID_Customer }).then((res) => {
                if (res.success) {
                    this.productsToAdd = [];
                    for (const item of res.data) {
                        this.productsToAdd.push({ ...item, quantity: item.Quantity, unitPrice: `${this.currency}${numberWithCommas(parseFloat(item.ItemPrice).toFixed(4))}` });
                    }
                }
            });
        },
        async saveItemsToBeBilled() {
            this.showBillPatient = false;
            this.loading = true;

            try {
                if (this.servicesToAdd.length) {
                    await this.$API.veterinarypatients.saveServicesToBeBilled({ 'customer': this.customer.ID_Customer, 'services': this.servicesToAdd })
                        .then((res) => {
                            console.log(res);
                        })
                        .catch((err) => {
                            console.log(err);
                            throw new Error('Ocurrio un error al guardar los servicios.');
                        });
                }
                if (this.productsToAdd.length) {
                    console.log(this.productsToAdd);

                    await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd })
                        .then((res) => {
                            console.log(res);
                        })
                        .catch((err) => {
                            console.log(err);
                            throw new Error('Ocurrio un error al guardar los productos.');
                        });
                }
                await this.getItemsToBeBilled();
                this.loading = false;
                this.showAlert("success", "Exito", "Los datos de facturación de este paciente han sido guradados.")
            } catch (error) {
                console.log(error);
                this.loading = false;
                this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
            }

        },
        async billedPatientsOfCustomer() {
            this.showBillPatient = false;
            this.loading = true;

            try {
                await this.$API.veterinarypatients.saveServicesToBeBilled({ 'customer': this.customer.ID_Customer, 'services': this.servicesToAdd });
                await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd });
                await this.$API.veterinarypatients.billedPatientsOfCustomer({ 'customer': this.customer.ID_Customer });

                this.loading = false;

                this.showAlert("success", "Exito", `Se ha realizado la factura de los ${this.servicesToAdd.length && this.productsToAdd.length ? 'servicios y productos' : this.servicesToAdd.length ? 'servicios' : this.productsToAdd.length ? 'productos' : 'items'} seleccionados, podrá observarla en el módulo de POS en listo para facturar.`);
                await this.getItemsToBeBilled();
            }
            catch (error) {
                console.log(error)
                this.loading = false
                this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
            }
        },


        async loadAllData() {
            this.loading = true;
            try {
                this.medicalhistorys = await this.$API.veterinarymedicalhistory.loadMedicalHistorySorted(this.searchHistory);
                this.prescriptions = await this.$API.veterinaryprescription.getVeterinaryPrescription(this.patientinfo.id_patient);
                this.reproductions = await this.$API.veterinaryReproduction.getVeterinaryReproduction(this.patientinfo.id_patient);

            } catch (error) {
                console.log(error);
                this.showAlert("warning", "Advertencia", "Ha ocurrido un error al cargar los datos del paciente, intentelo de nuevo más tarde.");

            } finally {
                let datetoday = this.moment().format("DD/MM/YYYY");
                if (this.medicalhistorys.length) {
                    for (const element of this.medicalhistorys) {
                        if (element.MedicalHistoryDate == datetoday) {
                            this.showMedicalHistory(element);
                        }
                    }
                }
                this.loading = false;
            }
        },

        onExportingMedicalHistory(e) {
            printToExcel(e, "Historial Medico");
        },
        handlerVetExt() {
            this.vetExt = this.medicalhistory.vetExt;
        },
        handlerDatePicker(param) {
            if (this.date) {
                this.reproduction.ReproductionDate = this.date;
                this.prescription.prescriptiondate = this.date;
                param = this.date;
            }
            else {
                this.reproduction.ReproductionDate = param;
                this.prescription.prescriptiondate = param;
            }
        },
        async saveAll() {
            this.loading = true;
            const success = {
                medicalHistory: false,
                prescription: false,
                reproduction: false,
            }
            let noCleanForm = false

            try {
                if (this.selectedValues) {
                    throw new Error("Ocurrio un error al crear un nuevo registro con la fecha y/o información seleccionada.");
                }

                if (this.medicalhistorys.some(item => item.MedicalHistoryDate == this.date)) {
                    this.showAlert("warning", "Advertencia", "Ya existe un registro para la fecha seleccionada.")
                    noCleanForm = true
                    return;
                }

                if (this.prescriptions.some(item => item.PrescriptionDate == this.date)) {
                    this.showAlert("warning", "Advertencia", "Ya existe un registro para la fecha seleccionada.")
                    noCleanForm = true
                    return;
                }

                if (this.reproductions.some(item => item.ReproductionDate == this.date)) {
                    this.showAlert("warning", "Advertencia", "Ya existe un registro para la fecha seleccionada.")
                    noCleanForm = true
                    return;
                }

                // Save medical history
                this.medicalhistory.MedicalHistoryDate = this.date;
                this.medicalhistory.ID_Patient = this.patientinfo.id_patient;
                this.medicalhistory.ID_User_Insert = JSON.parse(localStorage.getItem("user")).id;
                if (this.medicalhistory.Weight && this.weightunit) this.medicalhistory.Weight = this.medicalhistory.Weight.concat(' ', this.weightunit);
                this.medicalhistory.Talla = this.medicalhistory.height;
                this.medicalhistory.Pulso = this.medicalhistory.pulse;
                this.medicalhistory.FrecuenciaCardiaca = this.medicalhistory.heartRate;
                this.medicalhistory.FrecuenciaRespiratoria = this.medicalhistory.breathRate;
                this.medicalhistory.Temperatura = this.medicalhistory.temp;
                if (this.medicalhistory.vetExt) {
                    this.medicalhistory.VeterinarioExterno = 1;
                    this.medicalhistory.NombreVeterinarioExterno = this.medicalhistory.nameExt || '';
                }
                else {
                    this.medicalhistory.VeterinarioExterno = 0;
                    this.medicalhistory.NombreVeterinarioExterno = '';
                }

                await this.$API.veterinarymedicalhistory.saveMedicalHistory(this.medicalhistory)
                    .then((res) => {
                        if (res) {
                            success.medicalHistory = true;
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    });

                // Save prescription
                const formdataP = new FormData();
                this.prescription.prescriptiondate = this.date;
                if (this.usenextappointment.length < 1) {
                    this.prescription.nextappointment = null
                }
                Vue.set(this.prescription, "id_user_insert", this.id_user_login);

                if (this.prescription.id_patient === null || this.prescription.id_patient === undefined) {
                    Vue.set(this.prescription, "id_patient", this.patientinfo.id_patient);
                }
                if (this.prescription.Weight && this.weightunit) this.prescription.Weight = this.prescription.Weight.concat(' ', this.weightunit);

                formdataP.append("veterinaryprescription", JSON.stringify(this.prescription));
                await this.$API.veterinaryprescription.addVeterinaryPrescription(formdataP)
                    .then((res) => {
                        if (res) {
                            success.prescription = true;
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    });

                // Save reproduction
                const formdataR = new FormData();
                this.reproduction.ReproductionDate = this.date;
                Vue.set(this.reproduction, "ID_User_Insert", this.id_user_login);

                if (!this.reproduction.ID_Patient) {
                    Vue.set(this.reproduction, "ID_Patient", this.patientinfo.id_patient);
                }

                formdataR.append("veterinaryreproduction", JSON.stringify(this.reproduction));
                formdataR.append("reproductionswith", JSON.stringify(this.breeds));
                await this.$API.veterinaryReproduction.addVeterinaryReproduction(formdataR)
                    .then((res) => {
                        if (res) {
                            success.reproduction = true;
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    });

                if (success.medicalHistory && success.prescription && success.reproduction) {
                    this.showAlert("success", "Exito al guardar", `Se han guardado correctamente los datos registrados de historial medico, prescripción y reproducción del paciente seleccionado.`);
                }
                else {
                    throw new Error(`Error saving data: ${!success.medicalHistory ? 'medical history' : ''} ${!success.prescription ? 'prescription' : ''} ${!success.reproduction ? 'reproduction' : ''}`);
                }

            } catch (error) {
                console.log('--------save all error-----', error);
                this.showAlert("danger", "Advertencia", `Ocurrio un error al guardar los datos registrados, vuelva a intentarlo más tarde.`);
            } finally {
                if (!noCleanForm) {
                    await this.loadAllData();
                    this.resetData();
                }
                
                this.loading = false;
                //this.$store.commit('vetPatientData/setReloaders', 'VeterinaryMedicalHConsolidatedView');
            }
        },
        async updateAll() {
            this.loading = true;
            const success = {
                medicalHistory: false,
                prescription: false,
                reproduction: false,
            }

            try {
                // Update medical history
                this.medicalhistory.MedicalHistoryDate = this.date;

                this.medicalhistory.ID_User_Update = JSON.parse(localStorage.getItem("user")).id;
                this.medicalhistory.ID_Patient = this.patientinfo.id_patient;
                if (this.medicalhistory.Weight) {
                    if (!this.medicalhistory.Weight.includes('Lb') && !this.medicalhistory.Weight.includes('Kg')) {
                        this.medicalhistory.Weight = this.medicalhistory.Weight.concat(' ', this.weightunit);
                    }
                    else if (!this.medicalhistory.Weight.includes(this.weightunit)) {
                        const newValue = this.medicalhistory.Weight.slice(0, -3);
                        this.medicalhistory.Weight = newValue.concat(' ', this.weightunit);
                        console.log(this.medicalhistory.Weight);
                    }
                }
                this.medicalhistory.Talla = this.medicalhistory.height;
                this.medicalhistory.Pulso = this.medicalhistory.pulse;
                this.medicalhistory.FrecuenciaCardiaca = this.medicalhistory.heartRate;
                this.medicalhistory.FrecuenciaRespiratoria = this.medicalhistory.breathRate;
                this.medicalhistory.Temperatura = this.medicalhistory.temp;
                if (this.medicalhistory.vetExt) {
                    this.medicalhistory.VeterinarioExterno = 1;
                    this.medicalhistory.NombreVeterinarioExterno = this.medicalhistory.nameExt || '';
                }
                else {
                    this.medicalhistory.VeterinarioExterno = 0;
                    this.medicalhistory.NombreVeterinarioExterno = '';
                }

                await this.$API.veterinarymedicalhistory.updateMedicalHistory(this.medicalhistory)
                    .then((res) => {
                        if (res) {
                            success.medicalHistory = true;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                // Update prescription
                const formdataP = new FormData();
                this.prescription.prescriptiondate = this.date;

                if (this.usenextappointment.length == 0) {
                    this.prescription.nextappointment = null
                }
                if (this.prescription.Weight) {
                    if (!this.prescription.Weight.includes('Lb') && !this.prescription.Weight.includes('Kg')) {
                        this.prescription.Weight = this.prescription.Weight.concat(' ', this.weightunit);
                    }
                    else if (!this.prescription.Weight.includes(this.weightunit)) {
                        const newValue = this.prescription.Weight.slice(0, -3);
                        this.prescription.Weight = newValue.concat(' ', this.weightunit);
                    }
                }
                Vue.set(this.prescription, "id_user_update", this.id_user_login);

                formdataP.append("veterinaryprescription", JSON.stringify(this.prescription));

                await this.$API.veterinaryprescription.updateVeterinaryPrescription(this.prescription.id_pvp, formdataP)
                    .then((res) => {
                        if (res) {
                            success.prescription = true;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                // Update reproduction
                const formdataR = new FormData();
                this.reproduction.ReproductionDate = this.date;

                Vue.set(this.reproduction, "ID_User_Update", this.id_user_login);
                formdataR.append("veterinaryreproduction", JSON.stringify(this.reproduction));
                formdataR.append("reproductionswith", JSON.stringify(this.breeds));

                await this.$API.veterinaryReproduction.updateVeterinaryReproduction(formdataR)
                    .then((res) => {
                        if (res) {
                            success.reproduction = true;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                if (success.medicalHistory && success.prescription && success.reproduction) {
                    this.showAlert("success", "Exito al guardar", `Se han actualizado correctamente los datos registrados de historial medico, prescripción y reproducción del paciente seleccionado.`);
                }
                else {
                    throw new Error(`Error updating data: ${!success.medicalHistory ? 'medical history' : ''} ${!success.prescription ? 'prescription' : ''} ${!success.reproduction ? 'reproduction' : ''}`);
                }

            } catch (error) {
                console.log('--------update all error-----', error);
                this.showAlert("danger", "Advertencia", `Ocurrio un error al actualizar los datos registrados, vuelva a intentarlo más tarde.`);
            } finally {
                this.loading = false;
                await this.loadAllData();
                //this.$store.commit('vetPatientData/setReloaders', 'VeterinaryMedicalHConsolidatedView');
            }
        },
        async deleteAll() {
            this.loading = true;
            const success = {
                medicalHistory: false,
                prescription: false,
                reproduction: false,
            }

            try {
                // Delete medical history
                await this.$API.veterinarymedicalhistory.deleteMedicalHistory(this.medicalhistory)
                    .then((res) => {                        
                        if (res) {
                            success.medicalHistory = true;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                // Delete prescription
                const id = this.prescription.id_pvp;
                await this.$API.veterinaryprescription.deleteVeterinaryPrescription(id)
                    .then((res) => {
                        if (res) {
                            success.prescription = true;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                // Delete reproduction                
                await this.$API.veterinaryReproduction.deleteVeterinaryReproduction(this.reproduction.ID_Reproduction)
                    .then((res) => {                        
                        if (res) {
                            success.reproduction = true;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                if (success.medicalHistory && success.prescription && success.reproduction) {
                    this.showAlert("success", "Exito al eliminar", `Se han eliminado correctamente los datos registrados de historial medico, prescripción y reproducción del paciente seleccionado.`);
                }
                else {
                    throw new Error(`Error deleting data: ${!success.medicalHistory ? 'medical history' : ''} ${!success.prescription ? 'prescription' : ''} ${!success.reproduction ? 'reproduction' : ''}`);
                }

            } catch (error) {
                console.log('--------delete all error-----', error);
                this.showAlert("danger", "Advertencia", `Ocurrio un error al eliminar los datos seleccionados, vuelva a intentarlo más tarde.`);
            } finally {
                this.loading = false;
                await this.loadAllData();
                this.resetData();
            }
        },
        changeMedicalHistoryWeight(e) {
            this.medicalhistory.Weight = e.replace(' ', '');
            console.log('weigth', this.medicalhistory.Weight);
        },
        switchWeightUnit(e) {
            this.weightunit = e;
            console.log(this.weightunit);
        },
        Preview_image() {
            this.url = URL.createObjectURL(this.image);
        },

        disabledOne() {
            this.disabled1 = (this.disabled1 + 1) % 2;
        },
        disabledTwo() {
            this.disabled2 = (this.disabled2 + 1) % 2;
        },
        disabledThree() {
            this.disabled3 = (this.disabled3 + 1) % 2;
        },

        showMedicalHistory(data) {
            this.loading = true;
            this.resetData();
            this.date = data.MedicalHistoryDate;

            this.medicalhistory.Weight = data.Weight;

            if (this.medicalhistory.Weight.includes('Kg')) {
                this.weightunit = 'Kg';
                this.weight = this.medicalhistory.Weight.slice(0, -2).replace(' ', '');
            }
            else if (this.medicalhistory.Weight.includes('Lb')) {
                this.weightunit = 'Lb';
                this.weight = this.medicalhistory.Weight.slice(0, -2).replace(' ', '');
            }
            else {
                this.weightunit = '';
                this.weight = this.medicalhistory.Weight.replace(' ', '');
            }

            if (data.FinalDiagnosis.includes('undefined')) {
                data.FinalDiagnosis = "<p></p>";
            }
            if (data.PresumptiveDiagnosis.includes('undefined')) {
                data.PresumptiveDiagnosis = "<p></p>";
            }
            if (data.Treatment.includes('undefined')) {
                data.Treatment = "<p></p>";
            }
            if (data.Talla.includes('undefined')) {
                data.Talla = '';
            }
            if (data.Pulso.includes('undefined')) {
                data.Pulso = '';
            }
            if (data.Temperatura.includes('undefined')) {
                data.Temperatura = '';
            }
            if (data.FrecuenciaCardiaca.includes('undefined')) {
                data.FrecuenciaCardiaca = '';
            }
            if (data.FrecuenciaRespiratoria.includes('undefined')) {
                data.FrecuenciaRespiratoria = '';
            }

            this.medicalhistory = { ...data };

            this.medicalhistory.height = data.Talla || '';
            this.medicalhistory.pulse = data.Pulso || '';
            this.medicalhistory.temp = data.Temperatura || '';
            this.medicalhistory.heartRate = data.FrecuenciaCardiaca || '';
            this.medicalhistory.breathRate = data.FrecuenciaRespiratoria || '';
            if (data.VeterinarioExterno == 1 || data.VeterinarioExterno == true) {
                this.medicalhistory.vetExt = true;
                this.medicalhistory.nameExt = data.NombreVeterinarioExterno;
            }
            else {
                this.medicalhistory.vetExt = false;
                this.medicalhistory.nameExt = '';
            }
            this.vetExt = this.medicalhistory.vetExt;

            for (const item of this.reproductions) {
                if (data.MedicalHistoryDate == item.ReproductionDate) {
                    this.selectingReproduction(item);
                    console.log(item);

                }
            }
            for (const item of this.prescriptions) {
                if (data.MedicalHistoryDate == item.PrescriptionDate) {
                    this.selectedPrescription(item);
                    console.log(item);

                }
            }

            this.loading = false;
            this.selectedValues = true;
        },
        filterMedicalHistoryHandler(response) {
            console.log(response);
            this.medicalhistorys = response;
        },
        loadHistoryMedical() {
            this.loading = true;
            this.$API.veterinarymedicalhistory
                .loadMedicalHistorySorted(this.searchHistory)
                .then((data) => {
                    this.medicalhistorys = data;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        
        resetData() {
            this.date = this.moment().format("DD/MM/YYYY");

            this.branchConfig = JSON.parse(localStorage.getItem("branch"));
            if (this.branchConfig?.weightunit) this.weightunit = this.branchConfig.weightunit;
            if (this.data?.weightunit) this.weightunit = this.data.weightunit;

            const localparams = JSON.parse(localStorage.getItem("localparams")) || null;
            if (localparams && localparams.weightunit) this.weightunit = localparams.weightunit;

            this.medicalhistory = {};
            this.medicalhistory.Weight = this.data.Weight;
            if (this.medicalhistory && this.medicalhistory.Weight.includes('Kg')) this.weightunit = 'Kg';
            if (this.medicalhistory && this.medicalhistory.Weight.includes('Lb')) this.weightunit = 'Lb';
            if (this.medicalhistory.Weight && this.medicalhistory.Weight.includes(this.weightunit)) {
                this.weight = this.medicalhistory.Weight.slice(0, -2).replace(' ', '');
            } else {
                this.weight = this.medicalhistory.Weight.replace(' ', '');
            }

            this.prescription = {}
            this.prescription.Weight = this.data.Weight;
            if (this.prescription && this.prescription.Weight.includes('Kg')) this.weightunit = 'Kg';
            if (this.prescription && this.prescription.Weight.includes('Lb')) this.weightunit = 'Lb';
            if (this.prescription.Weight && this.prescription.Weight.includes(this.weightunit)) {
                this.weight = this.prescription.Weight.slice(0, -2).replace(' ', '');
            } else {
                this.weight = this.prescription.Weight.replace(' ', '');
            }
            this.prescription.prescriptiondate = this.date;
            this.prescription.nextappointment = this.date;

            this.reproduction = {};
            this.reproduction.Weight = this.data.Weight;
            this.reproduction.ReproductionDate = this.date;
            this.reproduction.DateGestation = this.date;
            this.reproduction.JealousyBeginning = this.date;
            this.reproduction.JealousyEnd = this.date;
            this.breeds = [];
            this.breed = {};
            this.breed.Date = this.date;

            this.selectedValues = false;
        },
        closeAlert() {
            this.alert.show = false;

            if (this.alert.options == "deleteAllList") {
                this.resetData();
            }
        },
        acceptAlert() {
            this.alert.show = false;
            
            if (this.alert.options == "deleteAll") {
                this.deleteAll();
            }
            if (this.alert.options == "deleteAllList") {
                this.deleteAll();
            }
        },
        showAlert(type, header, body, options = null) {
            type = type == null ? "danger" : type;
            this.alert.type = type;
            this.alert.header = header;
            this.alert.body = body;
            this.alert.show = true;
            this.alert.options = options != null ? options : null;
        },
        confirmDeleteAll() {
            this.showAlert(
                "question",
                "Eliminar",
                "¿Está seguro que desea eliminar el registro unificado de historial medico, prescripción y reproducción del paciente?",
                "deleteAll"
            );
        },
        confirmDeleteAllList(data) {
            this.showMedicalHistory(data);
            this.showAlert(
                "question",
                "Eliminar",
                "¿Está seguro que desea eliminar el registro unificado de historial medico, prescripción y reproducción del paciente?",
                "deleteAllList"
            );
        },

        onEditorChange() { },

        /*onEditorChange(e) {
                    //this.medicalhistory.comment = e.html;
                },*/
        assigning(prescription, patientinfo, useData, usenextappointment) {
            this.printPrescription = {
                show: true,
                data: {
                    prescription: prescription,
                    patientinfo: patientinfo,
                    useData: useData,
                    customer: patientinfo.customer,
                    nextappointment: usenextappointment
                }
            };
        },
        onExportingPrescription(e) {
            printToExcel(e, "Prescripciones")
        },
        changePrescriptionWeight(e) {
            this.prescription.Weight = e.replace(' ', '');
        },
        /* switchWeightUnit(e) {
        this.weightunit = e;
        }, */
        getHeaderFooter() {
            let search = {
                ID_branch: JSON.parse(localStorage.getItem("user")).branch || "",
                ID_business: JSON.parse(localStorage.getItem("user")).businessid || ""
            }
            this.$API.HF.getHF(search).then((data) => {
                let header = [], footer = []
                header = data.filter((x) => x.State == '1' && x.Type == 'Header')
                this.header = header.length > 0 ? JSON.parse(header[0].Body).html : ''
                footer = data.filter((x) => x.State == '1' && x.Type == 'Footer')
                this.footer = footer.length > 0 ? JSON.parse(footer[0].Body).html : ''
            })
        },
        getBodyTemplate() {
            let data = {
                obj: {
                    Branch_logo: this.infobusiness.BranchLogoPath,
                    BusinessName: this.infobusiness.BusinessName,
                    BranchName: this.infobusiness.BranchName,
                    BranchAddress: this.infobusiness.BranchAddress,
                    BranchPhone: this.infobusiness.BranchPhone,
                    BranchEmail: this.infobusiness.BranchEmail,
                    id_customer: this.patientinfo.id_customer,
                    id_patient: this.patientinfo.id_patient,
                    namepatient: this.patientinfo.namepatient,
                    species: this.patientinfo.species,
                    breed: this.patientinfo.breed,
                    UserNextAppointmentLength: this.usenextappointment.length,
                    next_appointment_date: this.prescription.nextappointment,
                    prescription: this.prescription.prescription
                },
                type: 'Prescription'
            }
            this.$API.TM.getResolveTemplate(data).then((x) => {
                console.log(x)
                this.template = x[0]
                this.template.Body = JSON.parse(this.template.Body).html
                this.dialogprint = true
            }, (err) => {
                console.log(err)
            })
            // this.$API.TM.getTM().then((data)=>{
            //   this.template = data.filter((x)=>x.Type == 'Prescription')[0];
            // var html = this.template.Body.replace("{{Name}}", "Test1")
            // html = html.replace("@username", "alex@gmail.com")
            // html = html.replace("@password", "f1_M1@j3[I2~")
            // html = html.replace("@website", "www.google.com")
            // this.template.Body = JSON.parse(html).html
            // Object.entries(this.prescriptionJSON).map(([k]) => {
            //   this.template.Body = this.template.Body.replace(`@${k}`,
            //   k=="Branch_logo" ? this.infobusiness.BranchLogoPath
            //    : k=="BusinessName" ? this.infobusiness.BusinessName
            //     : k=="BranchName" ? this.infobusiness.BranchName
            //       : k=="BranchAddress" ? this.infobusiness.BranchAddress
            //        : k=="BranchPhone" ? this.infobusiness.BranchPhone
            //         : k=="BranchEmail" ? this.infobusiness.BranchEmail
            //           : k=="id_customer" ? this.patientinfo.id_customer
            //            : k=="id_patient" ? this.patientinfo.id_patient
            //             : k=="namepatient" ? this.patientinfo.namepatient
            //              : k=="species" ? this.patientinfo.species
            //               : k=="breed" ? this.patientinfo.breed
            //                : k=="UserNextAppointmentLength" ? this.usenextappointment.length
            //                 : k=="next_appointment_date" ? this.prescription.nextappointment
            //                  : k=="prescription" ? this.prescription.prescription
            //                   : '');
            // });
            // this.template.Body = JSON.parse(this.template.Body).html;
            // console.log(this.template.Body)
            // })
        },
        getPrescriptions() {
            this.$API.veterinaryprescription
                .getVeterinaryPrescription(this.patientinfo.id_patient)
                .then((response) => {
                    this.prescriptions = response;
                    let datetoday = this.moment().format("DD/MM/YYYY");
                    let day = this.prescription.prescriptiondate || datetoday;
                    console.log(this.prescription);


                    for (const el of response) {
                        if (el.PrescriptionDate == day) {
                            this.selectedPrescription(el);
                        }
                    }
                })
        },
        selectedPrescription(data) {
            Object.keys(data).map((el) => {
                Vue.set(this.prescription, el.toLowerCase(), data[el])
                this.prescription.ID_PVP = data.ID_PVP
            })
            this.prescription.Weight = data.Weight;
            if (this.prescription.Weight != null && this.prescription.Weight.includes('Kg')) {
                this.weightunit = 'Kg';
                this.weight = this.prescription.Weight.slice(0, -2).replace(' ', '');
            }
            else if (this.prescription.Weight != null && this.prescription.Weight.includes('Lb')) {
                this.weightunit = 'Lb';
                this.weight = this.prescription.Weight.slice(0, -2).replace(' ', '');
            }
            else {
                this.weightunit = '';
                this.weight = this.prescription.Weight != null ? this.prescription.Weight.replace(' ', '') : '';
            }
            if (this.prescription.nextappointment != null) {
                this.usenextappointment.push("Appointment")
            } else {
                this.prescription.nextappointment = this.moment().format("DD/MM/YYYY")
                this.usenextappointment = []
            }
        },
        onPrescriptionEditorChange(e) {
            this.prescription.prescription = e.html
        },
        NextAppointmentFunction(e) {
            if (e[0] == "Appointment") this.usenextappointment.push("Appointment")
            else this.usenextappointment = []
        },
        Print() {
            this.$print(this.$refs.print)
        },
        getInfoBranch() {
            this.$API.branches.findSpecificBranch(this.searchbusiness)
                .then(res => {
                    this.infobusiness = res[0]
                })
        },

        filterPrescriptionHandler(response) {
            this.prescriptions = response
        },

        /* Reproductions Functions */
        onExportingReproductions(e) {
            printToExcel(e, "Reproducciones");
        },

        handleCaesareanSectionChange: function () {
            if (this.reproduction.CaesareanSection) {
                this.reproduction.Natural = false;
            }
        },
        handleNaturalChange: function () {
            if (this.reproduction.Natural) {
                this.reproduction.CaesareanSection = false;
            }
        },
        disabledROne() {
            this.disabledR1 = (this.disabledR1 + 1) % 2;
        },
        disabledRTwo() {
            this.disabledR2 = (this.disabledR2 + 1) % 2;
        },
        disabledRThree() {
            this.disabledR3 = (this.disabledR3 + 1) % 2;
        },
        disabledRFour() {
            this.disabledR4 = (this.disabledR4 + 1) % 2;
        },
        disabledRFive() {
            this.disabledR5 = (this.disabledR5 + 1) % 2;
        },
        selectingReproduction(data) {
            this.reproduction = data;

            this.disabledROne();
            this.disabledRTwo();
            this.disabledRThree();
            this.disabledRFour();
            this.disabledRFive();

            this.$API.veterinaryReproduction.getAllReproductionsWith(this.reproduction.ID_Reproduction)
                .then((response) => {
                    this.breeds = response;
                    for (let i = 0; i < this.breeds.length; i++) {
                        var count = i + 1;
                        this.breeds[i].id = count;
                    }
                })
                .catch(() => {
                    this.loading = false;
                    this.showAlert("danger", "error", "Ha ocurrido un error al obtener los datos");
                })
        },
        transportDataBreed() {
            this.count = this.count + 1;
            let br = {
                id: this.count,
                ReproductionWith: this.breed.ReproductionWith,
                Date: this.breed.Date,
            };
            this.breeds.push(br);
        },
        deleteBreedSelected(data) {
            const index = this.breeds.indexOf(data);
            this.breeds.splice(index, 1);
        }
    },
};
</script>

<style scoped>
.btndisable {
    background-color: #41464c !important;
    color: white;
}

.btndisable2 {
    background-color: #1976d2 !important;
    color: white;
}

.tabsB {
    width: 100%;
    padding: 10px;
    background-color: #1976d2;
    color: white;
    border-radius: 15px;
    border-style: solid;
    border-color: black;
    border-width: 1px;
}

.tabsB:hover {
    cursor: pointer;
}

.tabs:hover {
    background-color: rgba(80, 80, 80, 1);
    cursor: pointer;
}

.btnblue {
    background-color: #1976d2 !important;
    color: white;
}

.btnclean {
    background-color: #f29d35 !important;
    color: white;
}

.btnsave {
    background-color: #00a178 !important;
    color: white;
}

.tabs {
    width: 100%;
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white;
}

.text-white {
    color: white !important;
}

.box {
    border-style: solid;
    border-color: black;
    border-width: 1px;
    background-color: white;

    border-radius: 15px;
}

.label-forms {
    border-radius: 15px;
}

.tabs {
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white;
}

.btnblack {
    background-color: rgba(60, 60, 60, 1) !important;
    color: white !important;
}

.tabsNh {
    width: 100%;
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 10px;
}

.infopatient {
    margin-top: 5%;
}
</style>
