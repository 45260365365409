import { render, staticRenderFns } from "./SidebarOptions.vue?vue&type=template&id=398e360b&scoped=true&"
import script from "./SidebarOptions.vue?vue&type=script&lang=js&"
export * from "./SidebarOptions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "398e360b",
  null
  
)

export default component.exports