<template>
	<div>
		<block v-if="loading"></block>
		<v-card class="white" flat>
			<v-card-text>
				<v-card flat class="pa-4 bar">
					<div class="d-flex justify-end align-center">
						<v-btn
							class="btnsave mr-1"
							small
							@click.prevent.stop="saveAll"
						>
							<v-icon>
								mdi-content-save
							</v-icon>
						
						</v-btn>
					</div>
				</v-card>
				<div class="mx-2 mt-10">
					<v-row>
						<v-col cols="12">
							<h3>Configuración de Correo Eléctronico {{ nameSelected }}</h3>
						</v-col>
					</v-row>
					<v-row class="mt-5">
						<v-col cols="12" md="4">
							<v-text-field outlined dense v-if="isCustomMail" v-model="email.CustomUserNameEmail" type="email" label="Correo Electronico"></v-text-field>
							<v-text-field outlined dense v-else v-model="email.UserNameEmail" type="email" label="Correo eléctronico:"></v-text-field>
						</v-col>
						<v-col cols="12" md="4">
							<v-text-field outlined dense v-if="isCustomMail" v-model="email.CustomPasswordEmail" type="password" label="Contraseña"></v-text-field>
							<v-text-field outlined dense v-else v-model="email.PasswordEmail" type="password" label="Contraseña API de correo eléctronico:" :rules="[noSpacesRule]" @input="handleDeleteSpaces"></v-text-field>
						</v-col>
						<v-col cols="12" md="4">
							<v-autocomplete outlined dense :items="[{
								name: 'Gmail',
								value: 'Google'
							}, {
								name: 'SMTP',
								value: 'Personalizado'
							}]" @change="setValueEmail" 
							item-text="name" item-value="value"
							attach v-model="email.MailSendType" label="Tipo de correo"></v-autocomplete>
						</v-col>
					</v-row>
					<v-row class="mt-5" v-if="isCustomMail">
						<v-col cols="12" md="4">
							<v-text-field outlined dense v-model="email.CustomServerEmail" label="Servidor"></v-text-field>
						</v-col>
						<v-col cols="12" md="4">
							<v-text-field outlined dense v-model="email.CustomPortName" label="Puerto"></v-text-field>
						</v-col>
					</v-row>
				</div>

				<!-- Configuración de Recordatorio de Correo Eléctronico -->
				<div class="mx-2 mt-5" v-if="businessType === 'veterinary'">
					<v-row >
						<v-col cols="12">
							<h3>Configuración de Recordatorio de Correo Electrónico</h3>
						</v-col>
						<v-col cols="12">
							<p class="mb-n2"><span class="font-weight-bold mb-n3">Nota:</span> si no se asigna dia al recordatorio este se enviará con un dia de anticipación</p>
						</v-col>
					</v-row>
					<v-row class="mt-4">
						<v-col cols="12" md="3">
							<ValidationObserver ref="observer" tag="form" v-slot="{ invalid, handleSubmit }">
								<v-form @submit.prevent="handleSubmit(() => {
									save('groomingReminder')
								})">
									<v-col cols="12">
										<h4>Recordatorio de Peluquería</h4>
									</v-col>
									<v-col cols="12" class="mt-n3 mb-n5">
										<v-checkbox v-model="form.groomingReminder.active" label="Modificar" />
									</v-col>
									<v-col cols="8">
										<ValidationProvider name="Event scheduler duration" rules="required" v-slot="{ errors }">
											<v-text-field outlined dense :error-messages="errors" type="number" v-model="form.groomingReminder.anticipationDays" label="Días de anticipación" />
										</ValidationProvider>
									</v-col>
									<v-row>
										<v-col cols="4" class="mt-2">
											<h5 style="text-align: left;">Hora de envío</h5>
										</v-col>
										<v-col cols="8">
											<vue-timepicker v-model="form.groomingReminder.scheduleTime" :minute-interval="5"></vue-timepicker>
										</v-col>
									</v-row>
									<v-col cols="4">
									</v-col>
								</v-form>
							</ValidationObserver>
						</v-col>
						<v-col cols="12" md="3">
							<ValidationObserver ref="observer" tag="form" v-slot="{ invalid, handleSubmit }">
								<v-form @submit.prevent="handleSubmit(() => {
									save('birthdayReminder')
								})">
									<v-col cols="12">
										<h4>Recordatorio de Cumpleaños</h4>
									</v-col>
									<v-col cols="12" class="mt-n3 mb-n5">
										<v-checkbox v-model="form.birthdayReminder.active" label="Modificar" />
									</v-col>
									<v-col cols="8">
										<ValidationProvider name="Event scheduler duration" rules="required" v-slot="{ errors }">
											<v-text-field outlined dense :error-messages="errors" type="number" v-model="form.birthdayReminder.anticipationDays" label="Días de anticipación" />
										</ValidationProvider>
									</v-col>
									<v-row>
										<v-col cols="4" class="mt-2">
												<h5 style="text-align: left;">Hora de envío</h5>
											</v-col>
										<v-col cols="8">
											<vue-timepicker v-model="form.birthdayReminder.scheduleTime" :minute-interval="5"></vue-timepicker>
										</v-col>
									</v-row>
									<v-col cols="4">
									</v-col>
								
								</v-form>
							</ValidationObserver>
						</v-col>
						<v-col cols="12" md="3">

							<ValidationObserver ref="observer" tag="form" v-slot="{ invalid, handleSubmit }">
								<v-form @submit.prevent="handleSubmit(() => {
									save('vaccineReminder')
								})">
									<v-col cols="12">
										<h4>Recordatorio de Vacunas</h4>
									</v-col>
									<v-col cols="12" class="mt-n3 mb-n5">
										<v-checkbox v-model="form.vaccineReminder.active" label="Modificar" />
									</v-col>
									<v-col cols="8">
										<ValidationProvider name="Event scheduler duration" rules="required" v-slot="{ errors }">
											<v-text-field outlined  dense :error-messages="errors" type="number" v-model="form.vaccineReminder.anticipationDays" label="Días de anticipación" />
										</ValidationProvider>
									</v-col>
									<v-row>
										<v-col cols="4" class="mt-2">
												<h5 style="text-align: left;">Hora de envío</h5>
											</v-col>
										<v-col cols="8">
											<vue-timepicker v-model="form.vaccineReminder.scheduleTime" :minute-interval="5"></vue-timepicker>
										</v-col>
									</v-row>
									<v-col cols="4">
									</v-col>
								
								</v-form>
							</ValidationObserver>
						</v-col>
						<v-col cols="12" md="3">
							<ValidationObserver ref="observer" tag="form" v-slot="{ invalid, handleSubmit }">
								<v-form @submit.prevent="handleSubmit(() => {
									save('prescriptionReminder')
								})">
									<v-col cols="12">
										<h4>Recordatorio de Prescripciones</h4>
									</v-col>
									<v-col cols="12" class="mt-n3 mb-n5">
										<v-checkbox v-model="form.prescriptionReminder.active" label="Modificar" />
									</v-col>
									<v-col cols="8">
										<ValidationProvider name="Event scheduler duration" rules="required" v-slot="{ errors }">
											<v-text-field    outlined dense :error-messages="errors" type="number" v-model="form.prescriptionReminder.anticipationDays" label="Días de anticipación" />
										</ValidationProvider>
									</v-col>
									<v-row>
										<v-col cols="4" class="mt-2">
											<h5 style="text-align: left;">Hora de envío</h5>
										</v-col>
										<v-col cols="8">
											<vue-timepicker v-model="form.prescriptionReminder.scheduleTime" :minute-interval="5"></vue-timepicker>
										</v-col>
									</v-row>
									<v-col cols="4">
									</v-col>
								
								</v-form>
							</ValidationObserver>
						</v-col>
					</v-row>
				</div>

				<!-- Configuración del Sistema -->
				<div class="mx-2 mt-7">
					<v-row >
						<v-col cols="12">
							<h3>Configuración del Sistema</h3>
						</v-col>
					</v-row>
					<v-row class="mt-5" v-if="businessType === 'veterinary'">
						<v-col cols="12" md="3">
							<v-select outlined dense  attach  
							v-model="formEsp.weightunit" :items="['Kg', 'Lb']" label="Unidad de peso"></v-select>
						</v-col>
						<v-col cols="12" md="3">
							<v-btn class="btn-add mb-3" @click="showListSpecies =  true;">
								Agregar Listado de Especies
							</v-btn>
						</v-col>
						<v-col cols="12" md="2">
							<v-checkbox v-model="formEsp.modePatient"  :label="+ formEsp.modePatient ? 'Modo Completo': 'Modo Basico' " />
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="4">
							<v-autocomplete outlined dense 
							:items="countryList" 
							attach
							item-text="name" 
							v-model="formEsp.country"
							@change="getStates"
							label="País*">
							</v-autocomplete>
						</v-col>
						<v-col cols="12" md="4">
							<v-autocomplete outlined dense    v-if="formEsp.country == 'El Salvador'" :items="departments" v-model="formEsp.department" label="Estado/Departamento*" item-text="Valores" item-value="Codigo"  attach>
							</v-autocomplete>
							<v-autocomplete outlined dense   v-else v-model="formEsp.department" label="Estado/Departamento*" :items="stateList" item-text="name" @change="getCities" attach >
								<template v-slot:no-data>
								<div class="px-4">{{ noDataLabelStates }}</div>
								</template>
							</v-autocomplete>
						</v-col>
						<v-col cols="12" md="4">
							<v-autocomplete outlined dense   :items="municipalities" v-if="formEsp.country == 'El Salvador'" :readonly="!formEsp.department"  v-model="formEsp.municipality" label="Ciudad*" item-text="Valores" item-value="Codigo"  attach/>
							<div v-else>
							<v-autocomplete outlined dense    v-if="cityList.length > 0 || noDataLabelCities === 'Cargando...'" v-model="formEsp.municipality" label="Ciudad" item-text="name" :readonly="!formEsp.department" :items="cityList" attach>
								<template v-slot:no-data>
								<div class="px-4">{{ noDataLabelCities }}</div>
								</template>
							</v-autocomplete>
							<v-text-field outlined dense   v-else v-model="formEsp.municipality" label="Ciudad" placeholder="Digite la ciudad del cliente" />
							</div>
						</v-col>
						<v-col cols="12" md="4" v-if="EnableZoneConfiguration">
							<v-btn class="btn-add" @click="showZoneConfiguration = true">Configuración de zonas para clientes</v-btn>
						</v-col>
						<v-dialog v-model="showZoneConfiguration" max-width="60%" scrollable>
							<v-card>
								<v-card-title>
									<h3>Configuración de zonas para clientes</h3>
								</v-card-title>
								<v-card-text>
									<v-row class="mt-2">
										<v-col cols="10">
											<v-text-field v-model="selectedZone" label="Agregar zonas para clientes"
											outlined dense chips></v-text-field>
										</v-col>
										<v-col cols="2">
											<v-btn class="btn-add" @click="addZone"><v-icon>mdi-plus</v-icon></v-btn>
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="12">
											<v-simple-table class="elevation-1">
												<thead>
													<tr>
													<th class="text-left">Listado de zonas</th>
													<th class="text-left">Acción</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(item, index) in ZoneConfiguration" :key="index">
													<td>{{ item }}</td>
													<td><a @click="removeZoneConfiguration(index)"><v-icon color="error">mdi-delete</v-icon></a></td>
													</tr>
												</tbody>
											</v-simple-table>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-dialog>
					</v-row>
				</div>

				<!-- Configuración de Prescripción -->
				<div class="mx-2 mt-5">
					<v-row>
						<v-col cols="12">
							<h3>Configuración de Prescripción</h3>
						</v-col>
						<v-col cols="12">
							<p class="mb-n2"> <span class="font-weight-bold mb-n3">Nota:</span> La altura total de la prescripción consistirá siempre en dos valores que sumados resulten en un total de 130mm. Puede cambiar el tamaño del encabezado; el tamaño del contenido se recalcula automáticamente. </p>
						</v-col>
					</v-row>
					<v-row class="mt-5 mb-sm-n4" v-if="businessType !== 'accounting_and_bill'">
						<v-col cols="12" sm="4" md="3" class="mt-4">
							<v-text-field type="number" label="Altura del Encabezado" placeholder="Introduzca la altura en mm" v-model="prescriptionHeaderHeight" outlined suffix="mm" dense ></v-text-field>
						</v-col>
						<v-col cols="12" sm="4" md="3" class="mt-sm-4">
							<v-text-field type="number" label="Altura del Contenido" placeholder="Introduzca la altura en mm" v-model="prescriptionContentHeight" outlined suffix="mm" dense readonly disabled ></v-text-field>
						</v-col>
						<v-col cols="12" sm="4" md="3" class="mt-sm-4">
							<v-text-field type="number" label="Tamaño de Letra" placeholder="Introduzca tamaño en px" v-model="prescriptionFontSize" outlined suffix="px" dense ></v-text-field>
						</v-col>
					</v-row>
					<v-row class="mt-5" v-if="businessType !== 'accounting_and_bill'">
						<v-col cols="12" sm="6" md="3">
							<v-text-field type="number" label="Margen Superior" placeholder="Introduzca la altura en mm" v-model="prescriptionTopMargin" outlined suffix="mm" dense ></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="3">
							<v-text-field type="number" label="Margen Inferior" placeholder="Introduzca la altura en mm" v-model="prescriptionBottomMargin" outlined suffix="mm" dense ></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="3">
							<v-text-field type="number" label="Margen Derecho" placeholder="Introduzca la altura en mm" v-model="prescriptionRightMargin" outlined suffix="px" dense ></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="3">
							<v-text-field type="number" label="Margen Izquierdo" placeholder="Introduzca la altura en mm" v-model="prescriptionLeftMargin" outlined suffix="px" dense ></v-text-field>
						</v-col>
					</v-row>
				</div>

				<!-- Color de Encabezado de Tablas en Impresión de Facturas -->
				<div class="mx-2 mt-7">
					<v-row>
						<v-col cols="12" sm="4" v-if="TipoPlan != 'Emprendedor'">
							<h3 class="mb-2">Color del Encabezado de Tablas en Impresión de Facturas</h3>
							<v-color-picker v-model="specialInvoicesColor" mode="hexa"></v-color-picker>
						</v-col>
						<v-col cols="12" sm="4" v-if="TipoPlan != 'Emprendedor'">
							<h3 class="mb-2">Color del Título de Tablas en Impresión de Facturas</h3>
							<v-color-picker v-model="specialInvoicesTitleColor" mode="hexa" ></v-color-picker>
						</v-col>
						<v-col cols="12" sm="4" v-if="TipoPlan != 'Emprendedor'">
							<h3 class="mb-2">Formato Final</h3>
							<v-card flat class="align-self-center border py-7 px-5 text-center rounded-lg" :style="{ backgroundColor: specialInvoicesColor }" >
								<h2 :style="{ color: `${specialInvoicesTitleColor} !important` || '#FFF !important', }">
									Ejemplo de cómo se verá el encabezado
								</h2>
							</v-card>
						</v-col>
					</v-row>
				</div>

				<!-- Es Gasolinera -->
				<div class="mx-2 mt-5">
					<v-row>
						<v-col cols="12" md="2">
							<v-checkbox v-model="isFuelStation" label="¿Es gasolinera?" />
						</v-col>
						<template v-if="isFuelStation">
							<v-col cols="10" md="2">
								<v-text-field
								v-model="itemNoBomba"
								label="Agregar No. de bomba"
								outlined
								dense
								type="number"
								></v-text-field>
							</v-col>
							<v-col cols="1" md="1">
								<v-btn @click="addNoBomba()" class="btn-add">+</v-btn>
							</v-col>
							<v-col cols="1" md="1">
								<v-btn @click="cleanItemsNoBombas()" class="btnclean"><v-icon color="primary">mdi-eye</v-icon></v-btn>
							</v-col>
							<v-col cols="12" md="6">
								<h3>No. de Bombas: {{ formatNoBombas }}</h3>
							</v-col>
						</template>
					</v-row>
				</div>
			</v-card-text>
		</v-card>
		<v-dialog v-model="showListSpecies"
			:width="$vuetify.breakpoint.smAndDown ?'90%':'80%'"
			style="z-index: 100;">
			<v-card>
				<v-card-title class="text-h5">
				Listado de Especies
				</v-card-title>
			<v-card-text>
				<span>
					<strong>
					Nota:
					</strong>
					los datos de las especies deben estar separadas por una coma (,) y sin espacios.
					las especies que acepten cualquier raza deben dejar raza en blanco.
				</span>
				<div
					class="d-flex justify-end flex-wrap"
				>
					<v-btn small class="btnclean mr-2 mb-2" @click="setDefaultVal()">
						valores por defecto
					</v-btn>
					<v-btn small class="btn-add mr-2 mb-2" @click="showPreview ()">
						vista previa
					</v-btn>
					<v-btn small class="btn-add  mr-2" @click="formEsp.speciesList.push({name: null , value: null })">
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</div>
				<DxDataGrid
					class="mt-2"
					:data-source="formEsp.speciesList"
					:allow-column-reordering="true"
					:allow-column-resizing="true"
					:column-auto-width="true"
					:show-borders="true"
				>
					<DxColumnFixing :enabled="true"/>
					<DxColumn data-field="name" caption="Especies"></DxColumn>

					<DxColumn cell-template="viewTemplate" caption="ver listado" ></DxColumn>
					<DxEditing :allowUpdating="true" :allowDeleting="true" 
					:useIcons="true" :confirmDelete="false" 
					:mode="'cell'"></DxEditing>
					<template #viewTemplate="{data}">
						<a href="#" @click.prevent.stop="showSelectedList(data.data);showParams=true ">
							<v-icon color="primary">mdi-eye</v-icon>
						</a>
					</template>
				</DxDataGrid>
			</v-card-text>
		</v-card>
	</v-dialog>
		<v-dialog v-model="showPreviewSP"
			width="80%"
		>
			<v-card>
				<v-card-title>
					<h3><b>Previsualización de Especies</b></h3>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" md="6">
							<v-autocomplete
							outlined
							dense
							v-model="previwSelected"
							:items="previewEsp"
							label="Especies"
							attach
							></v-autocomplete>
						</v-col>
						<v-col cols="12" md="6">
							<template v-if="speciesValues == null || speciesValues == []">
								<v-text-field outlined dense label="Razas" />
							</template>
							<template v-else>     
								<v-autocomplete outlined dense attach :items="speciesValues" label="Razas" />
							</template>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="showParams"
			width="80%" >
			<v-card>
				<v-card-title>
					<h3><b>Lista de Especies</b></h3>
				</v-card-title>
				<v-card-text>
					<v-row class="mt-2">
						<v-col cols="12" md="12">
							<v-textarea
							outlined
							rows="5"
							v-model="praramsToShow.value"
							:items="previewEsp"
							label="Especies"
							attach
							/>
						</v-col>                        
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-btn class="btndelete" @click="showParams = false">
						cerrar
					</v-btn>
					<v-btn class="btnsave" @click="showParams = false; shaveChangues ">
						guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert"
			:properties="alert">
		</alerts>
	</div>
</template>


<script>
import _ from "lodash";
import Alerts from "@/components/Alerts"
import { DxDataGrid, DxColumn, DxColumnFixing, DxEditing } from 'devextreme-vue/data-grid';
import {speciesList} from '@/helpers/veterinary/speciesList';
import Block from "@/components/Block"
import { mapGetters } from "vuex";
import 'vue2-timepicker/dist/VueTimepicker.css';

export default {
	name: "ManageStore",
	components: { Alerts, DxDataGrid, DxColumn, DxColumnFixing, DxEditing , Block},
	props: ['business', 'win'],
	data: () => ({
		alert: {
			type: "success",
			show: false,
			header: "",
			body: "",
		},
		googleDriveApi: {},
		businessType: JSON.parse(localStorage.getItem("user")).businesstype,
		email: {},
		form: {
			groomingReminder: {
				active: false,
			},
			vaccineReminder: {
				active: false,
			},
			birthdayReminder: {
				active: false,
			},
			prescriptionReminder: {
				active: false,
			},
		},
		loading_btn: false,
		loading: false,
		//params of sistems
		showListSpecies: false, 
		formEsp: {
			weightunit: null,
			modePatient: false,
			speciesList: [],
			country: null,
			department: null,
			city: null,
		},
		showPreviewSP: false,
		previewEsp: [],
		previwSelected: null,
		praramsToShow: {
			name: null,
			value: null
		},
		showParams: false,
		stateList: [],
		cityList: [],
		noDataLabelCities: 'No hay Ciudades disponibles',
		noDataLabelStates: 'No hay Estados disponibles',
		reminderTypeConfig: [
			'groomingReminder',
			'birthdayReminder',
			'vaccineReminder',
			'prescriptionReminder'
		],
		typeEmali: null,
		isFuelStation: false,
		itemsNoBombas: [],
		itemNoBomba: null,
		specialInvoicesColor: JSON.parse(localStorage.getItem('user')).specialinvoicescolor,
		specialInvoicesTitleColor: JSON.parse(localStorage.getItem('user')).specialInvoicesTitleColor,
		TipoPlan: null,
		EnableZoneConfiguration: JSON.parse( localStorage.getItem( "user" ) ).enablezoneconfiguration,
		ZoneConfiguration: [],
		selectedZone: null,
		showZoneConfiguration: false,
		prescriptionHeaderHeight: JSON.parse(localStorage.getItem('user')).prescriptionHeaderHeight || 40,
		prescriptionContentHeight: JSON.parse(localStorage.getItem('user')).prescriptionContentHeight || 57.67,
		prescriptionTopMargin: JSON.parse(localStorage.getItem('user')).prescriptionTopMargin || 10,
		prescriptionBottomMargin: JSON.parse(localStorage.getItem('user')).prescriptionBottomMargin || 10,
		prescriptionRightMargin: JSON.parse(localStorage.getItem('user')).prescriptionRightMargin || 10,
		prescriptionLeftMargin: JSON.parse(localStorage.getItem('user')).prescriptionLeftMargin || 10,
		prescriptionFontSize: JSON.parse(localStorage.getItem('user')).prescriptionFontSize || 13,
	}),
	created() {
		this.email.UserNameEmail = this.business.UserNameEmail
		this.email.PasswordEmail = this.business.PasswordEmail
		this.email.ID_Business = this.business.ID_Business;
		this.email.MailSendType = this.business.MailSendType;
		this.typeEmali = this.business.MailSendType;
		this.email.CustomUserNameEmail = this.business.CustomUserNameEmail;
		this.email.CustomPasswordEmail = this.business.CustomPasswordEmail;
		this.email.CustomServerEmail = this.business.CustomServerEmail;
		this.email.CustomPortName = this.business.CustomPortName;
		this.googleDriveApi.keyfile = this.business.ApiKeyImages;
		this.googleDriveApi.ID_Business = this.business.ID_Business;
	},
	mounted() {
		this.TipoPlan = JSON.parse(localStorage.getItem('user')).TipoPlan;
		this.specialInvoicesColor = this.business.SpecialInvoicesColor;
		this.specialInvoicesTitleColor = this.business.SpecialInvoicesTitleColor;
		this.getAllReminders();
		this.fetchCurrentTemp();
		this.getallSysparams();
		this.getZoneConfiguration();
		this.updateContentHeight();
	},
	computed: {
		//params of sistems
		isCustomMail() {
			return this.typeEmali == 'Personalizado';
		},
		...mapGetters(['getCatalogMH']),
		countryList() {
			return this.$store.getters.getCountries;
		},
		departments() {
			return this.getCatalogMH('Departamento');
		},
		municipalities() {
			const municipalities = this.getCatalogMH('Municipio');
			if (this.formEsp.department) {
				const department = municipalities.filter(
					mun => mun.Departamento == this.formEsp.department
				);
				return department;
			}
			return [];
		},
		checkCountry() {
			if (this.formEsp.country == 'El Salvador') return true;
			else return false;
		},
		speciesValues() {
			let values = null;
			this.formEsp.speciesList.forEach(item => {
				if (item.name == this.previwSelected) values = item.value;
			});
			if (typeof values == 'object') return values;

			if (values != null) return values.split(',');
			else return [];
		},
		nameSelected() {
			const array = [
				{
					name: 'Gmail',
					value: 'Google',
				},
				{
					name: 'SMTP',
					value: 'Personalizado',
				},
			];
			return this.typeEmali == 'Google' ? array[0].name : array[1].name;
		},
		noSpacesRule() {
			return value => {
				return !/\s/.test(value) || 'No se permiten espacios';
			};
		},
		formatNoBombas() {
			const result = this.itemsNoBombas.join(', ');
			return result;
		},
		prescriptionContentHeightComputed() {
			let subtotal = Number(this.prescriptionHeaderHeight) + Number(this.prescriptionTopMargin) + Number(this.prescriptionBottomMargin) + Number(this.prescriptionRightMargin) + Number(this.prescriptionLeftMargin)
			let prescriptionContent = (137.67 - subtotal).toFixed(2)
			return prescriptionContent
		},
	},
	watch: {
		prescriptionHeaderHeight() {
			this.updateContentHeight();
		},
		prescriptionTopMargin() {
			this.updateContentHeight();
		},
		prescriptionBottomMargin() {
			this.updateContentHeight();
		},
		prescriptionRightMargin() {
			this.updateContentHeight();
		},
		prescriptionLeftMargin() {
			this.updateContentHeight();
		}
	},
	methods: {
		cleanItemsNoBombas() {
			this.itemsNoBombas = [];
		},
		addNoBomba() {
			this.itemsNoBombas.push(this.itemNoBomba);
			this.itemNoBomba = null;
		},
		setValueEmail(e) {
			this.email.MailSendType = e;
			this.typeEmali = e;
		},
		getAllReminders() {
			this.$API.remindersemailschedule.GetRemindersEmailSchedule().then(res => {
				const reminderTypeMap = {
					0: 'groomingReminder',
					1: 'birthdayReminder',
					2: 'vaccineReminder',
					3: 'prescriptionReminder',
				};
				if (res.length > 0)
					res.forEach(item => {
						item.active = false;
						this.form[reminderTypeMap[item.ReminderType]] = item;
					});
			});
		},
		async saveAll() {
			try {
				this.loading = true;
				const promises = [];
				//reminders promises
				const reminderTypes = [
					'groomingReminder',
					'birthdayReminder',
					'vaccineReminder',
					'prescriptionReminder',
				];
				for (const schedulerName of reminderTypes) {
					const obj = {
						anticipationDays: this.form[schedulerName].anticipationDays || 1,
						scheduleTime: this.form[schedulerName].scheduleTime,
						ReminderType: this.reminderTypeConfig.indexOf(schedulerName),
						businesstype: this.businessType,
					};
					if (this.form[schedulerName].active)
						promises.push(
							this.$API.remindersemailschedule.updateRemindersEmailSchedule(obj)
						);
				}
				//email credentials
				promises.push(this.$API.business.updateEmailCredentials(this.email));
				//sysparams
				promises.push(this.saveSysparams());
				//parametros cuando es gasolinera
				promises.push(
					this.$API.business.updateFuelStationData({
						isFuelStation: this.isFuelStation,
						itemsNoBombas: this.itemsNoBombas,
						ID_Business: this.business.ID_Business,
					})
				);
				//Guardado de color personalizado
				promises.push(
					this.$API.business.updateColorInvoices({
						specialInvoicesColor: this.specialInvoicesColor,
						ID_Business: this.business.ID_Business,
					})
				);
				promises.push(
					this.$API.business.updateColorTitleInvoices({
						specialInvoicesTitleColor: this.specialInvoicesTitleColor,
						ID_Business: this.business.ID_Business,
					})
				);
				//Guardado de configuración de prescripción
				const prescriptionData = {
					prescriptionHeaderHeight: String(this.prescriptionHeaderHeight),
					prescriptionContentHeight: String(this.prescriptionContentHeight),
					prescriptionTopMargin: String(this.prescriptionTopMargin),
					prescriptionBottomMargin: String(this.prescriptionBottomMargin),
					prescriptionRightMargin: String(this.prescriptionRightMargin),
					prescriptionLeftMargin: String(this.prescriptionLeftMargin),
					prescriptionFontSize: String(this.prescriptionFontSize),
					ID_Business: this.business.ID_Business,
				};

				if( this.businessType != 'accounting_and_bill' ) {
					promises.push(
						this.$API.business.updatePrescriptionHeights(prescriptionData)
					);
				}
				//Guardado de zonas para clientes
				if (this.ZoneConfiguration.length > 0) {
					let zones = '';
					zones = this.ZoneConfiguration.join(' | ');
					promises.push(
						this.$API.business.updateZoneConfiguration({
							ZoneConfiguration: zones,
							ID_Business: this.business.ID_Business,
						})
					);
				}

				await Promise.all(promises);
				this.loading = false;
				this.showAlert('success', 'exito', 'Datos guardados correctamente');
				this.getallSysparams();
				this.getAllReminders();
			} catch (error) {
				console.log(error);
				this.loading = false;
				this.showAlert('danger', 'error', 'Error al guardar los datos');
				this.getallSysparams();
				this.getAllReminders();
			}
		},
		async getStates(){
			if(this.formEsp.country == 'El Salvador') return [];
			
			if(this.formEsp.country){
				this.noDataLabelStates = 'Cargando...'
				this.stateList = []
				
				this.stateList = await this.$API.general.getStates(this.formEsp.country)

				this.noDataLabelStates = 'No hay Estados disponibles';
			}
		},
		async getCities(){
			if(this.formEsp.country == 'El Salvador') return [];
		
			if(this.formEsp.department) {
				this.noDataLabelCities = 'Cargando...'
				this.cityList = []
				
				this.cityList = await this.$API.general.getCities(this.formEsp.department);

				this.noDataLabelCities = 'No hay Ciudades disponibles';
			}
		},
		shaveChangues(){
			const array = this.formEsp.speciesList.map((item) => {
				if(item.name == this.praramsToShow.name)
					item.value = this.praramsToShow.value
				return item
			});
			this.formEsp.speciesList = array
		}, 
		showSelectedList(data){
			this.praramsToShow = data
		},
		showPreview(){
			const array = this.formEsp.speciesList.map((item) => {
				return item.name
			});
			this.previewEsp = array
			this.showPreviewSP = true
		},
		isEmpty(data) {
			return _.isEmpty(data);
		}, 
		fetchCurrentTemp() {
			this.form.eventschedulerduration = "";
			this.form.birthdayReminder.scheduleTime='05:00';
			this.form.groomingReminder.scheduleTime='05:00';
			this.form.prescriptionReminder.scheduleTime='05:00';
			this.form.vaccineReminder.scheduleTime='05:00';
			this.form.userId = JSON.parse(localStorage.getItem("user")).id;
		},
		async save(schedulerName) {
			this.loading = true;
			this.loading_btn = true;
			console.log('this.reminderTypeConfig.indexOf(schedulerName) ', schedulerName, this.reminderTypeConfig.indexOf(schedulerName));
			let obj = {
				// eventschedulerduration: this.form.eventschedulerduration,
				anticipationDays: this.form[ schedulerName ].anticipationDays,
				scheduleTime: this.form[ schedulerName ].scheduleTime,
				// 0 grooming, 1 birthday, 2 vaccines, 3 prescription
				ReminderType: this.reminderTypeConfig.indexOf(schedulerName),
				businesstype: this.businessType
			};

			this.$API.remindersemailschedule
				.updateRemindersEmailSchedule(obj)
				.then((response) => {
					this.loading_btn = false;
					console.log(response);
					this.showAlert(
						"success",
						"Actualización exitosa",
						"Recordatorio de correo electrónico actualizado correctamente"
					);      
					this.loading = false;           
				})
				.catch((err) => {
					this.loading_btn = false;
					console.log(err);
					this.showAlert(
						"danger",
						"Error",
						"Error al actualizar el recordatorio de correo electrónico"
					);
					this.loading = false;
			});
		},
		closeAlert() {
			this.alert.show = false;
		},
		acceptAlert() {
			this.alert.show = false;
		},
		showAlert(type, header, body, options = null) {
			type = type == null ? "danger" : type;
			this.alert.type = type;
			this.alert.header = header;
			this.alert.body = body;
			this.alert.show = true;
			this.alert.options = options != null ? options : null;
		},
		updateContentHeight() {
			const headerHeight = Number(this.prescriptionHeaderHeight);
			const topMargin = Number(this.prescriptionTopMargin);
			const bottomMargin = Number(this.prescriptionBottomMargin);
			const rightMargin = Number(this.prescriptionRightMargin);
			const leftMargin = Number(this.prescriptionLeftMargin);

			const totalHeight = headerHeight + topMargin + bottomMargin + rightMargin + leftMargin;
			this.prescriptionContentHeight = (137.67 - totalHeight).toFixed(2);
		},
		updateEmailCredentials(){
			this.loading = true;
			this.$API.business.updateEmailCredentials(this.email).then(res => {
				this.loading = false;
				this.showAlert('success', 'exito', res.message)
			})
			.catch(err => {
				this.loading = false;
				this.showAlert('danger', 'error', err.message)
			})
		},
		updateGoogleDriveApi(){
			this.loading = true;
			this.$API.business.updateGoogleDriveApi(this.googleDriveApi).then(res => {
				this.loading = false;
				this.showAlert('success', 'exito', res.message)
			})
			.catch(err => {
				this.loading = false;
				this.showAlert('danger', 'error', err.message)
			})
		},
		async saveSysparams(){
			const form = {
				weightunit: this.formEsp.weightunit,
				modePatient: this.formEsp.modePatient ? 'C' : 'B',
				speciesList: JSON.stringify(this.formEsp.speciesList),
				country: this.formEsp.country,
				department: this.formEsp.department,
				city: this.formEsp.municipality,
			}
			this.loading = true;
			console.log(form)
			if(!this.formEsp.EditMode)
			await this.$API.sysParams.insertParams(form)
			else if(this.formEsp.EditMode){
				const itemsToCreate = [];
				let newData = form;
				const newdata = this.formEsp.data.map((item) => {
					if(item.ParameterType == 'MeasureUnit'){
						item.ParameterValue = this.formEsp.weightunit
						delete newData.weightunit
					}
					else if(item.ParameterType == "ModePatient"){
						item.ParameterValue = this.formEsp.modePatient ? 'C' : 'B'
						delete newData.modePatient
					}
					else if(item.ParameterType == "SpeciesList"){
						item.ParameterValue = JSON.stringify(this.formEsp.speciesList)
						delete newData.speciesList
					}
					else if(item.ParameterType == "country"){
						item.ParameterValue = this.formEsp.country
						delete newData.country
					}
					else if(item.ParameterType == "department"){
						item.ParameterValue = this.formEsp.department
						delete newData.department
					}
					else if(item.ParameterType == "city"){
						item.ParameterValue = this.formEsp.municipality
						delete newData.city
					}
					else{
						item.Create = true
						itemsToCreate.push(item)
					}
					return item
				})

			await this.$API.sysParams.updateParams({toUpdate: newdata, toInsert: newData})
			}
		},
		setDefaultVal(){
			this.formEsp.weightunit ='Kg'
			this.formEsp.modePatient = false
			this.formEsp.speciesList = speciesList
		},
		getallSysparams(){
			this.$API.sysParams.getSysparam().then(res => {
				this.$store.dispatch('sysparameters/getAllparams');
				
				res.forEach((item) => {
					if(item.ParameterType == 'MeasureUnit')
						this.formEsp.weightunit = item.ParameterValue
					if(item.ParameterType == "ModePatient")
						this.formEsp.modePatient = item.ParameterValue == 'C' ? true : false
					if(item.ParameterType == "SpeciesList")
						this.formEsp.speciesList = JSON.parse(item.ParameterValue)
					if(item.ParameterType == "country")
						this.formEsp.country = item.ParameterValue
					if(item.ParameterType == "department")
						this.formEsp.department = item.ParameterValue
					if(item.ParameterType == "city")
						this.formEsp.municipality = item.ParameterValue
				})
				this.formEsp = {...this.formEsp,
					data: res,
					EditMode: true
				}
			})
			.catch(() => {
				this.formEsp.EditMode = false
			})
		},
		handleDeleteSpaces() {
			const value = event.target.value.replace(/\s/g, '');
			this.email.PasswordEmail = value;
			event.target.value = value;
		},
		onClickTestLog() {
			//this.email.PasswordEmail = this.email.PasswordEmail.replaceAll(' ', '');
			console.log(this.email);
		},
		addZone() {
			if (this.selectedZone && !this.ZoneConfiguration.includes(this.selectedZone)) {
				this.ZoneConfiguration.push(this.selectedZone);
				this.selectedZone = null;
			}
		},
		removeZoneConfiguration(index) {
			this.ZoneConfiguration.splice(index, 1);
		},
		getZoneConfiguration() {
			this.$API.business.getZoneConfiguration({ ID_Business: this.business.ID_Business })
			.then(response => {
				if (response.length > 0) {
					let data = response[0]

					if (data?.ZoneConfiguration) {
						let zones = data.ZoneConfiguration
						this.ZoneConfiguration = zones.split(' | ').map(item => item.trim());
					}
				}
			})
		}
	}
}
</script>

<style scoped>
	.btndelete {
	background-color: #E3191E !important;
	color: white;
	}
	.btnsave {
	background-color: #00A178 !important;
	color: white;
	}
	.textarea-code {
	font-family: 'Courier New', Courier, monospace;
	font-size: 14px;
	line-height: 1.5;
	padding: 10px;
	border: 1px solid #ccc;
	background-color: #f5f5f5;
	color: #333;
	}
	.bar {
	width: 100%;
	background-color: rgb(60, 60, 60) !important;
	color: white !important;
	border-style: solid;
	border-color: black;
	border-radius: 15px !important;
	margin-top: 1rem !important;
	}
	.btn-add{
	background-color: #3498DB  !important;
	color: white;
	}
	.btnsave{
	background-color: #00A178 !important;
	color: white;
	}
	.btnclean{
	background-color: #F29D35 !important;
	color: white;
	}
	.btndelete{
	background-color: #E3191E !important;
	color: white;
	}
</style>
