import axios from "./axios";

export default {
  /**
   * Get All Patients
   * @api {Get} managepatient
   * @param {object} filter
   */
  getPatients(filter) {
    const options = {
      method: 'GET',
      url: 'managepatient',
      headers: { 'content-type': 'application/json' },
      params: filter
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message)
        })
    })
  },
  getOne({id, customer_id}) {
    const options = {
        method: 'GET',
        url: `managepatient/${id}`,
        headers: {'content-type': 'application/json'},
        params: {
            customer_id: customer_id
        }
    };
    return new Promise((resolve, reject) => {
        axios(options)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.message)
            })
    })
},
  getPatientsHistory(filter) {
    const options = {
      method: 'GET',
      url: 'managepatient/list/history',
      headers: { 'content-type': 'application/json' },
      params: filter
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message)
        })
    })
  },
  getPatientsFiltered(filter) {
    const options = {
      method: 'GET',
      url: 'managepatient/list/filtered',
      headers: { 'content-type': 'application/json' },
      params: filter
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message)
        })
    })
  },
  getPatientsFilteredBilledList(filter) {
    const options = {
      method: 'GET',
      url: 'managepatient/billed/list/filtered',
      headers: { 'content-type': 'application/json' },
      params: filter
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message)
        })
    })
  },
  /**
   * Get All Patients
   * @api {Get} managepatient
   * @param {object} filter
   */
  filterPatients(filter) {
    const options = {
      method: 'GET',
      url: 'managepatient/filtered',
      headers: { 'content-type': 'application/json' },
      params: filter
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message)
        })
    })
  },

  /**
   * Add Patient
   * @api {POST} managepatient
   */
  addPatient(data) {
    const options = {
      method: 'POST',
      url: 'managepatient',
      headers: { 'content-type': 'application/json' },
      data: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.messageage)
        })
    })
  },
  /**
    * get Patient ASIGNEd LABS
    * @api {POST} managepatient
    */
  // LAB
  getPatientAssigned(data) {
    const options = {
      method: 'POST',
      url: `patientlabtest/getassigned`,
      headers: { 'content-type': 'application/json' },
      data: { id: data.ID_Patient, LaboratoryName: data.LaboratoryName }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.messageage)
        })
    })
  },
  getPatientAssignedHistory(data) {
    const options = {
      method: 'POST',
      url: `patientlabtest/getassigned/list/history`,
      headers: { 'content-type': 'application/json' },
      data: { id: data.ID_Patient, LaboratoryName: data.LaboratoryName }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.messageage)
        })
    })
  },
  getPatientAssignedFiltered(data) {
    const options = {
      method: 'POST',
      url: `patientlabtest/getassigned/list/filtered`,
      headers: { 'content-type': 'application/json' },
      data: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.messageage)
        })
    })
  },

  // VET
  getPatientAssignedvet(data) {
    const options = {
      method: 'POST',
      url: `patientlabtest/getassignedvet`,
      headers: { 'content-type': 'application/json' },
      data: { id: data.ID_Patient, LaboratoryName: data.LaboratoryName }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message)
        })
    })
  },
  getPatientAssignedvetHistory(data) {
    const options = {
      method: 'POST',
      url: `patientlabtest/getassignedvet/history`,
      headers: { 'content-type': 'application/json' },
      data: { id: data.ID_Patient, LaboratoryName: data.LaboratoryName }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message)
        })
    })
  },
  getPatientAssignedvetFiltered(data) {
    const options = {
      method: 'POST',
      url: `patientlabtest/getassignedvet/filtered`,
      headers: { 'content-type': 'application/json' },
      data: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message)
        })
    })
  },
  /**
   * Add Patient
   * @api {PUT} managepatient
   */
  updatePatient(id, data) {
    const options = {
      method: 'PUT',
      url: `managepatient/${id}`,
      headers: { 'content-type': 'application/json' },
      data: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message)
        })
    })
  },


  /**
   * Delete Patient
   * @api {DELETE} managepatient/:id
   */
  deletePatient(id) {
    const options = {
      method: 'DELETE',
      url: `managepatient/${id}`,
      headers: { 'content-type': 'application/json' },
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message)
        })
    })
  },

  logicalDeletePatient(id) {
    const options = {
      method: "PUT",
      url: `managepatient/logicaldelete/${id}`,
      headers: { "content-type": "application/json" },
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message)
        });
    });
  },
}
