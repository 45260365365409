<template>
    <v-expansion-panel v-model="panel">
        <v-expansion-panel-header class="mt-2">
            Gestionar negocios
            <template v-slot:actions>
                <v-btn class="mr-5" @click.native.stop="reloadp++" icon small>
                    <v-icon color="primary">mdi-reload</v-icon>
                </v-btn>
                <v-btn @click="$store.dispatch('deleteWindowByUuid',{uuid : win.uuid})" icon small>
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-card>
                <v-tabs
                        v-model="tab"
                        dark background-color="primary"
                        icons-and-text
                >
                    <v-tabs-slider  color="red"></v-tabs-slider>

                    <v-tab href="#tab-1">
                        Gestión del Negocio
                        <v-icon>mdi-store</v-icon>
                    </v-tab>

                    <v-tab href="#tab-2">
                        Gestión de Sucursales
                        <v-icon>mdi-pencil-circle</v-icon>
                    </v-tab>

                    <v-tab href="#tab-3">
                        Configuraciones del Negocio
                        <v-icon>mdi-store-cog</v-icon>
                    </v-tab>

                    <v-tab v-if="AccountingModule === 1 && TipoPlan != 'Emprendedor'" href="#tab-4">
                        Catálogo de cuentas
                        <v-icon>mdi-book-edit</v-icon>
                    </v-tab>

                    <!--v-tab href="#tab-3">
                        Manage Roles
                        <v-icon>mdi-pencil-circle</v-icon>
                    </v-tab>
                    <v-tab href="#tab-4">
                        Manage Comunication/Store
                        <v-icon>mdi-pencil-circle</v-icon>
                    </v-tab>
                    <v-tab href="#tab-5">
                        Manage Templates
                        <v-icon>mdi-pencil-circle</v-icon>
                    </v-tab-->
                </v-tabs>

                <v-tabs-items v-model="tab" touchless>
                    <v-tab-item
                            value="tab-1"
                    >
                        <v-card flat>
                            <v-card-text class="pa-1">
                                <ManageBusiness :key="reloadp" :business="business" :win="win"></ManageBusiness>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item
                            value="tab-2"
                    >
                        <v-card flat>
                            <v-card-text class="pa-1 py-0">
                                <ManageBranches :key="reloadp" :business="business" :win="win"></ManageBranches>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    
                    <v-tab-item
                            value="tab-3"
                    >
                        <v-card flat>
                            <v-card-text class="pa-1">
                                <ManageStore :key="reloadp" :business="business" :win="win"></ManageStore>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item
                    value="tab-4"
                    >
                        <v-card flat>
                            <v-card-text class="pa-1">
                                <AccountingLedger :key="reloadp"></AccountingLedger>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item
                            value="tab-5"
                    >
                        <v-card flat>
                            <v-card-text class="pa-1">
                                <ManageTemplates :key="reloadp"></ManageTemplates>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import ManageBranches from './partial/ManageBranches';
import ManageBusiness from './partial/ManageBusiness';
import ManageStore from './partial/ManageStore';
import ManageTemplates from './partial/ManageTemplates';
import AccountingLedger from './partial/AccountingLedger';

export default {
    name: "Business",
    components: {
        ManageBranches,ManageBusiness,ManageStore,ManageTemplates, AccountingLedger
    },
    props: ['win'],
    data () {
        return {
            tab: null,
            panel: 0,
            business: null,
            reloadp: 0,
            AccountingModule: JSON.parse(localStorage.getItem("user")).AccountingModule,
            TipoPlan: JSON.parse(localStorage.getItem("user")).TipoPlan
        }
    },
    mounted() {
        this.business = this.win.meta.business;
        this.tab = this.win.meta.tab;
    },
}
</script>

<style scoped>
.sidebar-options{
    display: none;
}

</style>
