<template>
  <div>
    <v-card flat>
      <v-card-text>
        <template v-if="type === 'medicalclinic'">
          <user-profile-lab
            :patient="data"
            :patientinfo="data"
            :useData="useData"
          ></user-profile-lab>
        </template>
        <template v-else>
          <user-profile :patientinfo="patientinfo" :data="data" :useData="useData" />
        </template>
        <v-row style="margin-top: 1rem">
          <v-col class="box" cols="12" style="margin-top: 1rem; background-color: white">
            <v-form v-model="valid" class="position-relative">
              <block v-if="loading"></block>

              <v-row class="ml-2 mr-2" style="margin-top: 0.3rem">
                <v-col cols="12" class="tabsNh">
                  <v-col
                    v-if="medication.length > 0 && rightTableUniqueParameter == 'ID_VPMP'"
                    cols="12"
                    style="display: flex; justify-content: space-between"
                  >
                    <div style="width: 293px;">
                      <v-btn
                        small
                        v-if="$store.getters['rolesUser/getCustomRole'](165)"
                        @click.prevent="
                          showAlert(
                            'question',
                            'Eiminar',
                            '¿Está seguro de eliminar este registro?',
                            'ConfirmDelete'
                          )
                        "
                        color="error"
                      >
                        <v-icon> mdi-delete </v-icon>
                      </v-btn>
                    </div>

                    <div>
                        <v-btn v-if="$store.getters['rolesUser/getCustomRole'](107)" small class="btn-add mr-1" @click.prevent="getItemsToBeBilled(); showBillPatient = true">
                            <v-icon class="mr-3">mdi-account-details</v-icon> Facturar paciente
                        </v-btn>
                    </div>

                    <div>
                      <v-btn
                        v-if="$store.getters['rolesUser/getCustomRole'](167)"
                        small
                        @click="showVacc = true"
                        style="margin-right: 0.3rem"
                        class="btnblue"
                      >
                        <v-icon class="mr-1">mdi-archive-clock</v-icon>
                        Vencimiento
                      </v-btn>
                      <v-btn
                        small
                        v-if="$store.getters['rolesUser/getCustomRole'](166)"
                        @click="
                          print = {
                            show: true,
                            data: {
                              date: date,
                              medication: medication,
                              patientinfo: patientinfo,
                              useData: useData,
                              customer: patientinfo.customer,
                            },
                          }
                        "
                        style="margin-right: 0.3rem"
                      >
                        <v-icon> mdi-printer </v-icon>
                      </v-btn>
                      <v-btn
                        small
                        class="btnblue mr-1"
                        @click="showhistory = !showhistory"
                        v-if="$store.getters['rolesUser/getCustomRole'](164)"
                      >
                        <v-icon> mdi-magnify </v-icon>
                      </v-btn>
                      <v-btn small class="btnclean mr-1" @click.prevent="resetData">
                        <v-icon> mdi-broom </v-icon>
                      </v-btn>

                      <v-btn
                        small
                        v-if="$store.getters['rolesUser/getCustomRole'](171)"
                        class="btnsave"
                        @click.prevent="updateVeterinaryParameterData"
                      >
                        <v-icon> mdi-content-save </v-icon>
                      </v-btn>
                    </div>
                  </v-col>

                  <v-col
                    v-else-if="
                      medication.length > 0 &&
                      rightTableUniqueParameter == 'ID_Plan_Perameter' &&
                      medicationChecked
                    "
                    cols="12"
                    style="display: flex; justify-content: space-between"
                  >

                    <div style="width: 293px;" ></div>
                    <div>
                        <v-btn v-if="$store.getters['rolesUser/getCustomRole'](107)" small class="btn-add mr-1" @click.prevent="getItemsToBeBilled(); showBillPatient = true">
                            <v-icon class="mr-3">mdi-account-details</v-icon> Facturar paciente
                        </v-btn>
                    </div>

                    <div>
                      <v-btn
                        small
                        @click="showVacc = true"
                        style="margin-right: 0.3rem"
                        class="btnblue"
                        v-if="$store.getters['rolesUser/getCustomRole'](167)"
                      >
                        <v-icon class="mr-1">mdi-archive-clock</v-icon>
                        Vencimiento
                      </v-btn>
                      <v-btn
                        small
                        v-if="$store.getters['rolesUser/getCustomRole'](166)"
                        @click="
                          print = {
                            show: true,
                            data: {
                              date: date,
                              medication: medication,
                              patientinfo: patientinfo,
                              useData: useData,
                              customer: patientinfo.customer,
                            },
                          }
                        "
                        style="margin-right: 0.3rem"
                      >
                        <v-icon> mdi-printer </v-icon>
                      </v-btn>
                      <v-btn small class="btnblue mr-1" @click="showhistory = !showhistory" v-if="$store.getters['rolesUser/getCustomRole'](164)">
                        <v-icon> mdi-magnify </v-icon>
                      </v-btn>

                      <v-btn small class="btnclean mr-1" @click.prevent="resetData">
                        <v-icon> mdi-broom </v-icon>
                      </v-btn>
                      <v-btn
                        small
                        class="btnsave"
                        @click.prevent.stop="SaveMedication"
                        v-if="$store.getters['rolesUser/getCustomRole'](171)"
                      >
                        <v-icon> mdi-content-save </v-icon>
                      </v-btn>
                    </div>
                  </v-col>

                  <v-col
                    v-else-if="
                      !(
                        medication.length > 0 && rightTableUniqueParameter == 'ID_VPMP'
                      ) &&
                      !(
                        medication.length > 0 &&
                        rightTableUniqueParameter == 'ID_Plan_Perameter'
                      )
                      || !medicationChecked
                    "
                    cols="12"
                    style="display: flex; justify-content: space-between"
                  >

                    <div style="width: 293px;" ></div>
                    <div>
                        <v-btn v-if="$store.getters['rolesUser/getCustomRole'](107)" small class="btn-add mr-1" @click.prevent="getItemsToBeBilled(); showBillPatient = true">
                            <v-icon class="mr-3">mdi-account-details</v-icon> Facturar paciente
                        </v-btn>
                    </div>
                    
                    <div>
                      <v-btn
                        small
                        @click="showVacc = true"
                        style="margin-right: 0.3rem"
                        class="btnblue"
                        v-if="$store.getters['rolesUser/getCustomRole'](167)"
                      >
                      <v-icon class="mr-1">mdi-archive-clock</v-icon>
                        Vencimiento
                      </v-btn>
                      <v-btn
                        class="mr-1"
                        small
                        v-if="$store.getters['rolesUser/getCustomRole'](166)"
                        @click="
                          print = {
                            show: true,
                            data: {
                              date: date,
                              medication: medication,
                              patientinfo: patientinfo,
                              useData: useData,
                              customer: patientinfo.customer,
                            },
                          }
                        "
                      >
                        <v-icon> mdi-printer </v-icon>
                      </v-btn>
                      <v-btn small class="btnblue mr-1" @click="showhistory = !showhistory" v-if="$store.getters['rolesUser/getCustomRole'](164)">
                        <v-icon> mdi-magnify </v-icon>
                      </v-btn>
  
                      <v-btn small class="btnclean" @click.prevent="resetData">
                        <v-icon> mdi-broom </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-col>
                <v-col cols="12" md="2" sm="12" style="margin-top: 1rem">
                  <date-picker
                    label="Fecha"
                    @dateSelected="
                      (param) => {
                        date = param;
                        plan.MedicationsDate = param;
                      }
                    "
                    v-model="date"
                  ></date-picker>
                </v-col>
                
                <v-col cols="6" md="2" style="margin-top: 1rem">
                  <v-text-field label="Peso" dense outlined v-model="weight" @change="changeWeight"></v-text-field>
                </v-col>
                <v-col cols="6" md="2" style="margin-top: 1rem">
                  <v-select outlined dense attach @change="switchWeightUnit" v-model="weightunit" :items="['Kg', 'Lb']" label="Unidad de peso"></v-select>
                </v-col>
                <v-col cols="12" md="7" sm="12"> </v-col>

                <v-col
                  cols="12"
                  md="1"
                  style="display: flex; justify-content: flex-end; margin-top: 0.5rem"
                >
                  <v-btn
                    @click="showComments = !showComments"
                    :class="plan.Comment ? 'btnclean' : 'btnblack'"
                  >
                    Comentarios
                  </v-btn>
                </v-col>

                <v-col cols="12" md="4">
                  <!--  <v-text-field outlined dense  disabled v-model="plan.PlanName"  label="Plantilla seleccionada"></v-text-field> -->
                </v-col>
                <v-col cols="12" md="6">
                  <!--  <template>
                            <v-btn
                          class="btn-add"
                          @click.prevent="plansDialog = !plansDialog"
                          right
                        >
                        Seleccionar Plantilla de medicación/vacunacion
                        </v-btn>
                          &nbsp;
                  
                        </template> -->
                </v-col>
              </v-row>

              

              <!--v-btn @click.prevent="">
                    <v-icon> mdi-mail </v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn dark color="green" @click.prevent="">
                    <v-icon> mdi-whatsapp </v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn @click.prevent="">
                    <v-icon> mdi-file-excel </v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn @click.prevent="">
                    <v-icon> mdi-xml </v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn @click.prevent="">
                    <v-icon> mdi-file-pdf-box </v-icon>
                  </v-btn-->

              <v-col cols="12" md="12">
                <v-col cols="12" style="display: flex; justify-content: flex-end">
                  <!-- <v-btn
                    v-if="plan.PlanName"
                        class="btn-add"
                        @click.prevent.stop="()=>{ showAllMedication = false ;medicationDialog = !medicationDialog}"
                        right

                      >
                       Agregar Medicamentos o Vacunas de plantilla
                  </v-btn> -->
                  &nbsp;
                  <v-btn
                    class="btn-add"
                    @click.prevent.stop="
                      () => {
                        showAllMedication = true;
                        medicationDialog = !medicationDialog;
                      }
                    "
                  >
                    Agregar Medicamentos o Vacunas
                  </v-btn>
                </v-col>
              </v-col>
              <v-col cols="12" style="margin-bottom: 1.2rem">
                <DxDataGrid width="100%"
               :scrolling="{useNative:true}"
                  :show-borders="true"
                  :data-source="medication"
                  key-expr=""
                  :onRowRemoving="handleRowDeleting"
                  @row-updated="UpdateRow"
                >
                  <DxEditing
                    :allow-updating="true"
                    :allow-deleting="true"
                    :confirmDelete="false"
                    :select-text-on-edit-start="true"
                    start-edit-action="click"
                    mode="cell"
                    :use-icons="true"
                  />
                  <DxColumn
                    :width="40"
                    :allow-editing="true"
                    cell-template="checkboxTemplate"
                  />
                  <DxColumn
                    :width="100"
                    :allow-editing="true"
                    caption="Orden"
                    data-field="Order"
                  />
                  <!--DxColumn
                  :width="30"
                    :allow-editing="false"
                    data-type="boolean"
                    data-field="checkbox"
                    header-cell-template="headerId"
                  ></DxColumn-->
                  <DxKeyboardNavigation
                    :edit-on-key-press="true"
                    enter-key-action="moveFocus"
                    enter-key-direction="column"
                  />
                  <DxColumn
                    data-field="Medicine"
                    :allow-editing="false"
                    header-cell-template="headerVaccines"
                    :width="350"
                  ></DxColumn>
                  <DxColumn
                    :width="100"
                    data-field="PeriodTime"
                    header-cell-template="headerPeriodTime"
                    data-type="number"
                  ></DxColumn>
                  <DxColumn
                  :width="columnWidth"
                    cell-template="periodUnit-selection"
                    header-cell-template="headerUnitPeriod"
                  >
                  </DxColumn>
                  <DxColumn
                    :width="250"
                    cell-template="periodTemplate"
                    caption="Períodos predefinidos"
                  />
                  <DxColumn
                  :width="columnWidth"
                    data-field="NextTime"
                    caption="Proxima vez"
                    data-type="date"
                    :allow-editing="false"
                    header-cell-template="headernextTime"
                  ></DxColumn>
                  <DxColumn
                  :width="columnWidth"
                    header-cell-template="headerItem"
                    cell-template="ItemType"
                    :allow-editing="false"
                  />

                  <template #periodUnit-selection="{ data }">
                    <span class="scroll-fixing-purchase">
                      <select
                        class="form-control custom-select"
                        v-model="data.data.PeriodUnit"
                        @change="handleMed"
                      >
                        <option v-for="item in items" :value="item.Name" :key="item.ID">
                          {{ item.NameESP }}
                        </option>
                      </select>
                    </span>
                  </template>
                  <template #checkboxTemplate="{ data }">
                    <v-checkbox v-model="data.data.checkbox" :disabled="rightTableUniqueParameter == 'ID_VPMP'" class="pt-0" @click="getSelectedRow(data.data)">
                    </v-checkbox>
                  </template>
                  <template #periodTemplate="{ data }">
                    <span class="scroll-fixing-purchase">
                      <select
                        class="form-control custom-select width-90"
                        v-model="data.data.FullPeriod"
                        @change="setPeriod(data)"
                      >
                        <option value="" disabled selected>Selecciona un período</option>
                        <option v-for="period in periods" :value="period.value" :key="period.id">
                          {{ period.name }}
                        </option>
                      </select>
                    </span>
                  </template>
                  <template #headerPeriodTime>
                    <div>
                      Período
                    </div>
                  </template>
                  <template #headerUnitPeriod>
                    <div>
                      Unidad de <br />
                      tiempo
                    </div>
                  </template>
                  <template #headerItem>
                    <div
                      style="display: flex; align-items: center; justify-content: center"
                    >
                      <div style="margin-top: 0.5rem">Tipo</div>
                    </div>
                  </template>
                  <template #ItemType="{ data }">
                    <div>
                      <label v-if="data.data.ItemType == 'VAC'">Vacuna</label>
                      <label v-if="data.data.ItemType == 'MED'">Medicamento</label>
                    </div>
                  </template>
                  <template #headernextTime>
                    <div>
                      Próxima <br />
                      Vez
                    </div>
                  </template>
                  <template #headerId>
                    <div
                      style="display: flex; align-items: center; justify-content: center"
                    >
                      <div style="margin-top: 0.5rem">ID</div>
                    </div>
                  </template>
                  <template #headerVaccines>
                    <div
                      style="display: flex; align-items: center; justify-content: center"
                    >
                      <div style="margin-top: 0.5rem">Medicamentos / Vacunas</div>
                    </div>
                  </template>
                </DxDataGrid>
              </v-col>
              <v-col style="margin-bottom: 1rem"> </v-col>
              <!-- <v-col cols="12">
                  <v-col  v-if="
                           medication.length > 0 &&
                          rightTableUniqueParameter == 'ID_VPMP'
                        " cols="12" style="display: flex; justify-content: space-between;margin-bottom: 1rem;">
                    <v-btn
                  
                        @click.prevent=' showAlert("question","Eiminar" ,"¿Está seguro de eliminar este registro?","ConfirmDelete");'
                      class="btndelete"
                    >
                    Eliminar registro
                    </v-btn>
                  
                      <v-btn
                        class="btnsave"
                  
                        @click.prevent="updateVeterinaryParameterData"
                      >
                       Guardar Registro
                      </v-btn>
                  </v-col>
                </v-col>
                <v-col cols="12">
                  <v-col  v-if="
                          medication.length > 0 &&
                          rightTableUniqueParameter == 'ID_Plan_Perameter'
                        " cols="12" style="display: flex; justify-content: flex-end; margin-bottom: 1rem;">
                    <v-btn
                        class="btnsave"
                        @click.prevent.stop="SaveMedication"
                  
                      >
                       Agregar Registro
                      </v-btn>
                    </v-col>
                </v-col> -->
            </v-form>
          </v-col>
        </v-row>
        <br />
        <v-dialog v-model="showVacc" scrollable width="80%">
          <v-card height="100%">
            <v-card-title>
              <span class="headline">Proximas vacunas</span>
            </v-card-title>
            <v-car-text>
              <GeneralFilter
                entity="VacExp"
                :filterEndpoint="$API.veterinarymedication.getNextvacFiltered"
                :search="search2"
                @emptyFilter="getVaccNextTime"
                @filtered="filterHandler2"
              >
                <DxDataGrid :scrolling="{useNative:true}" :show-borders="true" :data-source="vacData" key-expr="">
                  <DxColumn width="300px" data-field="MedicationsDate" caption="Fecha"></DxColumn>
                  <DxColumn :width="columnWidth" data-field="Medicine" caption="Vacuna"></DxColumn>
                  <DxColumn :width="columnWidth" data-field="NextTime" caption="Proxima vez"></DxColumn>
                  <DxColumn :width="columnWidth" data-field="Weight" caption="Peso"></DxColumn>
                </DxDataGrid>
              </GeneralFilter>
            </v-car-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="plansDialog" scrollable max-width="700">
          <MedicationPlanPopUp @closePlan="closePlan($event)"></MedicationPlanPopUp>
        </v-dialog>
        <v-dialog v-model="medicationDialog" scrollable v-if="plan" width="80%" :key="reloadMed">
          <VaccineListPopUp
            :PlanId="plan.ID_Medication_Plan"
            :species="patientinfo.species"
            @closeMedic="closeMedicPopUp($event)"
            :medicationDialog="medicationDialog"
            :GetALL="showAllMedication"
            :PatientMedic="medication"
            :key="reloadMed"
          ></VaccineListPopUp>
        </v-dialog>
        <v-dialog v-model="deleteDialog" v-if="deleteHeader" max-width="500">
          <DeleteUserDialog
            :data="deleteHeader"
            :name="deleteHeader.PlanName"
            :v-if="deleteDialog"
            @close="
              deleteDialog = false;
              deleteHeader = null;
              getHeaderData();
            "
            @f_call="deleteVeterinaryHeader($event)"
          ></DeleteUserDialog>
        </v-dialog>
        <MedicationVaccines :data="print"></MedicationVaccines>
        <alerts
          v-if="alert.show"
          v-on:close_alert="closeAlert"
          v-on:accept_alert="acceptAlert"
          :properties="alert"
        ></alerts>
      </v-card-text>
      <v-dialog v-model="showhistory" :width="'90%'" scrollable>
        <v-card class="pa-4">
          <v-card-text>
            <div
              style="
                margin-top: 0.3rem;
                margin-bottom: 0.3rem;
                display: flex;
                justify-content: flex-end;
              "
            >
              <v-btn small class="btndelete" @click.prevent="showhistory = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <GeneralFilter
              entity="vacuna medicamento"
              :filterEndpoint="$API.veterinarymedication.filterVeterinaryMedicationHeader"
              :search="search"
              @emptyFilter="getHeaderData"
              @filtered="filterHandler"
            >
              <DxDataGrid
                class="isScrolledByGeneralFilter"
                :show-borders="true"
                :data-source="medicationsHeader"
                key-expr=""
                @exporting="onExporting"
                :column-auto-width="true"
                style="width: fit-content;"
              >
                <DxExport :enabled="true" />
                <DxPaging :page-size="10" />
                <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                <DxColumn data-field="MedicationsDate" caption="Fecha" :min-width="100"></DxColumn>
                <DxColumn data-field="Weight" caption="Peso" :min-width="100"></DxColumn>
                <!-- <DxColumn data-field="PlanName" caption="Plantilla"></DxColumn> -->
                <DxColumn data-field="FullName" caption="Atendido por" :min-width="200"></DxColumn>
                <DxColumn data-field="Medications" caption="Medicamentos / Vacunas" :min-width="300" cssClass="left-align"></DxColumn>
                <DxColumn
                  v-if="$store.getters['rolesUser/getCustomRole'](165)"
                  cell-template="show-delete"
                  :width="80"
                  caption=""
                ></DxColumn>
                <template #show-template="{ data }">
                  <div>
                    <a
                      href="#"
                      @click.prevent.stop="
                        getVeterinaryDetails(data.data);
                        deleteHeader = data.data;
                        showhistory = false;
                      "
                    >
                      <v-icon color="primary">mdi-eye</v-icon>
                    </a>
                  </div>
                </template>
                <template #show-delete="{ data }">
                  <div>
                    <a
                      href=""
                      class="mx-2"
                      @click.prevent.stop="
                        deleteHeader = data.data;
                        showAlert(
                          'question',
                          'Eiminar',
                          '¿Está seguro de eliminar este registro?',
                          'ConfirmDelete'
                        );

                        showhistory = false;
                      "
                    >
                      <v-icon color="error">mdi-delete</v-icon>
                    </a>
                  </div>
                </template>
              </DxDataGrid>
            </GeneralFilter>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>

    <!-- Dialog bill patient -->
    <v-dialog v-model="showBillPatient" width="90%">
          <v-card class="py-3 px-3">
              <v-card-title class="text-h5">
              Facturar paciente 
              </v-card-title>

              <v-card-actions>
              <v-row>
                  <v-col cols="12" class="tabsNh" style="padding: 14px">
                  <v-col style="display: flex; justify-content: space-between; min-height: 50px">
                      <div>
                      <!-- <v-btn v-if="!showServicesToAdd" small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                          <v-icon>mdi-menu-down</v-icon>
                          Ver items
                      </v-btn>
                      <v-btn v-else small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                          <v-icon>mdi-menu-up</v-icon>
                          Ocultar
                      </v-btn> -->
                      </div>
                      <div>
                      <v-btn small class="btn-add" @click.prevent="saveItemsToBeBilled()">
                          <v-icon>mdi-content-save</v-icon>
                          Guardar
                      </v-btn>
                      </div>

                      <div>
                      <v-btn small class="btnsave" @click.prevent="billedPatientsOfCustomer()">
                          <v-icon>mdi-cash-register</v-icon>
                          Facturar cliente
                      </v-btn>
                      </div>
                  </v-col>
                  </v-col>
              </v-row>
              </v-card-actions>
              <div style="height: 500px; overflow-y: auto; overflow-x: hidden; margin-top: 20px; margin-bottom: 20px; padding: 0 20px;">
              <!-- Services default -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver servicios por defecto
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide1 = !togglehide1' v-show='!togglehide1' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide1 = !togglehide1' v-show='togglehide1' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide1">
                  <DxDataGrid class="isScrolledByGeneralFilter dataGridService" :data-source="servicesDefault" 
                  :show-borders="true" :column-auto-width="true" style="width: fit-content;">
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="CodeService" header-cell-template="headerCodeService" />
                      <template #headerCodeService="{ }">
                      <div>Código de<br />servicio</div>
                      </template>
                      <DxColumn data-field="NameService" header-cell-template="headerNameService" />
                      <template #headerNameService="{ }">
                      <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" header-cell-template="headerName" />
                      <template #headerName="{ }">
                      <div>Nombre a<br />facturar</div>
                      </template>
                      <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice" />
                      <template #headerTypeservice="{ }">
                      <div>Tipo de<br />servicio</div>
                      </template-->
                      <DxColumn :width="65" data-field="Price" caption="Precio" />
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""/>
                      <template #show-template="{ data }">
                      <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                          <v-icon>mdi-plus</v-icon>
                      </a>
                      </template>
                      <template #quantity-template="{ data }">
                      <p>{{ servicesToAdd.find(item => item.CodeService == data.data.CodeService).quantity }}</p>
                      </template>
                  </DxDataGrid>
                  </v-col>
              </v-row>

              <!-- Services to select -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver todos los servicios
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide2 = !togglehide2' v-show='!togglehide2' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide2 = !togglehide2' v-show='togglehide2' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide2">
                  <GeneralFilter 
                      :filterEndpoint="$API.services.getAllServicesFiltered" 
                      :search="searchToBill"
                      @emptyFilter="getServices" 
                      @filtered="servicesFilterHandler"
                  >
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="servicesToBill"
                      key-expr="CodeService" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="CodeService" caption="ID"></DxColumn>
                      <DxColumn data-field="NameService" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice">
                      </DxColumn-->
                      <!--template #headerTypeservice="{ }">
                          <div>Tipo de<br />servicio</div>
                      </template-->
                      <DxColumn :width="65" data-field="Price" caption="Precio"></DxColumn>
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                          <v-icon>mdi-plus</v-icon>
                          </a>
                      </template>
                      <template #quantity-template="{ data }">
                          <p style="margin: 0;">{{ servicesToAdd.find(item => item.CodeService == data.data.CodeService)?.quantity || '' }}</p>
                      </template>
                      </DxDataGrid>
                  </GeneralFilter>
                  </v-col>
              </v-row>

              <!-- Products to select -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver todos los productos
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide3 = !togglehide3' v-show='!togglehide3' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide3 = !togglehide3' v-show='togglehide3' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide3">
                  <GeneralFilter 
                      :filterEndpoint="$API.products.getProductsToBillForInvoiceFiltered" 
                      :search="searchToBill"
                      @emptyFilter="getProducts" 
                      @filtered="productsFilterHandler"
                  >
                      <v-row style="padding: 1rem;">
                          <v-col cols="12" md="3" sm="4">
                              <v-autocomplete
                              v-model="selectedWarehouse"
                              item-text="Warehouse"
                              item-value="ID_Warehouse"
                              :items="warehouses"
                              @change="handleSelectWarehouse"
                              label="Almacenes"
                              ref="werehouseRef"
                              dense 
                              outlined 
                              attach
                              >
                              </v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="9" sm="8">
                              <p style="color: #e3191e !important; font-size: 12px; margin: 12px 0" v-if="warehouses.length == 0">No existen almacenes de productos para pacientes.</p>
                          </v-col>
                      </v-row>
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="productsToBill"
                      key-expr="ID_Item" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="ID_Item" caption="ID"></DxColumn>
                      <DxColumn data-field="ItemName" header-cell-template="headerName"></DxColumn>
                      <template #headerName="{ }">
                          <div>Nombre del<br />producto</div>
                      </template>
                      <DxColumn data-field="ItemComercialName" header-cell-template="headerNameComercial"></DxColumn>
                      <template #headerNameComercial="{ }">
                          <div>Nombre<br />comercial</div>
                      </template>
                      <DxColumn :width="65" data-field="ItemPrice" caption="Precio"></DxColumn>
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="addProduct(data.data)">
                          <v-icon>mdi-plus</v-icon>
                          </a>
                      </template>
                      <template #quantity-template="{ data }">
                        <p style="margin: 0;">{{ productsToAdd.find(item => item.ID_Item == data.data.ID_Item)?.quantity || '' }}</p>
                      </template>
                      </DxDataGrid>
                  </GeneralFilter>
                  </v-col>
              </v-row>

              <!-- ServiceToAdd -->
              <v-row style="margin-top: 2rem">
                  <v-col cols="12">
                  <h4 style="padding: 16px;">Servicios seleccionados</h4>
                  <div style="overflow-x: scroll; padding: 0 12px;">
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="servicesToAdd"
                      key-expr="CodeService" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"    
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="5" />
                      <DxColumn data-field="CodeService" :allow-editing="false" caption="ID"></DxColumn>
                      <DxColumn data-field="NameService" :allow-editing="false" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" :allow-editing="false" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <DxColumn data-field="TypeService" :allow-editing="false" header-cell-template="headerTypeservice">
                      </DxColumn>
                      <template #headerTypeservice="{ }">
                          <div>Tipo de<br />servicio</div>
                      </template>
                      <DxColumn data-field="quantity" caption="Cantidad">
                          <DxFormat typed="fixedPoint" :precision="0" />
                          <DxRequiredRule message="La cantidad del servicio a facturar es requerida" />
                          <DxPatternRule :pattern="itemQtyPattern"
                          message="La cantidad del servicio facturado solo puede contener numeros enteros mayores a 0" />
                      </DxColumn>
                      <DxColumn data-field="unitPrice" :allow-editing="false" caption="Precio"></DxColumn>
                      <DxColumn cell-template="total-template" :allow-editing="false" caption="Total"></DxColumn>
                      <DxColumn cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="deleteService(data.data)">
                          <v-icon>mdi-delete</v-icon>
                          </a>
                      </template>
                      <template #total-template="{ data }">
                          <div>
                          {{ totalXQuantity( data.data ) }}
                          </div>
                      </template>
                      </DxDataGrid>
                  </div>
                  </v-col>
              </v-row>

              <!-- Product to add -->
              <v-row style="margin-top: 2rem">
                  <v-col cols="12">
                  <h4 style="padding: 16px;">Productos seleccionados</h4>
                  <div style="overflow-x: scroll; padding: 0 12px;">
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="productsToAdd"
                      key-expr="ID_Item" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"    
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="5" />
                      <DxColumn data-field="ID_Item" :allow-editing="false" caption="ID"></DxColumn>
                      <DxColumn data-field="ItemName" :allow-editing="false" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre del<br />producto</div>
                      </template>
                      <DxColumn data-field="ItemComercialName" :allow-editing="false" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <DxColumn data-field="ItemType" :allow-editing="false" header-cell-template="headerTypeservice">
                      </DxColumn>
                      <template #headerTypeservice="{ }">
                          <div>Tipo de<br />producto</div>
                      </template>
                      <DxColumn data-field="quantity" caption="Cantidad">
                          <DxFormat typed="fixedPoint" :precision="0" />
                          <DxRequiredRule message="La cantidad del producto a facturar es requerida" />
                          <DxPatternRule :pattern="itemQtyPattern"
                          message="La cantidad del producto facturado solo puede contener numeros enteros mayores a 0" />
                      </DxColumn>
                      <DxColumn data-field="unitPrice" :allow-editing="false" caption="Precio"></DxColumn>
                      <DxColumn cell-template="total-template" :allow-editing="false" caption="Total"></DxColumn>
                      <DxColumn cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="deleteProduct(data.data)">
                          <v-icon>mdi-delete</v-icon>
                          </a>
                      </template>
                      <template #total-template="{ data }">
                          <div>
                          {{ totalXQuantity( {...data.data, Price: data.data.ItemPrice} ) }}
                          </div>
                      </template>
                      </DxDataGrid>
                  </div>
                  </v-col>
              </v-row>

              </div>
          </v-card>
    </v-dialog>
    <!-- Dialog bill patient -->


    <v-dialog v-model="showComments" max-width="60%">
      <v-card class="pa-4">
        <div
          style="
            margin-top: 0.3rem;
            margin-bottom: 0.3rem;
            display: flex;
            justify-content: flex-end;
          "
        >
          <v-btn small class="btndelete" @click.prevent="showComments = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-col cols="12" md="12" style="">
          <quill-editor v-model="plan.Comment"></quill-editor>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import Block from "@/components/Block";
import { DxDataGrid, DxColumn, DxPaging, DxScrolling, DxEditing, DxFormat, DxPatternRule, DxRequiredRule, DxKeyboardNavigation, DxExport } from "devextreme-vue/data-grid";
import DatePicker from "../../../components/DatePicker";
import MedicationPlanPopUp from "../../../components/VeterinaryMedication/MedicationPlanList.vue";
import VaccineListPopUp from "../../../components/VeterinaryMedication/MedicationList.vue";
import DeleteUserDialog from "../../settings/business/partial/DeleteTemplateDialog.vue";
import alerts from "@/components/Alerts";
import MedicationVaccines from "../../../components/ReportsAndPrintings/MedicatinVaccines.vue";
import { mapState } from "vuex";
import GeneralFilter from "@/components/GeneralFilter.vue";
import { printToExcel } from "@/helpers/printToexcel";
import UserProfile from "@/components/Displays/UserProfileDisplay.vue";
import UserProfileLab from "@/components/Displays/UserProfileDisplayLab.vue";
import { numberWithCommas } from "@/helpers/money";

export default {
  name: "VeterinaryMedications",
  components: {
    MedicationVaccines,
    alerts,
    DxDataGrid, 
    DxColumn, 
    DxEditing, 
    DxPaging, 
    DxFormat, 
    DxPatternRule, 
    DxScrolling, 
    DxRequiredRule,
    UserProfile,
    UserProfileLab,
    //  DxLookup,
    DatePicker,
    Block,
    MedicationPlanPopUp,
    VaccineListPopUp,
    DeleteUserDialog,
    DxKeyboardNavigation,
    DxExport,
    GeneralFilter,
  },
  props: ["data", "win", "type"],
  data: () => ({
    currency: JSON.parse(localStorage.getItem('branch')).Currency,
    itemQtyPattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/i,
    showBillPatient: false,
    useData: {},
    showServicesToAdd: false,
    showProductsToAdd: false,
    servicesToBill: [],
    productsToBill: [],
    servicesToAdd: [],
    productsToAdd: [],
    filterProducts: {
        filter: null
    },
    searchToBill: {
        filter: null
    },
    togglehide1: true,
    togglehide2: true,
    togglehide3: true,
    warehouses: [],
    selectedWarehouse: null,
    customer: {},
    print: {
      show: false,
      data: [],
    },
    showVacc: false,
    vacData: [],
    alert: {
      type: "success",
      show: false,
      header: "",
      body: "",
    },
    date: "",
    valid: false,
    //useData: null,
    url: null,
    patientinfo: [],
    weightunit: '',
    weight: '',
    medicationsHeader: [],
    medication: [],
    loading: false,
    plansDialog: false,
    plan: {},
    showComments: false,
    Comments: null,
    medicationDialog: false,
    deleteDialog: false,
    id_user_login: null,
    deleteHeader: null,
    rightTableUniqueParameter: "ID_Plan_Perameter",
    showAllMedication: false,
    dataToshow: null,
    newTable: [],
    reloadMed: 0,
    items: [
      {
        ID: "1",
        Name: "Day",
        NameESP: "Día",
      },
      {
        ID: "2",
        Name: "Week",
        NameESP: "Semana",
      },
      {
        ID: "3",
        Name: "Month",
        NameESP: "Mes",
      },
      {
        ID: "4",
        Name: "Year",
        NameESP: "Año",
      },
    ],
    showMedicalData: false,
    showhistory: false,
    search: {},
    search2: {},
    medicationChecked: false,
    periods: [
      { id: 0, name: '1 Semanas', value: '1 Week' },
      { id: 1, name: '2 Semanas', value: '2 Week' },
      { id: 2, name: '3 Semanas', value: '3 Week' },
      { id: 3, name: '1 Mes', value: '1 Month' },
      { id: 4, name: '3 Meses', value: '3 Month' },
      { id: 5, name: '4 Meses', value: '4 Month' },
      { id: 6, name: '6 Meses', value: '6 Month' },
      { id: 7, name: '1 Año', value: '1 Year' }
    ],
  }),
  computed: {
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
    columnWidth() {
      return this.$vuetify.breakpoint.smAndDown ? '150px':'auto';
    },
  },
  async mounted() {
    this.$store.dispatch("rolesUser/getAllRoles");
    if (this.data) {
      Object.keys(this.data).map((el) => {
        Vue.set(this.patientinfo, el.toLowerCase(), this.data[el]);
      });
      this.url = this.$store.getters.getBackendUrl + "/public" + this.data.ProfilePicPath;
      this.plan.Weight = this.data.Weight;
      this.branchConfig = JSON.parse(localStorage.getItem("branch"));
      if(this.branchConfig?.weightunit) this.weightunit = this.branchConfig.weightunit;
      if(this.data?.weightunit) this.weightunit = this.data.weightunit;
    }

    const localparams = JSON.parse(localStorage.getItem("localparams")) || null;
    if (localparams && localparams.weightunit) this.weightunit = localparams.weightunit;

    if(this.plan && this.plan.Weight.includes('Kg')) this.weightunit = 'Kg';
    if(this.plan && this.plan.Weight.includes('Lb')) this.weightunit = 'Lb';

    if(this.plan.Weight && this.plan.Weight.includes(this.weightunit)) {
      this.weight = this.plan.Weight.slice(0, -2).replace(' ', '');
    } else {
      this.weight = this.plan.Weight.replace(' ', '');
    }

    this.id_user_login = JSON.parse(localStorage.getItem("user")).id;
    await this.getHeaderData();
    this.useData = JSON.parse(localStorage.getItem("user"));
    this.search2.ID_Patient = this.data.ID_Patient;
    this.search.ID_Patient = this.data.ID_Patient;
    await this.getVaccNextTime();
    await this.getMedicationTemplate();
    this.customer.ID_Customer = this.data.ID_Customer;
    console.log('customer', this.customer);
    //this.useData = JSON.parse(localStorage.getItem("user"));
    this.warehouses = await this.$API.warehouse.getWarehousesFiltered({ isPatient: true });
    this.filterProducts.business = this.useData.businessid;
    this.filterProducts.branch = this.useData.branch;
    this.selectedWarehouse = this.warehouses.length == 0 ? 0 : this.warehouses[0].ID_Warehouse;
    this.filterProducts.warehouse = this.selectedWarehouse;    
    await this.getServices();
    await this.getProducts();
    await this.getDefaultServices();
  },
  watch: {
    servicesToAdd: {
        deep: true,
        immediate: true,
        handler() {
            if(this.servicesToAdd.length !== 0) {
                this.showServicesToAdd = true;
            }
            else {
                this.showServicesToAdd = false;
            }
        }
    },
    productsToAdd: {
        deep: true,
        immediate: true,
        handler() {
            if(this.productsToAdd.length !== 0) {
                this.showProductsToAdd = true;
            }
            else {
                this.showProductsToAdd = false;
            }
        }
    },
  },
  methods: {
    totalXQuantity( { Price, quantity } ) {
        return `${this.currency}${ numberWithCommas( parseFloat( Price * quantity ).toFixed( 2 ) ) }`
    },
    async handleSelectWarehouse() {
        this.loading = true;
        this.filterProducts.warehouse = this.selectedWarehouse;
        await this.getProducts();
        this.$refs.werehouseRef.blur();
        this.loading = false;
    },
    addService(data) {
        const isRepetitive = this.servicesToAdd.find((service) => service.ID_Service === data.ID_Service);

        if (!isRepetitive) {
            this.servicesToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.Price), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.Price).toFixed(4))}` });
        } else {
            const service = this.servicesToAdd.find(item => item.ID_Service === data.ID_Service)
            if(service) {
                service.quantity++;
            }
            console.log("Service already exists in servicesToAdd");
        }
    },
    addProduct(data) {
        const isRepetitive = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
        if (!isRepetitive) {
            this.productsToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.ItemPrice), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.ItemPrice).toFixed(4))}` });
        } else {
            const product = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
            if(product) {
                product.quantity++;
            }
            console.log("Product already exists in productsToAdd.");
        }
    },
    deleteService(data) {
        const serviceIndex = this.servicesToAdd.findIndex((service) => service.CodeService === data.CodeService);

        if (serviceIndex !== -1) {
            this.servicesToAdd.splice(serviceIndex, 1);
        }
    },
    deleteProduct(data) {
        const index = this.productsToAdd.findIndex((item) => item.ID_Item === data.ID_Item);

        if (index !== -1) {
            this.productsToAdd.splice(index, 1);
        }
    },
    getServices() {
        this.$API.services.getAllServices(this.filterServices).then((response) => {
            this.servicesToBill = response;
        });
    },
    getProducts() {
        this.$API.products.getProductsToBillForInvoice(this.filterProducts).then((response) => {
            this.productsToBill = response;
        });
    },
    getDefaultServices() {
        this.$API.services.getDefaultServices().then((response) => {
            this.servicesDefault = response;
        });
    },
    servicesFilterHandler(response) {
        this.servicesToBill = response;
    },
    productsFilterHandler(response) {
        this.productsToBill = response;
    },
    getItemsToBeBilled() {
        this.$API.veterinarypatients.getServicesToBeBilled({ 'customer': this.data.ID_Customer })
        .then((response) => {
            this.servicesToAdd = []
            for(let i = 0; i < response.length; i++){
            this.servicesToAdd.push({ ...response[i], unitPrice: `${this.currency}${numberWithCommas(parseFloat(response[i].Price).toFixed(4))}` });
            }
        });
        this.$API.veterinarypatients.getProductsToBeBilled({ 'customer': this.data.ID_Customer }).then((res) => {
            if(res.success) {
            this.productsToAdd = [];
            for (const item of res.data) {
                this.productsToAdd.push({ ...item, quantity: item.Quantity, unitPrice: `${this.currency}${numberWithCommas(parseFloat(item.ItemPrice).toFixed(4))}`});
            }
            }
        });
    },
    async saveItemsToBeBilled() {
        this.showBillPatient = false;
        this.loading = true;

        try {
            if(this.servicesToAdd.length) {
            await this.$API.veterinarypatients.saveServicesToBeBilled({ 'customer': this.customer.ID_Customer, 'services': this.servicesToAdd })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
                throw new Error('Ocurrio un error al guardar los servicios.');
            });
            }
            if(this.productsToAdd.length) {
            console.log(this.productsToAdd);
            
            await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
                throw new Error('Ocurrio un error al guardar los productos.');
            });
            }
            await this.getItemsToBeBilled();
            this.loading = false;
            this.showAlert("success", "Exito", "Los datos de facturación de este paciente han sido guradados.")
        } catch (error) {
            console.log(error);
            this.loading = false;
            this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
        }

    },
    async billedPatientsOfCustomer() {
        this.showBillPatient = false;
        this.loading = true;

        try{
            await this.$API.veterinarypatients.saveServicesToBeBilled({'customer': this.customer.ID_Customer, 'services': this.servicesToAdd});
            await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd });
            await this.$API.veterinarypatients.billedPatientsOfCustomer({ 'customer': this.customer.ID_Customer});

            this.loading = false;

            this.showAlert("success", "Exito", `Se ha realizado la factura de los ${this.servicesToAdd.length && this.productsToAdd.length ? 'servicios y productos' : this.servicesToAdd.length ? 'servicios' : this.productsToAdd.length ? 'productos' : 'items' } seleccionados, podrá observarla en el módulo de POS en listo para facturar.`);
            await this.getItemsToBeBilled();        
        }
        catch(error) {
            console.log(error)
            this.loading = false
            this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
        }
    },

    getVaccNextTime() {
      this.$API.veterinarymedication
        .getNextvac({ ID_Patient: this.patientinfo.id_patient })
        .then((res) => {
          this.vacData = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filterHandler2(response) {
      this.vacData = response;
    },
    filterHandler(response) {
      this.medicationsHeader = response;
    },
    onExporting(e) {
      printToExcel(e, "Medicamentos y Vacunas");
    },
    orderParams(array) {
      array.sort(function (a, b) {
        if (
          (a["Order"] === "" || isNaN(parseInt(a["Order"]))) &&
          b["Order"] !== "" &&
          !isNaN(parseInt(b["Order"]))
        ) {
          return 1;
        } else if (
          a["Order"] !== "" &&
          !isNaN(parseInt(a["Order"])) &&
          (b["Order"] === "" || isNaN(parseInt(b["Order"])))
        ) {
          return -1;
        } else if (
          (a["Order"] === "" || isNaN(parseInt(a["Order"]))) &&
          (b["Order"] === "" || isNaN(parseInt(b["Order"])))
        ) {
          return 0;
        } else if (parseInt(a["Order"]) === parseInt(b["Order"])) {
          return 0;
        } else {
          return parseInt(a["Order"]) < parseInt(b["Order"]) ? -1 : 1;
        }
      });
      return array;
    },
    changeWeight(e) {
      this.plan.Weight = e.replace(' ', '');
      console.log('weigth', this.plan.Weight);
    },
    switchWeightUnit(e) {
      this.weightunit = e;
      console.log(this.weightunit);
    },
    closeAlert() {
      this.alert.show = false;
    },
    acceptAlert() {
      this.alert.show = false;
      if (this.alert.options == "ConfirmDelete") {
        this.deleteVeterinaryHeader(this.deleteHeader);
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    async closePlan(e) {
      this.plan = e;
      this.plansDialog = false;
      if (this.plan.ID_Medication_Plan) {
        this.loading = true;
        let data = {
          id: this.plan.ID_Medication_Plan,
          ID_Business: JSON.parse(localStorage.getItem("user")).businessid || "",
          ID_Branch: JSON.parse(localStorage.getItem("user")).branch || "",
        };
        this.$API.veterinarymedication
          .showParametersByPlanID(data)
          .then(async (response) => {
            let data = [];
            data.push(...response);
            data.forEach((el) => {
              el.checkbox = true;
              el.methods = "insert";
              el.ID_VPMP = Math.random().toString(36).substr(2, 9);
            });
            this.medication = await this.manageNextDate(data);
            this.orderParams(this.medication);

            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
            this.showAlert("danger", "Error", "Error al cargar los datos");
          })
          .finally(() => {
            this.medicationChecked = false;
          });
      }
    },

    async closeMedicPopUp(e) {
      e.map((el) => {
        if (el.checkbox == true && !this.medication.some(item => item.ID_Medicine == el.ID_Medicine))
          if (this.medication.indexOf(el) == -1)
            this.medication.push({
              ...el,
              methods: "insert",
              ID_VPMP: Math.random().toString(36).substr(2, 9),
            });
      });
      this.medication = await this.manageNextDate(this.medication);
      this.medicationDialog = false;
      this.reloadMed++;
      this.checkCheckboxes();
    },

    SaveMedication() {
      this.loading = true;
      let exist = false;
      //check if exist some medication in the same day
      console.log("HEADER DE MED")
      console.log(this.medicationsHeader)
      for (let x of this.medicationsHeader) {
        if (x.MedicationsDate == this.date) {
          exist = x;
        }
      }
      if (exist) {
        this.showAlert(
          "warning",
          "Error",
          "Ya existe un plan de medicación para la fecha seleccionada"
        );
        this.getVeterinaryDetails(exist);
        this.loading = false;
        return;
      }

      for (let x of this.medication) {
        if(x.checkbox){
            let WeightTmp = ''
            if(this.plan.Weight && this.weightunit) WeightTmp = this.plan.Weight.concat(' ', this.weightunit);
            let body = {
              ID_Medication_Plan: x.ID_Medication_Plan,
              ID_Patient: this.patientinfo.id_patient,
              ID_Medicine: x.ID_Medicine,
              MedicationsDate: this.date,
              Medicine: x.Medicine,
              PeriodTime: x.PeriodTime,
              PeriodUnit: x.PeriodUnit,
              NextTime: x.NextTime,
              Comment: this.plan.Comment,
              ID_User_Insert: this.id_user_login,
              ItemType: x.ItemType,
              Order: x.Order,
              Weight: WeightTmp,
          };
          console.log('cuerpe',body);
          this.$API.veterinarymedication.addVeterinaryMedicationParameter(body).then(
            () => {
              this.showAlert(
                "success",
                "Exito",
                "Se ingresaron datos de un medicamento del plan registrado."
              );

              this.loading = false;
              this.resetData();
              this.getHeaderData();
              this.getVaccNextTime();
            },
            (err) => {
              console.log(err);
              this.showAlert(
                "danger",
                "Error",
                "No se pudo ingresar datos de un medicamento del plan registrado."
              );
              this.loading = false;
            }
          )
          .finally(() => {
            this.checkCheckboxes()
          });
        }
      }
    },

    async manageNextDate(e) {
      for (let x = 0; x < e.length; x++) {
        const splittedDate = this.plan.MedicationsDate.split( '/' );
        e[x].NextTime = await this.moment( `${splittedDate[2]}${splittedDate[1]}${splittedDate[0]}`, "YYYYMMDD" )
          .add(e[x].PeriodTime, e[x].PeriodUnit)
          .format("DD/MM/YYYY");
      }
      return Promise.all(e);
    },

    resetData() {
      this.medication = [];
      this.rightTableUniqueParameter = "ID_Plan_Perameter";
      this.plan = {};
      
      this.plan.Weight = this.data.Weight;
      this.branchConfig = JSON.parse(localStorage.getItem("branch"));
      if(this.branchConfig?.weightunit) this.weightunit = this.branchConfig.weightunit;
      if(this.data?.weightunit) this.weightunit = this.data.weightunit;

      const localparams = JSON.parse(localStorage.getItem("localparams")) || null;
      if (localparams && localparams.weightunit) this.weightunit = localparams.weightunit;
      if(this.plan && this.plan.Weight.includes('Kg')) this.weightunit = 'Kg';
      if(this.plan && this.plan.Weight.includes('Lb')) this.weightunit = 'Lb';
      if(this.plan.Weight && this.plan.Weight.includes(this.weightunit)) {
        this.weight = this.plan.Weight.slice(0, -2).replace(' ', '');
      } else {
        this.weight = this.plan.Weight.replace(' ', '');
      }

      this.date = this.moment().format("DD/MM/YYYY");
      this.plan.MedicationsDate = this.date;
      this.newTable = [];

      this.getMedicationTemplate();
    },

    DeleteRow(row) {
      this.loading = true;
      this.$API.veterinarymedication
        .deleteVeterinaryMedicationPerameter(row.data.ID_VPMP)
        .then(
          () => {
            //   this.$toasted.info("Se elimino el medicamento seleccionado");
            this.loading = false;
          },
          (err) => {
            console.log(err);
            this.loading = false;
          }
        );
    },
    handleRowDeleting(e) {
      if (this.rightTableUniqueParameter == "ID_VPMP") {
        if (e.data.methods != "insert") {
          this.newTable.push({ data: { ...e.data }, methods: "delete" });
        }
      }
    },

    getHeaderData() {
      //console.log("ejecutado",this.data.ID_Patient);
      this.$API.veterinarymedication
        .getVeterinaryMedicationHeaderHistory(this.data.ID_Patient)
        .then(
          (res) => {
            this.medicationsHeader = res;
            //console.log("esto traje",res);

            if (this.$store.state.vetPatientData.dataLabs) {
              let date = this.$store.state.vetPatientData.dataLabs;

              let value = null;
              this.$store.commit("vetPatientData/cleanData");
              this.medicationsHeader.forEach((element) => {
                if (element.MedicationsDate == date) {
                  value = element;
                }
              });
              if (value) {
                this.getVeterinaryDetails(value);
              }
            }
          },
          (err) => {
            console.log(err);

            //this.$toasted.error(err.message);
          }
        );
    },

    getVeterinaryDetails(data) {
      this.dataToshow = data;
      this.loading = true;

      this.plan = data;
      if(this.plan.Weight?.includes('Kg')) {
        this.weightunit = 'Kg';
        this.weight = this.plan.Weight.slice(0, -2).replace(' ', '');
      }
      else if(this.plan.Weight?.includes('Lb')) {
        this.weightunit = 'Lb';
        this.weight = this.plan.Weight.slice(0, -2).replace(' ', '');
      }
      else {
        this.weightunit = '';
        this.weight = this.plan.Weight?.replace(' ', '');
      }

      const body = {
        ID_Medication_Plan: data.ID_Medication_Plan,
        ID_Patient: data.ID_Patient,
        MedicationsDate: data.MedicationsDate,
      };
      this.$API.veterinarymedication
        .getVeterinaryPerameterOfPlans(body)
        .then(
          (res) => {
            this.rightTableUniqueParameter = "ID_VPMP";
            res.map((data) => {
              data.checkbox = true;
              data.FullPeriod = ''
            });
            this.medication = res;
            this.plan.Comment = res[0].Comment;
            this.plan.Prev = res[0].Comment;
            this.plan.Weight = res[0].Weight;
            this.orderParams(this.medication);
            this.date = this.medication[0].MedicationsDate;
            if(this.plan.Weight.includes('Kg')) {
              this.weightunit = 'Kg';
              this.weight = this.plan.Weight.slice(0, -2).replace(' ', '');
            }
            else if(this.plan.Weight.includes('Lb')) {
              this.weightunit = 'Lb';
              this.weight = this.plan.Weight.slice(0, -2).replace(' ', '');
            }
            else {
              this.weightunit = '';
              this.weight = this.plan.Weight.replace(' ', '');
            }
            this.loading = false;
          },
          (err) => {
            this.loading = false;
            this.showAlert(
              "danger",
              "Error",
              "No se pudo obtener los datos de los planes de medicamentos."
            );
            console.log(err);
            // this.$toasted.error(err.message);
          }
        )
        .catch(() => {
          this.loading = false;
          this.getHeaderData();
        });
    },

    deleteVeterinaryHeader(data) {
      this.loading = true;
      const body = {
        ID_Medication_Plan: data.ID_Medication_Plan,
        ID_Patient: data.ID_Patient,
        MedicationsDate: data.MedicationsDate,
      };
      this.$API.veterinarymedication.deleteVeterinaryMedicationParameterBulk(body).then(
        () => {
          this.showAlert(
            "success",
            "Exito",
            "Se elimino el plan de medicamentos seleccionado."
          );
          this.deleteDialog = false;
          this.getHeaderData();
          this.resetData();
          this.loading = false;
        },
        (err) => {
          this.showAlert(
            "danger",
            "Error",
            "No se pudo eliminar el plan de medicamentos seleccionado."
          );
          console.log(err);
          // this.$toasted.error(err.message);
          this.loading = false;
        }
      );
    },
    async handleMed() {
      this.medication = await this.manageNextDate(this.medication);
    },
    async UpdateRow(e) {
      this.medication = await this.manageNextDate(this.medication);
      if (this.rightTableUniqueParameter == "ID_VPMP") {
        if (e.data.methods != "insert") {
          this.newTable.push({ data: { ...e.data }, methods: "update" });
        }
      }
      this.orderParams(this.medication);
    },

    updateVeterinaryParameterData() {
      if(this.plan.Weight) {
        if(!this.plan.Weight.includes('Lb') && !this.plan.Weight.includes('Kg')) {
          this.plan.Weight = this.plan.Weight.concat(' ', this.weightunit);
        } 
        else if(!this.plan.Weight.includes(this.weightunit)) {
          const newValue = this.plan.Weight.slice(0, -3);
          this.plan.Weight = newValue.concat(' ', this.weightunit);
          console.log(this.plan.Weight);
        }
      } 
      this.medication.forEach((el) => {
        console.log("element", el, el.methods, el.checkbox);
        if (el.methods == "insert" && el.checkbox) {
          this.newTable.push({
            data: { ...el },
            methods: "insert",
            Comment: this.plan.Comment,
            Weight: this.plan.Weight,
          });
        }
      });
      if (this.newTable.length == 0) {
        this.showAlert(
          "danger",
          "Advertencia",
          "No se han realizado cambios en el plan de medicamentos."
        );
        return;
      }
      if (this.plan.Comment != this.plan.Prev) {
        this.plan.NewComment = this.plan.Comment;
      }
      this.$API.veterinarymedication
        .updateVeterinaryMedicationPerameter(this.plan, this.newTable)
        .then(
          () => {
            this.showAlert(
              "success",
              "Exito",
              "Se actualizo el plan de medicamentos seleccionado."
            );
            this.getVeterinaryDetails(this.dataToshow);
            this.newTable = [];
            this.getVaccNextTime();
            this.loading = false;
          },
          (err) => {
            this.showAlert(
              "danger",
              "Error",
              "No se pudo actualizar el plan de medicamentos seleccionado."
            );
            console.log(err);
            this.loading = false;
            this.newTable = [];
          }
        );
    },

    setPeriod(data){
      const { data: innerData } = data
      const { ID_VPMP } = innerData
      const words = innerData.FullPeriod.split(' ');
      this.medication.find((med) => {
        if(med.ID_VPMP == ID_VPMP){
          med.PeriodTime = words[0]
          med.PeriodUnit = words[1]
        }
      })
      this.UpdateRow(data);
    },

    getMedicationTemplate(){
      const data = {
        species: this.patientinfo.species
      }
      this.loading = true;
      this.$API.veterinarypatients.findmedicationstemplate( data ).then((response) => {
        this.medication = response;
        response.map((med) => {
          med.ID_VPMP = new Date(new Date() - Math.random()*(1e+12))
          med.ID_Medicine = parseInt(med.ID_Medicine)
          med.Order = parseInt(med.Order)
          med.ID_Medication_Plan = undefined
          med.checkbox = false;
          med.methods = "insert";
          med.FullPeriod = `${med.PeriodTime} ${med.PeriodUnit}`
        })
        //console.log(this.medication)
        
      }).finally(() => {
        this.loading = false;
        this.rightTableUniqueParameter = "ID_Plan_Perameter";
        this.UpdateRow();
      })
    },

    getSelectedRow(data){
      console.log(data)
      this.checkCheckboxes();
    },

    checkCheckboxes() {
      this.medicationChecked = this.medication.some(({ checkbox }) => checkbox) && this.medication.length > 0;
      console.log("el check es", this.medicationChecked)
    }

  },
};
</script>

<style scoped>
.btndisable {
    background-color: #41464c !important;
    color: white;
}
.btndisable2 {
    background-color: #1976d2 !important;
    color: white;
}

.btnblack {
  background-color: rgba(60, 60, 60, 1) !important;
  color: white !important;
}
.tabs {
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white;
}

.infopatient {
  margin-top: 5%;
}
.btn-add {
  background-color: #3498db !important;
  color: white;
}
.btnsave {
  background-color: #00a178 !important;
  color: white;
}
.btnclean {
  background-color: #f29d35 !important;
  color: white;
}
.btndelete {
  background-color: #e3191e !important;
  color: white;
}
.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}
.btnblue {
  background-color: #1976d2 !important;
  color: white;
}
.tabsB {
  width: 100%;
  padding: 10px;
  background-color: #1976d2;
  color: white;
  border-radius: 15px;
  border-style: solid;
  border-color: black;
  border-width: 1px;
}
.tabsB:hover {
  cursor: pointer;
}
.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;

  border-radius: 15px;
}

/* .v-list {
  z-index: 10 !important;
  position: relative;
} */
</style>
