<template>
  <div>
    <v-card class="white box" flat>
      <!-- bar -->
      <!-- bar -->
      <v-card flat class="pa-4 bar">
        <div class="d-flex justify-space-between align-center">
          <div>
            <v-combobox
              v-if="false"
              v-model="selectedBranch"
              solo
              dense
              attach
              label="Sucursal"
              class="light-combo"
              :rules="requiredRule"
              :items="uniqueBranches"
              item-text="BranchName"
              item-value="ID_Branch"
              :hide-details="true"
              @change="showBranchAccountingLedger"
            ></v-combobox>
          </div>
          <div>
            <v-btn
              class="mr-2"
              color="primary"
              @click="configAccountCodes = true"
              small
              >Configurar códigos de cuentas</v-btn
            >
            <v-btn
              small
              color="primary"
              @click.prevent="printToExcel"
              style="margin-right: 0.3rem"
            >
              <v-icon> mdi-file-download</v-icon>
            </v-btn>
            <!-- <v-btn
              small
              color="#6251DA"
              @click.prevent="printToExcel"
              style="margin-right: 0.3rem"
            >
              <v-icon color="white"> mdi-export</v-icon>
            </v-btn> -->
            <v-btn
              small
              color="success"
              @click.prevent="addSheet"
              style="margin-right: 0.3rem"
            >
              <v-icon> mdi-microsoft-excel</v-icon>
            </v-btn>
            <!-- <v-btn small color="btn-add" @click.prevent="updateParam">
              <v-icon> mdi-content-save </v-icon>
            </v-btn> -->
          </div>
        </div>
      </v-card>
      <!-- bar -->
      <!-- bar -->

      <!-- table -->
      <!-- <v-card-text>
      </v-card-text> -->
      <!-- table -->
      <v-col cols="6" class="text-left">
        <v-btn class="btn-add" @click.prevent.stop="expandTable">
          {{ !expandAll ? "Expandir" : "Contraer" }} todos
        </v-btn>
      </v-col>
      <GeneralFilter
        :search="search"
        :filterEndpoint="filterPromise"
        @filtered="filterHandler"
        @emptyFilter="getAllAccounts"
      >
        <DxTreeList
          ref="AccountsCatalog"
          :data-source="treeListData"
          :key-field="keyField"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :auto-expand-all="expandAll"
          :show-borders="true"
          :word-wrap-enabled="true"
          :expanded-row-keys="expandedRowKeys"
          id="tasks"
          parent-id-expr="id_parent"
        >
          <DxPaging :enabled="true" :page-size="10" />
          <DxColumn
            caption="Código"
            cell-template="add-template"
            :width="300"
          ></DxColumn>
          <DxColumn data-field="BranchName" caption="Sucursal">
            <DxRequiredRule />
          </DxColumn>
          <DxColumn data-field="account_description" caption="Nombre">
            <DxRequiredRule />
          </DxColumn>
          <DxColumn data-field="id_level" caption="Nivel">
            <DxRequiredRule />
          </DxColumn>
          <DxColumn
            :width="50"
            cell-template="edit-template"
            caption=""
          ></DxColumn>
          <DxColumn
            :width="50"
            cell-template="delete-template"
            caption=""
          ></DxColumn>
          <!-- add -->
          <template #add-template="{ data }">
            <div>
              <a
                class="icon-size text-black"
                :class="
                  data.data.account_code.length <= 2
                    ? 'font-weight-bold'
                    : 'font-weight-regular'
                "
                @click="addAccountingLedge(data.data)"
              >
                {{ data.data.account_code }}
                <v-icon class="ml-2">mdi-plus-circle</v-icon>
              </a>
            </div>
          </template>
          <!-- add -->
          <!-- edit -->
          <template #edit-template="{ data }">
            <div class="d-flex justify-center">
              <a
                class="icon-size mx-1"
                @click="editAccountingLedge(data.data)"
              >
                <v-icon color="primary"> mdi-eye </v-icon>
              </a>
            </div>
          </template>
          <!-- edit -->
          <!-- delete -->
          <template #delete-template="{ data }">
            <div
              class="d-flex justify-center"
              v-if="!hasChildren(data.data.id_account)"
            >
              <a
                class="icon-size mx-1"
                @click="deleteAccountingLedge(data.data)"
              >
                <v-icon color="error">mdi-delete</v-icon>
              </a>
            </div>
          </template>
          <!-- delete -->
        </DxTreeList>
      </GeneralFilter>

      <div v-show="spreadsheetData.length > 0">
        <h2>Spreadsheet Data</h2>
        <ul>
          <li v-for="(row, index) in spreadsheetData" :key="index">
            {{ row }}
          </li>
        </ul>
      </div>

      <!-- dialog start -->
      <v-dialog
        persistent
        width="40%"
        class="deep-dialog"
        :value="addCatalogDialog"
      >
        <AddAccountingLedge
          :dialogAction="dialogAction"
          :selectedCatalog="selectedCatalog"
          :branches="uniqueBranches"
          :newLevels="levels"
          @closeCatalogDialog="closeCatalogDialog"
          @handleAccountingLedge="handleAccountingLedge"
        />
      </v-dialog>
      <!-- dialog end-->

      <!-- dialog start -->
      <v-dialog
        persistent
        :width="$vuetify.breakpoint.smAndDown ? '90%' : '40%'"
        :value="addSheetDialog"
      >
        <v-card class="border">
          <v-btn
            @click="closeSheetDialog"
            elevation="0"
            color="red"
            class="close-btn"
            :disabled="loadingFile"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-sheet class="box-dialog">
            <h2 class="mb-3">Agregar libro de Excel</h2>
            <v-row>
              <v-col cols="12">
                <v-file-input
                  ref="fileInput"
                  label="Agregar libro de Excel "
                  accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  @change="handleFileInputChange"
                  :value="this.file"
                  :disabled="loadingFile"
                ></v-file-input>
              </v-col>
            </v-row>
            <div class="d-flex flex-column">
              <v-btn
                color="success"
                class="mt-4"
                block
                @click="summitFileInputChange"
                :disabled="loadingFile"
              >
                {{
                  !this.loadingFile
                    ? "Seleccionar archivo Excel"
                    : "Procesando archivo..."
                }}
                <v-progress-circular
                  indeterminate
                  v-if="loadingFile"
                  :size="20"
                  style="margin: 0 1rem"
                ></v-progress-circular>
              </v-btn>
            </div>
          </v-sheet>
        </v-card>
      </v-dialog>
      <!-- dialog end-->
      <alerts
        v-if="alert.show"
        v-on:close_alert="closeAlert"
        v-on:accept_alert="acceptAlert"
        :properties="alert"
        class="deep-alert"
      >
      </alerts>
    </v-card>
    <config-account-codes
      v-model="configAccountCodes"
      max-width="600px"
    ></config-account-codes>
  </div>
</template>

<script>
import Alerts from "@/components/Alerts";
import { multipleCriteriaFilter } from "@/helpers/object";
import GeneralFilter from "@/components/GeneralFilter.vue";
import AddAccountingLedge from "@/components/Business/AddAccountingLedge.vue";

import {
  DxTreeList,
  DxColumn,
  DxRequiredRule,
  DxPaging,
} from "devextreme-vue/tree-list";
import ConfigAccountCodes from "./ConfigAccountCodes.vue";

export default {
  name: "AccountingLedger",
  components: {
    ConfigAccountCodes,
    AddAccountingLedge,
    DxTreeList,
    DxColumn,
    DxRequiredRule,
    GeneralFilter,
    DxPaging,
    Alerts,
  },
  data() {
    return {
      configAccountCodes: false,
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      rules: [
        (v) => !!v || "Correlativo es requerido",
        (v) => /^[0-9]{1,2}$/.test(v) || "Números 0 al 9, máximo 2 digitos)",
      ],
      tooltipOptions: {
        offset: 10,
      },
      selectedBranch: null,
      spreadsheetData: [],
      requiredRule: [(v) => v !== null || "Valor es requerido"],
      correlative: "",
      addCatalogDialog: false,
      addSheetDialog: false,
      checkboxChildren: false,
      checkboxSilbing: false,
      treeListData: [],
      search: {
        filter: null,
      },
      keyField: "id",
      accountingLedge: {
        id_account: null,
        BranchName: null,
        account_description: null,
        account_code: null,
        id_level: null,
        id_parent: null,
      },
      oldAccountingCode: null,
      selectedCatalog: null,
      dialogAction: "",
      DialogAction: {
        NEW: "new",
        EDIT: "edit",
        ADD: "add",
      },
      accountingBranches: [],
      levels: [],
      expandAll: false,
      expandedRowKeys: [],
      file: null,
      loadingFile: false,
    };
  },
  watch: {},
  computed: {
    businessId() {
      return JSON.parse(localStorage.getItem("user")).businessid != undefined
        ? JSON.parse(localStorage.getItem("user")).businessid
        : 0;
    },
    disableCode() {
      return (
        this.correlative !== null &&
        this.correlative.length > 0 &&
        this.accountingLedge.account_code !== null
      );
    },
    uniqueBranches() {
      // Check if accountingBranches is defined and not empty
      if (this.accountingBranches && this.accountingBranches.length > 0) {
        // Extract unique BranchName and ID_Branch values from accountingBranches
        const uniqueBranches = [
          ...new Set(
            this.accountingBranches.map((item) => ({
              BranchName: item.BranchName,
              ID_Branch: item.ID_Branch,
            }))
          ),
        ];
        return uniqueBranches;
      } else {
        return [];
      }
    },
    nameAndCode() {
      return this.treeListData.map((item) => ({
        labelName: `${item.account_description} - ${item.account_code}`,
        ...item,
      }));
    },
    newCodeToShow() {
      return this.accountingLedge.account_code != null;
    },
    correlativeToShow() {
      if (
        this.correlative !== null &&
        this.correlative !== undefined &&
        this.correlative.length > 0
      ) {
        if (this.correlative.length === 2) {
          return this.correlative;
        } else if (this.correlative.length === 1) {
          return `${this.correlative}_`;
        } else {
          return " No válido";
        }
      } else {
        return "__";
      }
    },
  },
  async mounted() {
    this.getAllAccounts();
    this.getAllAccountingBranches();
    this.getLevels();
  },
  methods: {
    expandTable() {
      if (this.expandAll) {
        this.expandedRowKeys = [0, 1, 2, 3, 4, 5, 6, 7, 8];
        this.expandAll = false;
        this.$refs.AccountsCatalog.instance.refresh();
        return;
      } else {
        this.expandAll = true;
        this.$refs.AccountsCatalog.instance.refresh();
      }
    },
    async getAllAccounts() {
      const result =
        await this.$API.branchaccountcatalog.getBranchAccountCatalog();
      this.treeListData = [...result.data];
      this.formatAccounts();
      // console.log( 'this.treeListData ', this.treeListData )
    },
    formatAccounts() {
      for (const index in this.treeListData) {
        this.treeListData[index].id = this.treeListData[index].id_account;
      }
    },
    acceptAlert() {
      this.alert.show = false;
    },
    closeAlert() {
      this.alert.show = false;
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    filterPromise(filterObj) {
      const filterArray = [
        "account_code",
        "BranchName",
        "account_description",
        "id_level",
      ];

      return new Promise((resolve, reject) => {
        try {
          resolve(
            multipleCriteriaFilter(filterArray, filterObj, this.treeListData)
          );
        } catch (error) {
          reject(error);
        }
      });
    },

    // Handler SpreadSheet start
    async printToExcel() {
      const print = await this.$API.branchaccountcatalog.exportAccountCatalog();
      console.log(print);
    },

    handleFileInputChange(file) {
      console.log(file);
      if (!file.name.includes(".xlsx") || !file.name.includes(".xls")) {
        this.showAlert(
          "warning",
          "Advertencia",
          "Debe seleccionar un archivo en formato xlsx o xls."
        );
        return (this.file = null);
      }
      this.file = file;
    },

    async summitFileInputChange() {
      this.loadingFile = true;
      if (!this.file) {
        this.showAlert(
          "warning",
          "Advertencia",
          "Debe seleccionar un archivo."
        );
        this.closeSheetDialog();
        return;
      }
      const response =
        await this.$API.branchaccountcatalog.importAccountCatalog(this.file);
      console.log(response);
      this.loadingFile = false;
      if (response.success) {
        this.showAlert("success", "Catalogo importado", response.message);
        this.closeSheetDialog();
        await this.getAllAccounts();
      } else {
        this.showAlert("warning", "Advertencia", response.message);
        this.closeSheetDialog();
        await this.getAllAccounts();
      }
    },
    // Function to get the parent code based on if exist
    getParentCode(code, level, data, orphans) {
      if (level === 1) {
        return 0;
      } else {
        const parentLevel = level - 1;
        const parentCodePrefix = code.toString().slice(0, parentLevel * 2);
        const parentItem = data.find(
          (item) => item.Código.toString() === parentCodePrefix
        );

        if (parentItem) {
          const parentCode = parentItem.Código;

          // Check if the parent is also an orphan, if so, add the current item to orphans
          if (orphans.includes(parentCode)) {
            orphans.push(code);
            return 0; // Return 0 as the default parent for orphans
          }

          return parentCode;
        } else {
          // Check if the parent code prefix itself is an orphan
          if (orphans.includes(parentCodePrefix)) {
            orphans.push(code);
            return 0; // Return 0 as the default parent for orphans
          }

          // Add the orphan item to the array
          orphans.push(code);
          return 0; // Return 0 as the default parent for orphans
        }
      }
    },
    // Handler SpreadSheet end

    // Handler SpreadSheet end

    async addNewAccountingLedge(data) {
      this.selectedCatalog = data;
      this.dialogAction = this.DialogAction.NEW;
      if (data) {
        this.accountingLedge.id = null;
        this.accountingLedge.account_code = data.account_code;
        this.accountingLedge.ID_Branch = data.ID_Branch;
        this.accountingLedge.account_description = data.account_description;
        this.accountingLedge.id_level = data.id_level;
        this.accountingLedge.id_parent = data.id_parent;
        this.accountingLedge.allow_transactions = data.allow_transactions;
        this.oldAccountingCode = data.account_code;
      }
      console.log("enviado:", this.accountingLedge);
      this.openCatalogDialog();
    },
    async addAccountingLedge(data) {
      this.dialogAction = this.DialogAction.ADD;
      if (data) {
        data.correlative =
          parseInt(
            await this.$API.branchaccountcatalog.getLastCode({
              ...data,
              id_parent: data.id_account,
            })
          ) + 1;
        this.selectedCatalog = { ...data };
        this.selectedCatalog.hasChildren = this.treeListData.some(
          (item) => item.id_parent === data.id_account
        );
        this.accountingLedge.id = null;
        this.accountingLedge.account_code = data.account_code;
        this.accountingLedge.ID_Branch = data.ID_Branch;
        this.accountingLedge.account_description = data.account_description;
        this.accountingLedge.id_level = data.id_level;
        this.accountingLedge.id_parent = data.id_parent;
        this.accountingLedge.allow_transactions = data.allow_transactions;
        this.oldAccountingCode = data.account_code;
      }
      // console.log("enviado:", this.accountingLedge, this.selectedCatalog);
      // this.accountingLedge = this.selectedCatalog;
      this.openCatalogDialog();
    },
    editAccountingLedge(data) {
      this.openCatalogDialog();
      this.dialogAction = this.DialogAction.EDIT;
      this.selectedCatalog = data;
      this.selectedCatalog.hasChildren = this.treeListData.some(
        (item) => item.id_parent === data.id_account
      );
    },
    clean() {
      this.correlative = null;
      this.correlative = null;
      this.oldAccountingCode = null;
      this.accountingLedge = {
        id_account: null,
        BranchName: null,
        account_description: null,
        account_code: null,
        id_level: null,
        id_parent: null,
      };
    },
    closeCatalogDialog() {
      this.clean();
      this.addCatalogDialog = false;
    },
    closeSheetDialog() {
      this.clean();
      //this.$refs.fileInput.value = null;
      this.file = null;
      this.loadingFile = false;
      this.addSheetDialog = false;
    },
    openCatalogDialog() {
      this.addCatalogDialog = true;
    },
    openSheetDialog() {
      this.addSheetDialog = true;
    },
    filterHandler(response) {
      // console.log('response acá ', response);
      this.treeListData = [...response];
      this.formatJerarchy();
      this.formatAccounts();
    },
    formatJerarchy() {
      const minIdAccount = Math.min(
        ...this.treeListData.map(({ id_account }) => {
          return id_account;
        })
      );

      for (const index in this.treeListData) {
        const { id_account } = this.treeListData[index];

        if (id_account === minIdAccount) {
          delete this.treeListData[index].id_parent;
        }
      }
    },
    showNewCode(id) {
      const selectedNameAndCode = this.account_descriptionAndCode.find(
        (item) => id === item.id_account
      );
      console.log(selectedNameAndCode);
      this.accountingLedge.account_code = selectedNameAndCode.account_code;
    },
    hasChildren(id) {
      return this.treeListData.some((item) => item.id_parent === id);
    },
    async deleteAccountingLedge(data) {
      console.log(data);
      try {
        const hasChildren = this.treeListData.some(
          (item) => item.id_parent === data.id_account
        );
        console.log(hasChildren);
        if (hasChildren) {
          this.showAlert(
            "danger",
            "Error",
            `No se puede eliminar la cuenta porque tiene cuentas hijas.`
          );
          return;
        }
        const result = await this.$API.branchaccountcatalog.deleteBranchAccount(
          {
            branchaccountcatalog: { ...data },
          }
        );

        const { error } = result;

        if (error) {
          this.showAlert("warning", "Advertencia", error);
          await this.getAllAccounts();
          this.closeCatalogDialog();
        } else {
          this.showAlert(
            "success",
            "Cuenta Eliminada",
            "se eliminó la cuenta correctamente"
          );
          await this.getAllAccounts();
          this.closeCatalogDialog();
        }
      } catch (error) {
        console.log(error);
        this.closeCatalogDialog();
        this.showAlert(
          "danger",
          "Error",
          `Ocurrió el siguiente error: ${error.error}`
        );
      }
    },
    getAccountingLedgers() {},
    addSheet() {
      this.openSheetDialog();
    },
    async handleAccountingLedge({ action, data }) {
      try {
        if (action === this.DialogAction.NEW) {
          console.log("new", data);
        }
        if (action === this.DialogAction.ADD) {
          data.id_parent = data.id_account;
          delete data.id_account;
          const result =
            await this.$API.branchaccountcatalog.createBranchAccount({
              branchaccountcatalog: { ...data },
            });
          if (result.success) {
            this.showAlert(
              "success",
              "Cuenta Insertada",
              "se inserto la cuenta correctamente"
            );
          } else {
            this.showAlert(
              "warning",
              "Hubo un problema al insertar la cuenta",
              `${result.message}`
            );
          }
          await this.getAllAccounts();
          this.closeCatalogDialog();
        }
        if (action === this.DialogAction.EDIT) {
          const result =
            await this.$API.branchaccountcatalog.updateBranchAccount({
              branchaccountcatalog: { ...data },
            });

          if (result.success) {
            this.showAlert(
              "success",
              "Cuenta Actualizada",
              "se actualizó la cuenta correctamente"
            );
          } else {
            this.showAlert(
              "warning",
              "Hubo un problema al actualizar la cuenta",
              `${result.message}`
            );
          }
          await this.getAllAccounts();
          this.closeCatalogDialog();
        }
        if (action === "error") {
          this.showAlert(
            "danger",
            "Error",
            `Ocurrió el siguiente error: ${"Se ingresaron datos inválidos."}`
          );
        }
      } catch (error) {
        // this.closeCatalogDialog();
        this.showAlert(
          "warning",
          "Hubo un problema al procesar la petición",
          `${error.message}`
        );
      }
    },
    getAllAccountingBranches() {
      return new Promise((resolve, reject) => {
        this.$API.branches
          .findBranchesAccounting(this.businessId)
          .then((result) => {
            this.accountingBranches = result;
            resolve(this.accountingBranches);
          })
          .catch((error) => {
            console.error("Error al obtener sucursales", error);
            reject(error);
          });
      });
    },
    showBranchAccountingLedger() {
      if (this.selectedBranch) {
        const selectedBranchId = this.selectedBranch.ID_Branch;
        console.log("Selected Branch ID:", selectedBranchId);
      }
    },
    getLevels() {
      // console.log(this.levels);
      this.$API.accountlevel
        .getAccountLevels()
        .then((response) => {
          this.levels = response.data;
          // console.log(this.levels);
        })
        .catch((error) => {
          console.error("Error al obtener niveles", error);
        });
    },
  },
};
</script>

<style scoped>
.bar {
  width: 100%;
  background-color: rgb(60, 60, 60) !important;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-radius: 15px !important;
  margin-top: 1rem !important;
}
.btnblue {
  background-color: #1976d2 !important;
  color: white;
}
.btnadd {
  background-color: #19d257 !important;
  color: white;
}

.text-white {
  color: white;
}

.text-black {
  color: black;
}

.zero-padding {
  padding: 0 !important;
  margin: 0 !important;
}

.box {
  border-radius: 15px !important;
  padding: 1rem;
}

.box-dialog {
  border-radius: 15px !important;
  padding: 2rem;
}

.border {
  border-radius: 15px !important;
}

.close-btn {
  color: white !important;
  position: relative !important;
  float: right !important;
}

.span-account_code {
  font-style: oblique;
  font-weight: 100;
}

.span-newaccount_code {
  font-weight: bold;
  font-size: 18px;
}
.span-correlative {
  font-weight: 600;
}

.light-combo {
  background-color: white;
  border-radius: 15px;
}

.deep-alert {
  z-index: 10 !important;
}

.deep-dialog {
  z-index: 5 !important;
}

#tasks {
  max-height: 540px;
  overflow-y: hidden !important;
}

#tasks .dx-treelist-rowsview td {
  vertical-align: middle;
  padding: 10px !important;
}
</style>
