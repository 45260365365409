<template #all>
  <div>
    <v-card flat>
      <v-card-text>
        <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
        </alerts>
        <template v-if="type === 'medicalclinic'">
          <user-profile-lab :patient="data" :patientinfo="data" :useData="useData"></user-profile-lab>
        </template>
        <template v-else>
          <user-profile :patientinfo="patientinfo" :data="data" :useData="useData" />
        </template>


        <v-row style="margin-top: 1rem;">


          <v-col cols="12" class="box" style="margin-top: 1rem;">
            <ValidationObserver ref="observer" tag="form">
              <v-form v-model="valid" class="position-relative">
                <block v-if="loading"></block>

                <v-row class="ml-2 mr-2 mb-2" style="margin-top: .3rem;margin-bottom: 30px;">
                  <v-col cols="12" class="tabsNh">


                    <v-col cols="12" v-if="form.ID_Images" style="display: flex; justify-content: space-between;">
                      <div style="width: 158px;">
                        <v-btn v-if="$store.getters['rolesUser/getCustomRole'](156)" small color="error"
                          @click.prevent="showDelete(toDelete)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                      <div>
                        <v-btn v-if="$store.getters['rolesUser/getCustomRole'](107)" small class="btn-add mr-1" @click.prevent="getItemsToBeBilled(); showBillPatient = true">
                            <v-icon class="mr-3">mdi-account-details</v-icon> Facturar paciente
                        </v-btn>
                      </div>
                      <div>
                        <v-btn small class="btnblue mr-1" @click="showhistory = !showhistory" 
                          v-if="$store.getters['rolesUser/getCustomRole'](154)" >
                          <v-icon>
                            mdi-magnify
                          </v-icon>
                        </v-btn>
                        <v-btn small class="btnclean" @click.prevent="clearData" style="margin-right: .3rem;">
                          <v-icon>
                            mdi-broom
                          </v-icon>
                        </v-btn>
                        <v-btn v-if="$store.getters['rolesUser/getCustomRole'](155)" small class="btnsave"
                          @click.prevent="updateImageWithFiles">
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col cols="12" v-if="!form.ID_Images" style="display: flex; justify-content: space-between;">
                      <div style="width: 158px;" ></div>
                      <div>
                          <v-btn v-if="$store.getters['rolesUser/getCustomRole'](107)" small class="btn-add mr-1" @click.prevent="getItemsToBeBilled(); showBillPatient = true">
                              <v-icon class="mr-3">mdi-account-details</v-icon> Facturar paciente
                          </v-btn>
                      </div>
                      <div>
                        <v-btn v-if="$store.getters['rolesUser/getCustomRole'](154)" small class="btnblue mr-1"
                          @click="showhistory = !showhistory">
                          <v-icon>
                            mdi-magnify
                          </v-icon>
                        </v-btn>
  
                        <v-btn small class="btnclean" @click.prevent="clearData" style="margin-right: .3rem;">
                          <v-icon>
                            mdi-broom
                          </v-icon>
                        </v-btn>
                        <v-btn v-if="$store.getters['rolesUser/getCustomRole'](155)" small class="btnsave"
                          @click.prevent.stop="SaveImageData">
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-col>
                  <v-col cols="12" md="3" sm="12" style="margin-top: 1rem;">
                    <div style="width: 265px !important; display: flex;">
                      <date-picker :value="form.ImagesDate" label="Fecha" @dateSelected="(param) =>
                          this.changeDate(param)
                        "></date-picker>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <template v-if="data">
                    </template>
                  </v-col>
                  <v-col cols="12" md="1"></v-col>
                  <v-col cols="12" md="2" style="display: flex;justify-content: flex-end;">
                    <v-btn style="margin-top: 1rem;" @click="showComment = !showComment"
                      :class="form.Comment ? 'btnclean' : 'btnblack'">
                      Comentarios
                    </v-btn>

                  </v-col>
                </v-row>


                <br />

                <v-row class="ml-2 mr-2">
                  <v-col cols="12" md="12">
                    <v-dialog v-model="showComment" scrollable :max-width="$vuetify.breakpoint.smAndDown ? '90%':'60%'">
                      <v-card class="pa-4">

                        <quill-editor v-model="form.Comment" :options="quillOptions"></quill-editor>
                      </v-card>
                    </v-dialog>
                  </v-col>

                  <v-col cols="12" md="12" style="text-align: right">
                    <br />
                    <v-btn class="btn-add" @click.prevent.stop="addImagesDialog = !addImagesDialog">
                      Añadir Documentos
                    </v-btn>
                  </v-col>
                </v-row>
                  <div style="margin-top: 20px;">
                    <DxDataGrid :scrolling="{useNative:true}" width="100%" :show-borders="true" :data-source="images" key-expr="">

                      <!--       <DxColumn :width="80"
                      cell-template="showdownload" caption="Descargar"></DxColumn> -->
                      <DxColumn :width="columnWidth" caption="Documentos" cell-template="show-image"></DxColumn>
                      <DxColumn :width="200" cell-template="ImageType" caption="Extensión archivo"></DxColumn>
                      <DxColumn :width="300" cell-template="category" caption="Categoría"></DxColumn>
                      <DxColumn :width="50" caption="" cell-template="template-delete" v-if="$store.getters['rolesUser/getCustomRole'](156)"></DxColumn>
                      <!-- <DxColumn
                        :width="80"
                        cell-template="show-template"
                        caption=""
                      ></DxColumn> -->
                      <template #showdownload="{ data }">
                        <div>
                          <a href="" class="mx-2 " @click.prevent.stop="downloadFile(data.data)">
                            <v-icon>mdi-download</v-icon>
                          </a>
                        </div>
                      </template>
                      <template #show-image="{ data }">
                        <div class="position-relative">

                          <v-icon dark class="extra-css-magnify" :color="'black'" @click="
                            (imageUrl = data.data.src),
                            (ZoomDialog = !ZoomDialog)
                            " v-if="data.data.src && !data.data.isdoc && !data.data.Picture.endsWith('.mp4') && !data.data.Picture.endsWith('.MP4')
                      ">mdi-magnify-plus</v-icon>
                          <v-icon dark class="extra-css-magnify" :color="'white'"
                            v-if="(data.data.src && data.data.isdoc) || (data.data.Picture.endsWith('.mp4') || data.data.Picture.endsWith('.MP4'))">mdi-magnify-plus</v-icon>
                          <img :src="data.data.src"
                            v-if="/*form.TypeOfImage != 'MP4' &&*/ data.data.src && !data.data.isdoc && !data.data.Picture.endsWith('.mp4')"
                            class="img" />
                          <v-icon dark class="extra-css-files"
                            v-if="/*form.TypeOfImage != 'MP4' &&*/ (data.data.src && data.data.isdoc) || (data.data.Picture.endsWith('.mp4') || data.data.Picture.endsWith('.MP4'))">mdi-folder-file-outline
                          </v-icon>
                          <!--video   id="video" :src="data.data.src" type="video/mp4" v-if=""
                            disabled></video-->
                          <span v-if="!data.data.src">{{ data.data.file.name }}</span>

                          <a :href="data.data.src" style="vertical-align: bottom;" target="_blank" rel="noopener"
                            v-if="data.data.src">Documento, imagen o video {{ data.data.ID_Image ? 'ID ' + data.data.ID_Image
                              : '' }}</a>


                        </div>
                      </template>
                      <template #ImageType="{ data }">
                        <div style="margin: auto;
                      width: 50%;

                      padding: 10px;">Archivo {{ data.data.Picture.substring(data.data.Picture.length - (data.data.Picture.length -
                      data.data.Picture.indexOf(".")) + 1) }}</div>
                      </template>
                      <template #category="{ data }">
                        <div style="margin: auto;
                      width: 50%;
                      padding: 10px;">{{ data.data.TypeOfImage }}</div>
                      </template>
                      <template #template-delete="{ data }">
                        <div>
                          <a href="" class="mx-2" @click.prevent="showAlert('question', 'Confirmar', 'Esta seguro de eliminar este registro', 'confirmDelete');
                          dataTodelete = data.data.ID_Image
                            ">
                            <v-icon color="error">
                              mdi-delete
                            </v-icon>
                          </a>
                        </div>

                      </template>
                      <!-- <template #show-template="{ data }">
                        <div>
                          <a
                            href=""
                            class="mx-2"
                            @click.prevent.stop="DeleteImageRow(data.data)"
                          >
                            <v-icon color="error">mdi-delete</v-icon>
                          </a>
                        </div>
                      </template> -->
                    </DxDataGrid>
                  </div>
                

                <br />
                <v-col style="margin-top: .6rem; margin-bottom: 1.0rem;">

                </v-col>

              </v-form>
            </ValidationObserver>
          </v-col>
        </v-row>
        <v-dialog v-model="showhistory" scrollable :width="'90%'">
          <v-card class="pa-4">
            <v-card-text>
              <div style="margin-top: .3rem;margin-bottom: .3rem; display: flex;justify-content: flex-end;">
                <v-btn small class="btndelete" @click.prevent="showhistory = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <GeneralFilter :search="{ ID_Patient: data.ID_Patient }"
                :filterEndpoint="$API.veterinaryImages.getImagesFiltered" @emptyFilter="getImages"
                @filtered="filterHandler">

                <DxDataGrid 
                  :show-borders="true" 
                  :data-source="masterData"
                  key-expr="ID_Images" 
                  @exporting="onExporting" 
                  :column-auto-width="true"
                  style="width: fit-content;"
                >
                  <DxExport :enabled="true" />
                  <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                  <DxPaging :page-size="10" />
                  <!--  <DxColumn data-field="ID_Images" caption="Id"></DxColumn> -->
                  <DxColumn :min-width="100" data-field="ImagesDate" caption="Fecha"></DxColumn>
                  <DxColumn :min-width="200" data-field="FullName" caption="Atendido por"></DxColumn>
                  <DxColumn :min-width="300" data-field="Currentypes" caption="Tipo de imagen"></DxColumn>
                  <DxColumn  v-if="$store.getters['rolesUser/getCustomRole'](156)" :width="80" cell-template="show-delete"
                    caption=""></DxColumn>
                  <template #show-delete="{ data }">
                    <div>
                      <a href="" class="mx-2" @click.prevent.stop="showDelete(data.data.ID_Images); showhistory = false">
                        <v-icon color="error">mdi-delete</v-icon>
                      </a>
                    </div>
                  </template>
                  <template #show-template="{ data }">
                    <div>
                      <a href="#" @click.prevent.stop="filesByImageID(data.data.ID_Images); showhistory = false;">
                        <v-icon color="primary">mdi-eye</v-icon>
                      </a>

                    </div>
                  </template>
                  <DxPager :allowed-page-sizes="pageSizes" :show-page-size-selector="true" />
                  <DxPaging :page-size="5" />
                </DxDataGrid>
              </GeneralFilter>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- Dialog bill patient -->
        <v-dialog v-model="showBillPatient" width="90%">
          <v-card class="py-3 px-3">
              <v-card-title class="text-h5">
              Facturar paciente 
              </v-card-title>

              <v-card-actions>
              <v-row>
                  <v-col cols="12" class="tabsNh" style="padding: 14px">
                  <v-col style="display: flex; justify-content: space-between; min-height: 50px">
                      <div>
                      <!-- <v-btn v-if="!showServicesToAdd" small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                          <v-icon>mdi-menu-down</v-icon>
                          Ver items
                      </v-btn>
                      <v-btn v-else small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                          <v-icon>mdi-menu-up</v-icon>
                          Ocultar
                      </v-btn> -->
                      </div>
                      <div>
                      <v-btn small class="btn-add" @click.prevent="saveItemsToBeBilled()">
                          <v-icon>mdi-content-save</v-icon>
                          Guardar
                      </v-btn>
                      </div>

                      <div>
                      <v-btn small class="btnsave" @click.prevent="billedPatientsOfCustomer()">
                          <v-icon>mdi-cash-register</v-icon>
                          Facturar cliente
                      </v-btn>
                      </div>
                  </v-col>
                  </v-col>
              </v-row>
              </v-card-actions>
              <div style="height: 500px; overflow-y: auto; overflow-x: hidden; margin-top: 20px; margin-bottom: 20px; padding: 0 20px;">
              <!-- Services default -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver servicios por defecto
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide1 = !togglehide1' v-show='!togglehide1' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide1 = !togglehide1' v-show='togglehide1' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide1">
                  <DxDataGrid class="isScrolledByGeneralFilter dataGridService" :data-source="servicesDefault" 
                  :show-borders="true" :column-auto-width="true" style="width: fit-content;">
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="CodeService" header-cell-template="headerCodeService" />
                      <template #headerCodeService="{ }">
                      <div>Código de<br />servicio</div>
                      </template>
                      <DxColumn data-field="NameService" header-cell-template="headerNameService" />
                      <template #headerNameService="{ }">
                      <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" header-cell-template="headerName" />
                      <template #headerName="{ }">
                      <div>Nombre a<br />facturar</div>
                      </template>
                      <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice" />
                      <template #headerTypeservice="{ }">
                      <div>Tipo de<br />servicio</div>
                      </template-->
                      <DxColumn :width="65" data-field="Price" caption="Precio" />
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""/>
                      <template #show-template="{ data }">
                      <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                          <v-icon>mdi-plus</v-icon>
                      </a>
                      </template>
                      <template #quantity-template="{ data }">
                      <p>{{ servicesToAdd.find(item => item.CodeService == data.data.CodeService).quantity }}</p>
                      </template>
                  </DxDataGrid>
                  </v-col>
              </v-row>

              <!-- Services to select -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver todos los servicios
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide2 = !togglehide2' v-show='!togglehide2' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide2 = !togglehide2' v-show='togglehide2' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide2">
                  <GeneralFilter 
                      :filterEndpoint="$API.services.getAllServicesFiltered" 
                      :search="searchToBill"
                      @emptyFilter="getServices" 
                      @filtered="servicesFilterHandler"
                  >
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="servicesToBill"
                      key-expr="CodeService" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="CodeService" caption="ID"></DxColumn>
                      <DxColumn data-field="NameService" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice">
                      </DxColumn-->
                      <!--template #headerTypeservice="{ }">
                          <div>Tipo de<br />servicio</div>
                      </template-->
                      <DxColumn :width="65" data-field="Price" caption="Precio"></DxColumn>
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                          <v-icon>mdi-plus</v-icon>
                          </a>
                      </template>
                      <template #quantity-template="{ data }">
                          <p style="margin: 0;">{{ servicesToAdd.find(item => item.CodeService == data.data.CodeService)?.quantity || '' }}</p>
                      </template>
                      </DxDataGrid>
                  </GeneralFilter>
                  </v-col>
              </v-row>

              <!-- Products to select -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver todos los productos
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide3 = !togglehide3' v-show='!togglehide3' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide3 = !togglehide3' v-show='togglehide3' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide3">
                  <GeneralFilter 
                      :filterEndpoint="$API.products.getProductsToBillForInvoiceFiltered" 
                      :search="searchToBill"
                      @emptyFilter="getProducts" 
                      @filtered="productsFilterHandler"
                  >
                      <v-row style="padding: 1rem;">
                          <v-col cols="12" md="3" sm="4">
                              <v-autocomplete
                              v-model="selectedWarehouse"
                              item-text="Warehouse"
                              item-value="ID_Warehouse"
                              :items="warehouses"
                              @change="handleSelectWarehouse"
                              label="Almacenes"
                              ref="werehouseRef"
                              dense 
                              outlined 
                              attach
                              >
                              </v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="9" sm="8">
                              <p style="color: #e3191e !important; font-size: 12px; margin: 12px 0" v-if="warehouses.length == 0">No existen almacenes de productos para pacientes.</p>
                          </v-col>
                      </v-row>
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="productsToBill"
                      key-expr="ID_Item" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="ID_Item" caption="ID"></DxColumn>
                      <DxColumn data-field="ItemName" header-cell-template="headerName"></DxColumn>
                      <template #headerName="{ }">
                          <div>Nombre del<br />producto</div>
                      </template>
                      <DxColumn data-field="ItemComercialName" header-cell-template="headerNameComercial"></DxColumn>
                      <template #headerNameComercial="{ }">
                          <div>Nombre<br />comercial</div>
                      </template>
                      <DxColumn :width="65" data-field="ItemPrice" caption="Precio"></DxColumn>
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="addProduct(data.data)">
                          <v-icon>mdi-plus</v-icon>
                          </a>
                      </template>
                      <template #quantity-template="{ data }">
                          <p style="margin: 0;">{{ productsToAdd.find(item => item.ID_Item == data.data.ID_Item)?.quantity || '' }}</p>
                      </template>
                      </DxDataGrid>
                  </GeneralFilter>
                  </v-col>
              </v-row>

              <!-- ServiceToAdd -->
              <v-row style="margin-top: 2rem">
                  <v-col cols="12">
                  <h4 style="padding: 16px;">Servicios seleccionados</h4>
                  <div style="overflow-x: scroll; padding: 0 12px;">
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="servicesToAdd"
                      key-expr="CodeService" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"    
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="5" />
                      <DxColumn data-field="CodeService" :allow-editing="false" caption="ID"></DxColumn>
                      <DxColumn data-field="NameService" :allow-editing="false" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" :allow-editing="false" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <DxColumn data-field="TypeService" :allow-editing="false" header-cell-template="headerTypeservice">
                      </DxColumn>
                      <template #headerTypeservice="{ }">
                          <div>Tipo de<br />servicio</div>
                      </template>
                      <DxColumn data-field="quantity" caption="Cantidad">
                          <DxFormat typed="fixedPoint" :precision="0" />
                          <DxRequiredRule message="La cantidad del servicio a facturar es requerida" />
                          <DxPatternRule :pattern="itemQtyPattern"
                          message="La cantidad del servicio facturado solo puede contener numeros enteros mayores a 0" />
                      </DxColumn>
                      <DxColumn data-field="unitPrice" :allow-editing="false" caption="Precio"></DxColumn>
                      <DxColumn cell-template="total-template" :allow-editing="false" caption="Total"></DxColumn>
                      <DxColumn cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="deleteService(data.data)">
                          <v-icon>mdi-delete</v-icon>
                          </a>
                      </template>
                      <template #total-template="{ data }">
                          <div>
                          {{ totalXQuantity( data.data ) }}
                          </div>
                      </template>
                      </DxDataGrid>
                  </div>
                  </v-col>
              </v-row>

              <!-- Product to add -->
              <v-row style="margin-top: 2rem">
                  <v-col cols="12">
                  <h4 style="padding: 16px;">Productos seleccionados</h4>
                  <div style="overflow-x: scroll; padding: 0 12px;">
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="productsToAdd"
                      key-expr="ID_Item" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"    
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="5" />
                      <DxColumn data-field="ID_Item" :allow-editing="false" caption="ID"></DxColumn>
                      <DxColumn data-field="ItemName" :allow-editing="false" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre del<br />producto</div>
                      </template>
                      <DxColumn data-field="ItemComercialName" :allow-editing="false" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <DxColumn data-field="ItemType" :allow-editing="false" header-cell-template="headerTypeservice">
                      </DxColumn>
                      <template #headerTypeservice="{ }">
                          <div>Tipo de<br />producto</div>
                      </template>
                      <DxColumn data-field="quantity" caption="Cantidad">
                          <DxFormat typed="fixedPoint" :precision="0" />
                          <DxRequiredRule message="La cantidad del producto a facturar es requerida" />
                          <DxPatternRule :pattern="itemQtyPattern"
                          message="La cantidad del producto facturado solo puede contener numeros enteros mayores a 0" />
                      </DxColumn>
                      <DxColumn data-field="unitPrice" :allow-editing="false" caption="Precio"></DxColumn>
                      <DxColumn cell-template="total-template" :allow-editing="false" caption="Total"></DxColumn>
                      <DxColumn cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="deleteProduct(data.data)">
                          <v-icon>mdi-delete</v-icon>
                          </a>
                      </template>
                      <template #total-template="{ data }">
                          <div>
                          {{ totalXQuantity( {...data.data, Price: data.data.ItemPrice} ) }}
                          </div>
                      </template>
                      </DxDataGrid>
                  </div>
                  </v-col>
              </v-row>

              </div>
          </v-card>
        </v-dialog>
        <!-- Dialog bill patient -->

        <v-dialog v-model="addImagesDialog" scrollable max-width="80%">
          <AddImages @uploadedImage="uploadedImageData($event)" @closepopup="addImagesDialog = false"
            :type="form.TypeOfImage || ''"></AddImages>
        </v-dialog>
        <v-dialog v-model="ZoomDialog" v-if="imageUrl" scrollable max-width="90%">
          <v-card>
            <v-card-text>
              {{ form.TypeOfImage }}
              <ZoomImage :ZoomDialog="ZoomDialog" :imageUrl="imageUrl" :type="form.TypeOfImage || ''"></ZoomImage>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!--    <v-dialog v-model="deleteDialog" v-if="imageToDelete" max-width="700">
      <DeleteUserDialog :data="imageToDelete" :name="'Image Type'" v-if="deleteDialog" @close="deleteDialog = false"
        @f_call="destroyImageWithFiles($event)"></DeleteUserDialog>
    </v-dialog> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Alerts from "../../../components/Alerts.vue";
import Vue from "vue";
import Block from "@/components/Block";
import {
  DxDataGrid, DxColumn, DxPaging, DxScrolling, DxEditing, DxFormat, DxPatternRule, DxRequiredRule, DxExport, DxPager, } from "devextreme-vue/data-grid";
import DatePicker from "../../../components/DatePicker";
import AddImages from "../../../components/VeterinaryImage/addImages.vue";
import ZoomImage from "../../../components/VeterinaryImage/zoomImageView.vue";
import { saveAs } from 'file-saver';
import { printToExcel } from "@/helpers/printToexcel";
/* import DeleteUserDialog from "../../settings/business/partial/DeleteTemplateDialog.vue"; */
import { mapState } from 'vuex'
import GeneralFilter from "../../../components/GeneralFilter.vue";
import UserProfile from "@/components/Displays/UserProfileDisplay.vue";
import UserProfileLab from "@/components/Displays/UserProfileDisplayLab.vue";
import { numberWithCommas } from "@/helpers/money";

export default {
  name: "VeterinaryImages",
  components: {
    DxDataGrid, DxColumn, DxEditing, DxPaging, DxFormat, DxPatternRule, DxScrolling, DxRequiredRule,
    DxExport,
    DxPager,
    // DxEditing,
    DatePicker,
    Block,
    AddImages,
    ZoomImage,
    GeneralFilter,
    UserProfile,
    UserProfileLab,
    /* DeleteUserDialog, */
    Alerts
  },
  props: ["data", "win", "type"],
  data: () => ({
    currency: JSON.parse(localStorage.getItem('branch')).Currency,
    itemQtyPattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/i,
    showBillPatient: false,
    useData: {},
    showServicesToAdd: false,
    showProductsToAdd: false,
    servicesToBill: [],
    productsToBill: [],
    servicesToAdd: [],
    productsToAdd: [],
    filterProducts: {
        filter: null
    },
    searchToBill: {
        filter: null
    },
    togglehide1: true,
    togglehide2: true,
    togglehide3: true,
    warehouses: [],
    selectedWarehouse: null,
    customer: {},
    dataTodelete: null,
    all: 0,
    valid: false,
    url: null,
    dateExists: false,
    patientinfo: [],
    dateRegistered: false,
    showComment: false,
    images: [],
    alert: {
      type: "success",
      show: false,
      header: "",
      body: "",
    },
    toDelete: undefined,
    image: null,
    loading: false,
    form: {},
    TypeOfImage: "",
    ExtensionImage: "",
    addImagesDialog: false,
    ZoomDialog: false,
    imageUrl: null,
    showhistory: false,
    loginUserID: null,
    masterData: [],
    pageSizes: [5, 15, 25, 100],
    deleteDialog: false,
    imageToDelete: null,
    tempFiles: [],
    isEdit: false,
    date: null,
    showImagesUploaded: false,
    quillOptions: {
      modules: {
        toolbar: {
          container:
            [
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'align': [] }],

              ['clean'],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'font': [] }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'script': 'sub' }, { 'script': 'super' }],
              [{ 'formula': '' }]
            ],
          handlers: {}
        }
      },
      theme: 'snow'
    },
    //useData: false,
    refdelete: null,
  }),
  computed: {
    ...mapState(
      "rolesUser",
      {
        rolesStatus: (state) => state.roles,
      }),
      columnWidth() {
        return this.$vuetify.breakpoint.smAndDown ? '150px' : 'auto !important';
      },
  },
  async mounted() {
    this.$store.dispatch('rolesUser/getAllRoles');
    await this.getImages();
    this.loginUserID = JSON.parse(localStorage.getItem("user")).id;
    this.useData = JSON.parse(localStorage.getItem("user"));
    if (this.data) {
      Object.keys(this.data).map((el) => {
        Vue.set(this.patientinfo, el.toLowerCase(), this.data[el]);
      });
      this.url = this.data.ProfilePicPath;
      //this.useData = JSON.parse(localStorage.getItem("user"));
      this.customer.ID_Customer = this.data.ID_Customer;
      console.log('customer', this.customer);
      this.warehouses = await this.$API.warehouse.getWarehousesFiltered({ isPatient: true });
      this.filterProducts.business = this.useData.businessid;
      this.filterProducts.branch = this.useData.branch;
      this.selectedWarehouse = this.warehouses.length == 0 ? 0 : this.warehouses[0].ID_Warehouse;
      this.filterProducts.warehouse = this.selectedWarehouse;    
      await this.getServices();
      await this.getProducts();
      await this.getDefaultServices();
    }
  },
  watch: {
    servicesToAdd: {
        deep: true,
        immediate: true,
        handler() {
            if(this.servicesToAdd.length !== 0) {
                this.showServicesToAdd = true;
            }
            else {
                this.showServicesToAdd = false;
            }
        }
    },
    productsToAdd: {
        deep: true,
        immediate: true,
        handler() {
            if(this.productsToAdd.length !== 0) {
                this.showProductsToAdd = true;
            }
            else {
                this.showProductsToAdd = false;
            }
        }
    },
  },
  methods: {
    totalXQuantity( { Price, quantity } ) {
        return `${this.currency}${ numberWithCommas( parseFloat( Price * quantity ).toFixed( 2 ) ) }`
    },
    async handleSelectWarehouse() {
        this.loading = true;
        this.filterProducts.warehouse = this.selectedWarehouse;
        await this.getProducts();
        this.$refs.werehouseRef.blur();
        this.loading = false;
    },
    addService(data) {
        const isRepetitive = this.servicesToAdd.find((service) => service.ID_Service === data.ID_Service);

        if (!isRepetitive) {
            this.servicesToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.Price), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.Price).toFixed(4))}` });
        } else {
            const service = this.servicesToAdd.find(item => item.ID_Service === data.ID_Service)
            if(service) {
                service.quantity++;
            }
            console.log("Service already exists in servicesToAdd");
        }
    },
    addProduct(data) {
        const isRepetitive = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
        if (!isRepetitive) {
            this.productsToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.ItemPrice), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.ItemPrice).toFixed(4))}` });
        } else {
            const product = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
            if(product) {
                product.quantity++;
            }
            console.log("Product already exists in productsToAdd.");
        }
    },
    deleteService(data) {
        const serviceIndex = this.servicesToAdd.findIndex((service) => service.CodeService === data.CodeService);

        if (serviceIndex !== -1) {
            this.servicesToAdd.splice(serviceIndex, 1);
        }
    },
    deleteProduct(data) {
        const index = this.productsToAdd.findIndex((item) => item.ID_Item === data.ID_Item);

        if (index !== -1) {
            this.productsToAdd.splice(index, 1);
        }
    },
    getServices() {
        this.$API.services.getAllServices(this.filterServices).then((response) => {
            this.servicesToBill = response;
        });
    },
    getProducts() {
        this.$API.products.getProductsToBillForInvoice(this.filterProducts).then((response) => {
            this.productsToBill = response;
        });
    },
    getDefaultServices() {
        this.$API.services.getDefaultServices().then((response) => {
            this.servicesDefault = response;
        });
    },
    servicesFilterHandler(response) {
        this.servicesToBill = response;
    },
    productsFilterHandler(response) {
        this.productsToBill = response;
    },
    getItemsToBeBilled() {
        this.$API.veterinarypatients.getServicesToBeBilled({ 'customer': this.data.ID_Customer })
        .then((response) => {
            this.servicesToAdd = []
            for(let i = 0; i < response.length; i++){
            this.servicesToAdd.push({ ...response[i], unitPrice: `${this.currency}${numberWithCommas(parseFloat(response[i].Price).toFixed(4))}` });
            }
        });
        this.$API.veterinarypatients.getProductsToBeBilled({ 'customer': this.data.ID_Customer }).then((res) => {
            if(res.success) {
            this.productsToAdd = [];
            for (const item of res.data) {
                this.productsToAdd.push({ ...item, quantity: item.Quantity, unitPrice: `${this.currency}${numberWithCommas(parseFloat(item.ItemPrice).toFixed(4))}`});
            }
            }
        });
    },
    async saveItemsToBeBilled() {
        this.showBillPatient = false;
        this.loading = true;

        try {
            if(this.servicesToAdd.length) {
            await this.$API.veterinarypatients.saveServicesToBeBilled({ 'customer': this.customer.ID_Customer, 'services': this.servicesToAdd })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
                throw new Error('Ocurrio un error al guardar los servicios.');
            });
            }
            if(this.productsToAdd.length) {
            console.log(this.productsToAdd);
            
            await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
                throw new Error('Ocurrio un error al guardar los productos.');
            });
            }
            await this.getItemsToBeBilled();
            this.loading = false;
            this.showAlert("success", "Exito", "Los datos de facturación de este paciente han sido guradados.")
        } catch (error) {
            console.log(error);
            this.loading = false;
            this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
        }

    },
    async billedPatientsOfCustomer() {
        this.showBillPatient = false;
        this.loading = true;

        try{
            await this.$API.veterinarypatients.saveServicesToBeBilled({'customer': this.customer.ID_Customer, 'services': this.servicesToAdd});
            await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd });
            await this.$API.veterinarypatients.billedPatientsOfCustomer({ 'customer': this.customer.ID_Customer});

            this.loading = false;

            this.showAlert("success", "Exito", `Se ha realizado la factura de los ${this.servicesToAdd.length && this.productsToAdd.length ? 'servicios y productos' : this.servicesToAdd.length ? 'servicios' : this.productsToAdd.length ? 'productos' : 'items' } seleccionados, podrá observarla en el módulo de POS en listo para facturar.`);
            await this.getItemsToBeBilled();        
        }
        catch(error) {
            console.log(error)
            this.loading = false
            this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
        }
    },

    filterHandler(response) {
      this.masterData = response
    },
    onExporting(e) {
      printToExcel(e, 'Historial de Imágenes')
    },
    async downloadFile(data) {
      console.log('data', data.Path);
      const res = await fetch(data.Path, {
        method: 'GET',
        headers: new Headers({ 'Content-type': 'application/json' }),
        //Access-Control-Allow-Origi

        withCredentials: true,
        crossorigin: true,
        mode: 'no-cors',
      });
      console.log('res', res);
      const blob = await res.blob();
      saveAs(blob, data.Picture);
    },
    uploadedImageData(e) {
      /*  if (e.Path.startsWith('blob')) {
         e.Path = URL.createObjectURL(e.Path)
       } */

      /*  let imgbb = this.blobtoImg(e.Path)

       console.log('img bb', imgbb); */
      console.log("esto recibe", e);
      let newFile = {
        ...e,
        Picture: e.file.name,
      }
      this.images.push(newFile);
      if (this.isEdit) this.tempFiles.push(newFile);
      this.addImagesDialog = false;
    },
    isBlob(string) {
      return string.startsWith('blob');
    },
    async SaveImageData() {
      if (
        !this.form.ID_Images && this.images.length < 1
      ) {
        this.showAlert("danger", "Error", "Por favor seleccione una imagen");
        return;
      }

      console.log('form imagesdate', this.form.ImagesDate);
      this.masterData.forEach(element => {

        console.log('elementmaster imagesdate', element.ImagesDate);
        if (element.ImagesDate == this.form.ImagesDate) {
          this.dateExists = true
        }
      });
      if (!this.dateExists) {

        this.loading = true;
        this.$refs.observer.validate();
        const isValid = await this.$refs.observer.validate();
        if (isValid) {
          let arr = [];
          for (let x of this.images) {
            /*  console.log('XXTypeOfImage',x.TypeOfImage); */

            x.file['TypeOfImage'] = x.TypeOfImage
            arr.push(x.file);
          }

          this.form.files = arr;
          this.form.ID_Patient = this.patientinfo.id_patient;
          this.form.ID_User_Insert = this.loginUserID;
          this.form.ID_Business = JSON.parse(localStorage.getItem("user")).businessid
          this.form.ID_Branch = JSON.parse(localStorage.getItem("user")).branch
          this.form.TypeOfImage = this.TypeOfImage;
          const formData = new FormData();
          for (const i of Object.keys(arr)) {
            formData.append("file", arr[i]);
            /*    console.log("type of new register", arr[i].TypeOfImage); */
            formData.append('types', arr[i].TypeOfImage);

          }
          formData.append("form", JSON.stringify(this.form));
          setTimeout(() => {
            this.$API.veterinaryImages.createImagesData(formData).then(
              (reg) => {
                this.showAlert("success", "Éxito", "Registro Agregado");
                this.getImages();
                this.loading = false;
                this.dateExists = false
                this.filesByImageID(reg.ID_Images)
              },
              (err) => {
                // this.$toasted.info(err.message);
                console.log('error', err);
                this.loading = false;
                this.showAlert("danger", "Error", "No se guardó Registro");
                this.dateExists = false
              }
            );
          }, 1000);
        } else {
          this.loading = false;
          this.dateExists = false
        }
      } else {
        this.showAlert("danger", "Error", "No se puede guardar mas de un registro por fecha");
        this.dateExists = false
      }

    },
    showDelete(id) {
      this.toDelete = id
      return this.showAlert("question", "Eliminar Paciente", "¿Está seguro que desea eliminar este paciente?", "destroyImageWithFiles");
    },
    getImages() {
      this.loading = true;
      this.$API.veterinaryImages.getImages(this.data.ID_Patient).then(
        (res) => {
          this.masterData = res;
          //console.log('master data', this.masterData);
          this.loading = false;
          this.clearData();

          if (this.$store.state.vetPatientData.dataImages) {

            let date = this.$store.state.vetPatientData.dataImages;
            this.$store.commit('vetPatientData/cleanData');
            let dateelement = null;
            this.masterData.forEach(element => {
              if (element.ImagesDate == date) {
                dateelement = element;
              }
            });
            console.log('dateelement', dateelement);
            if (dateelement) this.filesByImageID(dateelement.ID_Images)
          }
        },
        (err) => {
          //  this.$toasted.info(err.message);
          console.log('error', err);
          this.loading = false;
          this.showAlert("danger", "Error", "Hubo un error al obtener los datos");
        }
      );
    },
    closeAlert() {
      this.alert.show = false;

      if (this.alert.options == "redirectcustomer") {
        this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid });
        this.$store.dispatch("addWindow", {
          name: "customerList",
          component: "CustomersList",
          unique: true,
        });
        this.$store.dispatch("reloadData", "customers");
      }
      if (this.alert.options == "confirmDelete") {
        this.dataTodelete = null
      }

    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "deletecustomer") {
        this.deleteCustomer();
      }
      if (this.alert.options == "confirmDelete") {
        this.DeleteImageRow(this.dataTodelete);
      }
      if (this.alert.options == "destroyImageWithFiles") {
        this.destroyImageWithFiles(this.toDelete);
      }
      this.toDelete = undefined
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    destroyImageWithFiles(id) {
      this.loading = true;
      this.$API.veterinaryImages.deleteImageWithFiles(id).then(
        () => {
          this.showAlert("success", "Éxito", "Registro Eliminado");
          // this.$toasted.info("El registro de imágenes ha sido eliminado.");
          this.getImages();
          this.deleteDialog = false;
          this.loading = false;
          this.form = {}
          this.all += 1
        },
        (err) => {
          this.showAlert("danger", "Error", "Hubo un error al eliminarel registro");
          console.log('error', err);
          // this.$toasted.error(err.message);
          this.loading = false;
        }
      );
    },

    blobtoImg(blob) {
      return URL.createObjectURL(blob);
    },
    DeleteImageRow(id) {
      if (this.isEdit) {
        this.loading = true;
        this.$API.veterinaryImages.deletespecifiedFile(id).then(
          () => {
            this.showAlert("success", "Éxito", "Registro Eliminado");
            this.images = this.images.filter((item) => item.ID_Image != id);
            this.loading = false;
            this.dataTodelete = null
          },
          (err) => {
            this.showAlert("danger", "Error", "Ocurrió un error al eliminar el Registro");
            //  this.$toasted.info(err.message);
            console.log('error', err);
            this.loading = false;

          }
        );
      }
    },

    changeDate(date) {
      this.form.ImagesDate = date
      this.masterData.forEach(element => {
        if (element.ImagesDate == date) {
          this.filesByImageID(element.ID_Images)
        } else {
          Object.keys(this.form).forEach(key => {
            if (key !== "ImagesDate") {
              delete this.form[key];
            }
          });
          this.images = []
        }

      });
    },
    checkFileType(filename) {
      const validExtensions = ['xlsx', 'xls', 'pdf', 'doc', 'docx'];
      const fileExtension = filename.split('.').pop().toLowerCase();
      return validExtensions.includes(fileExtension);
    },
    filesByImageID(id) {
      this.loading = true;
      this.toDelete = id;

      this.$API.veterinaryImages.filesByImageID(id).then(
        (res) => {
          console.log('res files by image', res);
          this.images = res.files;
          this.images.map((ele) => {
            ele.isdoc = this.checkFileType(ele.Picture)
            ele.src = `${ele.Path}`;

          });
          console.log('images', this.images);
          this.form.ImagesDate = res.images.ImagesDate;
          this.form.TypeOfImage = res.images.TypeOfImage;
          this.TypeOfImage = res.images.TypeOfImage;

          this.form.Comment = res.images.Comment;
          this.form.ID_Images = res.images.ID_Images;

          this.loading = false;
          this.isEdit = true;
        },
        (err) => {
          // this.$toasted.info(err.message);
          console.log('error', err);
          this.loading = false;
        }
      );
    },

    async updateImageWithFiles() {
      this.loading = true;
      this.$refs.observer.validate();
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        let arr = [];
        for (let x of this.tempFiles) {
          x.file['TypeOfImage'] = x.TypeOfImage
          arr.push(x.file);
        }
        this.form.ID_User_Update = this.loginUserID;
        this.form.ID_Business = JSON.parse(localStorage.getItem("user")).businessid
        this.form.ID_Branch = JSON.parse(localStorage.getItem("user")).branch
        /*         console.log("este es el arr ", arr); */
        const formData = new FormData();
        for (const i of Object.keys(arr)) {

          formData.append("file", arr[i]);
          console.log("array i", arr[i].TypeOfImage);
          formData.append('types', arr[i].TypeOfImage);
        }
        formData.append("form", JSON.stringify(this.form));
        /*  console.log('este es el form',formData); */
        /* console.log("formdata files", formData.files); */
        setTimeout(() => {
          this.$API.veterinaryImages
            .updateImageswithFiles(this.form.ID_Images, formData)
            .then(
              () => {
                this.filesByImageID(this.form.ID_Images)
                this.getImages();
                this.showAlert("success", "Éxito", "Registro actualizado");
                /*    this.$toasted.info("El registro de imágenes seleccionado ha sido actualizado."); */
                this.loading = false;
                setTimeout(() => {
                  this.filesByImageID(this.form.ID_Images)
                }, 2000);


              },
              (err) => {
                // this.$toasted.info(err.message);
                console.log('error', err);
                this.loading = false;
                this.showAlert("danger", "Error", "No se pudo actualizar el Registro");
                this.filesByImageID(this.form.ID_Images)
              }
            );
        }, 1000);
      }
    },

    clearData() {
      this.form = {};
      this.form = {
        ImagesDate: this.moment().format("DD/MM/YYYY"),
      };
      this.images = [];
      this.TypeOfImage = "";
      this.$refs.observer.reset();
      this.tempFiles = [];
      this.isEdit = false;
    },
  },
};
</script>

<style scoped>
.btndisable {
    background-color: #41464c !important;
    color: white;
}
.btndisable2 {
    background-color: #1976d2 !important;
    color: white;
}

.btn-add {
  background-color: #3498DB !important;
  color: white;
}

.btnsave {
  background-color: #00A178 !important;
  color: white;
}

.btnclean {
  background-color: #F29D35 !important;
  color: white;
}

.btndelete {
  background-color: #E3191E !important;
  color: white;
}

.tabs {

  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white;
}

.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;
  border-radius: 15px;

}

.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;

}

.tabsB {
  width: 100%;
  padding: 10px;
  background-color: #1976d2;
  color: white;
  border-radius: 15px;
  border-style: solid;
  border-color: black;
  border-width: 1px;
}

.tabsB:hover {

  cursor: pointer;
}

.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;

  border-radius: 15px;


}

.infopatient {
  margin-top: 5%;
}

.img {
  max-width: 100%;
  max-height: 100%;
  width: 50px !important;
  height: 50px !important;
  margin-right: 10px;
}

#video {
  max-width: 100%;
  max-height: 100%;
  width: 50px !important;
  height: 50px !important;
}

.btnblack {

  background-color: rgba(60, 60, 60, 1) !important;
  color: white !important;


}

.btnblue {
  background-color: #1976d2 !important;
  color: white;
}

.extra-css-magnify {
  bottom: 15px;
  position: relative;
  left: 2px;
  margin-right: 20px;
}

.extra-css-files {
  font-size: 50px !important;
  margin-right: 10px;

}

.position-relative {
  vertical-align: middle;
}
</style>
