<template>
  <v-dialog
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
    @keydown.enter.prevent="$emit(btnCancelEvent)"
    @keydown.esc.prevent="$emit(btnCancelEvent)"
  >
    <v-card color="comment" rounded="lg" dark>
      <v-card-title>
        <v-icon :color="btnColor.cancel">
          <slot name="icon">{{ icon }}</slot>
        </v-icon>
        <span class="ml-2">
          <slot name="title">{{ title }}</slot>
        </span>
      </v-card-title>
      <v-card-text class="text-h6 text-justify white--text">
        <slot>{{ message }}</slot>
      </v-card-text>
      <v-card-actions>
        <v-row :justify="isConfirm ? 'space-between' : 'center'" no-gutters>
          <v-col class="px-2 py-1" cols="12" sm="4" md="4">
            <v-btn
              @click="$emit(btnCancelEvent, false)"
              :color="btnColor.cancel"
              block
            >
              <slot name="btn-cancel">{{ btnCancelTextC }}</slot>
            </v-btn>
          </v-col>
          <v-col class="px-2 py-1" v-if="isConfirm" cols="12" sm="4" md="4">
            <v-btn @click="$emit(btnOkEvent, true)" :color="btnColor.ok" block>
              <slot name="btn-ok">{{ btnOkTextC }}</slot>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: "info", // info, warning, error, confirm, confirm-danger
    },
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    maxWidth: {
      type: [String, Number],
      default: "600px",
    },
    overlayColor: {
      type: String,
      default: "white",
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    btnCancelText: {
      type: String,
      default: "",
    },
    btnCancelEvent: {
      type: String,
      default: "input",
    },
    btnOkText: {
      type: String,
      default: "",
    },
    btnOkEvent: {
      type: String,
      default: "action",
    },
  },
  computed: {
    isConfirm() {
      return ["confirm", "confirm-danger"].includes(this.type);
    },
    btnColor() {
      return {
        cancel: ["info", "confirm", "confirm-danger"].includes(this.type)
          ? "primary"
          : this.type,
        ok: ["confirm"].includes(this.type) ? "save" : "error",
      };
    },
    icon() {
      switch (this.type) {
        case "warning":
          return "mdi-alert";
        case "error":
          return "mdi-close-circle";
        case "confirm":
        case "confirm-danger":
          return "mdi-help-circle";
        default:
          return "mdi-information";
      }
    },
    btnCancelTextC() {
      return this.btnCancelText || this.type.includes("confirm")
        ? "Cancelar"
        : "Aceptar";
    },
    btnOkTextC() {
      return this.btnOkText || this.type.includes("confirm") ? "Aceptar" : "";
    },
  },
};
</script>
