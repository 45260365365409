<template>
    <v-dialog v-model="show" max-width="800px">
        <v-card>
            <v-card-title class="text-h6">
                Agregando Ejemplares del Producto "{{ itemToReturn.ItemName }}" a la Factura
            </v-card-title>
            <v-form v-model="valid" ref="formQuantity">
                <v-card-text class="mt-8 mb-8">
                    <v-row class="d-flex justify-center">
                        <v-col cols="12" md="4" lg="4">
                            <v-text-field ref="quantityInputField"
                                v-model="quantity"
                                label="Cantidad"
                                @keypress.enter="submit"
                                :rules="required"
                            >
                            </v-text-field>   
                        </v-col>    
                        <v-col v-if="false" cols="12" md="4" lg="4">
                            <v-text-field
                                v-model="priceResult"
                                label="Precio Total"
                                :readonly="true"
                                :disabled="true"
                            >
                            </v-text-field>   
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-form>
            <v-card-actions class="d-flex justify-space-between">
                <v-btn class="cancel" @click="cancel">
                    Cancelar
                </v-btn>
                <v-btn class="btn-add" @click="submit">
                    Continuar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { numberWithCommas } from '../../helpers/money';

    export default {
        name: 'QuantityDialog',
        props: {
            itemToReturn: {
                type: Object,
                default: null
            },
            show: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                valid: false,
                quantity: 0,
                currency: JSON.parse(localStorage.getItem("branch")).Currency,
                required: [(v) => !!v || "Este campo es requerido"]
            }
        },
        mounted() {
            this.$refs.quantityInputField.focus();
        },
        computed: {
            priceResult() {
                return `${ this.currency }${ numberWithCommas( this.itemToReturn.ItemOriginalPrice * this.quantity ) }`;
            }
        },
        methods: {
            cancel() {
                this.$emit( 'cancel' );
            },
            submit() {
                this.$refs.formQuantity.validate();
                if( this.valid ) {
                    this.$emit( 'submit', { ...this.itemToReturn, qty: this.quantity } );
                }
            }
        }
    }
</script>
<style scoped>
    .cancel{
        background-color: #41464c !important;
        color: white;
    }
</style>